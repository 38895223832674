import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { seoSelector } from '../../selectors/page';
import {
  hasNotificationStatusSelector,
  hasNotificationHeightSelector
} from '../../selectors/navigation';

import DefaultLayout from '../../components/Layouts/DefaultLayout';

const mapStateToProps = (state, props) => ({
  facebookUrl: state.app.settings?.facebookUrl,
  instagramUrl: state.app.settings?.instagramUrl,
  twitterUrl: state.app.settings?.twitterUrl,
  youtubeUrl: state.app.settings?.youtubeUrl,
  footerNav: state.navigation.footer,
  customerService: state.navigation.customerService,
  seo: seoSelector(state, props),
  notificationStatus: hasNotificationStatusSelector(state),
  notificationHeight: hasNotificationHeightSelector(state)
});

export default withRouter(connect(mapStateToProps)(DefaultLayout));
