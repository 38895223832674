import React from 'react';
import PropTypes from 'prop-types';
import { currencyFormatSek } from '../../../lib/currencyFormatSek';
import Image from '../../../components/Image';
import { Button } from '../../../components/Buttons';
import { Checkbox } from '../../../components/Form/FormItem/FormItems';
import { formatQuantity } from '../lib';
import { Link } from 'react-router-dom';
import DefaultIcon from '../../../components/assets/varor.svg';

import './RecipeIngredients.scss';
import { capitalizeWord } from '../../../lib/utils';

export const UnavailableRecipeIngredient = ({ name }) => (
  <div className="c-recipe-ingredients__row">
    <div className="table-column-xs-sm-4-24 table-column-md-lg-3-24 u-text-center">
      <div
        className="c-recipe-ingredients__image"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Image
          noLazy={true}
          src={DefaultIcon}
          alt=""
          className="img-responsive"
        />
      </div>
    </div>
    <div className="c-recipe-ingredients__title table-column-xs-sm-11-24 table-column-md-lg-7-24">
      {name}
      <div className="brand-package-quantity"></div>
    </div>
    <div className="c-recipe-ingredients__cost-per-unit table-column-md-lg-3-24 u-text-right u-screen-sm-hide u-screen-xs-hide"></div>
    <div className="c-recipe-ingredients__item-quantity table-column-md-lg-3-24 u-text-right u-screen-sm-hide u-screen-xs-hide"></div>
    <div className="c-recipe-ingredients__item-cost table-column-xs-sm-5-24 table-column-md-lg-4-24 u-text-right">
      {' '}
      <span className="u-screen-md-hide u-screen-lg-hide">
        <br />
      </span>
    </div>
    <div className="c-recipe-ingredients__checkbox table-column-xs-sm-3-24 table-column-md-lg-2-24"></div>
  </div>
);

const RecipeIngredient = ({
  articleName,
  articleNumber,
  brand,
  image,
  isSelected,
  url,
  pricePerUnit,
  priceTotal,
  descriptiveSize,
  quantity,
  onCheckboxChange,
  countryOfOrigin
}) => {
  return (
    <div className="c-recipe-ingredients__row">
      <Link
        className="table-column-xs-sm-4-24 table-column-md-lg-3-24 u-text-center"
        to={{
          pathname: url,
          state: { flyout: true, title: ' ', nopadding: true, tight: false }
        }}
        // onClick={operationOnProductClick}
        itemProp="url"
      >
        <div className="c-recipe-ingredients__image">
          {image && (
            <Image
              noLazy={true}
              src={image + '?w=70&h=70'}
              alt=""
              className="img-responsive"
            />
          )}
        </div>
      </Link>
      <Link
        className="c-recipe-ingredients__title table-column-xs-sm-11-24 table-column-md-lg-7-24"
        to={{
          pathname: url,
          state: { flyout: true, title: ' ', nopadding: true, tight: false }
        }}
        // onClick={operationOnProductClick}
      >
        {articleName}
        <div className="brand-package-quantity">
          {brand && (brand !== '.' || brand !== 'X') && `${brand},`}{' '}
          {countryOfOrigin && `${capitalizeWord(countryOfOrigin)},`}{' '}
          {descriptiveSize}
        </div>
      </Link>
      <div className="c-recipe-ingredients__cost-per-unit table-column-md-lg-3-24 u-text-right u-screen-sm-hide u-screen-xs-hide">
        {currencyFormatSek(pricePerUnit)}
      </div>
      <div className="c-recipe-ingredients__item-quantity table-column-md-lg-3-24 u-text-right u-screen-sm-hide u-screen-xs-hide">
        {formatQuantity(quantity)}
      </div>
      <div className="c-recipe-ingredients__item-cost table-column-xs-sm-5-24 table-column-md-lg-4-24 u-text-right">
        {' '}
        <span className="u-screen-md-hide u-screen-lg-hide">
          {formatQuantity(quantity)}
          <br />
        </span>
        {currencyFormatSek(priceTotal)}
      </div>
      <div className="c-recipe-ingredients__checkbox table-column-xs-sm-3-24 table-column-md-lg-2-24">
        <Checkbox
          id={articleNumber}
          onChange={onCheckboxChange}
          isChecked={isSelected}
        />
      </div>
    </div>
  );
};

RecipeIngredient.propTypes = {
  articleName: PropTypes.string.isRequired,
  articleNumber: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  image: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  pricePerUnit: PropTypes.number.isRequired,
  priceTotal: PropTypes.number.isRequired,
  recipeQuantity: PropTypes.object.isRequired,
  quantity: PropTypes.object.isRequired,
  onCheckboxChange: PropTypes.func.isRequired
};

const RecipeIngredients = ({
  addToCart,
  adding,
  ingredients,
  onCheckboxChange,
  selectedIngredients,
  unavailableArticles,
  selectedAmount,
  selectedTotalSum,
  success
}) => {
  return (
    <div className="c-recipe-ingredients u-bg-color-white">
      <div className="c-recipe-ingredients__row c-recipe-ingredients__row--no-divider">
        <h3 className="table-column-xs-sm-24-24 table-column-md-lg-24-24">
          Varor från ingredienslistan
        </h3>
      </div>
      <div className="c-recipe-ingredients__row c-recipe-ingredients__row--no-divider c-recipe-ingredients__row--header u-screen-sm-hide u-screen-xs-hide">
        <div className={'table-column-md-lg-10-24'}>&nbsp;</div>
        <div className={'table-column-md-lg-3-24 u-text-right'}>Pris/st</div>
        <div className={'table-column-md-lg-3-24 u-text-right'}>Antal</div>
        <div className={'table-column-md-lg-4-24 u-text-right'}>Pris</div>
        <div className={'table-column-md-lg-2-24'}>&nbsp;</div>
      </div>
      {ingredients.map(
        ({
          articleName,
          articleNumber,
          brand,
          descriptiveSize,
          image,
          url,
          pricePerUnit,
          priceTotal,
          recipeQuantity,
          quantity,
          countryOfOrigin
        }) => (
          <RecipeIngredient
            key={articleNumber}
            articleNumber={articleNumber}
            articleName={articleName}
            brand={brand}
            descriptiveSize={descriptiveSize}
            image={image}
            isSelected={Boolean(
              selectedIngredients.find(
                article => article.articleNumber === articleNumber
              )
            )}
            url={url}
            pricePerUnit={pricePerUnit}
            priceTotal={priceTotal}
            recipeQuantity={recipeQuantity}
            quantity={quantity}
            onCheckboxChange={onCheckboxChange}
            countryOfOrigin={countryOfOrigin}
          />
        )
      )}
      {unavailableArticles.map(({ name }) => (
        <UnavailableRecipeIngredient name={name} />
      ))}
      <div className="c-recipe-ingredients__row c-recipe-ingredients__row--no-divider">
        <div className="table-column-xs-sm-24-24 table-column-md-lg-24-24 u-text-right">
          <div className="c-recipe-ingredients__total">
            <span className="u-font-size-sm">
              {selectedAmount} stycken varor
            </span>
            <span className="u-mt-10 u-mb-10">
              <span className="u-font-size-base u-mr-5">Total </span>
              <span className="u-font-size-large u-bold">
                {currencyFormatSek(selectedTotalSum)}
              </span>
            </span>
            <div className="button-wrapper mt-5">
              {unavailableArticles.length > 0 && (
                <p>
                  <b>Detta recept är inte komplett!</b>
                </p>
              )}
              <Button
                buttonType="primary"
                onClick={addToCart}
                disabled={selectedIngredients.length <= 0}
                primary
                minWidth={190}
                loading={adding}
                success={success}
              >
                Lägg i varukorgen
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RecipeIngredients.propTypes = {
  ingredients: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  selectedAmount: PropTypes.number.isRequired,
  selectedTotalSum: PropTypes.number.isRequired
};

export default RecipeIngredients;
