export const extractProductIds = ingredients => {
  let productIds = [];
  ingredients.map(ingredient => {
    ingredient.physicalArticles.length &&
      ingredient.purchaseType === 'City Gross' &&
      productIds.push(ingredient.physicalArticles[0].articleNumber);
  });
  return productIds;
};

export const formatQuantity = quantity => {
  quantity.unit === 'KG'
    ? (quantity.value = quantity.value * 1000) && (quantity.unit = 'g')
    : (quantity.unit = quantity.unit.toLowerCase());
  return `${quantity.value} ${quantity.unit}`;
};

export const collectArticleLineAmounts = (ingredients, selectedProducts) => {
  const arrayOfPrices = [];
  for (let index = 0; selectedProducts.length > index; index++) {
    for (let j = 0; ingredients.length > j; j++) {
      if (
        ingredients[j].physicalArticles.length > 0 &&
        selectedProducts[index] ===
          ingredients[j].physicalArticles[0].articleNumber
      ) {
        arrayOfPrices.push(ingredients[j].totalRecipeLineAmount);
      }
    }
  }

  return arrayOfPrices;
};

export const sumPrices = arrayOfPrices =>
  arrayOfPrices.reduce((total, num) => parseFloat(total) + parseFloat(num), 0);
