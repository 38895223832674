import React, { Component } from 'react';
import { identity } from '../../lib/utils';

class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { componentName } = this.props;
    console.error(error, info);
    console.error(error, info.componentStack);
  }

  render() {
    const { componentName } = this.props;

    return this.state.hasError ? (
      <div style={{ textAlign: 'center', fontSize: '1.5em' }}>
        <strong>
          Error: Component {`'${componentName}'`} could not render. Please check
          for errors.
        </strong>
      </div>
    ) : (
      this.props.children
    );
  }
}

ComponentErrorBoundary.defaultProps = {
  reportError: identity
};

export default ComponentErrorBoundary;
