const defaultQuantity = {
  value: 0,
  unit: 'Piece',
  originalUnit: 'Piece'
};

export default {
  make: (gtin, quantity = {}) => {
    const q = { ...defaultQuantity, ...quantity };
    return {
      gtin,
      quantity: q
    };
  },

  incrementQuantity: (cartItem, value) => ({
    ...cartItem,
    quantity: {
      ...cartItem.quantity,
      value
    }
  }),

  decrementQuantity: (cartItem, value) => ({
    ...cartItem,
    quantity: {
      ...cartItem.quantity,
      value: Math.max(value, 0)
    }
  }),

  findOrDefault: (items, id) =>
    items.find(i => i.id === id) || { quantity: defaultQuantity },
  sync: (truth, item) => {
    const quantityValue =
      truth.quantity.value !== item.quantity.value
        ? truth.quantity.value
        : item.quantity.value;

    return {
      ...item,
      ...truth,
      quantity: {
        ...item.quantity,
        ...truth.quantity,
        value: quantityValue
      }
    };
  }
};
