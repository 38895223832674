import React from 'react';

import './ProductTableView.scss';

const ProductTableView: React.FC = ({ children }) => {
  return (
    <div className="c-producttable clear">
      <div className="c-producttable__labels table-column-md-lg-24-24">
        <div className="c-producttable__label meta table-column-md-lg-12-24" />
        <div className="c-producttable__label unitprice u-text-right table-column-md-lg-7-24">
          Pris/st
        </div>
        <div className="c-producttable__label qty table-column-md-lg-5-24 u-text-right">
          &nbsp;
        </div>
      </div>

      <div className="c-producttable__items table-column-md-lg-24-24">
        {children}
      </div>
    </div>
  );
};

export default ProductTableView;
