import { RECIPE_FIELDS } from './recipeCartItem';

export const determineBase = variants => {
  const DEFAULT_FIELDS = RECIPE_FIELDS.reduce(
    (obj, k) => ({ ...obj, [k]: false }),
    {}
  );
  return Object.keys(DEFAULT_FIELDS).reduce((acc, key) => {
    return {
      ...acc,
      [key]: variants.every(variant => variant[key])
    };
  }, {});
};

const splitByLi = str => {
  const LI_REGEX = /<li[^>]*>([\s\S]*?)<\/li>/gi;
  const li_matches = [];
  let lm;
  while ((lm = LI_REGEX.exec(str)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (lm.index === LI_REGEX.lastIndex) {
      LI_REGEX.lastIndex++;
    }

    lm.forEach((match, groupIndex) => {
      if (groupIndex > 0) {
        const line = sanitizeLine(match);
        if (line && line.length > 0) {
          li_matches.push(line);
        }
      }
    });
  }
  return li_matches;
};

const splitByOUl = str => {
  let oul_matches = [];
  oul_matches = str.split(/<\/[ou]l>/i);
  oul_matches = oul_matches.map(match => {
    return match
      .replace(/<[ou]l>/i, '')
      .replace(/\s\s+/g, ' ')
      .trim();
  });

  const result = oul_matches
    .map(group => {
      const HEAD_REGEX = /<p>(.*)<\/p>.*/gi;
      const headline = HEAD_REGEX.exec(group);
      const sanitizedGroup = {
        steps: splitByLi(group)
      };
      if (headline && headline[1]) {
        sanitizedGroup.headline = headline[1].replace(':', '');
      }
      return sanitizedGroup;
    })
    .filter(group => group.steps.length > 0);

  return result;
};

const sanitizeLine = str =>
  str
    .replace(/&nbsp;/g, ' ')
    .replace(/<[^>]*>/g, '') // remove remaining html
    .replace(/\s\s+/g, ' ') // remove linebreaks and stuff
    .trim();

export const recipeHtmlInstructionsToArray = str => {
  if (!str || str.length <= 0) {
    return null;
  }
  try {
    let result = [];
    // We have a potential group of lists
    if (
      str.toLowerCase().includes('<ol>') ||
      str.toLowerCase().includes('<ul>')
    ) {
      const strGroups = splitByOUl(str);
      return strGroups;
    } else if (str.toLowerCase().includes('<li>')) {
      // If string contains an <li> element, we will try to parse it as an list of <li> elements
      const steps = splitByLi(str);
      if (steps && steps.length > 0) {
        return [
          {
            steps: steps
          }
        ];
      }
      // If not a list of <li> we will try to parse the lines as separated by <br /> and a number instead.
    } else if (
      str.toLowerCase().includes('<br />') ||
      str.toLowerCase().includes('<br/>')
    ) {
      result = str
        .replace(/<(?:.|\n)p?>/gi, '') // remove all <p> and </p>
        .split(/<br\s?\/?>\s?\d+[.]/gi) // split on <br> or <br/> or <br />
        .map((line, index) => {
          let sanitizedLine = sanitizeLine(line);
          if (index === 0) {
            if (sanitizedLine.substr(0, 2) === '1.') {
              sanitizedLine = sanitizedLine.replace('1.', '').trim();
            }
          }
          return sanitizedLine;
        });
    }

    const linesWithoutEmpty = result.filter(line => line && line.length > 0); // remove possible blank lines

    if (linesWithoutEmpty && linesWithoutEmpty.length > 0) {
      return [
        {
          steps: linesWithoutEmpty
        }
      ];
    }
  } catch (err) {
    console.log('error', err);
    return null;
  }
  return null;
};

export const MinutesToIsoDuration = minutes => {
  const days = Math.floor(minutes / 1440);
  minutes = minutes - days * 1440;
  const hours = Math.floor(minutes / 60);
  minutes = minutes - hours * 60;

  let dur = 'PT';
  if (days > 0) {
    dur += days + 'D';
  }
  if (hours > 0) {
    dur += hours + 'H';
  }
  dur += minutes + 'M';

  return dur;
};

export const recipeMarkings = [
  {
    label: 'Veganskt',
    value: 'vegan'
  },
  {
    label: 'Vegetariskt',
    value: 'vegetarian'
  },
  {
    label: 'Laktosfritt',
    value: 'lactoseFree'
  },
  {
    label: 'Glutenfritt',
    value: 'glutenFree'
  },
  {
    label: 'Ekologiskt',
    value: 'organic'
  }
];

export const translateRecipeMarkings = value => {
  const marking = recipeMarkings.find(marking => marking.value === value);
  return marking ? marking.label : '';
};
