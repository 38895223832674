import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageIdSelector, pageSelector } from '../../selectors/page';
import { getPageById } from '../../actions/page';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import LoadingBlock from '../../components/LoadingBlock';
import ResponsiveImage from '../../contentblocks/ResponsiveImage';
import RichText from '../../containers/RichText';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import ErrorInfo from '../../components/ErrorInfo';

class ContentForCategoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: !props.page || !props.page.id,
      error: false
    };
  }

  componentDidMount() {
    const { getPageById, page, id } = this.props;
    if (page.id !== id) {
      getPageById(id)
        .catch(err => {
          console.error('getPageById failed', err);
          this.setState({
            error: true
          });
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    }
  }

  render() {
    const { page = {}, title } = this.props;
    const { loading, error } = this.state;

    if (error) {
      return (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
        />
      );
    }

    if (loading) {
      return <LoadingBlock />;
    }

    const {
      mainImage,
      showBreadCrumbs,
      mobileImage,
      headline,
      mainBody,
      contentBlocks
    } = page;

    return (
      <div className="c-content-category">
        {showBreadCrumbs && <PageTitle {...title} />}
        {mainImage && (
          <ResponsiveImage
            desktopImage={mainImage}
            mobileImage={mobileImage}
            caption={headline}
            blockOptions={{
              useFlushContainer: true
            }}
          />
        )}
        {mainBody && (
          <div className="c-content-category__body">
            {!mainImage && <h2>{headline}</h2>}
            {mainBody && <RichText text={mainBody} />}
          </div>
        )}
        <ContentBlocksArea blocks={contentBlocks} />
      </div>
    );
  }
}

ContentForCategoryPage.required = [
  (state, params, noCache) => getPageById(params.id, null, noCache)
];

const mapStateToProps = (state, props) => ({
  title: breadCrumbSelector(state, props),
  page: pageSelector(state, props),
  id: pageIdSelector(state, props)
});

const mapDispatchToProps = {
  getPageById: getPageById
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContentForCategoryPage)
);
