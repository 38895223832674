import { authenticatedRequest, request } from '../../lib/request';
import { graphQlQuery } from '../../lib/utils';
import queryParams from '../../lib/queryParams';
import {
  Site,
  HomeDeliverySortimentOptions,
  DeliveryMethods
} from '../../types/assortments';
import { StoreNumberResponse } from '../../types/delivery/storeNumberResponse';

interface SlotAvailabilityBody {
  deliveryTypeId: 'Home' | 'Pickup';
  siteId?: number;
  zipCode?: string;
  cartId: string;
  isConvertedStore?: boolean;
}

interface SlotAvailabilityRequest {
  data: SlotAvailabilityMap;
  errors: any;
}

interface SlotAvailabilityMap {
  [key: string]: SlotAvailability;
}

interface SlotAvailability {
  hasDelivery: boolean;
  isEnabled: boolean;
  id: number;
  deliveryGroupNo: string;
  deliveryMethod: number;
  deliveryDate: string;
  deliveryFromTime: string;
  deliveryToTime: string;
  lastReservationDateWithGroceryBag: string;
  lastReservationDateWithoutGroceryBag: string;
  maxReservations: number;
  reservations: number;
  priceWithGroceryBag: number | null;
  priceWithoutGroceryBag: number | null;
}

export const fetchDeliveryDates = ({
  deliveryTypeId,
  siteId,
  zipCode,
  cartId,
  isConvertedStore
}: SlotAvailabilityBody): Promise<SlotAvailabilityRequest> =>
  authenticatedRequest('/slotavailability', {
    method: 'POST',
    body: JSON.stringify({
      deliveryTypeId,
      siteId,
      zipCode,
      cartId,
      isConvertedStore
    })
  });

interface DeliverySlotsByDateBody {
  deliveryMethod: 'Home' | 'Pickup' | string;
  fromDate: string;
  toDate: string;
  zip?: number;
  site?: number;
}

export interface DeliverySlot {
  enabled: boolean;
  id: number;
  deliveryGroupNo: string;
  deliveryMethod: number;
  deliveryDate: string;
  deliveryFromTime: string;
  deliveryToTime: string;
  lastReservationDateWithGroceryBag: string;
  lastReservationDateWithoutGroceryBag: string;
  maxReservations: number;
  reservations: number;
  priceWithGroceryBag: number | null;
  priceWithoutGroceryBag: number | null;
}

interface DeliverySlotsRequest {
  data: {
    deliverySlots: DeliverySlot[];
  };
}

export const fetchDeliverySlotsByDate = ({
  deliveryMethod,
  fromDate,
  toDate,
  zip,
  site
}: DeliverySlotsByDateBody): Promise<DeliverySlotsRequest> => {
  if (site) {
    return request(
      `/DeliverySlots?DeliveryTypeId=${deliveryMethod}&From=${fromDate}&To=${toDate}&SiteId=${site}`
    );
  } else if (zip) {
    return request(
      `/DeliverySlots?DeliveryTypeId=${deliveryMethod}&From=${fromDate}&To=${toDate}&ZipCode=${zip}`
    );
  }

  return Promise.reject();
};

export const checkZipCode = (zip: string) =>
  request(`/checkzipcode?zipcode=${zip}`);

interface SiteResponse {
  data: {
    sites: Site[];
  };
}

export const fetchSites = (query: any): Promise<SiteResponse> =>
  request(`/sites?${queryParams(query)}`);

interface HomeDeliveryStoreNumberResponse {
  data: HomeDeliverySortimentOptions;
  provider: string;
}

export const fetchStoreNumberByZipCode = (
  zipCode: string | number
): Promise<HomeDeliveryStoreNumberResponse> =>
  request(`/zipCodes/${zipCode}/storeNumber`);

export const fetchStoreNumberBySiteId = (
  siteId: string | number
): Promise<StoreNumberResponse> => request(`/sites/${siteId}/storeNumber`);

interface CurrentStoreResponse {
  data: {
    storeNumber: string;
    provider: string;
  };
}

export const fetchCurrentStoreMeta = (
  siteId: string | number
): Promise<CurrentStoreResponse> => request(`/sites/${siteId}`);

interface DeliveryCityResponse {
  city: string;
  zipCodes: string[];
}

export const fetchDeliveryZipCodesForCity = (
  url: string
): Promise<DeliveryCityResponse> => request(`/districts/cities/${url}`);

export const fetchDeliveryCities = () => request('/districts/cities');

interface RecommendedStore {
  zip: string;
  store: number;
  weight: number;
}

interface RecommendedStoreResponse {
  data: RecommendedStore[];
}

export const fetchClosestStoreByZip = (
  activePostalCode?: string
): Promise<RecommendedStoreResponse> =>
  request(
    `/postalCode/GetRecommendedStoresByZip?zip=${activePostalCode}&count=5`
  );
export const fetchPostalcodeByZip = (
  activePostalCode?: string
): Promise<RecommendedStoreResponse> =>
  request(`/postalCode/GetPostalCodeByZip?zip=${activePostalCode}`);
