import React, { Component } from 'react';
import DropArrow from '../DropArrow';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Select.scss';

export class Select extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.defaultValue || '' };
  }

  componentDidUpdate(prevProps) {
    prevProps.defaultValue !== this.props.defaultValue &&
      this.setState({ value: this.props.defaultValue || '' });
  }

  handleChange = e => {
    const value = e.target.value;

    this.setState({ value });
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const {
      options = [],
      placeholder,
      defaultValue,
      selected,
      fullwidth,
      style,
      disabled,
      highlight
    } = this.props;
    const { value } = this.state;

    const defaultOptions = options.map(({ value, label }, key) => (
      <option value={value} key={key}>
        {label}
      </option>
    ));

    const opts = placeholder
      ? [
          <option value={placeholder} key={placeholder}>
            {placeholder}
          </option>
        ].concat(defaultOptions)
      : defaultOptions;

      return (
      <div
        className={classNames('c-stdselect', {
          'c-stdselect--fullwidth': fullwidth,
          'disabled': disabled,
          'c-stdselect--highlight': highlight
        })}
        style={style}
      >
        <select
          selected={selected}
          value={defaultValue || value}
          onChange={this.handleChange}
          disabled={disabled}
        >
          {opts}
        </select>
        <DropArrow />
      </div>
    );
  }
}

Select.defaultProps = {
  selected: false,
  fullwidth: false,
  disabled: false
};

Select.propTypes = {
  defaultValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  selectLabel: PropTypes.string,
  style: PropTypes.object,
  selected: PropTypes.bool,
  fullwidth: PropTypes.bool,
  disabled: PropTypes.bool
};

export default Select;
