import React, { useEffect, useRef, useState } from 'react';
import * as styles from '../SideModalDeliveryChoicePicker.styles';
import {
  Spacer,
  RadioBoxItem as RadioBox,
  SaveButton,
  Input,
  FormGroup
} from '@citygross/components';
// eslint-disable import/named
// eslint-disable-next-line import/namespace
import { Icons } from '@citygross/icons';
import { theme } from '@citygross/design-tokens';
import { BodyText, TextTypes } from '@citygross/typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveStore,
  selectSaveDeliverySettingsLoading,
  setActiveDeliveryMethodAndStore
} from '../../../../slices/deliveryPickerSlice';
import { AppState } from '../../../../reducers';
import { StoreMeta } from '../../../../types/assortments';
import { DeliveryMethods } from '../SideModalDeliveryChoicePicker';
import { closeSideModal } from '../../../../slices/sideModalSlice';

interface IChooseStoreStepTabsProps {
  stores: StoreMeta[] | null;
  handleSaveNewDeliverySettings: () => void;
  savedNewStore?: boolean;
  clientHeight?: string;
}

const ChooseStoreStepTabs: React.FC<IChooseStoreStepTabsProps> = ({
  stores,
  handleSaveNewDeliverySettings,
  savedNewStore,
  clientHeight
}) => {
  const dispatch = useDispatch();

  const activeStore = useSelector(selectActiveStore);
  const saveDeliverySettingsLoading = useSelector(
    selectSaveDeliverySettingsLoading
  );

  const tabs = {
    STORES_LIST: 'storesList'
    // STORES_MAP: 'map'
  };

  const [activeTab, setActiveTab] = useState(tabs.STORES_LIST);
  {
    /* @TODO: show when we have map ready */
  }

  // const [fade, setShowFade] = useState(false);

  // const onScroll = (e: EventTarget & HTMLElement) => {
  //   if (e.scrollWidth - e.scrollLeft >= e.clientWidth + 50) {
  //     setShowFade(true);
  //   } else {
  //     setShowFade(false);
  //   }
  // };

  return (
    <>
      {/* @TODO: show when we have map ready */}
      {/* <Menu
        showFade={fade}
        onScroll={e => {
          onScroll(e.currentTarget);
        }}
      >
        {Object.values(tabs).map(tab => (
          <MenuItem
            key={tab}
            active={activeTab === tab}
            onClick={() => setActiveTab(tab)}
            borderColor={
              activeTab === tab ? theme.palette?.alertBlue : 'transparent'
            }
            fontWeight={
              activeTab === tab
                ? theme.typography?.weight.semiBold
                : theme.typography?.weight.regular
            }
            minWidth={68}
            textAlign={'center'}
            style={{ margin: 0 }}
          >
            {tab === tabs.STORES_LIST ? 'Lista' : 'Karta'}
          </MenuItem>
        ))}
      </Menu> */}
      <Spacer />
      {activeTab === tabs.STORES_LIST ? (
        <styles.ChooseStoreStepStoresListWrapper maxHeight={clientHeight}>
          {stores &&
            stores
              ?.filter(store => store.storeNumber)
              ?.map(store => (
                <div style={{ width: '100%' }} key={store.id}>
                  <RadioBox
                    name={'pickupAtStore'}
                    checked={activeStore?.id === store.id}
                    setActive={() => {
                      dispatch(
                        setActiveDeliveryMethodAndStore({
                          activeDeliveryMethod: DeliveryMethods.PICKUP,
                          activeStore: store
                        })
                      );
                    }}
                    padding={16}
                    hideRadioButton
                    isDisabled={saveDeliverySettingsLoading}
                  >
                    <div style={{ width: '100%' }}>
                      <BodyText size={TextTypes.TextSize.SMALL}>
                        {store.name}
                      </BodyText>
                      <BodyText
                        size={TextTypes.TextSize.SMALL}
                        color={theme.palette?.darker}
                      >
                        {`${store.streetAddress}, ${store.zipcode} ${store.city}`}
                      </BodyText>
                    </div>
                  </RadioBox>
                </div>
              ))}
        </styles.ChooseStoreStepStoresListWrapper>
      ) : null}
      <styles.DeliverySaveButtonContainer>
        <SaveButton
          onSavedColor={theme.palette?.alertGreen}
          fullWidth
          onClick={handleSaveNewDeliverySettings}
          afterSaveText="Sparat"
          beforeSaveText="Spara"
          onAnimationComplete={() => dispatch(closeSideModal())}
          icon={<Icons.CheckMark width={16} height={16} color={'white'} />}
          saved={savedNewStore}
          loading={saveDeliverySettingsLoading && !savedNewStore}
          isDisabled={!activeStore}
        />
      </styles.DeliverySaveButtonContainer>
    </>
  );
};

const ChooseStoreStep = ({
  handleSaveNewDeliverySettings,
  savedNewStore,
  clientHeight,
  ...props
}) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const stores = useSelector((state: AppState) => state.assortments.stores);

  let storesList =
    stores &&
    [...stores]?.sort((a, b) =>
      a.name.localeCompare(b.name, 'sv', { sensitivity: 'case' })
    );

  if (storesList && storesList.length && searchInput.length)
    storesList = storesList.filter(store => {
      const storeAddress = `${store.streetAddress}, ${store.zipcode} ${store.city}`;
      return `${store.name} ${storeAddress} ${store?.populated?.searchKeyWords}`
        .toLowerCase()
        .includes(searchInput.toLowerCase());
    });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  return (
    <div
      style={{
        height: '100%',
        overflow: 'hidden'
      }}
    >
      <FormGroup>
        <Input
          inputRef={inputRef}
          flexGrow={1}
          placeholder="Sök butik, stad eller postnummer"
          icon={
            <div className={'header-search__icon header-pointer__container'}>
              <Icons.Search width={24} height={24} />
            </div>
          }
          onChange={e => setSearchInput(e.currentTarget.value.toLowerCase())}
        />
      </FormGroup>
      <Spacer />
      <ChooseStoreStepTabs
        stores={storesList}
        handleSaveNewDeliverySettings={handleSaveNewDeliverySettings}
        savedNewStore={savedNewStore}
        clientHeight={clientHeight}
      />
    </div>
  );
};

export default ChooseStoreStep;
