import { domSafe } from 'dynamicdog-analytics';
import React from 'react';
import BrowserDetector from '../BrowserDetector/BrowserDetector';

interface IMasterContainer {
  isSearchToggled: boolean
}

const MasterContainer : React.FC<IMasterContainer> = ({ isSearchToggled, children }) => {
  const isIE = domSafe() && /MSIE|Trident/.test(navigator.userAgent);
  return (
    <div id="citygross" className={isSearchToggled ? ' search-focus' : ''}>
      {children}
      {isIE && <BrowserDetector />}
    </div>
  )
};

export default MasterContainer;
