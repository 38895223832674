import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import groupBy from 'lodash.groupby';

import {
  pageSelector,
  pageIdSelector,
  getPageByIdSelector
} from '../../selectors/page';
import { getPageByUrl } from '../../selectors/routing/routingSelector';

import { setMode } from '../../actions/shoppingOrder';
import { getPageById } from '../../actions/page';
import {
  getDeliveryCities,
  getDeliveryZipCodesForCity
} from '../../actions/deliveryCities';

import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';

import DeliveryDistrictList from '../../modules/DeliveryDistrictList';
import DeliveryDistrict from '../../modules/DeliveryDistrict';

class DeliveryDistrictListPage extends Component {
  state = {
    pageError: false
  };

  componentDidMount() {
    const { match } = this.props;

    if (match && match.params && match.params.city) {
      this.didMountCityPage();
    } else {
      this.didMountListPage();
    }
  }

  didMountCityPage = () => {
    const { setMode, getDeliveryZipCodesForCity, listPage, match } = this.props;

    setMode(true, false, UNTOGGLE_BEHAVIOUR.none);

    if (!listPage) {
      this.getPageContent();
    }

    getDeliveryZipCodesForCity(match.params.city);
  };

  getPageContent = () => {
    const { getPageById, listPageId } = this.props;

    this.setState({
      pageError: false
    });

    getPageById(listPageId).catch(err => {
      this.setState({
        pageError: true
      });
      console.error('getPageById failed', err);
    });
  };

  didMountListPage = () => {
    const { page, id, setMode, getDeliveryCities, cities } = this.props;

    setMode(true, false, UNTOGGLE_BEHAVIOUR.none);

    if (page.id !== id) {
      this.getListPageContent();
    }

    if (!cities) {
      getDeliveryCities();
    }
  };

  getListPageContent = () => {
    const { id, getPageById } = this.props;

    this.setState({
      pageError: false
    });

    getPageById(id).catch(err => {
      console.error('getPageById failed', err);
      this.setState({
        pageError: true
      });
    });
  };

  getCities = () => {
    const { cities } = this.props;
    if (!cities) {
      return;
    }

    return groupBy(cities, city => city.city.slice(0, 1).toLowerCase());
  };

  render() {
    const { page, match } = this.props;
    const { pageError } = this.state;

    if (match && match.params && match.params.city) {
      const { templateBlock = {}, zipCodes = {} } = this.props;

      return (
        <DeliveryDistrict
          city={zipCodes.city}
          templateBlock={templateBlock}
          zipCodes={zipCodes.zipCodes}
          pageError={pageError}
          getPageContent={this.getPageContent}
        />
      );
    } else {
      const cities = this.getCities();
      const { title, mainBody, image, topBlocks, bottomBlocks } = page;

      return (
        <DeliveryDistrictList
          cities={cities}
          title={title}
          mainBody={mainBody}
          image={image}
          topBlocks={topBlocks}
          bottomBlocks={bottomBlocks}
          pageError={pageError}
          getListPageContent={this.getListPageContent}
        />
      );
    }
  }
}

DeliveryDistrictListPage.required = [
  (state, props, noCache) => getPageById(props.pageId || props.id, null, noCache),
  (state, props) => {
    if (props.params.city) {
      return getDeliveryZipCodesForCity(props.params.city);
    } else {
      return getDeliveryCities();
    }
  }
];

function mapStateToProps(state, props) {
  if (props.match && props.match.params && props.match.params.city) {
    const listPageNavItem = getPageByUrl(state, '/leveransorter');
    const listPage = getPageByIdSelector(state, listPageNavItem.id);

    return {
      city: props.match.params.city,
      listPageId: listPageNavItem && listPageNavItem.id,
      templateBlock: listPage && listPage.templateBlock,
      zipCodes: state.deliveryCities.zipCodes
    };
  } else {
    return {
      page: pageSelector(state, props),
      cities: state.deliveryCities.cities,
      id: pageIdSelector(state, props)
    };
  }
}

const mapDispatchToProps = {
  setMode,
  getPageById,
  getDeliveryCities,
  getDeliveryZipCodesForCity
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeliveryDistrictListPage)
);
