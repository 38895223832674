import React from 'react';
import className from 'classnames';
import './FavoriteButton.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../reducers';
import { authenticatedSelector } from '../../../selectors/user';
import { redirectToLogin } from '../../../actions/app';

const RoundSpinner = require('../../assets/round-spinner.svg');

interface IProps {
  light?: boolean,
  slideToggle?: boolean,
  active?: boolean,
  disabled?: boolean,
  loading?: boolean,
  handleClick: Function
}

const FavoriteButton : React.FC<IProps> = ({
  light = true,
  slideToggle,
  active,
  disabled = false,
  loading = false,
  handleClick = () => {}
}) => {
  const dispatch = useDispatch()

  const authenticated = useSelector((state: AppState) =>
  authenticatedSelector(state)
  );
  
  return (
    <div
      className={className('c-favoritebtn', {
        active,
        light,
        slideToggle,
        disabled,
        loading
      })}
      onClick={e => {
        e.preventDefault();
        if (!loading) {
          authenticated ? 
          handleClick()
          :
          dispatch(redirectToLogin())
        }
      }}
    >
      {loading && (
        <img
          src={RoundSpinner}
          alt="Laddar"
          className="c-favoritebtn__spinner"
        />
      )}
    </div>
  );
};

export { FavoriteButton };

export default FavoriteButton;
