import React from 'react';
import {
  withFlyoutPortal,
  withCloseableOverlay
} from '../../components/Flyout/withFlyout';
import Flyout from '../../components/Flyout';
import Login from './Login';

const Component = withFlyoutPortal(withCloseableOverlay(Flyout));

export const LoginFlyout = ({ back, ...props }) => (
  <Component {...props} onClose={back}>
    <Login ownProps={props} loginSuccessCb={back} />
  </Component>
);
