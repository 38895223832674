import { Input } from '@citygross/components';
import React, { useEffect, useRef } from 'react';
import { fireCallbackOnEnter } from '../../lib/utils';

type TLoginInput = {
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  flexGrow?: number;
  width?: number | string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  name?: string;
  isValid?: boolean;
  icon?: any;
  iconOnClick?: () => void;
  onSubmit?: (e: any) => void;
};
const LoginInput = ({
  value,
  placeholder,
  disabled,
  onChange,
  type,
  name,
  isValid,
  loading,
  iconOnClick,
  icon,
  onSubmit
}: TLoginInput) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref && ref?.current && !loading) {
      ref?.current?.focus();
    }
  }, [ref?.current, loading]);

  return (
    <Input
      inputRef={ref}
      placeholder={placeholder}
      flexGrow={10}
      width={1}
      type={type}
      value={value}
      onChange={onChange}
      name={name}
      disabled={disabled}
      isValid={isValid}
      icon={icon}
      autoFocus
      iconOnClick={iconOnClick ? iconOnClick : undefined}
      onKeyDown={
        onSubmit &&
        fireCallbackOnEnter(e => {
          onSubmit && onSubmit(e);
        })
      }
    />
  );
};

export default LoginInput;
