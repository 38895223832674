export type CookieConsent = {
	preferences: boolean | null;
	statistics: boolean | null;
	marketing: boolean | null;
}

export const CookieCategories = {
	Necessary: 1,
	Preferences: 2,
	Statistics: 3,
	Marketing: 4,
	Unclassified: 5
}