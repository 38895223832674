import Cache from 'node-cache';

const defaults = {
  stdTTL: 60,
  checkperiod: 100
};

const serialize = (args = []) => args.map(JSON.stringify).join('');

export const makeCache = (config = {}) => new Cache({ ...defaults, ...config });

export const wrapRequest = (cache, requestFn, serializeFn = serialize) => (
  ...args
) => {
  if (!cache) {
    return requestFn.apply(null, args);
  }

  const key = serialize(args);
  const maybeCached = new Promise((resolve, reject) =>
    cache.get(key, (err, value) => (value ? resolve(value) : reject(err)))
  );

  return maybeCached.catch(err => {
    return requestFn.apply(null, args).then(resp => {
      cache.set(key, resp);
      return resp;
    });
  });
};
