import * as endpoints from '../../api/endpoints/recipe';
import qs from 'query-string';
import {
  isFetchingRecipe,
  isFetchingRecipes,
  isPaginatingRecipes,
  receiveRecipe,
  receiveRecipes,
  paginateRecipes,
  fetchRecipeError,
  fetchRecipesError
} from './sync';
import { isNumber } from '../../lib/number';
import { updateHistory } from '../../lib/utils';
import { domSafe } from '../../lib/document';
const whiteList = ['page', 'f.marking', 'f.cookingtime', 'Sort'];
// TODO: If filters works the same as products we should generalize this, currently in both recipes and products.
/* Kind of a toggle, if there's already an entry in the current query with the same
   value. We remove it otherwise we just add it with the rest */
const uniqueEntries = (currentFilter, val) => {
  const current = currentFilter.split(',');
  return current.includes(val)
    ? current.filter(v => v !== val)
    : [].concat(current, val);
};
/* Merges query parameters, array values are unique */
const mergeQuery = (next, { search }) => {
  const current = qs.parse(search, { arrayFormat: 'index' });
  const merged = Object.entries(next).reduce((acc, [key, val]) => {
    acc[key] = current[key]
      ? [...uniqueEntries(current[key], val)].join()
      : [].concat(val).join();

    return acc;
  }, {});

  return Object.assign({}, current, merged);
};

export const getRecipeById = recipeId => dispatch => {
  dispatch(isFetchingRecipe(true));

  return endpoints
    .fetchRecipeById(recipeId)
    .then(({ data }) => {
      dispatch(receiveRecipe(data));
      return data;
    })
    .catch(err => {
      dispatch(fetchRecipeError(err));

      return Promise.reject(err);
    });
};

const getRecipes = (
  endpoint,
  receiveFunc = receiveRecipes,
  isFetching = isFetchingRecipes
) => dispatch => {
  dispatch(isFetching(true));
  return endpoint()
    .then(({ data }) => {
      dispatch(receiveFunc(data));

      return data;
    })
    .catch(err => {
      dispatch(fetchRecipesError(err));

      return Promise.reject(err);
    });
};

export const getRecipesByCategory = (categoryId, params) => dispatch => {
  
  return dispatch(
    getRecipes(
      () => endpoints.fetchRecipesByCategory(categoryId, params),
      response => {
       
        if (params && isNumber(params.size)) {
          response.meta = {
            ...response.meta,
            pageIndex: params.page ?? Math.max(
              0,
              Math.floor(params.size / endpoints.DEFAULT_RECIPEPAGE_SIZE) - 1
            )
          };
        }
        if (domSafe()) {
          dispatch(updateHistory({...params, page: params?.page ? params.page +1 : 1}, location, whiteList));
        }
        return receiveRecipes(response);
      }
    )
  );
};

export const requestMoreRecipes = (
  id,
  params,
  location = {},
  store = null
) => dispatch => {
  const query = qs.parse(location.search);
  const queryP = Object.assign({}, query, params);
  
  const page = queryP?.page ? Number(queryP?.page) + 1 : 1
  dispatch(updateHistory({...queryP, page: page}, location, whiteList));

  return dispatch(
    getRecipes(
      () => endpoints.fetchRecipesByCategory(id, { ...queryP, store, page : page -1 }),
      paginateRecipes,
      isPaginatingRecipes
    )
  );
};

export const filterRecipes = (
  id,
  filters,
  location,
  params = null
) => dispatch => {
  const query = {
    ...mergeQuery(filters, location),
    page: 1
  };
  return dispatch(getRecipesByCategory(id, { ...query, ...params, page: 0 }));
};

export const sortRecipes = (
  id,
  params,
  location = {},
  store = null
) => dispatch => {
  const query = qs.parse(location.search);
  const queryP = Object.assign({}, query, params);

  dispatch(updateHistory({...queryP, page: 1}, location, whiteList));

  return dispatch(
    getRecipes(
      () => endpoints.fetchRecipesByCategory(id, { ...queryP, store , page: 0}),
      receiveRecipes
    )
  );
};
