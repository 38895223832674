import {
  SW_RELOADING_START,
  SW_UPDATE_ACTIVATED
} from '../../types/reducers/serviceWorker';
import makeReducer from '../makeReducer';

const initialState = {
  newWorkerActivated: false,
  reloading_window: false
};

const ACTION_HANDLERS = {
  [SW_UPDATE_ACTIVATED]: state => ({
    ...state,
    newWorkerActivated: true
  }),
  [SW_RELOADING_START]: state => ({
    ...state,
    reloading_window: true
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
