import React, { useEffect } from 'react';
import { useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import StoreSelect from '../../modules/StoreSelect';
import { getPageById } from '../../actions/page';
import { setMode } from '../../actions/shoppingOrder';
import { pageIdSelector, pageSelector } from '../../selectors/page';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';
import { AppState } from '../../reducers';

const StoreListPage : React.FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const pageId = useSelector((state: AppState) => pageIdSelector(state, { match }));
  const page = useSelector((state: AppState) => pageSelector(state, { match }));
  const stores = useSelector((state: AppState) => state.assortments.epiStores);


  useEffect(() => {
    dispatch(setMode(UNTOGGLE_BEHAVIOUR.none))
    dispatch(getPageById(pageId))
  }, [])

    return (
      <StoreSelect
        page={page}
        stores={stores}
      />
    );
}

export default StoreListPage;
