import React, { useEffect } from 'react';
import AccountHeader from '../../components/Account/AccountHeader';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import { TopSellersCategoryPage } from '../../types/episerver/TopSellersCategoryPage';
import { useDispatch, useSelector } from 'react-redux';
import { getPageById } from '../../actions/page';
import { pageIdSelector, pageSelector } from '../../selectors/page';
import RichText from '../../containers/RichText';
import { useRouteMatch } from 'react-router-dom';
import { AppState } from '../../reducers';

const TopSellersContainer: React.FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const pageId = useSelector(state => pageIdSelector(state, { match }));
  const topSellers: TopSellersCategoryPage | null = useSelector(
    (state: AppState) => pageSelector(state, { match })
  );

  useEffect(() => {
    dispatch(getPageById(pageId));
  }, []);

  return (
    <>
      <AccountHeader>
        {!!topSellers?.headline && <h1>{topSellers.headline}</h1>}
        <RichText text={topSellers?.text} />
      </AccountHeader>
      <ContentBlocksArea blocks={topSellers?.contentBlocks} />
    </>
  );
};

export default TopSellersContainer;
