import React from 'react';
import { Helmet } from 'react-helmet';

interface IProductOpenGraph {
    name: string,
    url: string,
    imageUrl: string,
    brand: string,
    category: string,
    price: string,
    id: string,
}

export const ProductOpenGraph: React.FC<IProductOpenGraph> = ({
    name,
    url,
    imageUrl,
    brand,
    category,
    price,
    id
}) => {
    return (
        <Helmet>
            <meta property="og:title" content={name} />
            <meta property="og:description" content={name} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={imageUrl} />
            <meta property="product:brand" content={brand} />
            <meta property="product:category" content={category} />
            <meta property="product:availability" content="in stock" />
            <meta property="product:condition" content="new" />
            <meta property="product:price:amount" content={price} />
            <meta property="product:price:currency" content="SEK" />
            <meta property="product:retailer_item_id" content={id} />
        </Helmet>
    )
}
