import React, { memo } from 'react';
import Teaser from '../../components/Teasers';
import BlockGrid, { BlockGridItem } from '../../components/BlockGrid';
import { PromotionType } from 'dynamicdog-analytics';
import { ITeaserGridProps } from '../../types/episerver/blocks/TeaserGrid';
import * as ga4 from '@citygross/analytics';

const TeaserGrid: React.FC<ITeaserGridProps> = ({ teasers, name }) => {
  try {
    const promotions: PromotionType[] = teasers.map((teaser, index) => ({
      id: teaser.id.toString(),
      name: teaser.name,
      creative: name,
      position: `puff_position_${index}`
    }));

    promotions.forEach(promo => {
      ga4.viewPromotion({
        items: [],
        promotion_id: promo.id,
        promotion_name: promo.name,
        creative_name: promo.creative,
        creative_slot: promo.position
      });
    });
  } catch (error) {
    console.error(error);
  }

  return (
    <div className="b-teaser-grid">
      <BlockGrid colsInSmall={2}>
        {teasers.map((teaser, index) => (
          <BlockGridItem key={teaser.id}>
            <Teaser
              {...teaser}
              onClick={() => {
                try {
                  ga4.selectPromotion({
                    items: [],
                    promotion_id: teaser.id.toString(),
                    promotion_name: teaser.name,
                    creative_name: name,
                    creative_slot: `puff_position_${index}`
                  });
                } catch (error) {
                  console.error(error);
                }
              }}
            />
          </BlockGridItem>
        ))}
      </BlockGrid>
    </div>
  );
};

export default memo(TeaserGrid);
