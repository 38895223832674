import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { productFavoritesSelector } from '../../selectors/product/productSelectors';
import { fetchProductsByIds } from '../../api/endpoints/product';
import ProductList from '../../containers/ProductList';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { determineAvailable } from '../../lib/product';
import * as ga4 from '@citygross/analytics';

const impressionListName = 'Favorite products';
class FavoriteProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getFavoriteProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.favoriteProducts !== this.props.favoriteProducts) {
      this.getFavoriteProducts();
    }
  }

  getFavoriteProducts() {
    const { favoriteProducts, storeId } = this.props;
    const productIds = favoriteProducts.map(favorite => favorite.itemNo);
    if (productIds.length > 0) {
      fetchProductsByIds(productIds, null, productIds.length).then(
        ({ data }) => {
          this.setState({
            products: data,
            loading: false
          });
          try {
            const ga4ImpressionItems = data.map((prod, index) => {
              const priceInfo =
                prod.prices?.find(x => x.storeNumber === this.props.storeId) ||
                prod.prices[0];

              const unitPrice = priceInfo.ordinaryPrice.price || 0;
              let quantity = 1;
              let discount = 0;

              if (priceInfo.hasPromotion) {
                const promo = priceInfo.promotions2[0];
                quantity = promo?.minQuantity || 1;
                discount =
                  quantity > 1
                    ? unitPrice * quantity - (promo.value || 0)
                    : unitPrice - (promo.value || 0);
              }

              return {
                item_id: prod.gtin,
                item_name: prod.name,
                item_brand: prod.brand || 'City Gross',
                item_category:
                  prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                  undefined,
                item_variant: prod.variant || undefined,

                item_list_name: impressionListName,
                item_list_id: impressionListName,
                index: index + 1,

                price: unitPrice,
                quantity: quantity,
                discount: discount
              };
            });

            ga4.viewItemList({
              items: ga4ImpressionItems,
              item_list_id: impressionListName,
              item_list_name: impressionListName
            });
          } catch (error) {
            console.error(error);
          }
        }
      );
    } else {
      this.setState({
        products: [],
        loading: false
      });
    }
  }

  render() {
    const { products, loading } = this.state;

    const availableProducts = products.filter(product =>
      determineAvailable(product.availability, this.props.storeId)
    );

    return loading || (products && products.length > 0) ? (
      <ProductList
        products={availableProducts}
        skeletons={12}
        fetching={loading}
        withoutPaginationAndFilter
        impressionListName={impressionListName}
      />
    ) : (
      <div className="c-account--empty">
        <p className="mb-10">
          Du har inte valt några favoriter bland våra matvaror än.
        </p>

        <Link className="c-cmdbtn primary" to="/matvaror">
          Bläddra produkter
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  favoriteProducts: productFavoritesSelector(state),
  storeId: selectCurrentAssortment(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteProducts);
