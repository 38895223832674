import React from 'react';
import RoundButton from '../../RoundButton';
import './CartButton.scss';

interface IProps {
  onClick: Function,
  theme?: 'primary' | 'light',
  small?: boolean,
}

export const CartButton : React.FC<IProps> = ({ onClick, theme, small }) => (
  <RoundButton
    className="c-cartbtn"
    theme={theme}
    small={small}
    onClick={onClick}
  />
);
