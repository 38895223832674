const NAME = 'recipe';

export const FETCH_RECIPE_REQUESTED = `${NAME}/RECIPE_REQUESTED`;
export const FETCH_RECIPE_RECEIVED = `${NAME}/RECIPE_RECEIVED`;
export const FETCH_RECIPE_FAILED = `${NAME}/RECIPE_FAILED`;

export const FETCH_RECIPES_REQUESTED = `${NAME}/RECIPES_REQUESTED`;
export const FETCH_RECIPES_RECEIVED = `${NAME}/RECIPES_RECEIVED`;
export const FETCH_RECIPES_FAILED = `${NAME}/RECIPES_FAILED`;

export const QUANTITY_INCREASED = `${NAME}/QUANTITY_INCREASED`;
export const QUANTITY_DECREASED = `${NAME}/QUANTITY_DECREASED`;

export const PAGINATE_RECIPES_REQUESTED = `${NAME}/PAGINATE_RECIPES_REQUESTED`;
export const PAGINATE_RECIPES_RECEIVED = `${NAME}/PAGINATE_RECIPES_RECEIVED`;

