import React from 'react';
import BaseBlock from './BaseBlock';
import ComponentErrorBoundary from '../Error/ComponentErrorBoundary';
import { IBaseBlockProps } from '../../types/episerver/baseblock';

// TODO : This would be the ideal way, however our build doesnt allow us to use generics like this
// Replace when we fixed the environment
// const withBaseBlock = <P extends object>(TargetComponent: React.ComponentType<P>) => {
//   const BaseBlockContainer : React.FC<P & IBaseBlockProps> = (
//     props
//   ) => (
//     <BaseBlock
//       id={props.id}
//       type={props.type}
//       name={props.name}
//       ispublished={props.ispublished}
//       blockOptions={props.blockOptions}
//     >
//       <ComponentErrorBoundary componentName={TargetComponent.name}>
//         <TargetComponent
//           {...props as P}
//         />
//       </ComponentErrorBoundary>
//     </BaseBlock>
//   );

//   return BaseBlockContainer;
// };

const withBaseBlock = (TargetComponent: any) => {
  const BaseBlockContainer: React.FC<IBaseBlockProps> = props => (
    <BaseBlock
      id={props.id}
      type={props.type}
      name={props.name}
      ispublished={props.ispublished}
      blockOptions={props.blockOptions || {}}
    >
      <ComponentErrorBoundary componentName={TargetComponent.name}>
        <TargetComponent {...props} />
      </ComponentErrorBoundary>
    </BaseBlock>
  );

  return BaseBlockContainer;
};

export default withBaseBlock;
