import { DeliveryMethods } from '../types/assortments';
import format from 'date-fns/format';
import svLocale from 'date-fns/locale/sv';
import { HomeDelivery } from '../types/order/HomeDelivery';
import { PickupAtStore } from '../types/order/PickupAtStore';
import { fetchStoreNumberByZipCode } from '../api/endpoints/delivery';

export const formatTimeSpan = (
  timeFrom: string | null,
  timeTo: string | null
) => (timeFrom && timeTo ? `${timeFrom} - ${timeTo}` : null);
export const formatDateMedium = (date: string | Date) =>
  `${format(new Date(date), 'cccc d/M', { locale: svLocale })}`;
export const formatDate = (date: string | Date) =>
  `${format(new Date(date), 'cccc d MMMM', { locale: svLocale })}`;
export const formatDateTime = (date: string | Date) =>
  `${format(new Date(date), 'cccc d/M, HH:mm', { locale: svLocale })}`;
export const dateAsFormat = (
  date: string | Date,
  formatStr: string = 'd MMMM yyyy'
) => `${format(new Date(date), formatStr, { locale: svLocale })}`;
export const formatAddress = ({ street, postalCode, city }: HomeDelivery) =>
  `${street}, ${postalCode} ${city}`;
export const formatPickup = ({
  storeName,
  street,
  postalCode,
  city
}: PickupAtStore) => `${storeName} ${street}, ${postalCode} ${city}`;

interface IProps {
  deliveryMethod: DeliveryMethods;
  homeDelivery?: HomeDelivery;
  pickupAtStore?: PickupAtStore;
}

export const prettyPrintFromMethod = ({
  deliveryMethod,
  homeDelivery,
  pickupAtStore
}: any | IProps) => {
  switch (deliveryMethod) {
    case 'homeDelivery':
      return ['Hemleverans', homeDelivery ? formatAddress(homeDelivery) : ''];
    case 'pickupAtStore':
      return [
        'Hämta i butik',
        pickupAtStore ? formatPickup(pickupAtStore) : ''
      ];
    default:
      throw new TypeError(`${deliveryMethod} is not supported`);
  }
};

export const prettyPrintDeliveryMethod = (type: DeliveryMethods): string => {
  switch (type) {
    case 'homeDelivery':
      return 'Hemleverans';
    case 'pickupAtStore':
      return 'Hämta i butik';
    default:
      return '';
  }
};

export const checkZipCode = zipCode => fetchStoreNumberByZipCode(zipCode);
