import React from 'react';
import { ToggleButton } from '../Buttons';

interface IFaqFilter {
  category: string,
  activeFilter: string,
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const FaqFilter = ({ category, activeFilter, onClick }: IFaqFilter) => {
  const isActive = category === activeFilter;
  return (
    <div className="c-faqmodule__filter">
      <ToggleButton fullwidth active={isActive} onClick={onClick}>
        {category}
      </ToggleButton>
    </div>
  );
};

export default FaqFilter;
