import React from 'react';
import ProductNutritionTableRows from './ProductNutritionTableRows';

const parseNutrients = (nutritionStatement: string) => {
  const rows = nutritionStatement.split(',');
  const rgx = /^(.*?)(\s\d+.*)$/;

  return rows
    .map(row => {
      const match = row.trim().match(rgx);
      if (match) {
        return { name: match[1].trim(), value: match[2].trim() };
      }
      return null;
    })
    .filter(Boolean);
};

const ProductNutritionTable = ({ nutritionStatement }) => {
  const nutrients = parseNutrients(nutritionStatement);

  return (
    <table className="c-productdetails__table">
      <tbody>
        {nutrients.map((nutrient, index) => (
          <ProductNutritionTableRows key={index} nutrient={nutrient} />
        ))}
      </tbody>
    </table>
  );
};

export default ProductNutritionTable;
