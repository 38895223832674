import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ProgressivePicture from '../../components/ProgressivePicture';
import CenterElement from '../../components/CenterElement';
import Link from '../../components/Link';
import './ResponsiveImage.scss';
import { IResponsiveImageProps } from '../../types/episerver/blocks/ResponsiveImage';
import { EpiserverLink } from '../../types/episerver/link';
import * as ga4 from '@citygross/analytics';

interface ILinkWithChildren {
  link: EpiserverLink;
  alt: string;
  onClick: Function;
}

const LinkWithChildren: React.FC<ILinkWithChildren> = ({
  link,
  alt,
  onClick,
  children
}) => {
  return (
    <div>
      {link && link.url ? (
        <Link
          className="b-responsive-image__link"
          link={link}
          alt={alt}
          onClick={onClick}
        >
          {children}
        </Link>
      ) : (
        children
      )}
    </div>
  );
};

interface IResponsiveImage extends IResponsiveImageProps {
  blockId: string;
}
const ResponsiveImage: React.FC<IResponsiveImage> = ({
  desktopImage,
  mobileImage,
  link,
  caption,
  name,
  blockId
}) => {
  const ga4Promotion = {
    items: [],
    promotion_id: blockId,
    promotion_name: name,
    creative_name: name,
    creative_slot: '0'
  };

  try {
    ga4.viewPromotion(ga4Promotion);
  } catch (error) {
    console.error(error);
  }

  return (
    <div className="b-responsive-image">
      <LinkWithChildren
        link={link}
        alt={caption}
        onClick={() => {
          try {
            ga4.selectPromotion(ga4Promotion);
          } catch (error) {
            console.error(error);
          }
        }}
      >
        <ProgressivePicture
          largeImage={desktopImage}
          smallImage={mobileImage}
          maxWidth={{
            large: 1860,
            small: 767
          }}
        />
      </LinkWithChildren>
      {caption && (
        <div className="b-responsive-image__caption">
          <CenterElement>
            <div className="header">
              <h1>{caption}</h1>
            </div>
          </CenterElement>
        </div>
      )}
    </div>
  );
};

export default memo(ResponsiveImage);
