import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  cartTotalValueSelector,
  cartTotalItemCountSelector
} from '../../selectors/cart/cartSelector';
import { upcomingOrderCount } from '../../selectors/order';
import { setPickingFeeRange, setPickingFee } from '../../actions/cart';

import Shopping from '../../components/Shopping';
import { closeSideModal, openSideModal } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../../components/SideModal';
import { setOpenSearch } from '../../slices/searchSlice';

const ShoppingContainer = props => <Shopping {...props} />;

function mapStateToProps(state) {
  return {
    isReplacement: state.cart.meta.isReplacement,
    cart: {
      ...state.cart,
      cartCount: cartTotalItemCountSelector(state),
      totalValue: cartTotalValueSelector(state)
    },
    delivery: {
      count: upcomingOrderCount(state)
    },
    shoppingOrder: {
      ...state.shoppingOrder
    },
    sideModal: {
      modalTab: state.sideModal.modalTab
    },
    auth: {
      isAuthenticated: state.auth.isAuthenticated
    }
  };
}

const mapDispatchToProps = dispatch => {
  const actions = {
    clearActiveTab: () => dispatch => {
      dispatch(closeSideModal());
    },
    setPickingFeeRange,
    setPickingFee,
    // TODO fix
    toggleActiveCart: () => dispatch => {
      dispatch(openSideModal(SideModalTabs.CART));
    },
    toggleLogin: () => dispatch => {
      dispatch(openSideModal(SideModalTabs.LOGIN));
    },
    closeSearch: () => dispatch => {
      dispatch(setOpenSearch(false));
    }
  };

  return bindActionCreators(Object.assign({}, actions), dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingContainer);
