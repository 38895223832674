import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import { setMode } from '../../actions/shoppingOrder';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import LoadingBlock from '../../components/LoadingBlock';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';

export class NotFoundPage extends React.Component {
  state = {
    fetchingHost: true
  };
  componentDidMount() {
    this.props.setMode(true, false, UNTOGGLE_BEHAVIOUR.none);
    fetch(this.props.location.pathname, { redirect: 'follow' })
      .then(res => {
        if (res.status === 200 && res.redirected) {
          this.props.push(new URL(res.url).pathname);
          console.log('Redirected to ' + res.url);
        } else {
          this.setState({
            fetchingHost: false
          });
        }
      })
      .catch(error => this.setState({ fetchingHost: false }));
  }
  render() {
    const { blocks } = this.props;
    if (this.state.fetchingHost) {
      return <LoadingBlock />;
    }
    return (
      <div className="not-found" style={{ marginBottom: '40px' }}>
        {blocks.length ? (
          <ContentBlocksArea blocks={blocks} />
        ) : (
          <div>
            <h1>404</h1>
            <h2>Sidan hittas inte!</h2>
            <p>
              <Link to="/">Gå till startsidan</Link>
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  blocks: state.app.settings?.notFoundArea || []
});

const mapDispatchToProps = {
  setMode,
  push
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotFoundPage)
);
