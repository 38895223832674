import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { withInfiniteScroll } from '../../components/InfiniteScrollHOC';
import { ProdCard } from '../../components/Skeletons';

import ProductCard from '../ProductCard';
import Spot from '../../components/Spot';
import { domSafe } from '../../lib/document';

import './ProductList.scss';
import {
  ticketNotifierByClick,
  ticketNotifierByAddingToCard
} from '../../api/endpoints/notify';
import Pagination from '../Pagination';
import CardGrid from '../CardGrid';

export const ProductList = ({
  fetching,
  addToCart,
  setProductFavorite,
  removeFavorite,
  increaseItemQuantity,
  decreaseItemQuantity,
  requestMoreProducts,
  pagination,
  items,
  listTeaser,
  withoutPaginationAndFilter,
  buttonText,
  skeletons,
  colStructure,
  impressionListName,
  itemsPerPage = 24
}) => {
  return (
    <Fragment>
      <CardGrid
        noPad
        renderItem={() => (
          <>
            {listTeaser && listTeaser.image && <Spot {...listTeaser} />}
            {fetching &&
              items?.length === 0 &&
              [...Array(skeletons)].map((e, i) => (
                <ProdCard key={i} className="card" />
              ))}
            {items &&
              items.length > 0 &&
              items.map((item, index) => {
                return (
                  <ProductCard
                    key={item.id}
                    colStructure={colStructure}
                    product={item}
                    addToCart={() => addToCart(item)}
                    setProductFavorite={setProductFavorite}
                    removeFavorite={removeFavorite}
                    increaseItemQuantity={quantity =>
                      increaseItemQuantity(item.cartItemId, quantity)
                    }
                    decreaseItemQuantity={quantity =>
                      decreaseItemQuantity(item.cartItemId, quantity)
                    }
                    buttonText={buttonText}
                    esalesClickTrigger={
                      item.ticket
                        ? () => ticketNotifierByClick(item.ticket)
                        : () => {}
                    }
                    esalesClickTriggerOnAddingToCart={
                      item.ticket
                        ? () => ticketNotifierByAddingToCard(item.ticket)
                        : () => {}
                    }
                    impressionListName={impressionListName}
                    impressionListPosition={index + 1}
                  />
                );
              })}
          </>
        )}
      />

      {items?.length > 0 && pagination && !withoutPaginationAndFilter && (
        <Pagination
          fetchPage={requestMoreProducts}
          pageCount={pagination?.pageCount}
          pageIndex={pagination?.pageIndex}
          totalCount={pagination?.totalCount}
          itemsPerPage={itemsPerPage}
        />
      )}
    </Fragment>
  );
};

const infiniteScrollCondition = props => {
  if (domSafe()) {
    return (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      props.items.length &&
      !props.fetching &&
      props.items.length > 20 &&
      props.pagination.totalCount > props.items.length
    );
  } else {
    return false;
  }
};

ProductList.defaultProps = {
  skeletons: 20
};

export const ProductListWithInfiniteScroll = compose(
  withInfiniteScroll(infiniteScrollCondition)
)(ProductList);

export default ProductList;
