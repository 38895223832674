import React from 'react';
import { PurchaseRule } from '../../../components/PurchaseRule';

const ProductPricePromotions = (product, formattedPrice) => {
  return (
    <>
      {product.price?.promotions2 &&
      product?.price?.promotions2[0]?.maxAppliedPerReceipt &&
      formattedPrice?.validPromotion &&
      Number(product.price.promotions2[0].maxAppliedPerReceipt) > 0 ? (
        <PurchaseRule>
          {`Max ${product.price.promotions2[0].maxAppliedPerReceipt} ${
            product.unit === 'kg' ? 'kg' : 'köp'
          } per kund`}
        </PurchaseRule>
      ) : null}
    </>
  );
};

export default ProductPricePromotions;
