import { Button, Spacer } from '@citygross/components';
import React from 'react';
import * as styles from './SideModalRegister.styles';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import { useDispatch } from 'react-redux';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal/SideModal';
import RegisterErrorMessage from '../../Login/CreateAccount/ErrorMessage.js';
import { setInitialStep } from '../../../slices/loginSlice';
import { loginSteps } from '../SideModalLogin/SideModalLogin';
import { Link } from 'react-router-dom';

type TLoginEmail = {
  onClear: () => void;
  error: any;
} & IAnimatedStepsChildProps;

const RegisterError = ({ onClear, error }: TLoginEmail) => {
  const dispatch = useDispatch();

  return (
    <>
      <styles.RegisterTitle>Tyvärr</styles.RegisterTitle>
      {error && (
        <styles.RegisterErrorContainer>
          <RegisterErrorMessage
            errorCode={error}
            clearError={() => onClear()}
            onForgotPassword={() => {
              dispatch(setInitialStep(loginSteps.LOGIN_FORGOT));
              dispatch(openSideModal(SideModalTabs.LOGIN));
            }}
            dontShowButton
          />
        </styles.RegisterErrorContainer>
      )}

      <div>
        <Button color={'secondary'} fullWidth onClick={() => onClear()}>
          Prova igen
        </Button>
        <Spacer />
        <Link to={'/kundservice'}>
          <Button color={'white'} fullWidth>
            Kontakta support
          </Button>
        </Link>
      </div>
    </>
  );
};
export default RegisterError;
