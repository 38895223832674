import { CLOSE } from '../../types/reducers/flyout';
import { clearOverlay } from '../../actions/app';
import { open as openModal } from '../../actions/confirmModal';

export const flyoutMiddleware = ({ dispatch, getState }) => next => action => {
  const state = getState();

  switch (action.type) {
    case CLOSE:
      if (state.flyout.shouldConfirm) {
        return dispatch(
          openModal({
            title: 'Är du säker?',
            body: 'Är du säker på att du vill gå vidare utan att spara?',
            confirmLabel: 'Bekräfta',
            cancelLabel: 'Gå tillbaka',
            onConfirmClick: () => {
              dispatch(clearOverlay());
              next(action);
            },
            onCancelClick: () => console.log('cancel')
          })
        );
      } else {
        dispatch(clearOverlay());
      }

      break;
  }
  return next(action);
};
