import * as endpoints from '../../api/endpoints/customer';
import {
  requestCustomer,
  receiveCustomer,
  fetchCustomerError,
  requestNewCustomer,
  receiveNewCustomer,
  newCustomerError
} from './sync';
import { Dispatch } from 'redux';

export const getCustomerById = (customerId: string | number) => (dispatch : Dispatch) => {
  dispatch(requestCustomer(customerId));

  return endpoints
    .fetchCustomerById(customerId)
    .then(({ data }) => {
      dispatch(receiveCustomer(data));
      return data;
    })
    .catch(err => {
      dispatch(fetchCustomerError(err));
      return Promise.reject(err);
    });
};

export const setNewCustomer = (customer : endpoints.NewCustomerData) => (dispatch: Dispatch) => {
  dispatch(requestNewCustomer(customer));

  return endpoints
    .setNewCustomer(customer)
    .then(({ data }) => {
      dispatch(receiveNewCustomer(data));
      return data;
    })
    .catch(err => {
      dispatch(newCustomerError(err));
      return Promise.reject(err);
    });
};
