import { createSelector } from 'reselect';
import { activeStoreNoSelector } from '../assortments';
import config from '../../config/config';

const getRecipes = state => state.recipe.recipes;
const getBlockRecipes = (_, recipes) => recipes || [];

const filtersSelector = state => state.recipe.filters;
const sweetenedFiltersSelector = state => state.app.settings?.recipeMarkings;
const favoriteRecipesSelector = state =>
  state.auth &&
  state.auth.recipeFavorites &&
  state.auth.recipeFavorites.length > 0
    ? state.auth.recipeFavorites
    : [];
const favoriteRecipesLoadingSelector = state =>
  state.auth ? state.auth.favoriteRecipesLoading : [];

// TODO: Create a general selector for product / recipes
export const filtersCombinationSelector = createSelector(
  [filtersSelector, sweetenedFiltersSelector],
  (filters, sweetenedFilters) => {
    const combinedFilters = Object.values(filters || [])?.map(filter => {
      const F = filter.options.map(option => {
        const matchedFilter = sweetenedFilters
          ? sweetenedFilters
              .map((sf, index) => {
                return { ...sf, sortOrder: index };
              })
              .find(item => item.code === option.id)
          : [];

        return matchedFilter
          ? {
              ...option,
              niceName: matchedFilter.name,
              sortOrder: matchedFilter.sortOrder
            }
          : { ...option, sortOrder: 99 };
      });
      return {
        ...filter,
        options: F.sort((optA, optB) =>
          optA.sortOrder > optB.sortOrder ? 1 : -1
        )
      };
    });
    return Object.values(combinedFilters);
  }
);

// TODO: Create a general selector for product / recipes
export const selectedFiltersSelector = createSelector(
  // combining with epi-values
  [filtersCombinationSelector],
  filters => {
    return Object.values(filters).reduce((acc, { id, options }) => {
      const selected = options
        .filter(opt => opt.selected)
        .map(opt => ({ parent: id, ...opt }));

      return [...acc, ...selected];
    }, []);
  }
);

// const determinePrice = variants => {
//   const cheapestVariant =
//     variants.reduce(
//       (prev, current) => (prev.price < current.price ? prev : current)
//     ) || [];
//   return cheapestVariant.price;
// };

export const setImage = (images, type) => {
  const image = (images || []).find(image => image.type === type);
  return image
    ? { ...image, url: `${config.PICTURE_BASE_URL}/recipes/${image.url}` }
    : { url: '', alt: '', type: 0 };
};

const determineMarkingVariants = (preview, sweetenedFilters) => {
  if (!preview) {
    return [];
  }
  const matchedMarkings = sweetenedFilters
    ?.map(sweetFilter => {
      return preview?.variants?.some(variant => variant === sweetFilter.code) ? sweetFilter : undefined;
    })
    ?.filter(v => v);
  return matchedMarkings;
};

const recipeDefaults = (
  r,
  sweetenedFilters,
  favorites,
  favoritesLoading,
  storeId
) => {
  const favorite = favorites.filter(item => item.itemNo === r.id);
  return {
    ...r,
    images: r.images || [],
    image: setImage(r.images, 0),
    tags: r.tags || [],
    preview: r.previews.find(x => Number(x.storeNumber) === Number(storeId)) || null,
    markings:
      determineMarkingVariants(r?.previews?.find(x => Number(x.storeNumber) === Number(storeId)), sweetenedFilters) || [],
    favorite: favorite.length > 0 ? favorite[0] : false,
    isFavoriteLoading: favoritesLoading.includes(r.id)
  };
};

export const makeRecipeEnhancerSelector = (selector = getRecipes) =>
  createSelector(
    [
      selector,
      sweetenedFiltersSelector,
      favoriteRecipesSelector,
      favoriteRecipesLoadingSelector,
      activeStoreNoSelector
    ],
    (
      recipes,
      sweetenedFilters,
      favoriteRecipes,
      favoriteRecipesLoading,
      storeId
    ) => {
      return recipes.map(recipe =>
        recipeDefaults(
          recipe,
          sweetenedFilters,
          favoriteRecipes,
          favoriteRecipesLoading,
          storeId
        )
      );
    }
  );

// For blocks that gets recipes from props/epi instead of state.
export const makeBlockRecipeEnhancerSelector = createSelector(
  [
    getBlockRecipes,
    sweetenedFiltersSelector,
    favoriteRecipesSelector,
    favoriteRecipesLoadingSelector,
    activeStoreNoSelector
  ],
  (
    recipes,
    sweetenedFilters,
    favoriteRecipes,
    favoriteRecipesLoading,
    storeId
  ) => {
    return recipes.map(recipe =>
      recipeDefaults(
        recipe,
        sweetenedFilters,
        favoriteRecipes,
        favoriteRecipesLoading,
        storeId
      )
    );
  }
);

export const recipeFavoritesSelector = createSelector(
  [favoriteRecipesSelector],
  favorites => {
    return favorites;
  }
);
