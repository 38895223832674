import { esalesRequest } from '../../lib/request';

export const ticketNotifierByClick = ticket =>
  esalesRequest(`/esales/notify/click?ticket=${ticket}`, {
    method: 'POST'
  });

export const ticketNotifierByAddingToCard = ticket =>
  esalesRequest(`/esales/notify/adding-to-cart?ticket=${ticket}`, {
    method: 'POST'
  });

export const ticketNotifierByFavoriteAdd = (productKey, variantKey) =>
  esalesRequest(`/esales/notify/favorite/add`, {
    method: 'POST',
    body: JSON.stringify({
        productKey: productKey
    })
  });

export const ticketNotifierByFavoriteRemove = (productKey, variantKey) =>
  esalesRequest(`/esales/notify/favorite/remove`, {
    method: 'POST',
    body: JSON.stringify({
        productKey: productKey
    })
  });
