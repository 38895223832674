import React from 'react';
import './Grid.scss';

interface IGrid {
  className?: string
}

const Grid : React.FC<IGrid> = ({ className, children }) => (
  <div className={`c-grid ${className}`}>{children}</div>
);

export default Grid;
