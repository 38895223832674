import {
  FETCH_IMBOXCATEGORIES_RECEIVED,
  FETCH_IMBOXANSWER_RECEIVED,
  ImboxState,
  ImboxCategoriesReceivedAction,
  ImboxAnswerReceivedAction
} from '../../types/reducers/imbox';

const initialState : ImboxState = {
  questions: [],
  answers: [],
  loading: true
};

export default function imboxReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //ImboxActionTypes
): ImboxState {
  switch (action.type) {
    case FETCH_IMBOXCATEGORIES_RECEIVED: {
      const { payload }: ImboxCategoriesReceivedAction = action;
      return {
        ...state,
        questions: payload,
        loading: false
      };
    }
    case FETCH_IMBOXANSWER_RECEIVED: {
      const { payload }: ImboxAnswerReceivedAction = action;

      return {
        ...state,
        answers: [
          ...state.answers,
          {
            ...payload
          }
        ]
      };
    }
    default:
      return state;
  }
}
