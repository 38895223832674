import { addOrUpdate, remove, setFailed } from '../../actions/request';
import { domSafe } from '../document';

export const REQUEST_IDS = {
  settings: 'SETTINGS',
  navigation: 'NAVIGATION',
  customer: 'CUSTOMER',
  favorites: 'FAVORITES',
  customerOrders: 'CUSTOMER_ORDERS',
  customerStorePage: 'CUSTOMER_STORE_PAGE'
};

const SLOW_TIMEOUT = 5000;
const DEFAULT_TIMEOUT_LOW = 3000;
const DEFAULT_TIMEOUT_HIGH = 10000;
const DEFAULT_ATTEMPTS_UNTIL_HIGH = 5;
const DEFAULT_ATTEMPTS_UNTIL_FAIL = 30;

class OfflineQueue {
  constructor() {
    this.queue = [];
    this.listener = false;
  }

  add = fn => {
    console.log('Request paused, waiting for internet');
    this.queue.push(fn);

    if (!this.listener) {
      window.addEventListener('online', this.run);
      this.listener = true;
    }
  };

  run = () => {
    if (this.queue.length > 0) {
      console.log('Running paused requests');
    }
    while (this.queue.length > 0) {
      this.queue.shift()();
    }
    window.removeEventListener('online', this.run);
    this.listener = false;
  };
}

const offlineQueue = new OfflineQueue();

const run = (req, dispatch, resolve, reject, id, runs, opts) => {
  dispatch(addOrUpdate(id, runs, 'error', opts));

  const timeout =
    runs <= DEFAULT_ATTEMPTS_UNTIL_HIGH
      ? DEFAULT_TIMEOUT_LOW
      : DEFAULT_TIMEOUT_HIGH;

  const reqFn = () => {
    console.log('Retrying failed request', id, runs);
    req()
      .then(res => {
        dispatch(remove(id));
        return resolve(res);
      })
      .catch(err => {
        if (runs >= DEFAULT_ATTEMPTS_UNTIL_FAIL) {
          dispatch(setFailed(id));
          return reject(err);
        } else {
          run(req, dispatch, resolve, reject, id, runs + 1, opts);
        }
      });
  };

  // If not online, wait for internet
  if (!navigator.onLine) {
    offlineQueue.add(reqFn);
  } else {
    window.setTimeout(reqFn, timeout);
  }
};

export const requestRetry = (req, dispatch, id, opts) => {
  // In SSR we won't retry
  if (!domSafe()) {
    return req();
  }
  // ID is required to show loading message
  if (!id || Object.values(REQUEST_IDS).indexOf(id) === -1) {
    throw new Error('Unkown or missing request ID', id);
  }

  return new Promise((resolve, reject) => {
    let isSlow = false;
    const slowTimeoutTimerId = window.setTimeout(() => {
      dispatch(addOrUpdate(id, 0, 'slow', opts));
      isSlow = true;
    }, SLOW_TIMEOUT);
    return req()
      .then(res => {
        isSlow && dispatch(remove(id));
        return resolve(res);
      })
      .catch(() => run(req, dispatch, resolve, reject, id, 0, opts))
      .finally(() => {
        window.clearTimeout(slowTimeoutTimerId);
      });
  });
};
