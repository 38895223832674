import React, { useState, useEffect, useRef } from 'react';

import ProductList from '../ProductList';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { cartItemsBlockProductCombinationSelector } from '../../selectors/cart/cartSelector';
import { authenticatedSelector } from '../../selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRecommendedProducts } from '../../api/endpoints/product';
import { Product } from '../../types/xim/product';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { AppState } from '../../reducers';
import { LoadMoreToolbar } from '../LoadMoreToolbar';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from '../../store/middleware/analytics';

interface IProps {
  productId: string;
  excludeCategoryByCode?: number;
  storeId: number;
  isFlyout?: boolean;
}

const RecommendedProducts: React.FC<IProps> = ({
  productId,
  excludeCategoryByCode,
  storeId,
  isFlyout
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [paginationIndex, setPaginationIndex] = useState<number>(0);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: AppState) =>
    authenticatedSelector(state)
  );
  const activeStore = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );
  const recommendedProducts: Product[] = useSelector(state =>
    cartItemsBlockProductCombinationSelector(state, products)
  );
  const componentMounted = useRef(true);

  const amountOfProductsToFetch = 8;
  const onAddToCart = item => {
    if (isAuthenticated) {
      return dispatch(addToCart(item));
    } else {
      return dispatch(redirectToLogin);
    }
  };

  useEffect(() => {
    const ac = new AbortController();

    fetchRecommendedProducts(
      activeStore,
      0,
      amountOfProductsToFetch,
      productId,
      excludeCategoryByCode
    )
      .then(({ data }) => {
        if (data.data && data.data.length > 0 && componentMounted.current) {
          setProducts([...data.data]);
        }

        try {
          const impressionListName = 'ProductSingle - Liknande varor';

          const ga4ImpressionItems = data.data.map((prod, index) => {
            let unitPrice = prod?.defaultPrice?.ordinaryPrice?.price || 0;
            let discount = 0;
            let quantity = 1;

            const priceInfo = prod.prices?.find(x => x.storeNumber === storeId);

            if (priceInfo?.hasPromotion) {
              const promo = priceInfo.promotions2 && priceInfo.promotions2[0];
              quantity = promo?.minQuantity || 1;
              discount =
                quantity > 1
                  ? unitPrice * quantity - (promo?.value || 0)
                  : unitPrice - (promo?.value || 0);
            }

            return {
              item_id: prod.gtin,
              item_name: prod.name,
              item_brand: prod.brand || 'City Gross',
              item_category:
                prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                undefined,
              item_list_name: impressionListName,
              item_list_id: impressionListName,
              index: index + 1,

              price: Number(formatPrice(unitPrice)),
              discount: Number(formatPrice(discount)),
              quantity: quantity
            };
          });

          ga4.viewItemList({
            // @ts-ignore
            items: ga4ImpressionItems,
            item_list_id: impressionListName,
            item_list_name: impressionListName
          });
        } catch (error) {
          console.error(error);
        }
      })
      .catch(e => {
        console.error(e);
        if (componentMounted.current) {
          setProducts([]);
        }
      });
    return () => {
      ac.abort();
      componentMounted.current = false;
      if (isFlyout) setProducts([]);
    };
  }, [activeStore]);

  const getRecommendedProducts = () => {
    if (paginationIndex === 0) {
      const halfLength = Math.ceil(recommendedProducts.length / 2);
      const arrayCopy = [...recommendedProducts];
      return arrayCopy.splice(0, halfLength);
    }
    return recommendedProducts;
  };

  if (!products || !products.length) {
    return null;
  }

  return (
    <div className="c-productdetails__related">
      <h2>Rekommenderade varor</h2>
      <ProductList
        fetching={false}
        addToCart={onAddToCart}
        setProductFavorite={(id, item) =>
          dispatch(setProductFavorite(id, item))
        }
        removeFavorite={x => dispatch(removeFavorite(x))}
        increaseItemQuantity={(id, quantity) =>
          dispatch(increaseItemQuantity(id, quantity))
        }
        decreaseItemQuantity={(id, quantity) =>
          dispatch(decreaseItemQuantity(id, quantity))
        }
        items={getRecommendedProducts()}
        colStructure={'l-column-15_xs-30_sm-20_md-15_lg-15_xlg-15-mobileGutter'}
      />
      {/* We do not want pagination on recommendations, duo to a fluent relevance, therefore this nasty hack. */}
      <LoadMoreToolbar
        requestMore={() => setPaginationIndex(1)}
        count={
          paginationIndex === 0
            ? Math.ceil(recommendedProducts.length / 2)
            : amountOfProductsToFetch
        }
        totalCount={amountOfProductsToFetch}
        fetching={false}
      />
    </div>
  );
};

export default RecommendedProducts;
