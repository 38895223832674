import React, { Component } from 'react';
import { LoadMoreToolbar } from '../../components/LoadMoreToolbar';
import { connect } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';

import {
  fetchProductsByPanel,
  fetchRecipesByPanel
} from '../../api/endpoints/product';

import {
  PanelTypes,
  ProductTypes,
  IEsalesFilterOptions
} from '../../types/esales';
import withBaseBlock from '../../components/BaseBlock';
import RecipeGrid from '../../modules/RecipeGrid';
import { Product } from '../../types/xim/product';
import { Recipe } from '../../types/xim/recipe';
import { SearchMetaResponse } from '../../types/esales';
import { ImpressionType } from 'dynamicdog-analytics';
import {
  activeStoreNoSelector,
  selectCurrentAssortment
} from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';
import { generateGA4ImpressionsArrayFromEecImpressions } from '../../lib/analytics/analytics';
import {
  productDefaults,
  sweetenedFiltersSelector,
  sweetenedSignalWordsSelector,
  sweetenedSymbolsSelector
} from '../../selectors/product/productSelectors';
import { Marking } from '../../types/episerver/marking';
import { WarningSymbol } from '../../types/episerver/warningSymbol';
import { SignalWords } from '../../types/episerver/signalWords';
import CardGrid from '../../components/CardGrid';
import ProductCardList from '../../modules/ProductCardList';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { redirectToLogin } from '../../actions/app';
import { removeFavorite, setProductFavorite } from '../../actions/auth';

interface IProps {
  id: number;
  storeId: number;
  isAuthenticated: boolean;
  blockOptions: {
    marginTopBottom: string;
    useFlushContainer: boolean;
    width: string;
    backgroundColor: string;
  };
  ispublished: boolean;
  name: string;
  title?: string;
  type: string;
  fontColor?: string;
  linkText?: string;
  link?: Link;
  paginationEnabled: boolean;
  productType: ProductTypes;
  recommendationType: PanelTypes;
  __type__: string;
  store: string | number | null;
  sweetenedFilters?: Marking[];
  sweetenedSignalWords?: SignalWords[];
  sweetenedSymbols?: WarningSymbol[];
  mappedAddToCart: Function;
  mappedIncreaseItemQuantity: Function;
  mappedDecreaseItemQuantity: Function;
  mappedRedirectToLogin: Function;
  mappedSetProductFavorite: Function;
  mappedRemoveFavorite: Function;
}

interface Link {
  internal: boolean;
  url: string;
}
interface IState {
  fetchedProducts: Product[] | null;
  fetchedRecipes: Recipe[] | null;
  pagination: SearchMetaResponse | null;
  allRows: boolean;
  loading: boolean;
  fetchingFailed: boolean;
  paginateFinished: boolean;
}

const size = 12;

class ProductArticleBlock extends Component<IProps, IState> {
  state = {
    fetchedProducts: [],
    fetchedRecipes: [],
    pagination: {
      completions: null,
      corrections: null,
      recentSearches: null,
      revisited: null,
      filters: new Map<string, IEsalesFilterOptions>(),
      pageCount: 0,
      pageIndex: 0,
      pageSize: 0,
      sort: null,
      totalCount: 0,
      priceonly: false
    },
    allRows: false,
    loading: true,
    fetchingFailed: false,
    paginateFinished: false
  };

  componentDidUpdate(prevProps: IProps) {
    if (
      prevProps.storeId !== this.props.storeId &&
      this.props.isAuthenticated
    ) {
      this.fetchProducts();
    }
  }
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.fetchProducts();
    }
  }
  extendProducts = items => {
    const {
      store,
      sweetenedFilters,
      sweetenedSignalWords,
      sweetenedSymbols
    } = this.props;
    return items?.map(item =>
      productDefaults(
        item,
        store,
        sweetenedFilters,
        sweetenedSignalWords,
        sweetenedSymbols
      )
    );
  };
  loadMoreProducts = () => {
    if (!this.state.allRows) {
      return this.setState({
        allRows: true
      });
    }
    this.setState({
      loading: true
    });
    const { storeId, recommendationType, productType } = this.props;
    const newPageIndex = this.state.pagination.pageIndex + 1;

    if (productType === 'products') {
      // add pagination to this queryString
      fetchProductsByPanel(
        recommendationType,
        productType,
        storeId,
        newPageIndex,
        size
      )
        .then(({ data }) => data)
        .then(({ data, meta }) => {
          const fetchedProducts = data?.map(p => this.extendProducts(p));

          this.setState({
            fetchedProducts: fetchedProducts
              ? [...this.state.fetchedProducts, ...fetchedProducts]
              : this.state.fetchedProducts,
            pagination: meta,
            loading: false,
            paginateFinished: data ? size !== data.length : true
          });
        });
    } else {
      fetchRecipesByPanel(recommendationType, storeId, newPageIndex, size)
        .then(({ data }) => data)
        .then(({ data, meta }) => {
          this.setState({
            fetchedRecipes: data
              ? [...this.state.fetchedProducts, ...data]
              : this.state.fetchedProducts,
            pagination: meta,
            loading: false,
            paginateFinished: data ? size !== data.length : true
          });
        });
    }
  };

  fetchProducts = () => {
    this.setState({ loading: true });
    const { storeId, recommendationType, productType } = this.props;

    if (productType === 'products') {
      fetchProductsByPanel(recommendationType, productType, storeId, 0, size)
        .then(({ data }) => data)
        .then(({ data, meta }) => {
          if (!data || data.length === 0) {
            return this.setState({
              fetchingFailed: true
            });
          }
          const fetchedProducts = data?.map(p => this.extendProducts(p));
          this.setState({
            fetchedProducts,
            pagination: meta,
            fetchingFailed: false,
            loading: false
          });

          // TODO @TRACKING
          try {
            const impressionList: ImpressionType[] = data.map((prod, index) => {
              const price = prod.prices
                ? prod.prices.find(x => x.storeNumber === this.props.storeId) ||
                  prod.prices[0]
                : false;
              return {
                id: prod.gtin,
                name: prod.name || '',
                category: prod.url
                  ? prod.url.substring(0, prod.url.lastIndexOf('/') + 1)
                  : 'Not set',
                brand: prod.brand || 'City Gross',
                list: this.props.name,
                position: index,
                dimension8:
                  price && price.hasDiscount ? 'On Sale' : 'Not On Sale'
              };
            });

            console.log('----- RecommendationBlock --------');

            ga4.viewItemList({
              items: generateGA4ImpressionsArrayFromEecImpressions(
                impressionList
              ),
              item_list_id: this.props.name,
              item_list_name: this.props.name
            });
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => this.setState({ fetchingFailed: true }));
    } else {
      fetchRecipesByPanel(recommendationType, storeId, 0, size)
        .then(({ data }) => data)
        .then(({ data, meta }) => {
          if (!data || data.length === 0) {
            return this.setState({
              fetchingFailed: true
            });
          }

          this.setState({
            fetchedRecipes: data,
            pagination: meta,
            fetchingFailed: false,
            loading: false
          });

          try {
            const impressionList: ImpressionType[] = data.map((prod, index) => {
              return {
                id: prod.id,
                name: prod.name || '',
                category: prod.url
                  ? '/recept' +
                    prod.url.substring(0, prod.url.lastIndexOf('/') + 1)
                  : 'Not set',
                brand: prod.source || 'City Gross',
                list: this.props.name,
                position: index,
                dimension8: 'Not On Sale'
              };
            });

            ga4.viewItemList({
              items: generateGA4ImpressionsArrayFromEecImpressions(
                impressionList
              ),
              item_list_id: this.props.name,
              item_list_name: this.props.name
            });
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => this.setState({ fetchingFailed: true }));
    }
  };
  render() {
    const {
      paginationEnabled,
      productType,
      isAuthenticated,
      mappedAddToCart,
      mappedIncreaseItemQuantity,
      mappedDecreaseItemQuantity,
      mappedRedirectToLogin,
      mappedSetProductFavorite,
      mappedRemoveFavorite,
      ...rest
    } = this.props;
    const {
      pagination,
      fetchedProducts,
      fetchedRecipes,
      allRows,
      loading,
      fetchingFailed,
      paginateFinished
    } = this.state;

    if (fetchingFailed || !isAuthenticated) {
      return null;
    }

    const padding = this.props.title ? '0px' : '25px';
    const cardFuncs = {
      addToCart: mappedAddToCart,
      increaseItemQuantity: mappedIncreaseItemQuantity,
      decreaseItemQuantity: mappedDecreaseItemQuantity,
      setProductFavorite: isAuthenticated
        ? mappedSetProductFavorite
        : mappedRedirectToLogin,
      removeFavorite: isAuthenticated
        ? mappedRemoveFavorite
        : mappedRedirectToLogin
    };
    return (
      <div style={{ paddingTop: padding }}>
        {productType === 'products' ? (
          <CardGrid
            noPad
            {...rest}
            renderItem={maxItems => (
              <ProductCardList
                items={fetchedProducts || []}
                maxItems={2 * maxItems}
                cardFuncs={cardFuncs}
                allRows={allRows}
                loading={loading}
                impressionListName={this.props.name}
              />
            )}
          />
        ) : (
          <RecipeGrid noPad recipes={fetchedRecipes} {...rest} />
        )}
        {paginationEnabled &&
          !paginateFinished &&
          this.state.fetchedProducts &&
          pagination &&
          fetchedProducts && (
            <div style={{ paddingBottom: '30px' }}>
              <LoadMoreToolbar
                hideArticleCount={false}
                totalCount={500}
                count={fetchedProducts.length}
                requestMore={this.loadMoreProducts}
                fetching={loading}
              />
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state: any, props: any) => {
  return {
    isAuthenticated: authenticatedSelector(state),
    storeId: selectCurrentAssortment(state),
    store: activeStoreNoSelector(state),
    sweetenedFilters: sweetenedFiltersSelector(state),
    sweetenedSignalWords: sweetenedSignalWordsSelector(state),
    sweetenedSymbols: sweetenedSymbolsSelector(state)
  };
};

const mapDispatchToProps = {
  mappedIncreaseItemQuantity: increaseItemQuantity,
  mappedDecreaseItemQuantity: decreaseItemQuantity,
  mappedRedirectToLogin: redirectToLogin,
  mappedAddToCart: addToCart,
  mappedSetProductFavorite: setProductFavorite,
  mappedRemoveFavorite: removeFavorite
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBaseBlock(ProductArticleBlock));
