import { request, externalRequest } from '../../lib/request';

export const getBlockData = (id, workId, auth) => {
  const opts = {
    headers: {
      cookie: `cg.preview=${auth}`
    }
  };
  const url = `/BlockData?id=${id}&workId=${workId}`;
  return request(url, opts);
};
export const getPageData = id => {};
