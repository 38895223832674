const compareNumbers = (a, b) => a - b;
const compareStrings = (a, b) => {
  let cmpA = a.toLowerCase();
  let cmpB = b.toLowerCase();

  if (cmpA < cmpB) {
    return -1;
  }

  if (cmpA > cmpB) {
    return 1;
  }

  return 0;
};
// Sorts a list by prop, i.e the list
// contains records with: [{a: <value>, b: <value>}]
// where sortOrder: a | b | relevance
export const sortByProp = (list, sortOrder) => {
  if (sortOrder === 'relevance') {
    return list;
  }

  return list.sort((a, b) => {
    const valA = a[sortOrder];
    const valB = b[sortOrder];

    if (typeof valA === 'string' && typeof valB === 'string') {
      return compareStrings(valA, valB);
    }

    if (typeof valA === 'number' && typeof valB === 'number') {
      return compareNumbers(valA, valB);
    }

    return 0;
  });
};
