import {
  fetchDeliveryCities,
  fetchDeliveryZipCodesForCity
} from '../../api/endpoints/delivery';
import {
  fetchingCities,
  receiveCities,
  failedReceivingCities,
  fetchingZipcodes,
  receiveZipcodes,
  failedReceivingZipcodes
} from './sync';

export const getDeliveryCities = () => dispatch => {
  dispatch(fetchingCities());
  return fetchDeliveryCities()
    .then(({ data }) => {
      dispatch(receiveCities(data));
      return data;
    })
    .catch(err => {
      dispatch(failedReceivingCities(err));
      return Promise.reject(err);
    });
};

export const getDeliveryZipCodesForCity = citySlug => dispatch => {
  dispatch(fetchingZipcodes());
  return fetchDeliveryZipCodesForCity(citySlug)
    .then(({ data }) => {
      dispatch(receiveZipcodes(data));
      return data;
    })
    .catch(err => {
      dispatch(failedReceivingZipcodes(err));
      return Promise.reject(err);
    });
};
