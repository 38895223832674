import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ZIP_CODE_REGEX } from '../../../../lib/validator';

const ZipCodeInput = ({
  id,
  label,
  hint,
  placeholder,
  required,
  formLoading,
  children,
  ...rest
}) => {
  const inputAttr = {};
  placeholder && (inputAttr.placeholder = placeholder);
  required && (inputAttr.required = 'required');
  const { value, ...restr } = rest;

  return (
    <Fragment>
      <label htmlFor={id}>
        {label}
        {children}
        {hint && <span className="hint">{hint}</span>}
      </label>
      <input
        type="tel"
        pattern={ZIP_CODE_REGEX}
        id={id}
        name={id}
        maxLength="5"
        value={value}
        placeholder={placeholder}
        readOnly={formLoading}
        {...inputAttr}
        {...restr}
      />
    </Fragment>
  );
};

ZipCodeInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  formLoading: PropTypes.bool,
  children: PropTypes.any
};

ZipCodeInput.defaultProps = {
  placeholder: 'Postnummer'
};

export default ZipCodeInput;
