import React from 'react';
import config from '../../config/config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  FlyoutRoute,
  ProtectedRoute,
  RouteWithSubroutes
} from '../../components/Routing';
import { defineRoutesSelector } from '../../selectors/routing/routingDefineSelector';
import { authenticatedSelector } from '../../selectors/user';
import RoutingStateKeeper from '../../components/Routing/RoutingStateKeeper';
import { STATIC_ROUTES, STATIC_FLYOUT } from './routes';
import LoadingPage from '../../modules/LoadingPage';

const fromLocation = location =>
  !location || !location.state
    ? {}
    : {
        tight: Boolean(location.state.tight),
        title: location.state.title,
        nopadding: Boolean(location.state.nopadding),
        noheader: Boolean(location.state.noheader)
      };

const makeStaticRoutes = (statics, opts = {}) =>
  statics.map(staticR => {
    const { isProtected, ...route } = staticR;
    const props = { ...opts, ...route };

    if (!props.navigationhasFetched) {
      return <LoadingPage />;
    }

    return isProtected && !config.DEBUG ? (
      <ProtectedRoute {...props} />
    ) : (
      <RouteWithSubroutes {...props} />
    );
  });

const mapStateToProps = (state, props) => {
  const statics = makeStaticRoutes(STATIC_ROUTES, {
    isAuthenticated: authenticatedSelector(state),
    navigationhasFetched: state.navigation.hasFetched
  });

  const { flyoutRef, overlayRef, confirmModalRef } = props;
  // Determine the actual <Route> component to use.
  // The already resolved component <*Page> component, is coupled with a layout, and returns the <Route>
  // any components labeled as 'protected' will get a route which is only reachable when authenticated.

  // The Flyout routes are pairs of 'regular' routes, so flyout routes are rendered based on how you got there.
  const { defaultRoutes, flyoutRoutes } = defineRoutesSelector(state);
  const routes = defaultRoutes.concat(statics);

  return {
    mapFlyoutLocationProps: fromLocation,

    location: props.location,
    goBack: props.history.goBack,

    baseRoutes: routes,
    flyoutRoutes: STATIC_FLYOUT.concat(flyoutRoutes),

    flyoutRouteProps: {
      flyoutRef,
      overlayRef,
      confirmModalRef
    },
    flyoutRouteComponent: FlyoutRoute
  };
};

export default withRouter(connect(mapStateToProps)(RoutingStateKeeper));
