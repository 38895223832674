import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSettingsSelector } from '../../../selectors/app';
import { H2 } from '@citygross/typography';
import { CgButton, EButtonVariant, ECgButtonSize } from '@citygross/components';
import { useHistory, withRouter } from 'react-router-dom';
import * as styles from './CartEmptyState.styles';
import { closeSideModal } from '../../../slices/sideModalSlice';
import { authenticatedSelector } from '../../../selectors/user';
const CartEmptyState = () => {
  const settings = useSelector(appSettingsSelector);
  const settingsCart = settings?.cart?.emptyCart;
  const history = useHistory();
  const textRef = useRef<HTMLDivElement>(null);
  const isLoggedIn = useSelector(authenticatedSelector);
  const dispatch = useDispatch();

  const handleClick = (event, linkHref?: string) => {
    const isLink = event?.target?.tagName === 'A' || linkHref;
    const target = event?.target?.getAttribute('target');
    const href = event?.target.getAttribute('href') || linkHref;

    if (isLink) {
      if (target === '_blank') {
        return;
      }
      event?.preventDefault();
      dispatch(closeSideModal());

      history.push(href);
    }
  };

  const texts = useMemo(() => {
    return {
      title: isLoggedIn
        ? settingsCart?.cartEmptyTitle
        : settingsCart?.cartEmptyTitleNoLogin,
      description: isLoggedIn
        ? settingsCart?.cartEmptyDescription
        : settingsCart?.cartEmptyDescriptionNoLogin,
      buttonLink: isLoggedIn
        ? settingsCart?.cartEmptyButtonLink
        : settingsCart?.cartEmptyButtonLinkNoLogin,
      buttonLabel: isLoggedIn
        ? settingsCart?.cartEmptyButtonLabel
        : settingsCart?.cartEmptyButtonLabelNoLogin
    };
  }, [isLoggedIn, settingsCart]);

  return (
    <styles.CartEmptyStateContainer>
      <H2>{texts?.title}</H2>

      <div className="c-richtext">
        {texts?.description && (
          <div
            className="c-richtext__inner"
            dangerouslySetInnerHTML={{
              __html: texts?.description
            }}
            ref={textRef}
            onClick={handleClick}
          />
        )}
      </div>
      {texts?.buttonLink && (
        <styles.CartEmptyButtonContainer>
          <CgButton
            variant={EButtonVariant.secondary}
            onClick={() => handleClick(null, texts?.buttonLink)}
            size={ECgButtonSize.medium}
          >
            {texts?.buttonLabel}
          </CgButton>
        </styles.CartEmptyButtonContainer>
      )}
    </styles.CartEmptyStateContainer>
  );
};

export default withRouter(CartEmptyState);
