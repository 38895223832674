import React from 'react';
import PropTypes from 'prop-types';
import GoogleReCAPTCHA from 'react-google-recaptcha';
import './ReCaptcha.scss';

const ReCAPTCHA = ({
  onRecaptchaChange,
  settings,
  appSettingsLoadingSelector
}) => {
  if (appSettingsLoadingSelector) return null;
  return settings?.RecaptchaPublicKey ? (
    <GoogleReCAPTCHA
      className="c-frm-recaptcha"
      sitekey={settings.RecaptchaPublicKey}
      onChange={onRecaptchaChange}
    />
  ) : (
    <div className="c-frm-recaptcha">
      <p>
        <strong>ERROR: Missing RECAPTCHA_SITE_KEY!</strong>
      </p>
    </div>
  );
};

ReCAPTCHA.propTypes = {
  onRecaptchaChange: PropTypes.func.isRequired,
  siteKey: PropTypes.string.isRequired
};

export default ReCAPTCHA;
