const NAME = 'customer';

export const FETCH_CUSTOMER_REQUESTED = `${NAME}/FETCH_CUSTOMER_REQUESTED`;
export const FETCH_CUSTOMER_RECEIVED = `${NAME}/FETCH_CUSTOMER_RECEIVED`;
export const FETCH_CUSTOMER_FAILED = `${NAME}/FETCH_CUSTOMER_FAILED`;
export const REGISTER_CUSTOMER_REQUESTED = `${NAME}/REGISTER_CUSTOMER_REQUESTED`;
export const REGISTER_CUSTOMER_RECEIVED = `${NAME}/REGISTER_CUSTOMER_RECEIVED`;
export const REGISTER_CUSTOMER_FAILED = `${NAME}/REGISTER_CUSTOMER_FAILED`;
export const REGISTER_CUSTOMER_CLEAR = `${NAME}/REGISTER_CUSTOMER_CLEAR`;
export const REGISTER_CUSTOMER_CLEAR_ERROR = `${NAME}/REGISTER_CUSTOMER_CLEAR_ERROR`;
