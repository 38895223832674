import {
  CLOSE_NEWCART_MODAL,
  LOADING,
  PREPARE_NEWCART,
  RESET_NEWCART,
  OPEN_NEWCART_MODAL,
  SET_CART_TO_CHANGE
} from '../../types/reducers/newCartPrep';

type TPrepareNewCartOpts = {
  show: boolean;
  cartDiff: any;
  isDifferent: boolean;
  loading: boolean;
  newCart: any;
  newCartId: string;
  storeNoToBeSelected: string | number;
};

export const closeNewCartModal = () => ({
  type: CLOSE_NEWCART_MODAL
});

export const togglePrepareNewCartLoading = () => ({
  type: LOADING
});

export const openNewCartModal = () => ({
  type: OPEN_NEWCART_MODAL
});

export const prepareNewCart = (opts: TPrepareNewCartOpts) => ({
  type: PREPARE_NEWCART,
  opts: opts
});
export const setCartToChange = (cartId: string[] | null) => ({
  type: SET_CART_TO_CHANGE,
  cartToBeChanged: cartId
});
export const resetNewCart = () => ({
  type: RESET_NEWCART
});
