import { passThroughUpdate } from './utils';
const KEY_TO_PROP = {
  organic: {
    sortIndex: 4,
    value: 'ECO'
  },
  vegetarian: {
    sortIndex: 3,
    value: 'VEG'
  },
  lactoseFree: {
    sortIndex: 2,
    value: 'LF'
  },
  glutenFree: {
    sortIndex: 1,
    value: 'GF'
  },
  vegan: {
    sortIndex: 5,
    value: 'VEGAN'
  }
};

export const RECIPE_FIELDS = [
  'organic',
  'vegetarian',
  'lactoseFree',
  'glutenFree',
  'vegan'
];

const DELIM = '_';

const sortReplace = opts => {
  const sortByIndex = (keyA, keyB) => {
    return KEY_TO_PROP[keyA].sortIndex - KEY_TO_PROP[keyB].sortIndex;
  };

  return Object.entries(opts)
    .filter(([key, value]) => value)
    .map(([key]) => key)
    .sort(sortByIndex)
    .map(key => KEY_TO_PROP[key].value);
};

export const generateRecipeId = (base, opts = {}) =>
  [base, ...sortReplace(opts)].join(DELIM);

export const increaseInCollection = (collection, recipes) => {
  return collection.map(item => {
    const match = recipes.find(recipe => recipe.id === item.variantId);
    if (match) {
      return {
        ...item,
        numberOfMeals: item.numberOfMeals + match.numberOfMeals
      };
    } else {
      return item;
    }
  });
};

export const decreaseInCollection = (collection, id, quantity) => {
  return passThroughUpdate(
    collection,
    item => item.recipeId !== id,
    item => ({
      ...item,
      numberOfMeals: item.numberOfMeals - quantity
    })
  ).filter(({ numberOfMeals }) => numberOfMeals > 0);
};
