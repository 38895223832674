import { Badge, Button, Spacer } from '@citygross/components';
import React from 'react';
import { useTheme } from 'styled-components';
import * as styles from './SideModalRegister.styles';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import { useDispatch } from 'react-redux';
import { Icons } from '@citygross/icons';
import {
  openSideModal,
  sideModalForceOpen
} from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal/SideModal';

type TLoginEmail = {} & IAnimatedStepsChildProps;

const RegisterSuccess = ({ ...props }: TLoginEmail) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const submit = e => {
    e.preventDefault();
    dispatch(sideModalForceOpen(true));
    dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
  };

  return (
    <styles.RegisterWrapper>
      <styles.RegisterSuccessContainer>
        <Badge
          background={theme.palette?.secondary}
          height={'40px'}
          width={'40px'}
        >
          <Icons.CheckMark color="white" />
        </Badge>
        <styles.RegisterTitle center>
          Ditt konto är skapat!
        </styles.RegisterTitle>
        <styles.RegisterTextCenter>
          Börja handla genom att gå till våra avdelningar, matvaror, recept,
          matkassar eller catering.
        </styles.RegisterTextCenter>
        <Spacer lgSpacing="xs" xsSpacing="xs" />
        <Button color={'secondary'} fullWidth onClick={submit}>
          Börja handla
        </Button>
      </styles.RegisterSuccessContainer>
    </styles.RegisterWrapper>
  );
};

export default RegisterSuccess;
