export const isObject = (obj: any) : boolean => typeof obj === 'object' && obj !== null;

export const deepCompare = (objA: Object, objB: Object) : boolean => {
  if (!isObject(objA) || !isObject(objB)) {
    return objA === objB;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);

  return (
    aKeys.length === bKeys.length &&
    aKeys.every(key => {
      const aValue = objA[key];
      const bValue = objB[key];

      return deepCompare(aValue, bValue);
    })
  );
};
