import React from 'react';
import './RecipeCard.scss';

interface IRecipeCard {
  className: string
}

const RecipeCard : React.FC<IRecipeCard> = ({ className }) => {
  return <div className={`c-skeleton__recipecard ${className}`} />;
};

RecipeCard.defaultProps = {
  className: ''
};

export { RecipeCard };

export default RecipeCard;
