import React from 'react';
import Link from './Link';

const WithLink = ({ link, to, children, ...rest }) =>
  (link && link.url) || to ? (
    <Link link={link} to={to} {...rest}>
      {children}
    </Link>
  ) : (
    children
  );

export default WithLink;
