import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectedFilters from '../SelectedFilters';
import CategoryFiltersSort from './CategoryFiltersSort';
import CategoryFiltersGroup from './CategoryFiltersGroup';
import FilterButton from '../Buttons/FilterButton';
import { filterTypes } from '../../types/categoryFilterTypes';
import { Checkbox } from '../Form/FormItem/FormItems';
import './CategoryFilter.scss';
import DropArrow from '../DropArrow';
import Select from '../Select';

const CategoryFiltersGroupButton = ({ label, active, onClick }) => (
  <FilterButton
    onClick={onClick}
    active={active}
    className={'default'}
    buttonType={'filter'}
  >
    {label} <DropArrow small direction={active ? 'up' : 'down'} />
  </FilterButton>
);

class CategoryFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleGroups: []
    };
  }

  toggleGroupVisibility = id => {
    this.setState(prevState => {
      if (prevState.visibleGroups.includes(id)) {
        return {
          visibleGroups: prevState.visibleGroups.filter(item => item !== id)
        };
      } else {
        return {
          // visibleGroups: [...prevState.visibleGroups, id]
          visibleGroups: [id]
        };
      }
    });
  };

  render() {
    const {
      id,
      recipe,
      filters,
      selectedFilters,
      pagination,
      filterAction,
      sortProducts,
      sortOptions,
      filterByPrice,
      activeAssortment
    } = this.props;
    const { visibleGroups } = this.state;
    return (
      <div className="c-category-filters">
        <div
          className={`c-category-filters__top ${
            filterByPrice ? 'c-category-filters__top--customfilter' : null
          }`}
        >
          <div className="c-category-filters__filters">
            {filters?.length > 0 &&
              filters?.map(({ id, options }) =>
                options?.length > 0 ? (
                  <CategoryFiltersGroupButton
                    key={id}
                    onClick={() => this.toggleGroupVisibility(id)}
                    active={visibleGroups.includes(id)}
                    label={filterTypes[id] || id}
                  />
                ) : null
              )}
            <div className="c-category-filters__sort--mobile">
              <Select
                onChange={value =>
                  sortProducts(id, {
                    Sort: value,
                    page: 0
                  })
                }
                options={sortOptions}
                defaultValue={pagination.sort}
              />
            </div>
          </div>
          {filterByPrice && activeAssortment && (
            <div className="c-category-filters__customsort">
              Visa endast köpbara recept{' '}
              <div>
                <Checkbox
                  isChecked={pagination.priceonly}
                  onClick={() =>
                    filterByPrice(id, {
                      page: 0,
                      priceonly: !pagination.priceonly
                    })
                  }
                />
              </div>
            </div>
          )}
          <div className="c-category-filters__sort">
            <CategoryFiltersSort
              id={id}
              options={sortOptions}
              sortAction={sortProducts}
              activeSort={pagination.sort}
            />
          </div>
        </div>
        {filters?.length > 0 &&
          filters
            ?.filter(item => visibleGroups.includes(item.id))
            ?.map(item => {
              return (
                <CategoryFiltersGroup
                  key={item.id}
                  filterAction={filterAction}
                  {...item}
                  heading={filterTypes[item.id] || item.id}
                  recipe={recipe}
                />
              );
            })}
        {selectedFilters.length > 0 && (
          <div className="c-category-filters__bottom">
            <SelectedFilters
              filters={selectedFilters}
              deselectFilter={filterAction}
            />
          </div>
        )}
      </div>
    );
  }
}

CategoryFilter.propTypes = {
  id: PropTypes.number,
  filters: PropTypes.array,
  selectedFilters: PropTypes.array,
  sortProducts: PropTypes.func,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  filterAction: PropTypes.func,
  pagination: PropTypes.object,
  recipe: PropTypes.bool,
  filterByPrice: PropTypes.func,
  isAuthenticated: PropTypes.bool
};

CategoryFilter.defaultProps = {
  filters: [],
  selectedFilters: [],
  sortOptions: []
};

export default CategoryFilter;
