import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button } from '../Buttons';
import './ErrorInfo.scss';

class ErrorInfo extends Component {
  state = {
    online: true
  };

  componentDidMount() {
    window.addEventListener('online', this.setOnline);
    window.addEventListener('offline', this.setOffline);
    this.setState({
      online: navigator && navigator.onLine
    });
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.setOnline);
    window.removeEventListener('offline', this.setOffline);
  }

  setOnline = () => {
    this.setState({
      online: true
    });
  };

  setOffline = () => {
    this.setState({
      online: false
    });
  };

  render() {
    const {
      title,
      text,
      retryButtonFn,
      offlineHint = true,
      sideMargins = false,
      fullWidth = false,
      left = false
    } = this.props;
    const { online } = this.state;
    const showOfflineHint = offlineHint && !online;

    return (
      <div
        className={cs('c-error-info', {
          'c-error-info--side-margins': sideMargins,
          'c-error-info--full-width': fullWidth,
          'c-error-info--left-align': left
        })}
      >
        <div className="c-error-info__inner">
          {title && <h2 className="c-error-info__title">{title}</h2>}
          {text && <div className="c-error-info__text">{text}</div>}
          {showOfflineHint && (
            <div className="c-error-info__offline">
              Innehållet finns inte tillgänglig offline, kontrollera din
              internetanslutning.
            </div>
          )}
          {retryButtonFn && (
            <div className="c-error-info__retry">
              <Button onClick={retryButtonFn} primary>
                Försök igen
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ErrorInfo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  retryButtonFn: PropTypes.func,
  offlineHint: PropTypes.bool,
  sideMargins: PropTypes.bool,
  fullWidth: PropTypes.bool,
  left: PropTypes.bool
};

export default ErrorInfo;
