import {
  FETCH_CUSTOMER_REQUESTED,
  FETCH_CUSTOMER_RECEIVED,
  FETCH_CUSTOMER_FAILED,
  REGISTER_CUSTOMER_REQUESTED,
  REGISTER_CUSTOMER_RECEIVED,
  REGISTER_CUSTOMER_FAILED,
  REGISTER_CUSTOMER_CLEAR,
  REGISTER_CUSTOMER_CLEAR_ERROR
} from '../../types/reducers/customer';
import { CustomerData, UserData } from '../../types/customer/UserData';
import { NewCustomerData } from '../../api/endpoints/customer';

export const requestCustomer = (customerId: string | number) => ({
  type: FETCH_CUSTOMER_REQUESTED,
  fetching: true,
  customerId: customerId
});

export const receiveCustomer = (payload: CustomerData) => ({
  type: FETCH_CUSTOMER_RECEIVED,
  payload: payload
});

export const fetchCustomerError = err => ({
  type: FETCH_CUSTOMER_FAILED,
  err
});

export const requestNewCustomer = (customer : NewCustomerData) => ({
  type: REGISTER_CUSTOMER_REQUESTED,
  fetching: true,
  customer: customer
});

export const receiveNewCustomer = (payload: UserData) => ({
  type: REGISTER_CUSTOMER_RECEIVED,
  payload: payload
});

export const newCustomerError = err => ({
  type: REGISTER_CUSTOMER_FAILED,
  err
});

export const clearCustomer = () => ({
  type: REGISTER_CUSTOMER_CLEAR
});

export const clearError = () => ({
  type: REGISTER_CUSTOMER_CLEAR_ERROR
});
