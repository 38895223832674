import moment from 'moment';
import React from 'react';

const ProductOfferEndDate = ({ product }) => {
  const handleShowEndDate = promotions => {
    const date = new Date();
    const fromDate = new Date(promotions.from);
    const toDate = new Date(promotions.to);

    if (date < fromDate) {
      return null;
    }

    if (date >= fromDate && date <= toDate) {
      return `Erbjudandet gäller ${moment(fromDate).format('DD/MM')} - ${moment(
        toDate
      ).format('DD/MM')}`;
    }

    return null;
  };

  return (
    <div>
      {product &&
        product.price.promotions2.map((promotions, index) => (
          <span className="c-productdetails__offer-details" key={index}>
            {handleShowEndDate(promotions)}
          </span>
        ))}
    </div>
  );
};

export default ProductOfferEndDate;
