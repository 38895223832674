import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Buttons';
import PriceTag from '../../components/PriceTag';
import {
  LazyProgressivePicture,
  ImageFitTypes
} from '../../components/ProgressivePicture';
import './RecipeSpot.scss';

const RecipeSpot = ({ recipe, singleMeta }) => {
  const { description, themeText, image } = singleMeta;
  return (
    <Link
      to={{
        pathname: recipe.url
      }}
      className="b-recipespot"
    >
      <div className="b-recipespot__image">
        <LazyProgressivePicture
          largeImage={image ? image : recipe.image}
          inheritHeight
          fitType={ImageFitTypes.Cover}
        />
      </div>
      <div className="b-recipespot__body">
        <div className="c-center">
          <div className="name">{recipe.name}</div>
          <div className="content">
            <div className="expression">{themeText}</div>
            <div className="description">{description}</div>
            <div className="meta">
              {recipe.cookingTime} minuter  •  4 portioner
            </div>
            <div className="shop">
              {recipe.price && <PriceTag price={{ value: recipe.price }} />}
              <Button primary>Se receptet</Button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default RecipeSpot;
