import React from 'react';
import throttle from 'lodash.throttle';
import {
  addScrollListener,
  removeScrollListener
} from '../../lib/windowEvents';

export const withInfiniteScroll = conditionFn => Component => {
  return class WithInfiniteScroll extends React.Component {
    constructor(props) {
      super(props);

      // Store a reference to the throttled function
      this.trottledFunction = throttle(this.onScroll, 100);
    }

    componentDidMount() {
      addScrollListener(this.trottledFunction);
    }

    componentWillUnmount() {
      removeScrollListener(this.trottledFunction);
    }

    onScroll = () =>
      conditionFn(this.props) && this.props.requestMoreProducts();

    render() {
      return <Component {...this.props} />;
    }
  };
};

export default withInfiniteScroll;
