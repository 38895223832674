import React, { Component } from 'react';
import { ErrorBoundary } from '../Error/ErrorBoundary';
import { Route, Redirect } from 'react-router-dom';

export const WrappedComponent = ({
  component: Component,
  layout: Layout,
  subRoutes,
  layoutProps = {},
  ...rest
}) => {
  return (
    <Layout {...rest} {...layoutProps}>
      <ErrorBoundary componentName={Component.name}>
        <Component {...{ ...rest, subRoutes }} />
      </ErrorBoundary>
    </Layout>
  );
};

export const RouteWithSubroutes = ({
  component,
  layout,
  layoutProps,
  subRoutes,
  navigationhasFetched,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <WrappedComponent
        component={component}
        subRoutes={subRoutes}
        layout={layout}
        layoutProps={layoutProps}
        navigationhasFetched={navigationhasFetched}
        {...props}
      />
    )}
  />
);

export const ProtectedRoute = ({
  component,
  layout,
  layoutProps,
  subRoutes,
  isAuthenticated,
  navigationhasFetched,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <InnerProtectedRoute
        component={component}
        layout={layout}
        layoutProps={layoutProps}
        subRoutes={subRoutes}
        isAuthenticated={isAuthenticated}
        navigationhasFetched={navigationhasFetched}
        routeRenderProps={props}
      />
    )}
  />
);

class InnerProtectedRoute extends Component {
  state = {
    shouldMount: false
  };

  componentDidMount() {
    /**
     * The reason we do this is to avoid rendering different content on server and client.
     * Protected routes will otherwise always render a <Redirect/> on the server and
     * then render the <WrappedComponent/> on first client render if the user is authorized.
     * This causes bugs in the rendered html since react can't rehydrate properly.
     * By always rendering null on the server and on the first client render we avoid these bugs.
     * Since it's a protected route which does not need any SEO we should be fine with this solution.
     */
    this.setState(() => ({ shouldMount: true }));
  }

  render() {
    const {
      component,
      layout,
      layoutProps,
      subRoutes,
      isAuthenticated,
      navigationhasFetched,
      routeRenderProps
    } = this.props;

    if (!this.state.shouldMount) {
      return null;
    }

    if (isAuthenticated) {
      return (
        <WrappedComponent
          component={component}
          subRoutes={subRoutes}
          layout={layout}
          layoutProps={layoutProps}
          navigationhasFetched={navigationhasFetched}
          {...routeRenderProps}
        />
      );
    }

    return (
      <Redirect
        to={{ pathname: '/login', state: { from: routeRenderProps.location } }}
      />
    );
  }
}
