import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { togglePortalOverlay } from '../../actions/app';
import Overlay from './Overlay';

class OverlayPortal extends Component {
  componentDidMount() {
    const { show, togglePortalOverlay } = this.props;
    togglePortalOverlay(show);
  }

  componentDidUpdate(prevProps) {
    const { show, togglePortalOverlay } = this.props;
    if (prevProps.show !== show) {
      togglePortalOverlay(show);
    }
  }

  componentWillUnmount() {
    this.props.togglePortalOverlay(false);
  }

  render() {
    return ReactDOM.createPortal(
      <Overlay {...this.props} />,
      this.props.overlayRef
    );
  }
}

OverlayPortal.propTypes = {
  show: PropTypes.bool,
  togglePortalOverlay: PropTypes.func.isRequired,
  overlayRef: PropTypes.any.isRequired
};

const mapStateToPropsPortalOverlay = state => ({
  modal: state.app.modal,
  portalOverlay: state.app.portalOverlay
});

const mapDispatchToPropsPortalOverlay = {
  togglePortalOverlay
};

export default connect(
  mapStateToPropsPortalOverlay,
  mapDispatchToPropsPortalOverlay
)(OverlayPortal);
