import React from 'react';
import RichText from '../../containers/RichText';
import BlockGrid, { BlockGridItem } from '../../components/BlockGrid';

import './RichTextGrid.scss';
import {
  IRichTextGridProps,
  IRichTextGridColumn
} from '../../types/episerver/blocks/RichTextGrid';

const TextColumn = ({ enabled, text }: IRichTextGridColumn) =>
  enabled ? (
    <BlockGridItem>
      <RichText text={text} />
    </BlockGridItem>
  ) : null;

const RichTextGrid: React.FC<IRichTextGridProps> = ({
  headline,
  column1,
  column2,
  column3,
  column4
}) => (
  <div className="b-rich-text-grid">
    {headline && <h2 className="b-rich-text-grid__headline">{headline}</h2>}
    <BlockGrid>
      {column1 ? <TextColumn {...column1} /> : null}
      {column2 ? <TextColumn {...column2} /> : null}
      {column3 ? <TextColumn {...column3} /> : null}
      {column4 ? <TextColumn {...column4} /> : null}
    </BlockGrid>
  </div>
);

export default RichTextGrid;
