import { sendCustomerMail, sendComplainMail } from '../../api/endpoints/mail';

export const sendCustomerEmail = (
  { name, phone, email, body, kdbId },
  reCaptcha
) => dispatch => {
  return sendCustomerMail(name, phone, email, body, kdbId, reCaptcha).then(
    ({ data }) => data
  );
};

export const sendComplainEmail = (
  { name, phone, email, products, body, kdbId },
  reCaptcha
) => dispatch => {
  return sendComplainMail(
    name,
    phone,
    email,
    products,
    body,
    kdbId,
    reCaptcha
  ).then(({ data }) => data);
};
