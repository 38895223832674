import { createSelector } from 'reselect';
import { activeStoreNoSelector } from '../assortments';
import { determinePrice } from '../../lib/product';
import { setImage } from '../../lib/product';
import groupby from 'lodash.groupby';

export const getSingleCateredMeal = createSelector(
  (state, id) => ({
    items: state.catering.products,
    storeId: activeStoreNoSelector(state),
    id
  }),
  catering => ({
    products: catering.items
      .filter(item => item.id === catering.id)
      .map(item => {
        let filteredVariants;

        if (catering.storeId) {
          filteredVariants = item.variants.filter(
            x =>
              x.prices &&
              x.prices.length > 0 &&
              x.prices.some(y => y.storeNumber === catering.storeId) &&
              x.visible === true
          );
        } else {
          filteredVariants = item.variants.filter(
            x => x.prices && x.prices.length > 0 && x.visible === true
          );
        }

        const variants = filteredVariants.map((variant, index) => ({
          ...variant,
          prices: determinePrice(
            variant.prices,
            variant.prices[0],
            catering.storeId
          ),
          isChecked: index === 0
        }));

        return {
          ...item,
          image: setImage(item.variants[0].images, 0, 'catering'),
          variants: groupby(variants, variant => variant.name)
        };
      })
  })
);
