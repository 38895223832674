import React from 'react';
import PropTypes from 'prop-types';
import { userPropType } from '../../prop-types';
import Form, {
  FormItem,
  SubmitButton,
  ErrorMessage,
  Row,
  Column
} from '../../components/Form';

const ContactForm = ({ onSubmit, formLoading, user, sent, formError }) => {
  const { fullName, email, mobilePhone, kdbId } = user;
  if (sent) {
    return (
      <div>
        Formuläret har skickats & du kommer nu att få en kopia skickad till din
        mail.
      </div>
    );
  }
  return (
    <Form
      onSubmit={onSubmit}
      loading={formLoading}
      useRecaptcha
      errorList={formError && [formError]}
    >
      {kdbId && <FormItem type="hidden" id="kdbid" value={kdbId} />}

      <p className="c-customerservice__formparagraph">
        {' '}
        Har du en fråga till oss? Använd formuläret nedan.{' '}
      </p>

      <Row>
        <Column width={'sm'}>
          <FormItem
            id="name"
            type="text"
            label="Namn"
            placeholder="För- och efternamn"
            maxLength={40}
            autoComplete="off"
            defaultValue={fullName}
            required
          />
        </Column>
        <Column width={'sm'}>
          <FormItem
            id="phone"
            type="number"
            label="Telefon"
            placeholder="Telefonnummer"
            maxLength={20}
            defaultValue={mobilePhone}
            autoComplete="off"
          />
        </Column>
        <Column width={'sm'}>
          <FormItem
            id="email"
            type="email"
            label="E-postadress"
            placeholder="E-postadress"
            defaultValue={email}
            autoComplete="off"
            required
          />
        </Column>
      </Row>
      <FormItem
        id="body"
        type="textarea"
        label="Beskriv ditt ärende"
        placeholder="Meddelande"
        required
        autoComplete="off"
      />

      <ErrorMessage />
      <p className="c-customerservice__formparagraph">
        Vi behandlar ditt ärende så fort som möjligt och hör av oss
      </p>
      <SubmitButton primary> Skicka ditt ärende </SubmitButton>
    </Form>
  );
};

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  formLoading: PropTypes.bool,
  user: userPropType,
  sent: PropTypes.bool,
  formError: PropTypes.any
};

export default ContactForm;
