import React from 'react';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import LoadingBlock from '../../components/LoadingBlock';
import ErrorInfo from '../../components/ErrorInfo';

export const FullWidthPage = ({ blocks, error = false, errorRetryFn }) => {
  if (error) {
    return (
      <ErrorInfo
        title="Hoppsan, något gick fel!"
        text="Det går inte att hämta denna sidan just nu."
        retryButtonFn={errorRetryFn}
      />
    );
  }
  return blocks && blocks.length > 0 ? (
    <ContentBlocksArea blocks={blocks} />
  ) : (
    <LoadingBlock />
  );
};

export default FullWidthPage;
