import pages from '../../../pages';
import { makeRegistry } from '../../../registry';
import { identity } from '../../utils';
import { mapPageTypeToRouteType } from '../defaults';
const Registry = makeRegistry(pages);

const createNode = (tree, subRoutes = []) => ({
  id: tree.id,
  pageId: tree.pageId,
  name: tree.name,
  type: tree.type,
  path: tree.link ? tree.link.url : '/',
  component: Registry.lookup(tree.type).component,
  subRoutes
});

const createSubroutes = tree => {
  return createNode(tree, tree.children.map(child => createSubroutes(child)));
};

const recurFlatWithProps = (prop, cb = identity) => {
  const recurFlat = routes =>
    routes.reduce(
      (acc, route) => acc.concat(cb(route), recurFlat(route[prop] || [])),
      []
    );
  return recurFlat;
};

export const resolveRoutes = (tree = {}, staticRoutes = []) => {
  if (!tree.children) {
    return [];
  }

  staticRoutes.map(route => tree.children.push(route));

  const { subRoutes, ...root } = createSubroutes(tree);

  return [Object.assign({ subRoutes: [] }, root)].concat(subRoutes);
};

export const resolveRoutesFlat = (tree = {}) => {
  if (!tree.children) {
    return [];
  }

  const fn = recurFlatWithProps('children', createNode);

  return [createNode(tree)].concat(fn(tree.children));
};

/* performs a recursive flatMap which concats the route with all its children. The wrapping concat turns our array of arrays to a flat structure */
export const fromTree = args =>
  Array.prototype.concat(
    ...args.map(route => [route, ...fromTree(route.subRoutes || [])])
  );

// TODO duplicate in define.js
export const resolveWilcardRoutes = routes =>
  routes
    .filter(
      ({ type }) =>
        (type.toLowerCase().includes('folder') &&
          !type.toLowerCase().includes('bag')) ||
        type === 'DeliveryDistrictListPage'
    )
    .map((route, i) =>
      createNode({
        id: `${route.id}:${i}`,
        pageId: route.id,
        exact: false,
        name: 'TODO',
        link: {
          internal: true,
          url: mapPageTypeToRouteType[route.type].conditionalPattern
        },
        type: `${route.type.replace('Folder', 'Single')}`,
        subRoutes: []
      })
    );
