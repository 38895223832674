import React from 'react';
import { dateAsFormat } from '../../lib/delivery';

const AvailabilityText = ({ availability }) => {
  const { availableFrom, availableTo, expired } = availability;
  return (
    <span className="c-availability__text">
      {availableFrom ? (
        <>Tillgänglig {dateAsFormat(new Date(availableFrom), 'd/M')}</>
      ) : availableTo ? (
        <>Tillgänglig till {dateAsFormat(new Date(availableTo), 'd/M')}</>
      ) : expired ? (
        <>Utgått</>
      ) : null}
    </span>
  );
};

export default AvailabilityText;
