import React, { memo } from 'react';
import contentBlocks from '../../contentblocks';
import { makeRegistry } from '../../registry';
import { zip } from '../../lib/utils';

const registry = makeRegistry(contentBlocks);

const ContentBlocksArea = ({ blocks = [] }) => {
  if (blocks.length > 0) {
    return zip(blocks.map(({ type }) => registry.lookup(type)), blocks).map(
      ([Comp, data], index) => (
        <Comp key={data.id + '' + index} blockId={data.id} {...data} />
      )
    );
  }
  return null;
};

export default memo(ContentBlocksArea);
