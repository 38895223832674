const NAME = 'cart';

export const REPLACEMENT_MODE_ENTERED = `${NAME}/CART_REPLACEMENT_MODE_ENTERED`;
export const REPLACEMENT_MODE_EXITED = `${NAME}/CART_REPLACEMENT_MODE_EXITED`;
export const CART_RECEIVED = `${NAME}/CART_RECEIVED`;
export const CART_SYNC = `${NAME}/CART_SYNC`;
export const CART_REPLACE = `${NAME}/CART_REPLACE`;
export const ITEM_ADDED = `${NAME}/ITEM_ADDED`;
export const ITEM_ADDED_WITH_DEBOUNCE = `${NAME}/ITEM_ADDED_WITH_DEBOUNCE`;
export const ITEMS_ADDED = `${NAME}/ITEMS_ADDED`;
export const DEBOUNCE_START = `${NAME}/DEBOUNCE_START`;
export const ADD_TO_IMEDIATE_QUEUE = `${NAME}/ADD_TO_IMEDIATE_QUEUE`;
export const ADD_TO_QUEUE = `${NAME}/ADD_TO_QUEUE`;
export const SET_DEBOUNCE = `${NAME}/SET_DEBOUNCE`;
export const QUEUE_READY = `${NAME}/QUEUE_READY`;
export const PENDING_FIRST_ADD = `${NAME}/PENDING_FIRST_ADD`;
export const CLEAR_QUEUE = `${NAME}/CLEAR_QUEUE`;
export const APPLY_QUEUED_ACTIONS = `${NAME}/APPLY_QUEUED_ACTIONS`;
export const ITEM_REMOVED = `${NAME}/ITEM_REMOVED`;
export const ITEMS_REMOVED = `${NAME}/ITEMS_REMOVED`;
export const RECIPE_ADDED = `${NAME}/RECIPE_ADDED`;
export const RECIPE_REMOVED = `${NAME}/RECIPE_REMOVED`;
export const QUANTITY_INCREASED = `${NAME}/QUANTITY_INCREASED`;
export const QUANTITY_DECREASED = `${NAME}/QUANTITY_DECREASED`;
export const SET_PRODUCT_REFS = `${NAME}/SET_PRODUCT_REFS`;
export const RECIPE_QUANTITY_INCREASED = `${NAME}/RECIPE_QUANTITY_INCREASED`;
export const RECIPE_QUANTITY_DECREASED = `${NAME}/RECIPE_QUANTITY_DECREASED`;
export const SET_RECIPE_REFS = `${NAME}/SET_RECIPE_REFS`;

export const CATERING_ADDED = `${NAME}/CATERING_ADDED`;
export const CATERING_REMOVED = `${NAME}/CATERING_REMOVED`;
export const CATERINGS_REMOVED = `${NAME}/CATERINGS_REMOVED`;
export const SET_CATERED_MEALS_REFS = `${NAME}/SET_CATERED_MEALS_REFS`;

export const CART_LOADING = `${NAME}/CART_LOADING`;
export const CART_ERROR = `${NAME}/CART_ERROR`;
export const ITEM_ADD_ERROR = `${NAME}/ITEM_ADD_ERROR`;
export const CLEAR_ITEM_ADD_ERRORS = `${NAME}/CLEAR_ITEM_ADD_ERRORS`;
export const CART_REQUESTED = `${NAME}/CART_REQUESTED`;
export const REFRESH_CART = `${NAME}/CART_REFRESH`;

export const CLEAR_ITEMS = `${NAME}/CLEAR_ITEMS`;
export const DELETE_USABLE_CARTS = `${NAME}/DELETE_USABLE_CARTS`;

export const SET_HANDLE = `${NAME}/SET_HANDLE`;
export const MAGIC = { recipe: 'receptkassen', flex: 'flexkassen' };
export const SET_SELECTED_SLOT_ID = `${NAME}/SET_SELECTED_SLOT_ID`;

export const DISCOUNT_APPLY = `${NAME}/DISCOUNT_APPLY`;
export const DISCOUNT_FAILED = `${NAME}/DISCOUNT_FAILED`;
export const DISCOUNT_SUCCESS = `${NAME}/DISCOUNT_SUCCESS`;
export const DISCOUNT_REMOVE = `${NAME}/DISCOUNT_REMOVE`;
export const DISCOUNT_RESET = `${NAME}/DISCOUNT_RESET`;

export const UPDATE_LOWEST_PICKING_FEE = `${NAME}/UPDATE_LOWEST_PICKING_FEE`;
