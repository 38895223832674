import axios from "axios";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { graphQlQuery } from '../../lib/utils.js';

export type TClearCookies = {
  cookieCategoriesToClear: number[]
}
  
export const ClearCookies = ({ cookieCategoriesToClear }: TClearCookies) => {
	useEffect(() => {
    getCookies();
  }, []);

	const getCookies = async () => {
		const data = await graphQlQuery('query { getCookies { domain, cookies { Name, Category }}}');

		if (data && data.getCookies && data.getCookies.cookies) {
			const { getCookies: { cookies } } = data;

			cookies
				.filter(cookie => cookieCategoriesToClear.some(category => category === parseInt(cookie.Category)))
				.forEach(cookie => Cookies.remove(cookie.Name));
	
			window.location.reload();
		}
	}

  return null;
}

export default ClearCookies;
