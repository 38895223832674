import { createSelector } from 'reselect';
import { defineRoutes } from '../../lib/route';
import { authenticatedSelector } from '../user';

const routesSelector = state => state.navigation.routes;
const rootRoutesSelector = state => state.navigation.rootRoutes;

export const defineRoutesSelector = createSelector(
  [routesSelector, rootRoutesSelector, authenticatedSelector],
  (routes, rootRoutes, isAuthenticated) =>
    defineRoutes(routes, rootRoutes, { isAuthenticated })
);
