import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import * as pageActions from '../../actions/page';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import FullWidthPageModule from '../../modules/FullWidthPage';

function CateringFolderPage({ page, title, id, getPageById}) {
  const [pageError, setPageError] = useState(false);
  useEffect(() => {
    getPageContent();
  }, [page.id !== id]);

  function getPageContent() {
    getPageById(id).catch(() => {
      setPageError(false);
    });
  }

  return (
    <>
      <PageTitle {...title} />
      <FullWidthPageModule
        {...page}
        title={title}
        blocks={page.contentBlocks}
        error={pageError}
        errorRetryFn={getPageContent}
      />
    </>
  );
}

CateringFolderPage.required = [
  (state, params, noCache) => {
    return pageActions.getPageById(params.id, state.router.location.search, noCache, params.userSiteId);
  }
];

function mapStateToProps(state, props) {
  return {
    title: breadCrumbSelector(state, props),
    page: pageSelector(state, props),
    id: pageIdSelector(state, props)
  };
}

const mapDispatchToProps = {
  getPageById: pageActions.getPageById,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CateringFolderPage)
);
