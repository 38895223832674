import React from 'react';

type TRowsProps = {
  nutrient: {
    name: string;
    value: string;
  } | null;
};

const ProductNutritionTableRows = ({ nutrient }: TRowsProps) => (
  <tr>
    <td>{nutrient?.name}</td>
    <td>{nutrient?.value}</td>
  </tr>
);

export default ProductNutritionTableRows;
