export const findAvailabilityByStore = (availabilityArray, store) => {
  return availabilityArray.find(
    availability => availability?.storeNumber === store
  );
};

export const isAvailableInFuture = (fromDate: Date, currentDate: Date) => {
  return fromDate && fromDate > currentDate;
};

export const isAvailableAndNearExpiryDate = (
  fromDate: Date,
  toDate: Date,
  currentDate: Date,
  closeInterval = 7
) => {
  return (
    fromDate &&
    fromDate <= currentDate &&
    toDate &&
    toDate > currentDate &&
    currentDate >= new Date(toDate.setDate(toDate.getDate() - closeInterval))
  );
};

export const isExpired = (toDate: Date, currentDate: Date) => {
  return toDate && toDate < currentDate;
};

export const isIncomingStock = (
  stockStatus: number,
  fromDate: Date,
  currentDate: Date
) => {
  return stockStatus === 2 && fromDate > currentDate;
};
