import React, { useState, useEffect } from 'react';
import { IVideoBlockProps } from '../../types/episerver/blocks/VideoBlock';
import Observer from 'react-intersection-observer'
import withBaseBlock from '../../components/BaseBlock';
import ReactResizeDetector from 'react-resize-detector';
import { domSafe } from '../../lib/document';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;

const VideoBlock: React.FC<IVideoBlockProps> = ({
	mp4Src,
	mp4SrcMobile,
	shouldAutoPlay,
	shouldHideControls,
	shouldLoopVideo,
	link,
	shouldBeMuted
}) => {
	const breakpoint = 768;
	const [mobile, setMobile] = useState(false);
	const [width, setWidth] = useState(domSafe() ? window.innerWidth : 0);
	const [videoUrl, setVideoUrl] = useState(mp4Src.url);

	const videoRef = React.useRef<HTMLVideoElement>(null);
	const sourceRef = React.useRef<any>(null);
	const muted = shouldAutoPlay ? true : shouldBeMuted;
	useEffect(() => {
		if (mp4SrcMobile && width < breakpoint) {
			setMobile(width < breakpoint);
			setVideoUrl(mp4SrcMobile.url);
		} else {
			setVideoUrl(mp4Src.url);
		}
	}, []);

	const onVisibilityChange = (inView: boolean) => {
		if (videoRef && videoRef.current && shouldAutoPlay) {
			if (inView) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
			}
		}
	}

	const onresize = () => {
		// Early exit if no mobile video
		if (!mp4SrcMobile || videoRef.current === null) {
			return;
		}

		var newMobile = window.innerWidth < breakpoint;
		if (newMobile !== mobile) {
			// Switched from mobile -> desktop or vice versa
			const isPlaying = !videoRef.current.paused;
			setMobile(newMobile);
			setWidth(window.innerWidth);
			videoRef.current.pause();

			if (newMobile) {
				sourceRef.current.src = mp4SrcMobile.url;
			} else {
				sourceRef.current.src = mp4Src.url;
			}
			videoRef.current.oncanplay = () => {
				if (isPlaying) {
					videoRef.current?.play();
				}
			};
			videoRef.current.load();
		}
	}

	return (
		<div>
			<ReactResizeDetector handleHeight onResize={() => onresize()} />
			<Observer onChange={(inView: boolean) => onVisibilityChange(inView)}>
				<ConditionalWrapper
					condition={link}
					wrapper={children => <a href={link.url}>{children}</a>}
				>
					<div className="b-video-block">
						{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
						<video preload="metadata" playsInline ref={videoRef} muted={muted} controls={!shouldHideControls} loop={shouldLoopVideo}>
							<source ref={sourceRef} src={`${videoUrl}#t=0.1`} type="video/mp4" />
						</video>
					</div>
				</ConditionalWrapper>
			</Observer>
		</div>
	)
};

export default withBaseBlock(VideoBlock);
