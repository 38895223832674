import { isFetching, receiveNavigation, fetchNavigationError } from './sync';
import * as endpoints from '../../api/endpoints/navigation';
import { requestRetry, REQUEST_IDS } from '../../lib/request/requestRetry';

import { Dispatch } from 'redux';

export const getNavigation = (noCache?: boolean) => (dispatch: Dispatch) => {
  dispatch(isFetching(true));
  const cReq = noCache ? () => endpoints.fetchNavigation(noCache) : () => endpoints.fetchNavigation();

  return requestRetry(
    cReq,
    dispatch,
    REQUEST_IDS.navigation
  )
    .then(({data}) => {
      let res = data.data;
      dispatch(receiveNavigation(res));
      return data;
    })
    .catch(err => {
      dispatch(fetchNavigationError(err));
      return Promise.reject(err);
    });
};
