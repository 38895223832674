import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRecipeById } from '../../actions/recipe';
import { addRecipeToCart } from '../../actions/cart';
import { setRecipeFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import { open as openModal } from '../../actions/confirmModal';
import { makeRecipeEnhancerSelector } from '../../selectors/recipe/recipeSelectors';
import { authenticatedSelector } from '../../selectors/user';
import LoadingBlock from '../../components/LoadingBlock';
import * as PageLayoutTypes from '../../prop-types/PageTypePropType';

import RecipeSingle from '../../modules/RecipeSingle';

// This is needed since when rendering into its own page
// the match will render this component directly, in the flyout case
// it's the upper route that matches and the withRouter HOC will overwrite
// our passed props with their own props. So passing the { match, location, history } object directly won't work.
// See https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/withRouter.js#L14
const retrieveId = props => {
  const ownProps = props.ownProps || { match: { params: {} } };
  // ID of recipes starts with R, products does not have P{id}.
  // Therefor this prefix needs to be added here because ID from url matches on the R prefix...
  const recipeId =
    'R' + props.match.params.recipeId || ownProps.match.params.recipeId;

  return recipeId;
};

class RecipeSinglePage extends Component {
  componentDidMount() {
    this.getRecipe();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (prevProps.id !== id) {
      this.getRecipe();
    }
  }

  getRecipe = () => {
    const { recipe, isFetching, getRecipeById, id } = this.props;
    if (!recipe && !isFetching) {
      getRecipeById(id);
    }
  };

  render() {
    const { recipe, pageLayout } = this.props;
    return recipe ? (
      <RecipeSingle
        pageLayout={pageLayout ? pageLayout : PageLayoutTypes.SIDEBAR}
        {...this.props}
      />
    ) : (
      <LoadingBlock />
    );
  }
}

RecipeSinglePage.required = [
  (_, { params }) => getRecipeById(`R${params.recipeId}`)
];

const mapStateToProps = (state, props) => {
  const id = retrieveId(props);
  const selector = makeRecipeEnhancerSelector();
  const recipe = selector(state).find(r => r.id === id);
  return {
    id,
    recipe,
    isFetching: state.recipe.fetching,
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {
  getRecipeById,
  addRecipeToCart,
  redirectToLogin,
  setRecipeFavorite,
  removeFavorite,
  openModal
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecipeSinglePage)
);
