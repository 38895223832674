import React from 'react';
import Breadcrumbs from '../Breadcrumbs';

import './PageTitle.scss';

const dot = '>';

export const PageTitle = ({ parents = [], current, children }) => (
  <header className="c-pagetop">
    <div className="mast">
      <span className="breadcrumbs">
        {`${parents.join(' ' + dot + ' ')} ${parents.length ? dot : ''}`}{' '}
      </span>
      <h1>{current}</h1>
    </div>
    {children}
  </header>
);

export const LinkablePageTitle = ({ currentPage, children }) => (
  <header className="c-pagetop">
    <Breadcrumbs passedInCurrent={currentPage} />
    {children}
  </header>
);

export default PageTitle;
