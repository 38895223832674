import React from 'react';

const ProductPricePromotionsDisclaimer = ({ product }) => {
  return (
    <>
      {product.price?.promotions2 &&
        (parseInt(product.price?.promotions2[0]?.type || '0') === 1 ||
          parseInt(product.price?.promotions2[0]?.type || '0') === 5) &&
        product.price?.promotions2[0]?.minAmount && (
          <div className={'shop__disclaimer'}>
            <p>
              *Gäller ej spel, tidningar, tobak, läkemedel, presentkort, pant
              och alkoholhaltiga drycker eller i samband med andra rabatter.
            </p>
          </div>
        )}
    </>
  );
};

export default ProductPricePromotionsDisclaimer;
