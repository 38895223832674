import { eachDayOfInterval, addDays, isWeekend, format, subDays, isEqual, isAfter, isBefore } from 'date-fns';

export const convertToReadableTime = (time: string | Date) => {
  const timeObj = new Date(time);
  return `${timeObj.getHours()}:${('0' + timeObj.getMinutes()).slice(-2)}`;
};

/**
 * Gets date after businessdays & custom holidays is excluded
 *
 * @param {Date} from From date
 * @param {Number} count Amount of days to add
 * @param {Array} holidays Custom holiday dates
 */

export const addCustomBusinessDays = (from: Date, count: number, holidays: string[] = [] ) => {

  const daysArray = eachDayOfInterval({
    start: from,
    end: addDays(from, 365)
  });

  const validDaysInterval = daysArray.filter(day => {
    if(isWeekend(day) || holidays.some(x => x === format(day, 'yyyy-MM-dd'))) {
      return false
    }
    return true
  });

  return addDays(validDaysInterval[count-1], 1);
}

/**
 * Gets date after businessdays & custom holidays is excluded
 *
 * @param {Date} from From date
 * @param {Number} count Amount of days to add
 * @param {Array} holidays Custom holiday dates
 */
const subtractCustomBusinessDays = (from: Date, count: number, holidays: string[] = []) => {
  const daysArray = eachDayOfInterval({
    start: subDays(from, 365),
    end: from
  });

  const validDaysInterval = daysArray.filter(day => {
    if(isWeekend(day) || holidays.some(x => x === format(day, 'yyyy-MM-dd'))) {
      return false
    }
    return true
  });

  return validDaysInterval[validDaysInterval.length - (count + 1)];
}

interface TemporaryOrderBreak {
  orderBreak: number;
  startingPickupDate: Date;
  endingPickupDate: Date;
}

export const normaliseTime = (date: Date) => new Date(date.setHours(0,0,0,0));

/**
 * Calculates if the order break needs to be reset and the 
 * dates to exclude for a temporary change in order break
 *
 * @param {Number} defaultOrderBreak Default amount of order break days
 * @param {Array} customHolidayDates Custom holiday dates
 * @param {TemporaryOrderBreak} temporaryOrderBreak Temporary order break information
 */
export const setTemporaryOrderBreak = (
  defaultOrderBreak: number,
  customHolidayDates: string[] = [],
  tempOrderBreak: TemporaryOrderBreak,
) => {
  let altOrderBreak = defaultOrderBreak;
  let daysToExclude: Date[] = [];

  const today = normaliseTime(new Date());
  const tempStartDate = normaliseTime(tempOrderBreak.startingPickupDate);
  const tempEndDate = normaliseTime(tempOrderBreak.endingPickupDate);
  const newOrderBreakStart = normaliseTime(subtractCustomBusinessDays(
    tempStartDate, 
    tempOrderBreak.orderBreak, 
    customHolidayDates
  ));

  if (
    (isEqual(today, newOrderBreakStart) || isAfter(today, newOrderBreakStart)) 
    && isBefore(today, tempEndDate)
  ) {
    if (tempOrderBreak.orderBreak > defaultOrderBreak) {
      const interval = eachDayOfInterval({
          start: addCustomBusinessDays(today, defaultOrderBreak),
          end: addCustomBusinessDays(today, tempOrderBreak.orderBreak)
        })
        .filter(date => 
          (isAfter(date, tempStartDate) || isEqual(date, tempStartDate))
          && (isBefore(date, addDays(tempEndDate, 1)) || isEqual(date, addDays(tempEndDate, 1)))
        )
  
      daysToExclude = interval.slice(0, interval.length - 1);
    }
  
    if (tempOrderBreak.orderBreak < defaultOrderBreak ) {
      const interval = eachDayOfInterval({
          end: addCustomBusinessDays(today, defaultOrderBreak),
          start: addCustomBusinessDays(today, tempOrderBreak.orderBreak)
        })
        .filter(date => 
          isBefore(date, tempStartDate) || isAfter(date, tempEndDate)
        );
  
      altOrderBreak = tempOrderBreak.orderBreak;
      daysToExclude = isAfter(today, newOrderBreakStart) 
        ? interval.slice(0, interval.length - 1)
        : interval;
    }
  }
  
  return {
    altOrderBreak,
    daysToExclude
  };
}
