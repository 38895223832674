const createFragments = split => {
  const [current, ...parents] = split.reverse();

  return {
    parents: parents.reverse(),
    current
  };
};

const getPath = (lookup, path) => {
  if (lookup[path]) {
    return lookup[path].name;
  }

  return path;
};

export const routeSplit = route => {
  // eslint-disable-next-line no-unused-vars
  const [_firts, ...rest] = route.split('/');

  return createFragments(rest);
};

export const toRouteTransform = (lookup, path) => lookup[path];

export const splitPretty = (route, lookup, transform = getPath) => {
  const match = route.match(/(\/?[^/]+)/g) || [''];
  const paths = match
    .reduce((memo, path) => {
      let previous = memo[memo.length - 1] || '';
      let current = previous + path;

      return memo.concat([current]);
    }, [])
    .map(path => transform(lookup, path));

  return createFragments(paths);
};

export default routeSplit;
