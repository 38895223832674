import { domSafe } from './lib/document';

// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read http://bit.ly/CRA-PWA

class ServiceWorkerContainer {
  constructor() {
    this.registration = null;
    this.waitingServiceWorker = null;
    this.intervalTimer = null;
    this.reloading = false;
  }

  isLocalhost = () =>
    Boolean(
      window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );

  register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      // The URL constructor is available in all browsers that support SW.
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Our service worker won't work if PUBLIC_URL is on a different origin
        // from what our page is served on. This might happen if a CDN is used to
        // serve assets; see https://github.com/facebook/create-react-app/issues/2374
        return;
      }

      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (this.isLocalhost()) {
          // This is running on localhost. Let's check if a service worker still exists or not.
          this.checkValidServiceWorker(swUrl, config);

          // Add some additional logging to localhost, pointing developers to the
          // service worker/PWA documentation.
          navigator.serviceWorker.ready.then(() => {
            console.log(
              'This web app is being served cache-first by a service ' +
                'worker. To learn more, visit http://bit.ly/CRA-PWA'
            );
          });
        } else {
          // Is not localhost. Just register service worker
          this.registerValidSW(swUrl, config);
        }
      });
    }
  }

  forceUpdate = () => {
    if (this.registration) {
      this.registration.update();
    }
  };

  registerValidSW = (swUrl, config) => {
    navigator.serviceWorker.addEventListener('message', event => {
      if (event.data === 'reloadWindow') {
        window.location.reload();
      }
    });

    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        this.registration = registration;
        config.onRegistration();

        window.addEventListener('focus', () => {
          this.forceUpdate();
          this.intervalTimer = window.setInterval(
            this.forceUpdate(),
            60 * 1000 * 30 // Every 30 minutes
          );
        });

        window.addEventListener('blur', () => {
          if (this.intervalTimer) {
            window.clearInterval(this.intervalTimer);
          }
        });

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }

          config.onUpdateFound();
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                registration.waiting.postMessage('skipWaiting');
                config.onUpdateInstalled();
              }
            } else if (installingWorker.state === 'activated') {
              config.onActivated();
            }
          };
        };
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  };

  checkValidServiceWorker = (swUrl, config) => {
    fetch(swUrl)
      .then(response => {
        // Ensure service worker exists, and that we really are getting a JS file.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // No service worker found. Probably a different app. Reload the page.
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Service worker found. Proceed as normal.
          this.registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  };

  unregister = () => {
    if (!domSafe()) {
      return;
    }
    navigator?.serviceWorker?.getRegistrations()?.then(function(registrations) {
      for(let registration of registrations) {
       registration.unregister()
     } })
     
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
    }
  };
}

// In SSR we can't register a ServiceWorker, just return an empty "interface";
const serviceWorkerContainerInstance = domSafe()
  ? new ServiceWorkerContainer()
  : {};

export default serviceWorkerContainerInstance;
