import { connect } from 'react-redux';
import { activeNavigationSelector } from '../../selectors/routing/routingSelector';
import { appSettingsSelector } from '../../selectors/app';
import {
  hasNotificationStatusSelector,
  hasNotificationHeightSelector
} from '../../selectors/navigation';

import Sidebar from '../../modules/Sidebar';

const mapStateToProps = (state, props) => ({
  nav: activeNavigationSelector(state, props),
  activePath: props.match.path,
  settings: appSettingsSelector(state),
  notificationStatus: hasNotificationStatusSelector(state),
  notificationHeight: hasNotificationHeightSelector(state)
});

export default connect(mapStateToProps)(Sidebar);
