import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { ImagePropType } from '../../prop-types';
import { isObjectFitSupported } from '../../lib/document';
import { calcRatio, makeSrcSet } from './lib';
import { ImageFitTypes, ImageFitPositions } from './constants';
import Spinner from '../Spinner';

import './ProgressivePicture.scss';
import defaultPlaceholder from '../assets/image-fallback.svg';

const loadedImages = {};

class ProgressivePicture extends PureComponent {
  constructor(props) {
    super(props);

    this.imageRef = React.createRef();
    const hasLoadedBefore =
      loadedImages[props?.largeImage?.url] === true ? true : false;

    this.state = {
      loaded: hasLoadedBefore,
      thumbLoaded: false,
      error: false,
      objectFitSupported: true,
      thumbSrc: null,
      mounted: false
    };
  }

  componentDidMount() {
    const { showThumb } = this.props;

    // Trigger picture polyfill
    if (window && 'picturefill' in window && this.imageRef.current) {
      window.picturefill({
        elements: [this.imageRef.current]
      });
    }

    this.setState({
      objectFitSupported: isObjectFitSupported,
      mounted: true
    });

    if (this.imageRef.current && this.imageRef.current.complete) {
      this.onImageLoad();
    } else {
      if (showThumb) {
        this.loadThumb();
      }
    }
  }

  componentWillUnmount() {
    if (this.thumbImage) {
      this.thumbImage.onload = null;
    }
  }

  loadThumb = () => {
    if (this.state.loaded !== true) {
      const thumbSrc = `${this.props.largeImage?.url}?w=10`;
      this.thumbImage = new Image();
      this.thumbImage.onload = () => {
        this.setState({
          thumbLoaded: true
        });
      };
      this.setState({
        thumbSrc: thumbSrc
      });
      this.thumbImage.src = thumbSrc;
    }
  };

  onImageLoad = () => {
    if (!this.imageRef.current) {
      return;
    }

    const currentImageSource =
      this.imageRef.current.currentSrc ||
      // fallback for browsers not supporting srcSet (IE)
      this.imageRef.current.src;

    this.setState({
      loaded: true,
      imageSrc: currentImageSource
    });
    loadedImages[this.props.largeImage?.url] = true;
  };

  onImageLoadError = () => {
    this.setState({
      error: true
    });
  };

  render() {
    const {
      largeImage,
      smallImage,
      maxWidth,
      fitType,
      fitPosition,
      inheritHeight,
      placeholder,
      showSpinner
    } = this.props;

    const {
      loaded,
      mounted,
      thumbLoaded,
      thumbSrc,
      imageSrc,
      objectFitSupported,
      error
    } = this.state;

    const shouldLoadFallbackForObjectFit = fitType && !objectFitSupported;

    return (
      <div
        className={cs(
          'c-progressive-picture',
          fitType ? `c-progressive-picture--${fitType}` : '',
          fitType && fitPosition ? `c-progressive-picture--${fitPosition}` : ''
        )}
        style={{
          height: inheritHeight ? 'inherit' : null,
          width: inheritHeight ? '100%' : null
        }}
      >
        {!inheritHeight && (
          <Fragment>
            <div
              className="c-progressive-picture__ratio c-progressive-picture__ratio--lg"
              style={{
                paddingBottom: `${calcRatio(
                  largeImage?.width,
                  largeImage?.height
                )}%`
              }}
            />
            <div
              className="c-progressive-picture__ratio c-progressive-picture__ratio--sm"
              style={{
                paddingBottom: `${calcRatio(
                  (smallImage || largeImage)?.width,
                  (smallImage || largeImage)?.height
                )}%`
              }}
            />
          </Fragment>
        )}

        <picture className="c-progressive-picture__picture">
          <source
            srcSet={makeSrcSet(largeImage?.url, maxWidth?.large)}
            media="(min-width: 768px)"
          />
          <source
            srcSet={makeSrcSet((smallImage || largeImage)?.url, maxWidth?.small)}
            media="(max-width: 767px)"
          />
          <img
            srcSet={largeImage?.url}
            alt={largeImage?.alt}
            className={cs(
              'c-progressive-picture__img',
              { 'c-progressive-picture__img--mounted': mounted },
              { 'c-progressive-picture__img--loaded': loaded },
              {
                'c-progressive-picture__img--hidden': shouldLoadFallbackForObjectFit
              }
            )}
            ref={this.imageRef}
            onLoad={this.onImageLoad}
            onError={this.onImageLoadError}
          />
        </picture>

        {shouldLoadFallbackForObjectFit && (
          <div
            className={cs('c-progressive-picture__fallback', {
              'c-progressive-picture__fallback--loaded': loaded
            })}
            style={{
              backgroundImage: `url(${imageSrc})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: fitPosition,
              backgroundSize: fitType ? fitType : '100% 100%'
            }}
          />
        )}

        {thumbSrc && (
          <div
            className={cs(
              'c-progressive-picture__thumb',
              { 'c-progressive-picture__thumb--loaded': thumbLoaded },
              { 'c-progressive-picture__thumb--hidden': loaded }
            )}
            style={{
              backgroundImage: `url(${thumbSrc})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: fitPosition,
              backgroundSize: fitType ? fitType : '100% 100%'
            }}
          />
        )}

        {!loaded &&
          !error &&
          mounted &&
          showSpinner && (
            <div className="c-progressive-picture__spinner">
              <Spinner />
            </div>
          )}

        {error && (
          <div
            className="c-progressive-picture__error"
            style={{
              backgroundColor: placeholder.bgColor,
              backgroundImage: `url(${placeholder.image})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: fitPosition,
              backgroundSize: 'auto 80%'
            }}
          />
        )}
      </div>
    );
  }
}

ProgressivePicture.propTypes = {
  // Image to show for large devices
  largeImage: ImagePropType.isRequired,
  // Image to show for small devices,
  // will fallback to largeImage if not set.
  smallImage: ImagePropType,
  // Image maxwidths, use for image sizing
  // eg. ?w=XXXX
  // small must be set even if no smallImage
  maxWidth: PropTypes.shape({
    large: PropTypes.number.isRequired,
    small: PropTypes.number.isRequired
  }).isRequired,
  // Show thumbnail while loading highres
  showThumb: PropTypes.bool.isRequired,
  // Show spinner while loading highres
  showSpinner: PropTypes.bool.isRequired,
  // Object fit type and position
  fitType: PropTypes.oneOf(Object.values(ImageFitTypes)),
  fitPosition: PropTypes.oneOf(Object.values(ImageFitPositions)).isRequired,
  // Don't use image ratio for height,
  // instead sets height from parent
  inheritHeight: PropTypes.bool.isRequired,
  // Placeholder image to show onError
  placeholder: PropTypes.shape({
    // Path to image
    image: PropTypes.string.isRequired,
    // Background color, eg. 'transparent' or '#e8e8e8'
    bgColor: PropTypes.string.isRequired
  }).isRequired
};

ProgressivePicture.defaultProps = {
  showThumb: true,
  showSpinner: true,
  fitPosition: ImageFitPositions.Center,
  maxWidth: {
    large: 1860,
    small: 767
  },
  inheritHeight: false,
  placeholder: {
    image: defaultPlaceholder,
    bgColor: '#e8e8e8'
  }
};

export default ProgressivePicture;
