import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReCaptcha from '../ReCaptcha';
import { Button } from '../../Buttons';
import './SubmitButton.scss';

const SubmitButton = ({ children, style, onRecaptchaChange, ...rest }) => (
  <Fragment>
    {onRecaptchaChange && <ReCaptcha onRecaptchaChange={onRecaptchaChange} />}
    <div className="c-frm-submitbtn" style={style || {}}>
      <Button type="submit" {...rest}>
        {children}
      </Button>
    </div>
  </Fragment>
);

SubmitButton.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  onRecaptchaChange: PropTypes.func
};

SubmitButton.defaultProps = {
  primary: false
};

export default SubmitButton;
