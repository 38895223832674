import {
  OPEN_IN,
  CLOSE,
  TOGGLE_CONFIRMATION
} from '../../types/reducers/flyout';
import makeReducer from '../makeReducer';

const initialState = {
  title: '',
  component: null,
  show: false,
  opts: {},
  shouldConfirm: false
};

const ACTION_HANDLERS = {
  [OPEN_IN]: (state, action) => ({
    ...state,
    component: action.component,
    title: action.title,
    opts: action.opts || {},
    show: true
  }),
  [CLOSE]: (state, action) => ({
    ...state,
    show: false,
    component: null,
    opts: {},
    shouldConfirm: false
  }),
  [TOGGLE_CONFIRMATION]: (state, action) => ({
    ...state,
    shouldConfirm: action.payload
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
