import React from 'react';
import NewCartPreview from '../NewCartPreview/NewCartPreview';
import { Modal } from '@citygross/components';

interface IProps {
  handleSubmit?: any;
  cartId?: string;
  cartToBeChangedId?: string[];
}

const NewCartPreviewModal: React.FC<IProps> = ({
  handleSubmit,
  cartId,
  cartToBeChangedId
}) => {
  return cartId && cartToBeChangedId?.includes(cartId) ? null : (
    <Modal isConfirm={false} onBackdropClick={() => {}}>
      <NewCartPreview handleSubmit={handleSubmit} />
    </Modal>
  );
};

export default NewCartPreviewModal;
