import {
  FETCH_PAGE_REQUESTED,
  FETCH_PAGE_RECEIVED,
  FETCH_PAGE_FAILED
} from '../../types/reducers/page';

export function isFetchingPage(fetching) {
  return {
    type: FETCH_PAGE_REQUESTED,
    fetching
  };
}

export function receivePage(payload, isUpdate) {
  return {
    type: FETCH_PAGE_RECEIVED,
    data: payload.data,
    meta: payload.meta,
    isUpdate: isUpdate
  };
}

export function fetchPageError(error) {
  return {
    type: FETCH_PAGE_FAILED,
    error
  };
}
