import React from 'react';
import { Link } from 'react-router-dom';
import RichText from '../../containers/RichText';
import ProgressivePicture from '../../components/ProgressivePicture';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import { scrollToId } from '../../lib/scrollToId';
import ErrorInfo from '../../components/ErrorInfo';

import './DeliveryDistrictList.scss';

const DeliveryDistrictList = ({
  title,
  mainBody,
  image,
  topBlocks,
  bottomBlocks,
  cities,
  pageError,
  getListPageContent
}) => {
  return (
    <div className="b-delivery-district-list">
      {pageError && (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={getListPageContent}
        />
      )}
      {image && (
        <ProgressivePicture
          largeImage={image}
          maxWidth={{
            large: 1372,
            small: 747
          }}
        />
      )}
      {title && <h1 className="b-delivery-district-list__title">{title}</h1>}
      {mainBody && (
        <div className="b-delivery-district-list__body">
          <RichText text={mainBody} />
        </div>
      )}
      {topBlocks &&
        topBlocks.length > 0 && (
          <div className="b-delivery-district-list__top-blocks">
            <ContentBlocksArea blocks={topBlocks} />
          </div>
        )}
      {cities && (
        <div className="b-delivery-district-list__sites">
          <div className="b-delivery-district-list__sites-navbar">
            <ul className="b-delivery-district-list__sites-nav-list">
              {Object.keys(cities).map((group, index) => (
                <li key={group} className="b-delivery-district-list__nav-item">
                  <div
                    onClick={() => scrollToId(`#_${index}`)}
                    className="b-delivery-district-list__nav-link"
                  >
                    {group.toUpperCase()}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="b-delivery-district-list__columns">
            {Object.keys(cities).map((group, index) => (
              <div
                className="b-delivery-district-list__site-group"
                key={`group-${group.id}`}
              >
                <div className="anchor" id={`_${index}`}>
                  &nbsp;
                </div>
                <h4 className="b-delivery-district-list__site-group-title">
                  {group.toUpperCase()}
                </h4>
                <ul className="b-delivery-district-list__site-group-list">
                  {cities[group].map(({ city, url }) => (
                    <li
                      key={url}
                      className="b-delivery-district-list__site-group-item"
                    >
                      <Link
                        to={`/leveransorter/${url}`}
                        className="b-delivery-district-list__site-group-link"
                      >
                        {city}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      )}
      {bottomBlocks &&
        bottomBlocks.length > 0 && (
          <div className="b-delivery-district-list__bottom-blocks">
            <ContentBlocksArea blocks={bottomBlocks} />
          </div>
        )}
    </div>
  );
};

export default DeliveryDistrictList;
