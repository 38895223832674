import { OPEN, CLOSE } from '../../types/reducers/confirmModal';
import makeReducer from '../makeReducer';

const initialState = { show: false };

const ACTION_HANDLERS = {
  [OPEN]: (state, action) => ({
    ...state,
    show: true,
    ...action.opts
  }),
  [CLOSE]: (state, action) => ({
    show: false
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
