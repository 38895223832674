import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { setVisibility } from '../../actions/shoppingOrder';
import { clearOverlay } from '../../actions/app';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import ProductNutritionTable from '../../modules/ProductSingle/ProductSingleDetails/ProductNutritionTable';
import './RichText.scss';

type TRichTextProps = {
  text?: string;
  children?: any;
  setVisibility?: Function;
  clearOverlay?: Function;
} & RouteComponentProps;

const RichText = ({
  text,
  children,
  setVisibility,
  clearOverlay
}: TRichTextProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const history = useHistory();

  const handleClick = e => {
    if (
      !!e.target.getAttribute('data-internal') &&
      e.target.getAttribute('data-internal') !== 'false' &&
      !e.target.getAttribute('target')
    ) {
      e.preventDefault();
      const href = e.target.getAttribute('href');
      if (href) {
        history.push(href);
        if (setVisibility) setVisibility(false);
        if (clearOverlay) clearOverlay();
      }
    }
  };

  useEffect(() => {
    const currentRef = ref.current;
    if (currentRef) {
      currentRef.addEventListener('click', handleClick);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div className="c-richtext">
      {text ? (
        <div
          className="c-richtext__inner"
          dangerouslySetInnerHTML={{ __html: text }}
          ref={ref}
        />
      ) : (
        children
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setVisibility,
  clearOverlay
};

export default connect(null, mapDispatchToProps)(RichText);
