import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { deepCompare } from '../../lib/object';
import RoutingRenderer from './RoutingRenderer';

const stateKeyword = 'flyout';
const isLocationFlyout = location =>
  Boolean(location && location.state && location.state[stateKeyword]);

const buildLocationUrl = location =>
  `${location.pathname}${location.search}${location.hash}`;

const isLocationSame = (locationA, locationB) =>
  Boolean(
    locationA &&
      locationB &&
      (locationA.key === locationB.key ||
        (locationA.pathname === locationB.pathname &&
          locationA.search === locationB.search &&
          locationA.hash === locationB.hash &&
          deepCompare(locationA.state, locationB.state)))
  );

class RoutingStateKeeper extends Component {
  state = {
    allLocationKeysHistory: [this.props.location.key],
    isFlyoutActive: false,
    flyoutLocationKeysHistory: [],
    flyoutLocation: null,
    baseLocation: null,
  };

  componentWillReceiveProps(nextProps) {
  
    const previousLocation = this.props.location;
    const currentLocation = nextProps.location;
    if (previousLocation === currentLocation) {
      return;
    }

    const latestHistoryAction = nextProps.history.action;
    if (latestHistoryAction === 'REPLACE') {
      this.setState(state => ({
        allLocationKeysHistory: [
          ...state.allLocationKeysHistory.slice(0, -1),
          currentLocation.key
        ]
      }));
      return;
    }

    if (latestHistoryAction === 'PUSH') {

      this.onLocationPush(currentLocation, previousLocation);
    } else if (latestHistoryAction === 'POP') {
      const isActuallyAPush = !this.state.allLocationKeysHistory.includes(
        currentLocation.key
      );
      if (isActuallyAPush) {
        this.onLocationPush(currentLocation, previousLocation);
      } else {
        this.onLocationPop(currentLocation, previousLocation);
      }
    }
  }

  onLocationPush(currentLocation, previousLocation) {
    const { isFlyoutActive, baseLocation } = this.state;

    this.setState(state => ({
      allLocationKeysHistory: [
        ...state.allLocationKeysHistory,
        currentLocation.key
      ]
    }));

    const isCurrentFlyout = isLocationFlyout(currentLocation);
    if (!isCurrentFlyout) {
      if (isFlyoutActive) {
        this.startClosingFlyout();
      }

      return;
    }

    const isCurrentSameAsBase = isLocationSame(baseLocation, currentLocation);
    if (isCurrentSameAsBase) {
      this.startClosingFlyout();
    } else {
      this.startOpenFlyout(currentLocation, previousLocation);
    }
  }

  onLocationPop(currentLocation, previousLocation) {
    const previousKeyHistoryIndex = this.state.allLocationKeysHistory.indexOf(
      previousLocation.key
    );
    if (previousKeyHistoryIndex !== -1) {
      this.setState(state => ({
        allLocationKeysHistory: state.allLocationKeysHistory.slice(
          0,
          previousKeyHistoryIndex
        )
      }));
    }

    const { isFlyoutActive, baseLocation } = this.state;
    const isCurrentFlyout = isLocationFlyout(currentLocation);
    const isCurrentSameAsBase = isLocationSame(baseLocation, currentLocation);
    if (isCurrentFlyout) {
      if (isFlyoutActive) {
        if (isCurrentSameAsBase) {
          this.startClosingFlyout();
        } else {
          this.startOpenFlyout(currentLocation, previousLocation);
        }
      } else {
        if (isCurrentSameAsBase) {
          // Do a normal navigation
        } else {
          this.startOpenFlyout(currentLocation, previousLocation);
        }
      }
    } else {
      if (isFlyoutActive) {


        this.startClosingFlyout();
      } else {
        // Do a normal navigation
      }
    }
  }

  onFlyoutClose = () => {
    const { history } = this.props;
    const {
      flyoutLocationKeysHistory,
    } = this.state;

   
      const flyoutHistoryEntries = flyoutLocationKeysHistory.length;
      if (flyoutHistoryEntries > 0) {
        this.setState(() => ({ flyoutLocationKeysHistory: [] }));
        history.go(-flyoutHistoryEntries);
      } else {
        this.setState(() => ({ isFlyoutActive: false }));
      }
  };

  onFlyoutAnimationExit = () => {
    this.setState({ flyoutLocation: null });
  };

  startOpenFlyout(flyoutLocation, previousLocation) {
    let { flyoutLocationKeysHistory, baseLocation } = this.state;

    if (baseLocation === null) {
      this.setState(() => ({
        baseLocation: previousLocation
      }));
    }

    const historyIndex = flyoutLocationKeysHistory.indexOf(flyoutLocation.key);
    if (historyIndex === -1) {
      flyoutLocationKeysHistory = [
        ...flyoutLocationKeysHistory,
        flyoutLocation.key
      ];
    } else {
      flyoutLocationKeysHistory = flyoutLocationKeysHistory.slice(
        0,
        historyIndex
      );
    }

    this.setState(() => ({
      isFlyoutActive: true,
      flyoutLocation,
      flyoutLocationKeysHistory
    }));
  }

  startClosingFlyout() {
    this.setState(() => ({
      isFlyoutActive: false,
      flyoutLocationKeysHistory: [],
      baseLocation: null
    }));
  }

  render() {
    const { location, stateKeyword, ...passOnProps } = this.props;
    const { isFlyoutActive, flyoutLocation, baseLocation } = this.state;

    return (
      <RoutingRenderer
        isFlyoutActive={isFlyoutActive}
        flyoutLocation={flyoutLocation}
        baseLocation={baseLocation || location}
        onFlyoutClose={this.onFlyoutClose}
        onFlyoutAnimationExit={this.onFlyoutAnimationExit}
        {...passOnProps}
      />
    );
  }
}

export default withRouter(RoutingStateKeeper);
