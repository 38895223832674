import { LOGOUT_SUCCESS } from '../../types/reducers/auth';
import {
  ITEM_ADDED,
  RECIPE_ADDED,
  DEBOUNCE_START,
  ADD_TO_QUEUE,
  SET_DEBOUNCE,
  QUEUE_READY,
  PENDING_FIRST_ADD,
  CLEAR_QUEUE,
  ITEM_REMOVED,
  ITEMS_REMOVED,
  CART_SYNC,
  CART_REPLACE,
  ITEM_ADD_ERROR,
  CLEAR_ITEM_ADD_ERRORS,
  SET_PRODUCT_REFS,
  CLEAR_ITEMS,
  CART_RECEIVED,
  DELETE_USABLE_CARTS,
  ADD_TO_IMEDIATE_QUEUE
} from '../../types/reducers/cart';
import makeReducer from '../makeReducer';
import { toLookup } from '../../lib/utils';
import { splitByType } from '../../lib/cart';

const initialState = {
  items: [],
  productRefs: {},
  pendingFirstAdd: false,
  debounceQueue: {
    queue: [],
    debounceId: null,
    debounceTimeout: 500,
    debounce: false,
    queueReady: false
  },
  imediateQueue: [],
  failedItemAdds: []
};

const sync = (state, { payload }) => {
  const { items } = splitByType(payload.cart);
  return {
    ...state,
    items: items,
    imediateQueue: []
  };
};

const ACTION_HANDLERS = {
  [ITEM_REMOVED]: (state, action) => ({
    ...state,
    items: state.items.filter(i => i.id !== action.id)
  }),
  [ITEMS_REMOVED]: (state, action) => {
    return {
      ...state
    };
  },
  [CART_SYNC]: sync,
  [CART_RECEIVED]: sync,
  [CART_REPLACE]: sync,
  [ITEM_ADDED]: (state, { queue }) => {
    const productRef = queue.reduce((refArr, item) => {
      refArr[item.product.id] = item.product;
      return refArr;
    }, {});

    return {
      ...state,
      items: state.items.concat(queue),
      productRefs: {
        ...state.productRefs,
        ...productRef
      }
    };
  },

  [RECIPE_ADDED]: (state, action) => {
    const { productRefs } = action;
    const newProductRefs = productRefs.reduce((acc, productRef) => {
      acc[productRef.id] = productRef;
      return acc;
    }, {});

    return {
      ...state,
      productRefs: {
        ...state.productRefs,
        ...newProductRefs
      }
    };
  },
  [ADD_TO_IMEDIATE_QUEUE]: (state, action) => {
    return {
      ...state,
      imediateQueue: [...state.imediateQueue, action.payload]
    };
  },
  [SET_DEBOUNCE]: state => {
    return {
      ...state,
      debounceQueue: {
        ...state.debounceQueue,
        debounce: true
      }
    };
  },

  [QUEUE_READY]: state => {
    return {
      ...state,
      debounceQueue: {
        ...state.debounceQueue,
        queueReady: true
      }
    };
  },

  [PENDING_FIRST_ADD]: (state, { set }) => {
    return {
      ...state,
      pendingFirstAdd: set
    };
  },

  [DEBOUNCE_START]: (state, { timeoutId }) => {
    return {
      ...state,
      debounceQueue: {
        ...state.debounceQueue,
        debounceId: timeoutId
      }
    };
  },

  [ADD_TO_QUEUE]: (state, { item }) => {
    return {
      ...state,
      debounceQueue: {
        ...state.debounceQueue,
        queue: state.debounceQueue.queue.concat(item)
      }
    };
  },

  [CLEAR_QUEUE]: state => {
    return {
      ...state,
      debounceQueue: initialState.debounceQueue
    };
  },

  [ITEM_ADD_ERROR]: (state, { ids }) => {
    return {
      ...state,
      loading: false,
      failedItemAdds: ids
    };
  },

  [CLEAR_ITEM_ADD_ERRORS]: state => {
    return {
      ...state,
      failedItemAdds: initialState.failedItemAdds
    };
  },

  [SET_PRODUCT_REFS]: (state, { products }) => {
    return {
      ...state,
      productRefs: toLookup(products)
    };
  },

  [CLEAR_ITEMS]: (state, action) => initialState,
  [DELETE_USABLE_CARTS]: (state, action) => initialState,
  [LOGOUT_SUCCESS]: (state, action) => initialState
};

export default makeReducer(ACTION_HANDLERS, initialState);
