import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { toggleModal } from '../../actions/app';

import './ConfirmModal.scss';
import {
  CgButton,
  EButtonVariant,
  ECgButtonSize,
  Modal
} from '@citygross/components';
import { BodyText, H2 } from '@citygross/typography';

export const ConfirmModal = ({
  show,
  title,
  body,
  confirmLabel,
  cancelLabel,
  onCancelClick,
  onConfirmClick,
  loading,
  confirmModalRef
}) => {
  const [prevPosition, setPrevPosition] = useState(0);

  useEffect(() => {
    toggleModal(show);
    const pos = window?.scrollY;
    if (show) {
      setPrevPosition(pos);
      document.body.style.overflowY = 'scroll';
      document.body.style.position = 'fixed';
      document.body.style.top = -pos + 'px';
    } else {
      document.body.style.removeProperty('top');
      document.body.style.overflowY = 'auto';
      document.body.style.position = 'relative';
      if (prevPosition) {
        window.scrollTo(0, prevPosition);
      }
    }
    return () => {
      toggleModal(false);
    };
  }, [show]);

  return show ? (
    <>
      <Modal
        querySelector={confirmModalRef?.id}
        maxWidth={'450px'}
        hideCancel
        onBackdropClick={onCancelClick}
        isConfirm
        footer={
          <div className="c-confirm-modal__buttons">
            {onCancelClick && cancelLabel && (
              <CgButton
                onClick={onCancelClick}
                variant={EButtonVariant.secondary}
              >
                {cancelLabel}
              </CgButton>
            )}

            <CgButton loading={loading} onClick={onConfirmClick}>
              {confirmLabel}
            </CgButton>
          </div>
        }
      >
        {title && <h2 className="c-modal-title">{title}</h2>}
        {body && <BodyText>{body}</BodyText>}
      </Modal>
    </>
  ) : null;
};

ConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.function, PropTypes.string]),
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  loading: PropTypes.bool
};

ConfirmModal.defaultProps = {
  cancelLabel: 'Stäng utan att spara',
  confirmLabel: 'Tillbaka',
  onCancelClick: () => {},
  loading: false,
  children: null
};

const mapStateToProps = state => ({
  modal: state.app.modal
});

const mapDispatchtoProps = {
  toggleModal
};

const ConnectedConfirmModal = connect(
  mapStateToProps,
  mapDispatchtoProps
)(ConfirmModal);

export const ConfirmModalPortal = rest => {
  return <ConnectedConfirmModal {...rest} />;
};

export default ConnectedConfirmModal;
