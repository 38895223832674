import { Cart } from '../order/Cart';
import { Order } from '../order/Order';

const NAME = 'order';

export const FETCH_ORDERS = `${NAME}/FETCH_ORDERS`;
export const ORDERS_LOADED = `${NAME}/ORDERS_LOADED`;
export const FETCH_ORDERS_RECEIVED = `${NAME}/ORDERS_RECEIVED`;
export const FETCH_ORDERS_FAILED = `${NAME}/FETCH_ORDERS_FAILED`;
export const ORDER_CREATED = `${NAME}/ORDER_CREATED`;
export const ORDER_DELETED = `${NAME}/ORDER_DELETED`;
export const ORDER_INITIAL_LOAD = `${NAME}/ORDER_INITIAL_LOAD`;

export interface OrdersState {
  orders: Order[];
  carts: Cart[];
  loading: boolean;
  initialLoad: boolean;
  replacementOrder: number | null;
}
