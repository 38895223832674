import React, { Component } from 'react';
import { Button } from '../Button';
import PropTypes from 'prop-types';

import './FilterButton.scss';

class FilterButton extends Component {
  render() {
    const {
      active,
      buttonType,
      children,
      className,
      disabled,
      onClick
    } = this.props;

    return (
      <Button
        active={active}
        buttonType={buttonType}
        disabled={disabled}
        onClick={onClick}
        className={className}
      >
        {children}
      </Button>
    );
  }
}

FilterButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.oneOf([
    'ekologiskt',
    'glutenfritt',
    'laktosfritt',
    'vegetariskt',
    'veganskt'
  ]),
  color: PropTypes.string,
  colorType: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default FilterButton;
