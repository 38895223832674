import { EFromLinkPosition } from '@citygross/analytics';
import { MenuListItem } from '@citygross/components';
import React, { useMemo, useRef, useState } from 'react';
import {
  NavLink,
  RouteComponentProps,
  useLocation,
  withRouter
} from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ga4FavoriteOrPurchaseLinkClick } from '../../../../lib/analytics/analytics';
import * as styles from './SideMenuItem.styles';
import { useSelector } from 'react-redux';
import { selectPortalOverlayToggle } from '../../../../selectors/app';
import { ca } from 'date-fns/locale';
import {
  ROUTE_CATERING_PATTERN,
  ROUTE_PRODUCT_PATTERN,
  ROUTE_RECIPE_PATTERN
} from '../../../../lib/route/defaults';
import pathToRegexp from 'path-to-regexp';

const productRoute = pathToRegexp(ROUTE_PRODUCT_PATTERN);
const cateringRoute = pathToRegexp(ROUTE_CATERING_PATTERN);
const recipeRoute = pathToRegexp(ROUTE_RECIPE_PATTERN);
const SideMenuItem = ({
  categoryItem,
  activeRef,
  closeModal,
  isSidebar,
  ...routingProps
}: {
  categoryItem: any;
  activeRef?: any;
  closeModal: () => void;
  isSidebar?: boolean;
} & RouteComponentProps) => {
  const theme = useTheme();
  const location = useLocation();
  const [forceClose, setForceClose] = useState(false);
  const locationRef = useRef(location?.pathname);
  const portalOpen = useSelector((state: any) =>
    selectPortalOverlayToggle(state)
  );
  // dropdowns will always be atleast 2 level deeps /matvaror.split('/') === 2 length
  const categoryLevel = categoryItem?.link?.url?.split('/')?.length - 2;
  if (categoryItem?.type === 'ContentStructureContainer') {
    return (
      // add name in epi
      <styles.SideMenuSpacerItem isSidebar={isSidebar}>
        {categoryItem?.header}
      </styles.SideMenuSpacerItem>
    );
  }

  if (!categoryItem?.visible || !categoryItem?.link?.url) {
    return null;
  }
  const item = useMemo(() => {
    let locationChanged;
    const isPdp = [productRoute, cateringRoute, recipeRoute].some(path =>
      path.test(location?.pathname)
    );
    if (isPdp) {
      locationChanged = locationRef?.current;
    } else {
      locationChanged = location?.pathname;
      locationRef.current = location?.pathname;
    }
    return (
      <MenuListItem
        iconWidth={16}
        onClick={() =>
          location?.pathname?.includes(categoryItem?.link?.url) && !forceClose
            ? setForceClose(true)
            : setForceClose(false)
        }
        isOpen={
          forceClose
            ? false
            : locationChanged?.includes(categoryItem?.link?.url)
        }
        dropdownHeader={categoryItem?.name}
        key={categoryItem?.id}
        paddingLeft={16}
        backgroundColor={
          categoryLevel === 1
            ? theme.palette?.brandYellow
            : categoryLevel === 2
            ? theme.palette?.yellowLight
            : theme.palette?.yellowLighter
        }
        hoverBackground={
          location?.pathname?.includes(categoryItem?.link?.url)
            ? categoryLevel === 1
              ? '#F5D800'
              : categoryLevel === 2
              ? '#FFFBE7'
              : theme.palette?.yellowLighter
            : theme.palette?.lighter
        }
      >
        <styles.SideMenuItemWrapper>
          {categoryItem?.children?.map((item: any) => {
            const itemProps = location?.pathname?.includes(
              categoryItem?.link?.url
            )
              ? { ref: activeRef }
              : {};
            if (locationChanged?.includes(categoryItem?.link?.url))
              return (
                <styles.ItemWrapper {...itemProps}>
                  <SideMenuItem
                    categoryItem={item}
                    key={item?.name}
                    closeModal={() => closeModal && closeModal()}
                    {...routingProps}
                  />
                </styles.ItemWrapper>
              );
          })}
        </styles.SideMenuItemWrapper>
      </MenuListItem>
    );
  }, [categoryItem, forceClose, location?.pathname, portalOpen, routingProps]);
  return categoryItem?.children?.length > 0 ? (
    <styles.ItemWrapper>
      <NavLink to={categoryItem?.link?.url} exact={true} key={categoryItem?.id}>
        {item}
      </NavLink>
    </styles.ItemWrapper>
  ) : (
    <NavLink
      to={categoryItem?.link?.url}
      exact={true}
      key={categoryItem?.id}
      onClick={() => {
        ga4FavoriteOrPurchaseLinkClick(
          categoryItem?.link?.url,
          EFromLinkPosition.SIDE_MODAL
        );
      }}
    >
      <styles.SideMenuLastItem
        active={location?.pathname === categoryItem?.link?.url}
        ref={location?.pathname === categoryItem?.link?.url ? activeRef : null}
        onClick={() => closeModal && closeModal()}
      >
        {categoryItem?.name}
      </styles.SideMenuLastItem>
    </NavLink>
  );
};

export default withRouter(SideMenuItem);
