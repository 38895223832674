import makeReducer from '../makeReducer';
import {
  FETCH_DELIVERY_CITIES_REQUESTED,
  FETCH_DELIVERY_CITIES_RECEIVED,
  FETCH_DELIVERY_CITIES_FAILED,
  FETCH_DELIVERY_ZIPCODES_REQUESTED,
  FETCH_DELIVERY_ZIPCODES_RECEIVED,
  FETCH_DELIVERY_ZIPCODES_FAILED
} from '../../types/reducers/deliveryCities';

const initialState = {
  fetching: false,
  error: false
};

const ACTION_HANDLERS = {
  [FETCH_DELIVERY_CITIES_REQUESTED]: (state, action) => ({
    ...state,
    fetching: true,
    error: false,
    cities: []
  }),
  [FETCH_DELIVERY_CITIES_RECEIVED]: (state, action) => ({
    ...state,
    fetching: false,
    error: false,
    cities: action.cities
  }),
  [FETCH_DELIVERY_CITIES_FAILED]: (state, action) => ({
    ...state,
    fetching: false,
    error: true
  }),
  [FETCH_DELIVERY_ZIPCODES_REQUESTED]: (state, action) => ({
    ...state,
    fetching: true,
    error: false,
    zipCodes: []
  }),
  [FETCH_DELIVERY_ZIPCODES_RECEIVED]: (state, action) => ({
    ...state,
    fetching: false,
    error: false,
    zipCodes: action.zipCodes
  }),
  [FETCH_DELIVERY_ZIPCODES_FAILED]: (state, action) => ({
    ...state,
    fetching: false,
    error: true
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
