import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

const Tab = ({ children, active }) => (
  <div
    className={cs('c-flyouttabs__tab', { 'c-flyouttabs__tab--active': active })}
  >
    {children}
  </div>
);

Tab.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool.isRequired
};

Tab.defaultProps = {
  active: false
};

export default Tab;
