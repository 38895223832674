import React from 'react';
import './ProdCard.scss';

interface IProdCard {
  className: string;
  colStructure?: string;
}

const ProdCard: React.FC<IProdCard> = ({ className, colStructure }) => {
  return (
    <div className={`c-skeleton__prodcard ${className} ${colStructure}`} />
  );
};

ProdCard.defaultProps = {
  className: '',
  colStructure: 'l-column-30_xs-30_sm-20_md-15_lg-12_xlg-10-mobileGutter'
};

export { ProdCard };

export default ProdCard;
