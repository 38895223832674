import React, { useEffect, useState } from 'react';
import { LazyProgressivePicture } from '../../components/ProgressivePicture';
import { CloseButton } from '../../components/Buttons';
import YouTube from 'react-youtube';
import './YouTubeBlock.scss';
import { IYouTubeBlockProps } from '../../types/episerver/blocks/YouTubeBlock';
import useCookieConsent from '../../hooks/useCookieConsent';
import { toast } from 'react-toastify';

const YouTubeBlock : React.FC<IYouTubeBlockProps> = ({ desktopImage, mobileImage, youtubeSrc }) => {
  const cookieConsent = useCookieConsent();
  const [ isPlaying, setIsPlaying ] = useState<boolean>(false);
  const _toggleVideo = () => cookieConsent.preferences 
    ? setIsPlaying(!isPlaying) 
    : toast.error("För att spela videon måste du godkänna våra cookies för Inställningar."
  );

  return (
    <div className="b-video-block">
      {desktopImage && (
        <div
          className="b-video-block__image"
          onClick={_toggleVideo}
          role="link"
          tabIndex={0}
        >
          <LazyProgressivePicture
            largeImage={desktopImage}
            smallImage={mobileImage}
          />
        </div>
      )}

      {cookieConsent.preferences && isPlaying && (
        <div>
          <YouTube
            videoId={youtubeSrc}
            className="y-player"
            opts={{
              height: '100%',
              width: '100%',
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                showinfo: 0,
                rel: 0,
                iv_load_policy: 3,
                modestbranding: 1,
                enablejsapi: 1
              }
            }}
          />
          <div className="b-video-block__closebtn">
            <CloseButton onClick={_toggleVideo} />
          </div>
        </div>
      )}
    </div>
  );
}

export default YouTubeBlock;
