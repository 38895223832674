import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_RECEIVED,
  USER_CHANNEL_RECEIVED,
  PASSWORD_RESET,
  PHONE_UPDATE,
  ADD_CUSTOMER_FAVORITE_PRODUCT_REQUESTED,
  ADD_CUSTOMER_FAVORITE_PRODUCT_RECEIVED,
  ADD_CUSTOMER_FAVORITE_PRODUCT_FAILED,
  ADD_CUSTOMER_FAVORITE_RECIPE_REQUESTED,
  ADD_CUSTOMER_FAVORITE_RECIPE_RECEIVED,
  ADD_CUSTOMER_FAVORITE_RECIPE_FAILED,
  REMOVE_CUSTOMER_FAVORITE_REQUESTED,
  REMOVE_CUSTOMER_FAVORITE_RECEIVED,
  REMOVE_CUSTOMER_FAVORITE_FAILED,
  GET_CUSTOMER_TOKEN_REQUESTED,
  GET_CUSTOMER_TOKEN_RECEIVED,
  GET_CUSTOMER_TOKEN_FAILED,
  SET_EMAIL_RECEIPT_REQUESTED,
  SET_EMAIL_RECEIPT_RECEIVED,
  SET_EMAIL_RECEIPT_FAILED,
  USER_FAVORITES_RECEIVED
} from '../../types/reducers/auth';
import { AuthResponse } from '../../types/auth/AuthResponse';
import { UserData } from '../../types/customer/UserData';
import { UserChannelData } from '../../types/customer/ContactData';
import { FavoriteModel } from '../../types/customer/SettingsData';

export function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
}

export interface LoginUserProps {
  username: string,
  password: string
}

export function requestLogin(creds: LoginUserProps) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  };
}

export function receiveLogin(user: AuthResponse) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    access_token: user.access_token
  };
}

export function loginError(message: string) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

export function retrieveUser(user: UserData) {
  return {
    type: USER_RECEIVED,
    user
  };
}

export function retrieveFavorites(favorites: FavoriteModel[]) {
  return {
    type: USER_FAVORITES_RECEIVED,
    favorites
  }
}

export function retrieveUserContactChannel(channel: UserChannelData) {
  return {
    type: USER_CHANNEL_RECEIVED,
    channel
  };
}

//Password reset
export const passwordResetRequest = () => ({
  type: PASSWORD_RESET,
  isFetching: true
});

export const passwordResetSuccess = (email: string) => ({
  type: PASSWORD_RESET,
  successMail: email,
  isSuccess: true
});

export const passwordResetFailure = () => ({
  type: PASSWORD_RESET,
  isError: true
});

export const clearPasswordReset = () => ({
  type: PASSWORD_RESET
});

// User deliveries
export const phoneUpdateRequest = () => ({
  type: PHONE_UPDATE,
  isFetching: true
});

export const phoneUpdateSuccess = () => ({
  type: PHONE_UPDATE,
  isSuccess: true
});

export const phoneUpdateFailure = () => ({
  type: PHONE_UPDATE,
  isError: true
});

export const clearPhoneUpdate = () => ({
  type: PHONE_UPDATE
});

// Favorite product/recipe
export const requestAddProductFavorite = (productId: string) => ({
  type: ADD_CUSTOMER_FAVORITE_PRODUCT_REQUESTED,
  productId: productId
});

export const receiveAddProductFavorite = (favorite: FavoriteModel) => ({
  type: ADD_CUSTOMER_FAVORITE_PRODUCT_RECEIVED,
  favorite: favorite
});

export const failedAddProductFavorite = (productId: string) => ({
  type: ADD_CUSTOMER_FAVORITE_PRODUCT_FAILED,
  productId: productId
});

export const requestAddRecipeFavorite = (recipeId: string) => ({
  type: ADD_CUSTOMER_FAVORITE_RECIPE_REQUESTED,
  recipeId: recipeId
});

export const receiveAddRecipeFavorite = (favorite: FavoriteModel) => ({
  type: ADD_CUSTOMER_FAVORITE_RECIPE_RECEIVED,
  favorite: favorite
});

export const failedAddRecipeFavorite = (recipeId: string) => ({
  type: ADD_CUSTOMER_FAVORITE_RECIPE_FAILED,
  recipeId: recipeId
});

export const requestRemoveFavorite = (favorite: FavoriteModel) => ({
  type: REMOVE_CUSTOMER_FAVORITE_REQUESTED,
  favorite: favorite
});

export const receiveRemoveFavorite = (favorite: FavoriteModel) => ({
  type: REMOVE_CUSTOMER_FAVORITE_RECEIVED,
  favorite: favorite
});

export const failedRemoveFavorite = (favorite: FavoriteModel) => ({
  type: REMOVE_CUSTOMER_FAVORITE_FAILED,
  favorite: favorite
});

export const requestCustomerToken = () => ({
  type: GET_CUSTOMER_TOKEN_REQUESTED
});

export const receiveCustomerToken = () => ({
  type: GET_CUSTOMER_TOKEN_RECEIVED
});

export const failedCustomerToken = () => ({
  type: GET_CUSTOMER_TOKEN_FAILED
});

export const requestSetEmailRecieptCopy = () => ({
  type: SET_EMAIL_RECEIPT_REQUESTED
});
export const receiveSetEmailRecieptCopy = (user: UserData) => ({
  type: SET_EMAIL_RECEIPT_RECEIVED,
  user
});
export const failedSetEmailRecieptCopy = () => ({
  type: SET_EMAIL_RECEIPT_FAILED
});
