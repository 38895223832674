import styled from 'styled-components';

export const SideMenuItemWrapper = styled.div`
  width: 100%;
  padding-left: 16px;
`;

type TSideMenuLastItem = {
  active?: boolean;
};

type TSideMenuSpacerItem = {
  isSidebar?: boolean;
};

export const SideMenuLastItem = styled.p<TSideMenuLastItem>`
  padding: 8px;
  padding-left: 16px;
  font-size: 13px;
  font-weight: 400;
  color: ${({ active, theme }) =>
    active ? theme.palette?.brandBlue : theme.palette?.darkest};
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    text-decoration: underline;
  }
`;

export const SideMenuSpacerItem = styled.div<TSideMenuSpacerItem>`
  ${props => props.isSidebar && `margin-top: 24px`};
  font-weight: 700;
  padding: ${props => (props.isSidebar ? '4px' : '8px')};
  padding-left: 16px;
  font-size: 12px;
`;
export const ItemWrapper = styled.span`
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
`;
