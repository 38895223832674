import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { getPageById } from '../../actions/page';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import FullWidthPageModule from '../../modules/FullWidthPage';

class RecipeFolderPage extends Component {
  state = {
    pageError: false
  };

  componentDidMount() {
    const { page, id } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }
  }

  getPageContent = () => {
    const { getPageById, id } = this.props;
    this.setState({
      pageError: false
    });
    getPageById(id).catch(err => {
      console.error('getPageById failed', err); // eslint-disable-line
      this.setState({
        pageError: true
      });
    });
  };

  render() {
    const { page, title } = this.props;
    const { pageError } = this.state;

    return (
      <Fragment>
        <PageTitle {...title} />
        <FullWidthPageModule
          {...page}
          title={title}
          blocks={page.contentBlocks}
          error={pageError}
          errorRetryFn={this.getPageContent}
        />
      </Fragment>
    );
  }
}

RecipeFolderPage.required = [
  (state, params, noCache) => getPageById(params.id, state.router.location.search, noCache)
];

const mapStateToProps = (state, props) => ({
  title: breadCrumbSelector(state, props),
  page: pageSelector(state, props),
  id: pageIdSelector(state, props)
});

const mapDispatchToProps = {
  getPageById: getPageById
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RecipeFolderPage)
);
