import React from 'react';
import { REQUEST_IDS } from '../../lib/request/requestRetry';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Spinner from '../Spinner';
import DropArrow from '../DropArrow';
import WarningIcon from '../assets/warning.svg';
import './CriticalRequestsInfo.scss';

export const regDescriptions = {
  [REQUEST_IDS.navigation]: 'Navigation',
  [REQUEST_IDS.settings]: 'Generella inställningar',
  [REQUEST_IDS.customer]: 'Kundinformation',
  [REQUEST_IDS.customerOrders]: 'Leveranser & varukorg',
  [REQUEST_IDS.customerStorePage]: 'Butiksinformation'
};

const CriticalRequestsInfo = ({ items, online, open, onToggleClick }) => {
  const hasFailedItemsAll = !Object.keys(items).reduce(
    (hasNonFailure, i) => hasNonFailure || items[i].failed !== true,
    false
  );
  const hasFailedItems = Object.keys(items).some(i => items[i].failed === true);
  const hasSlowItems = Object.keys(items).some(i => items[i].isSlow === true);
  const hasErrorItems = Object.keys(items).some(i => items[i].isError === true);
  const nbrOfItems = Object.keys(items).length;

  const visible = nbrOfItems > 0 || !online;

  return (
    <CSSTransition in={visible} classNames="c-critical-requests-" timeout={500}>
      <div className="c-critical-requests">
        <div className="c-critical-requests__head">
          <div className="c-critical-requests__toggler" onClick={onToggleClick}>
            <DropArrow direction={open ? 'up' : 'down'} />
          </div>
          {open ? (
            <div className="c-critical-requests__head-open">
              <div className="c-critical-requests__head-title">
                {hasSlowItems && <p>Anrop tar längre tid än normalt.</p>}
                {hasErrorItems && <p>Anrop når inte fram till våra servrar.</p>}
              </div>
              Vi försöker igen, under tiden kan sajtens funktioner och innehåll
              vara helt eller delvis begränsade.<br />
              <br />
              {hasFailedItems && (
                <strong>
                  Ett eller flera anrop har misslyckats, testa att ladda om
                  sidan.
                </strong>
              )}
            </div>
          ) : (
            <div className="c-critical-requests__head-closed">
              <img
                src={WarningIcon}
                className="c-critical-requests__warning"
                alt="Varnings ikon"
              />
              Anslutningsproblem
              {!hasFailedItemsAll && (
                <div className="c-critical-requests__head-spinner">
                  <Spinner />
                </div>
              )}
            </div>
          )}
        </div>
        {open && (
          <ul className="c-critical-requests__items">
            <TransitionGroup
              classNames="c-critical-requests__item-"
              timeout={300}
              component={null}
            >
              {!online && (
                <CSSTransition
                  classNames="c-critical-requests__item-"
                  timeout={200}
                >
                  <li className="c-critical-requests__item c-critical-requests__item--online">
                    <div className="c-critical-requests__item-inner">
                      <strong>Kontrollera din internetanslutning</strong>
                    </div>
                  </li>
                </CSSTransition>
              )}
              {Object.keys(items).map(i => {
                const item = items[i];
                const { id, failed } = item;
                return (
                  <CSSTransition
                    key={i}
                    classNames="c-critical-requests__item-"
                    timeout={200}
                  >
                    <li className="c-critical-requests__item">
                      <div className="c-critical-requests__item-inner">
                        {regDescriptions[id]}
                        {failed ? (
                          <img
                            src={WarningIcon}
                            className="c-critical-requests__item-failed"
                            alt="Misslyckades"
                            title="Misslyckades"
                          />
                        ) : (
                          <div className="c-critical-requests__item-spinner">
                            <Spinner />
                          </div>
                        )}
                      </div>
                    </li>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </ul>
        )}
      </div>
    </CSSTransition>
  );
};

export default CriticalRequestsInfo;
