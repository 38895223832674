import { formatPriceWithNoZero } from '@citygross/utils';
import React from 'react';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import { removeCateredMealFromCart } from '../../../actions/cart';
import { CartCard } from '@citygross/components';
import { BodyText } from '@citygross/typography';
import { useTheme } from 'styled-components';
const makeCateringQuantityString = item => {
  let qString = '';
  if (
    item?.cateredMeal?.hasPortionsVariant ||
    item?.cateredMeal?.quantityFrom
  ) {
    if (item?.cateredMeal?.quantityFrom || item?.cateredMeal?.quantityTo) {
      if (item?.cateredMeal?.quantityFrom)
        qString += item?.cateredMeal?.quantityFrom;
      if (item?.cateredMeal?.quantityTo)
        qString += ` - ${item?.cateredMeal?.quantityTo}`;
    } else {
      qString = item?.cateredMeal?.quantityFrom
        ? item?.cateredMeal?.quantityFrom
        : item?.quantity?.value;
    }
  }
  return qString;
};

const CateringCartCard = item => {
  const isDiscountPrice = item?.totalDiscountAmount > 0;
  const hasSides =
    item?.notes?.cateredMealSide &&
    item?.cateredMeal?.sides?.find(
      side => side?.id === item?.notes?.cateredMealSide
    )?.name;
  const formattedPrice = formatPriceWithNoZero(item?.amount);

  const cateringQuantity = makeCateringQuantityString(item);
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <CartCard
      titleLineClamp={2}
      title={item?.name}
      image={
        (process.env.PUBLIC_URL || '') +
        item?.cateredMeal?.image?.url +
        '?w=80&h=80'
      }
      description={
        <div className="cart-card__description">
          <p>
            {cateringQuantity ? (
              <span>{cateringQuantity} portioner</span>
            ) : (
              <span>{item?.totalCount} st</span>
            )}
          </p>
        </div>
      }
      secondaryDescription={
        <div className="cart-card__description">
          {item?.cateredMeal?.name !== 'Standard' ? (
            <p>{item?.cateredMeal?.name}</p>
          ) : null}

          {hasSides && <p>{hasSides}</p>}
        </div>
      }
      price={
        <BodyText
          fontWeight="medium"
          color={
            isDiscountPrice ? theme.palette?.alertRed : theme.palette?.darkest
          }
        >
          {formattedPrice} kr
        </BodyText>
      }
      showDelete
      onDelete={() => {
        dispatch(removeCateredMealFromCart(item?.id, item));
      }}
    />
  );
};

export default CateringCartCard;
