import { OPEN, CLOSE } from '../../types/reducers/confirmModal';

export const close = () => ({
  type: CLOSE
});

export const open = opts => ({
  type: OPEN,
  opts: opts
});
