import React from 'react';
import OfferSingleDetails from './OfferSingleDetails';
import './OfferSingle.scss';

import { TOffer } from '../../types/offers/offers';

type Props = {
  offer: TOffer;
  isFetching: boolean;
  isFlyout: boolean;
  storeId: string | number | null;
  isAuthenticated: boolean;
  currentBreadCrumb: { name: string; link: { url: string } };
};

export const OfferSingle = (props: Props) => {
  const { offer } = props;

  return (
    <div className="c-productdetails">
      <OfferSingleDetails offer={offer} isFlyout={props.isFlyout} />
    </div>
  );
};
