import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { domSafe } from '../../lib/document';
import CriticalRequestsInfo from './CriticalRequestsInfo';

import './CriticalRequestsInfo.scss';

class CriticalRequestsInfoContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      online: domSafe() ? navigator && navigator.onLine : true,
      open: false
    };
  }

  componentDidmount() {
    this.setState({
      online: navigator && navigator.onLine
    });
  }

  componentDidMount() {
    window.addEventListener('online', this.setOnline);
    window.addEventListener('offline', this.setOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.setOnline);
    window.removeEventListener('offline', this.setOffline);
  }

  setOnline = () => {
    this.setState({
      online: true
    });
  };

  setOffline = () => {
    this.setState({
      online: false
    });
  };

  onToggleClick = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  render() {
    const { online, open } = this.state;

    return (
      <CriticalRequestsInfo
        {...this.props}
        online={online}
        open={open}
        onToggleClick={this.onToggleClick}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  items: state.request
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CriticalRequestsInfoContainer);
