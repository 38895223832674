import { connect } from 'react-redux';
import ReCaptcha from './ReCaptcha';
import {
  appSettingsLoadingSelector,
  appSettingsSelector
} from '../../../selectors/app';

const mapStateToProps = state => ({
  settings: appSettingsSelector(state),
  appSettingsLoadingSelector: appSettingsLoadingSelector(state)
});

export default connect(mapStateToProps, null)(ReCaptcha);
