import React from 'react';
import cs from 'classnames';
import Check from '../Check';
import { MiniCross } from '../Common';

import './Tag.scss';



interface IProps {
  onClick: React.EventHandler<React.MouseEvent>,
  checkbox?: boolean,
  disabled?: boolean,
  cross?: boolean,
  active: boolean
}

export const Tag : React.FC<IProps> = ({
  children,
  onClick,
  checkbox,
  disabled,
  cross,
  active
}) => (
  <span onClick={onClick} className={cs('c-tag', { disabled })}>
    {checkbox ? <Check active={active} /> : null}

    {children}

    {active && cross ? <MiniCross /> : null}
  </span>
);

export default Tag;
