import React from 'react';
import cs from 'classnames';

const OverlayRenderer = ({ includeHeader, includeFlyout, onClick }) => (
  <div
    className={cs('c-overlay', {
      aboveheader: includeHeader,
      aboveflyout: includeFlyout
    })}
    onClick={onClick}
  />
);

export default OverlayRenderer;
