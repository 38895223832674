import React, { Component } from 'react';
import Login from '../../containers/Login';

const loginLayoutStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '10px',
  margin: '0 auto',
  width: '320px'
};

class LoginPage extends Component {
  render() {
    const { pathname: previous = '/' } =
      (this.props.location &&
        this.props.location.state &&
        this.props.location.state.from) ||
      {};
    const back = () => this.props.history.push(previous);
    return (
      <div style={loginLayoutStyle}>
        <Login {...this.props} loginSuccessCb={back} />
      </div>
    );
  }
}

export default LoginPage;
