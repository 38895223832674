import {
  SW_UPDATE_ACTIVATED,
  SW_RELOADING_START
} from '../../types/reducers/serviceWorker';

export const sw_update_activated = () => ({
  type: SW_UPDATE_ACTIVATED
});

export const sw_reload_start = () => ({
  type: SW_RELOADING_START
});
