import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CategoryFilter from '../../components/CategoryFilter';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import ErrorInfo from '../../components/ErrorInfo';
import ProductList from '../../components/ProductList';
import OverFlowContainer from '../../components/OverFlowContainer';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';

import './ProductCategory.scss';

const ProductCategory = ({
  storeId,
  fetching,
  products,
  selectedFilters,
  filters,
  pagination,
  addToCart,
  setProductFavorite,
  removeFavorite,
  redirectToLogin,
  isAuthenticated,
  increaseItemQuantity,
  decreaseItemQuantity,
  requestMoreProducts,
  sortProducts,
  title,
  filterProducts,
  location,
  id,
  page,
  pageError,
  getPageContent,
  sortOptions,
  impressionListName
}) => {
  const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;
  const setProductFavoriteFn = isAuthenticated
    ? setProductFavorite
    : redirectToLogin;
  const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

  const listTeaser = {
    link: page.teaserLink,
    image: page.teaserImage,
    text: page.teaserText
  };

  const requestPage = page => {
    requestMoreProducts(id, { page }, location, storeId);
  };

  return (
    <Fragment>
      <PageTitle {...title} sort />
      {pageError ? (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={getPageContent}
        />
      ) : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page.contentBlocks} />
        </OverFlowContainer>
      )}
      <CategoryFilter
        id={id}
        filters={filters}
        selectedFilters={selectedFilters}
        sortProducts={(id, params) => {
          document.cookie = `sortArticles=${params.Sort};path=/`;
          sortProducts(id, params, location, storeId);
        }}
        filterAction={filter =>
          filterProducts(
            id,
            filter,
            location,
            storeId ? { store: storeId } : null
          )
        }
        pagination={pagination}
        sortOptions={sortOptions}
      />

      <ProductList
        fetching={fetching}
        id={id}
        addToCart={addToCartFn}
        setProductFavorite={setProductFavoriteFn}
        removeFavorite={removeFavoriteFn}
        increaseItemQuantity={increaseItemQuantity}
        decreaseItemQuantity={decreaseItemQuantity}
        requestMoreProducts={requestPage}
        pagination={pagination}
        items={products}
        listTeaser={listTeaser}
        impressionListName={impressionListName}
      />
      {pageError ? null : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page.bottomContentBlocks} />
        </OverFlowContainer>
      )}
    </Fragment>
  );
};

ProductCategory.propTypes = {
  addToCart: PropTypes.func,
  decreaseItemQuantity: PropTypes.func,
  fetching: PropTypes.bool,
  filters: PropTypes.array,
  filterProducts: PropTypes.func,
  getPageContent: PropTypes.func,
  id: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  increaseItemQuantity: PropTypes.func,
  location: PropTypes.object,
  page: PropTypes.object,
  pageError: PropTypes.bool,
  pagination: PropTypes.object,
  products: PropTypes.array,
  redirectToLogin: PropTypes.func,
  removeFavorite: PropTypes.func,
  requestMoreProducts: PropTypes.func,
  selectedFilters: PropTypes.array,
  setProductFavorite: PropTypes.func,
  sortProducts: PropTypes.func,
  storeId: PropTypes.number,
  title: PropTypes.object,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  )
};

ProductCategory.defaultProps = {
  page: {},
  sortOptions: []
};

export default ProductCategory;
