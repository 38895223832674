import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import * as pageActions from '../../actions/page';
import { setMode } from '../../actions/shoppingOrder';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';
import { pageSelector, pageIdSelector } from '../../selectors/page';

import FullWidthPageModule from '../../modules/FullWidthPage';
import ErrorInfo from '../../components/ErrorInfo';

class FullWidthPage extends Component {
  state = {
    error: false
  };

  componentDidMount() {
    const { setMode, page, id } = this.props;
    // Dont refetch startpage if already been reloaded
    if (page.id !== id) {
      this.getPageContent();
    }

    setMode(true, false, UNTOGGLE_BEHAVIOUR.none);
  }

  getPageContent = () => {
    const { getPageById, id } = this.props;
    if (id) {
      this.setState({
        error: false
      });
      getPageById(id).catch(err => {
        console.error('getPageByIdFailed', err);
        this.setState({
          error: true
        });
      });
    }
  };

  render() {
    const { page, title } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={this.getPageContent}
          sideMargins
        />
      );
    }

    return (
      <FullWidthPageModule
        {...page}
        title={title}
        blocks={page.contentBlocks}
      />
    );
  }
}

FullWidthPage.required = [
  (state, params, noCache) => {
    return pageActions.getPageById(params.id, state.router.location.search, noCache, params.userSiteId);
  }
];

function mapStateToProps(state, props) {
  return {
    title: breadCrumbSelector(state, props),
    page: pageSelector(state, props),
    id: pageIdSelector(state, props)
  };
}

const mapDispatchToProps = {
  getPageById: pageActions.getPageById,
  setMode
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FullWidthPage)
);
