import { graphQlQuery } from '../../lib/utils';

export const fetchMostBoughtProducts = async (days, size) => {
  try {
    const data = await graphQlQuery(
      `query MostBoughtProducts($size: Int!, $days: Int!) {
        mostBoughtProducts(size: $size, days: $days)
      }`,
      {
        size: size ?? 20,
        days: days ?? 90
      }
    );
    return data.mostBoughtProducts;
  } catch (ex) {
    console.error('fetching of mostBoughtProducts failed');
  }
};

export const fetchMostBoughtRecipes = async size => {
  try {
    const data = await graphQlQuery(
      `query MostBoughtRecipes($size: Int!) {
        mostBoughtRecipes(size: $size)
      }`,
      {
        size: size ?? 20
      }
    );

    return data.mostBoughtRecipes;
  } catch (ex) {
    console.error('fetching of mostBoughtRecipes failed');
  }
};
