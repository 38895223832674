import {
  FETCH_PRODUCT_REQUESTED,
  FETCH_PRODUCT_RECEIVED,
  FETCH_PRODUCT_FAILED,
  FETCH_CATEGORY_PRODUCTS_REQUESTED,
  FETCH_CATEGORY_PRODUCTS_RECEIVED,
  FETCH_CATEGORY_PRODUCTS_FAILED,
  PAGINATE_CATEGORY_PRODUCTS_REQUESTED,
  PAGINATE_CATEGORY_PRODUCTS_RECEIVED
} from '../../types/reducers/product';
import { Product } from '../../types/xim/product';
import { EsalesProductPanelResponse } from '../../types/esales';

export function isFetchingProduct(fetching: boolean) {
  return {
    type: FETCH_PRODUCT_REQUESTED,
    fetching
  };
}

export function receiveProduct(payload: Product[]) {
  return {
    type: FETCH_PRODUCT_RECEIVED,
    payload: payload
  };
}

export function fetchProductError(error: string) {
  return {
    type: FETCH_PRODUCT_FAILED,
    error
  };
}

export function isFetchingProducts(fetching: boolean) {
  return {
    type: FETCH_CATEGORY_PRODUCTS_REQUESTED,
    fetching
  };
}

export function receiveProducts(payload : EsalesProductPanelResponse<Product>) {
  return {
    type: FETCH_CATEGORY_PRODUCTS_RECEIVED,
    payload: {
      products: payload.data,
      meta: payload.meta
    }
  };
}

export function paginateProducts(payload: EsalesProductPanelResponse<Product>) {
  return {
    type: PAGINATE_CATEGORY_PRODUCTS_RECEIVED,
    payload: {
      products: payload.data,
      meta: payload.meta
    }
  };
}
export function isPaginatingProducts(fetching: boolean) {
  return {
    type: PAGINATE_CATEGORY_PRODUCTS_REQUESTED,
    fetching
  };
}

export function fetchProductsError(error: string) {
  return {
    type: FETCH_CATEGORY_PRODUCTS_FAILED,
    error
  };
}
