import React from 'react';
import './CartGroup.scss';

const CartGroup = ({ name, children }) => (
  <div className={'cart-group'}>
    {name && <div className="cart-group__title">{name}</div>}
    {children}
  </div>
);

export default CartGroup;
