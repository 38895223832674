import React, { useEffect, useState } from 'react';
import { fetchAllOffers, fetchOffersByIds } from '../../api/endpoints/offer';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pageIdSelector } from '../../selectors/page';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { TOffer, TOfferData } from '../../types/offers/offers';
import CardGrid from '../../components/CardGrid';
import OfferCardList from '../../modules/OfferCardList';

interface IXimOffers {
  OfferId: string;
  id: number;
  name: string;
  type: string;
}

interface IOfferBlock {
  bfcategorycode?: string[];
  fontColor: string;
  isCarousel: boolean;
  offers?: IXimOffers[];
  numRows: number;
}

const OfferBlock = ({
  fontColor,
  bfcategorycode,
  isCarousel,
  offers,
  numRows,
  ...rest
}: IOfferBlock) => {
  const storeNo = useSelector(selectCurrentAssortment);
  const [loading, setLoading] = useState(true);
  const [blockOffers, setBlockOffers] = useState<TOffer[]>([]);
  const fetchOffers = async () => {
    try {
      let offerResponse: TOfferData | null = null;
      if (bfcategorycode && bfcategorycode?.length) {
        offerResponse = await fetchAllOffers({
          nextWeek: false, // should this be controlled ? no field in epi currently
          categories: bfcategorycode
        });
      } else if (offers?.length) {
        offerResponse = await fetchOffersByIds({
          ignoreStore: false,
          ximEntitiesList: offers.map(o => o.OfferId)
        });
      }
      if (offerResponse?.data?.offers?.length) {
        setBlockOffers(offerResponse.data.offers);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'error fetching offers by ids');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!storeNo) return;
    fetchOffers();
  }, [storeNo]);

  if (!storeNo || !blockOffers.length) {
    return null;
  }
  return (
    <div>
      <CardGrid
        noPad
        fontColor={fontColor}
        renderItem={(maxItems: number) => (
          <OfferCardList
            offers={blockOffers}
            maxItems={2 * maxItems}
            allRows={!numRows}
            loading={loading}
            isCarousel={isCarousel}
          />
        )}
        {...rest}
      />
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    pageId: pageIdSelector(state, props)
  };
}

export default withRouter(connect(mapStateToProps)(OfferBlock));
