import React from 'react';
import * as styles from './CartScrollArea.styled';
export const CartScrollArea = ({
  scrollRef,
  isInModal,
  hasScrolled,
  bottomOffset,
  onScroll,
  children
}) => {
  return (
    <styles.CartScroll
      scrollRef={scrollRef}
      showBorder={isInModal && hasScrolled}
      bottomOffset={bottomOffset}
      onScroll={onScroll}
      clientHeight={window.visualViewport?.height}
      isCart
    >
      {children}
    </styles.CartScroll>
  );
};
