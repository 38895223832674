const genRandom = () =>
  Math.random()
    .toString(36)
    .substring(2, 15);

// TODO consider putting this as a part of the initialState of the navigation
// reducer.
export default [
  {
    id: genRandom(),
    name: 'BlockPreviewPage',
    link: {
      internal: true,
      url: '/preview/block'
    },
    type: 'BlockPreviewPage',
    tags: [],
    children: []
  },
  {
    id: genRandom(),
    name: 'Logga in',
    link: {
      internal: true,
      url: '/login'
    },
    type: 'LoginPage',
    tags: [],
    children: []
  },
  {
    id: genRandom(),
    name: 'Registrera',
    link: {
      internal: true,
      url: '/registrera'
    },
    type: 'RegisterPage',
    tags: [],
    children: []
  },
  {
    id: genRandom(),
    name: 'Glömt lösenord',
    link: {
      internal: true,
      url: '/glomt-losenord'
    },
    type: 'LostPasswordPage',
    tags: [],
    children: []
  },
  {
    id: genRandom(),
    name: 'Sökresultat',
    link: {
      internal: true,
      url: '/sok'
    },
    type: 'SearchPage',
    tags: [],
    children: []
  }
];
