import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { applyDiscountCode, discountReset } from '../../../actions/cart';
import './CartCoupons.scss';
import { CouponCode, ECouponCodeStatus } from '@citygross/components';
import { retriveDiscountName } from '../../../actions/cart';

const initialStatus = {
  status: ECouponCodeStatus.Empty,
  message: ''
};
const ALPHA_NUMERIC_REGEX = /^[A-Za-z0-9åäöÅÄÖ]+$/;

export const COUPON_ERRORS = {
  ERR_COUPON_ALREADY_USED: 'ERR_COUPON_ALREADY_USED',
  ERR_COUPON_DOESNT_EXISTS: 'ERR_COUPON_DOESNT_EXISTS',
  ERR_COUPON_EXPIRED: 'ERR_COUPON_EXPIRED',
  ERR_INVALID_COUPON: 'ERR_INVALID_COUPON',
  ERR_COUPON_VALIDATION: 'ERR_COUPON_VALIDATION'
};

export const COUPON_ERROR_MSG = {
  USED: 'Coupon has already been used',
  RESERVED: 'Coupon has already been reserved'
};

const couponErros = (error?: string | null, message?: string) => {
  if (!error) return '';
  switch (error) {
    case COUPON_ERRORS.ERR_COUPON_ALREADY_USED:
      let errorMsg = 'Rabattkoden redan använd';
      if (message === COUPON_ERROR_MSG.RESERVED) {
        errorMsg = 'Rabattkoden är reserverad';
      }
      return errorMsg;
    case COUPON_ERRORS.ERR_COUPON_EXPIRED:
      return 'Rabattkoden har gått ut';
    case COUPON_ERRORS.ERR_COUPON_VALIDATION:
      return 'Validering misslyckades';
    default:
      return 'Rabattkod ej giltig';
  }
};

const CartCoupons = props => {
  const [coupon, setCoupon] = useState('');
  const [status, setStatus] = useState(initialStatus);
  const [loading, setLoading] = useState(false);
  const { failed, success, cart } = props;
  const dispatch = useDispatch();

  const resolveCouponStatus = (
    status: ECouponCodeStatus,
    error?: { code?: string; message?: string } | null,
    code?: string
  ) => {
    if (status === ECouponCodeStatus.Success) {
      setStatus({
        status: ECouponCodeStatus.Success,
        message: `Rabattkod har lagts till ${code}`
      });
    }
    if (status === ECouponCodeStatus.Error) {
      setStatus({
        status: ECouponCodeStatus.Error,
        message: couponErros(COUPON_ERRORS.ERR_COUPON_VALIDATION)
      });
    }
    if (status === ECouponCodeStatus.Invalid) {
      setStatus({
        status: ECouponCodeStatus.Invalid,
        message: couponErros(error?.code, error?.message)
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (success || cart?.discountCodes?.length > 0) {
      if (cart?.discountCodes?.length > 0) {
        if (cart?.discountCodes?.[0]?.name) {
          return resolveCouponStatus(
            ECouponCodeStatus.Success,
            null,
            cart?.discountCodes?.[0]?.name
          );
        }
        dispatch(retriveDiscountName(cart?.discountCodes?.[0]?.gtin)).then(
          res => {
            if (res.data) {
              resolveCouponStatus(success, null, res?.data?.code || coupon);
            }
          }
        );
      } else {
        resolveCouponStatus(ECouponCodeStatus.Success, null, coupon);
      }
    }
    if (failed) {
      if (failed === COUPON_ERRORS.ERR_COUPON_VALIDATION) {
        resolveCouponStatus(ECouponCodeStatus.Error, failed);
      } else {
        resolveCouponStatus(ECouponCodeStatus.Invalid, failed);
      }
    }
  }, [failed, success, cart?.discountCodes]);

  const onClick = async () => {
    if (!coupon) {
      props?.checkoutBtnRef?.current?.focus();
      setStatus({
        status: ECouponCodeStatus.Error,
        message: `Rabattkod saknas`
      });
      return;
    }
    setLoading(true);

    try {
      // Remove spaces
      const reCode = coupon.replace(/\s/g, '');
      const { applyDiscountCode, cart, storeProvider } = props;
      await applyDiscountCode(cart.id, reCode, storeProvider);
    } catch (e) {
      resolveCouponStatus(ECouponCodeStatus.Error);
    }
  };

  return (
    <div className="cart-coupons">
      <div className="cart-coupons__wrapper">
        <CouponCode
          placeholder="Rabattkod"
          label="Lägg till"
          resetLabel="Försök igen"
          onSubmit={onClick}
          value={coupon}
          setValue={val => {
            if (
              (ALPHA_NUMERIC_REGEX.test(val) || val === '') &&
              val.length < 20
            )
              setCoupon(val);
          }}
          onReset={() => {
            setStatus(initialStatus);
            dispatch(discountReset());
          }}
          loading={loading}
          status={status}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state.cart.discount,
  ...state.assortments
});

const mapDispatchToProps = dispatch => ({
  applyDiscountCode: (cart, recode, storeProvider) =>
    dispatch(applyDiscountCode(cart, recode, storeProvider))
});
export default connect(mapStateToProps, mapDispatchToProps)(CartCoupons);
