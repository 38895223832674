/* eslint-disable import/no-unresolved */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import './ProductCarousel.scss';
import useDragDetection from '../../hooks/useDragDetection';
import { IconButton } from '@citygross/components';
import { Icons } from '@citygross/icons';
import { useWindowSize } from '@citygross/utils';
import Slider from 'react-slick';
import debounce from 'lodash.debounce';
import cs from 'classnames';

export type DefaultSliderProps = {
  items?: number;
  productsLength?: number;
  recipeCarousel?: boolean;
};

const MoveDragThreshold = 10;
const dotSize = 14;

const productCarouselSizes = {
  product: {
    mobile: 2,
    tabletSmall: 3,
    tablet: 4,
    desktopSmall: 5,
    desktop: 6
  },
  recipe: {
    mobile: 1.2,
    tabletSmall: 2,
    tablet: 3,
    desktopSmall: 3,
    desktop: 4
  }
};

const carouselBreakPoints = {
  mobile: 600,
  tabletSmall: 768,
  tablet: 1212,
  desktopSmall: 1700,
  desktop: 1701
};

const determineChunkSize = (width: number, recipeCarousel?: boolean) => {
  const carouselType = recipeCarousel
    ? productCarouselSizes.recipe
    : productCarouselSizes.product;
  if (width < carouselBreakPoints.mobile) {
    return carouselType.mobile;
  } else if (width < carouselBreakPoints.tabletSmall) {
    return carouselType.tabletSmall;
  } else if (width < carouselBreakPoints.tablet) {
    return carouselType.tablet;
  } else if (width < carouselBreakPoints.desktopSmall) {
    return carouselType.desktopSmall;
  } else {
    return carouselType.desktop;
  }
};

const ProductCarousel = (
  props: PropsWithChildren<DefaultSliderProps>
): React.ReactElement => {
  const { children, productsLength } = props;
  const { handleMouseDown, dragging } = useDragDetection(MoveDragThreshold);
  const [leftOffset, setLeftOffset] = useState(0);
  const [dotRefs, setDotRefs] = useState([0, 6]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();
  function handleChildClick(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    if (dragging) {
      e.preventDefault();
    }
  }

  const { width } = useWindowSize();

  const chunkSize = useMemo(() => {
    return determineChunkSize(width, props?.recipeCarousel);
  }, [width]);

  const pages = useMemo(() => {
    return Math.ceil(productsLength ? productsLength / chunkSize : 0);
  }, [chunkSize, children]);

  const handlePagination = useCallback(
    (active, dir, fromWidthAdjust?) => {
      let visibleDots: [number, number] = [0, 6];
      if (dir === 1) {
        visibleDots = [
          Math.max(0, active - 4),
          Math.max(6, active <= 5 ? active + 1 : active + 1)
        ];
      } else {
        visibleDots = [active, active + 5];
      }
      if (active <= 1) {
        visibleDots = [0, 6];
      }
      if (pages && active >= pages - 1) {
        visibleDots = [pages - 5, pages];
      }
      if (fromWidthAdjust) {
        visibleDots = [
          Math.min(active, Math.max(0, pages - 5)),
          Math.max(6, Math.min(pages, active + 5))
        ];
      }
      setDotRefs(visibleDots);
    },
    [dotRefs, width, productsLength, pages]
  );
  const adjustPageAfterWidth = () => {
    if (!sliderRef?.current) return;
    //@ts-ignore
    sliderRef?.current?.slickGoTo(currentSlide);
    const currentAdjustedPage = Math.ceil(currentSlide / chunkSize);
    setCurrentPage(currentAdjustedPage);

    const maxOffset = pages - 6;
    const calculatedOffset = Math.ceil(currentSlide / chunkSize) - 1;
    setLeftOffset(Math.max(0, Math.min(maxOffset, calculatedOffset)) * dotSize);
    handlePagination(currentAdjustedPage, -1, true);
  };

  const adjustPageByWidth = useCallback(
    debounce(adjustPageAfterWidth, 500, { trailing: true }),
    [sliderRef.current, pages, currentSlide, chunkSize, dotRefs]
  );

  useEffect(() => {
    adjustPageByWidth();
  }, [width, chunkSize]);

  const makeSlides = useMemo(() => {
    const childs = React.Children?.map(children, child => child);
    return childs.map((child, i) => (
      <div
        onMouseDownCapture={handleMouseDown}
        onClickCapture={handleChildClick}
        key={i}
      >
        {child}
      </div>
    ));
  }, [chunkSize, children, dragging]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: determineChunkSize(
      carouselBreakPoints.desktop,
      props?.recipeCarousel
    ),
    slidesToScroll: determineChunkSize(
      carouselBreakPoints.desktop,
      props?.recipeCarousel
    ),
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: determineChunkSize(
            carouselBreakPoints.mobile - 1,
            props?.recipeCarousel
          ),
          slidesToScroll: Math.floor(
            determineChunkSize(
              carouselBreakPoints.mobile - 1,
              props?.recipeCarousel
            )
          ),
          initialSlide: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: determineChunkSize(
            carouselBreakPoints.tabletSmall - 1,
            props?.recipeCarousel
          ),
          slidesToScroll: determineChunkSize(
            carouselBreakPoints.tabletSmall - 1,
            props?.recipeCarousel
          ),
          initialSlide: 1
        }
      },
      {
        breakpoint: 1212,
        settings: {
          slidesToShow: determineChunkSize(
            carouselBreakPoints.tablet - 1,
            props?.recipeCarousel
          ),
          slidesToScroll: determineChunkSize(
            carouselBreakPoints.tablet - 1,
            props?.recipeCarousel
          ),
          initialSlide: 1
        }
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: determineChunkSize(
            carouselBreakPoints.desktopSmall - 1,
            props?.recipeCarousel
          ),
          slidesToScroll: determineChunkSize(
            carouselBreakPoints.desktopSmall - 1,
            props?.recipeCarousel
          ),
          initialSlide: 1
        }
      }
    ],
    nextArrow: (
      <div>
        <IconButton
          borderRadius={100}
          icon={<Icons.ChevronRight color="#fff" height={20} width={20} />}
          opacity={'90%'}
        />
      </div>
    ),
    prevArrow: (
      <div>
        <IconButton
          borderRadius={100}
          opacity={'90%'}
          icon={<Icons.ChevronLeft color="#fff" height={20} width={20} />}
        />
      </div>
    ),
    appendDots: dots => (
      <div className="slick__pagination-wrapper">
        <div className="slick__pagination-container">
          <ul
            className="slick__pagination"
            style={{
              left: pages < 6 ? 'unset' : `-${leftOffset}px`
            }}
          >
            {dots}
          </ul>
        </div>
      </div>
    ),
    customPaging: i => (
      <div className="slick__dot-container" key={'dot' + i}>
        <span
          className="slick__dot"
          style={{
            background: i === currentPage ? '#a7a7a7' : '#e7e7e7',
            scale:
              i === currentPage || i === 0 || i === pages - 1
                ? '1'
                : (i === 0 || i < dotRefs?.[1]) && i >= dotRefs?.[0]
                ? '1'
                : '0.5'
          }}
        />
      </div>
    ),
    beforeChange: (current, next) => {
      const currentPage = current / chunkSize;
      const nextPage = next / chunkSize;
      const beforeDotsChange = dotRefs;
      setCurrentSlide(next);
      setCurrentPage(Math.ceil(nextPage));
      if (nextPage < beforeDotsChange[1] && nextPage >= beforeDotsChange[0]) {
        return;
      }
      handlePagination(nextPage, currentPage < nextPage ? 1 : -1);
      if (currentPage < nextPage) {
        setLeftOffset(cur => cur + dotSize);
      } else {
        setLeftOffset(cur => cur - dotSize);
      }
    },
    onInit: () => {
      handlePagination(0, 1);
    }
  };
  return (
    <div
      style={{ maxWidth: '100%', width: '100%' }}
      className={cs('b-product-carousel', {
        'b-recipe-carousel': props?.recipeCarousel
      })}
    >
      <Slider ref={sliderRef} {...settings}>
        {makeSlides}
      </Slider>
    </div>
  );
};
export default ProductCarousel;
