import React, { Component } from 'react';
import { Route } from 'react-router-dom';

export class DispatchWrapper extends Component {
  componentDidMount() {
    this.props.action();
  }
  render() {
    let { component: Component, action, ...rest } = this.props;
    return <Component {...rest} />;
  }
}

export const DispatchBeforeRoute = ({
  component: Component,
  action,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return (
        <DispatchWrapper {...props} action={action} component={Component} />
      );
    }}
  />
);
