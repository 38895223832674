import {
  FETCH_IMBOXCATEGORIES_RECEIVED,
  FETCH_IMBOXANSWER_RECEIVED,
  ImboxCategoriesReceivedAction,
  ImboxAnswerReceivedAction
} from '../../types/reducers/imbox';
import { IImboxQuestion } from '../../types/imbox/Question';
import { IImboxAnswer } from '../../types/imbox/Answer';

export function receiveCategories(payload: IImboxQuestion[]) : ImboxCategoriesReceivedAction {
  return {
    type: FETCH_IMBOXCATEGORIES_RECEIVED,
    payload
  };
}

export function receiveArticle(payload: IImboxAnswer) : ImboxAnswerReceivedAction {
  return {
    type: FETCH_IMBOXANSWER_RECEIVED,
    payload
  };
}
