import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { hasGroceriesInCart } from '../cart/cartSelectorNew';
export const siteIdSelector = (state: AppState) => state.assortments.siteId;
const homeSiteIdSelector = (state: AppState) => state.assortments.homeSiteId;
const zipCodeSelector = (state: AppState) => state.assortments.zipCode;
const deliveryMethodSelector = (state: AppState) =>
  state.assortments.deliveryMethod;
const homeDeliveryOptions = (state: AppState) =>
  state.assortments.homeDeliveryAssortmentValues;
const storesSelector = (state: AppState) => state.assortments.stores;
const loading = (state: AppState) =>
  state.assortments.assortmentLoading || state.assortments.sitesLoading;
export const sitesLoading = (state: AppState) => state.assortments.sitesLoading;
export const activeStoreNoSelector = (state: AppState) =>
  state.assortments?.storeNo;

export const activeStoreProviderSelector = (state: AppState) =>
  state.assortments.storeProvider;

export const selectedStore = createSelector(
  [siteIdSelector, homeSiteIdSelector, deliveryMethodSelector, storesSelector],
  (siteId, homeSiteId, deliveryMethod, stores) => {
    if (deliveryMethod === 'homeDelivery' && homeSiteId) {
      const selectedStore = stores?.find(x => x.id === homeSiteId);
      return selectedStore;
    }

    const selectedStore = stores?.find(x => x.id === siteId);
    return selectedStore;
  }
);

export const selectedPickupStore = createSelector(
  [siteIdSelector, storesSelector],
  (siteId, stores) => {
    const selectedStore = stores?.find(x => x.id === siteId);
    return selectedStore;
  }
);

export const selectedHomeStore = createSelector(
  [homeSiteIdSelector, storesSelector],
  (homeSiteId, stores) => {
    const selectedStore = stores?.find(x => x.id === homeSiteId);
    return selectedStore;
  }
);

export const selectedDeliverySettings = createSelector(
  [zipCodeSelector, siteIdSelector, deliveryMethodSelector],
  (zipCode, siteId, deliveryMethod) => {
    return {
      deliveryMethod,
      siteId,
      zipCode
    };
  }
);

export const selectCurrentAssortment = createSelector(
  [activeStoreNoSelector],
  storeNo => {
    return storeNo;
  }
);

export const selectCurrentStoreProvider = createSelector(
  [activeStoreProviderSelector],
  storeProvider => {
    return storeProvider;
  }
);

export const assortmentsLoading = createSelector([loading], isLoading => {
  return isLoading;
});

export const determineDeliveryOptions = createSelector(
  [
    deliveryMethodSelector,
    siteIdSelector,
    homeSiteIdSelector,
    hasGroceriesInCart,
    homeDeliveryOptions
  ],
  (
    deliveryMethod,
    pickupSiteId,
    homeSiteId,
    hasGroceries,
    hasBags,
    homeDeliveryOptions
  ) => {
    // If homeDelivery we have to check if a site is specifically set for home.
    if (deliveryMethod === 'homeDelivery') {
      const productCondition =
        Boolean(homeSiteId) && homeDeliveryOptions?.grocery;
      return {
        canAddProductsOrRecipes: productCondition,
        canAddBags: false,
        hasHomeSiteId: Boolean(homeSiteId)
      };
      // If needed to be more explicit check for pickupDelivery in the else statement
    } else {
      return {
        canAddProductsOrRecipes: true,
        canAddBags: true
      };
    }
  }
);
