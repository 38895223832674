import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { Transition } from 'react-transition-group';

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  display: 'flex',
  alignItems: 'flex-start'
};

const transitionStyles = {
  entering: {
    opacity: 0
  },
  entered: {
    opacity: 1
  },
  exiting: { transition: '0s' }
};

let cachedImages = [];

export class LazyFadeIn extends Component {
  state = {
    loaded: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.loadId !== this.props.loadId) {
      this.setState(() => ({ loaded: false }));
    }
  }

  resetLoad() {}

  onLoad = () => {
    this.setState({ loaded: true });
  };
  onLoaded = () => {
    const { loadId } = this.props;
    const isInCache = cachedImages.find(x => x === loadId);

    if (!isInCache) {
      cachedImages.push(loadId);
    }
  };

  render() {
    const { height, children, loadId } = this.props;
    const { loaded } = this.state;
    const isInCache = cachedImages.find(x => x === loadId);
    return (
      <LazyLoad once height={height} offset={500}>
        <Transition in={loaded} timeout={duration} onEntered={this.onLoaded}>
          {state => (
            <div
              className="c-lazycontainer"
              style={
                !isInCache
                  ? { ...defaultStyle, ...transitionStyles[state] }
                  : {}
              }
            >
              {children(this.onLoad)}
            </div>
          )}
        </Transition>
      </LazyLoad>
    );
  }
}
LazyFadeIn.propTypes = {
  height: PropTypes.number,
  children: PropTypes.func,
  loadId: PropTypes.string
};

export default LazyFadeIn;
