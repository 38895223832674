import React, { Component } from 'react';
import PropTypes from 'prop-types';
const HeaderHeightContext = React.createContext();

export const HeaderHeightConsumer = HeaderHeightContext.Consumer;

const FULL_HEADER_HEIGHT = 110;
const STICKY_HEADER_HEIGHT = 58;
const TRIGGERPOINT = 110;
class HeaderHeightProvider extends Component {
  state = {
    headerHeight: FULL_HEADER_HEIGHT,
    stickyHeader: false,
    constants: {
      fullheaderHeight: FULL_HEADER_HEIGHT,
      stickyheaderHeight: STICKY_HEADER_HEIGHT,
      triggerPoint: TRIGGERPOINT
    }
  };
  setDefaultHeaderHeight = () =>
    this.setState({
      headerHeight: FULL_HEADER_HEIGHT,
      stickyHeader: false
    });

  setStickyHeaderHeight = () =>
    this.setState({
      headerHeight: STICKY_HEADER_HEIGHT,
      stickyHeader: true
    });

  render() {
    return (
      <HeaderHeightContext.Provider
        value={{
          ...this.state,
          setDefaultHeaderHeight: this.setDefaultHeaderHeight,
          setStickyHeaderHeight: this.setStickyHeaderHeight
        }}
      >
        {this.props.children}
      </HeaderHeightContext.Provider>
    );
  }
}

HeaderHeightProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default HeaderHeightProvider;
