import makeReducer from '../makeReducer';
import {
  TABS,
  UNTOGGLE_BEHAVIOUR,
  TOGGLE_ACTIVE,
  ADD_ACTIVE_RECIPE,
  SET_MODE,
  SET_VISIBILITY,
  REMOVE_ACTIVE_RECIPE
} from '../../types/reducers/shoppingOrder';
import { isMatch } from '../../lib/route';

const ROUTER_CHANGE = '@@router/LOCATION_CHANGE';

const initialState = {
  visible: false,
  activeRecipes: [],
  toggleable: false,
  lastLocation: null,
  unToggleBehaviour: UNTOGGLE_BEHAVIOUR.active, // keeps the last active
  activeTab: TABS.cart
};

const defaultTo = (prop, defVal) =>
  typeof prop === 'undefined' ? defVal : prop;

const ACTION_HANDLERS = {
  [TOGGLE_ACTIVE]: (state, action) => {
    if (action.active === state.activeTab) {
      return {
        ...state,
        activeTab:
          state.unToggleBehaviour === UNTOGGLE_BEHAVIOUR.none && state.visible
            ? null
            : action.active,
        visible: !state.visible
      };
    } else if (!state.visible) {
      return {
        ...state,
        visible: true,
        activeTab: action.active
      };
    } else {
      return {
        ...state,
        activeTab: action.active
      };
    }
  },

  [ADD_ACTIVE_RECIPE]: (state, action) => {
    return {
      ...state,
      activeRecipes: state.activeRecipes.concat(action.id)
    };
  },

  [SET_MODE]: (state, action) => {
    const behaviour = defaultTo(
      action.unToggleBehaviour,
      state.unToggleBehaviour
    );

    return {
      ...state,
      toggleable: action.toggleable,
      activeTab: behaviour === UNTOGGLE_BEHAVIOUR.none ? null : state.activeTab,
      unToggleBehaviour: defaultTo(
        action.unToggleBehaviour,
        state.unToggleBehaviour
      ),
      visible: defaultTo(action.visible, state.visible)
    };
  },

  [SET_VISIBILITY]: (state, action) => {
    return {
      ...state,
      visible: action.visible,
      activeTab:
        action.unToggleBehaviour === UNTOGGLE_BEHAVIOUR.none
          ? null
          : state.activeTab
    };
  },

  [REMOVE_ACTIVE_RECIPE]: (state, action) => {
    return {
      ...state,
      activeRecipes: state.activeRecipes.filter(id => id !== action.id)
    };
  },
  [ROUTER_CHANGE]: (state, action) => {
    if (
      state.lastLocation === action.payload.location.pathname ||
      (isMatch(action.payload.location.pathname) &&
        action.payload.location.pathname !== '/checkout-old') // Added exception when going to checkout
    ) {
      return state;
    }
    return {
      ...state,
      visible: initialState.visible,
      lastLocation: action.payload.location.pathname,
      activeTab: initialState.activeTab,
      toggleable: false
    };
  }
};

export default makeReducer(ACTION_HANDLERS, initialState);
