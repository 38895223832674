import { useWizard } from '@citygross/react-use-bg-wizard';
import { motion } from 'framer-motion/dist/framer-motion';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction === 0 ? 0 : direction < 0 ? 300 : -300,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  }
};

export interface IBasicAnimatedStepProps {
  slideInDirection: number;
}

export interface IAnimatedStepsChildProps {
  goToStep?: (step: number, prev: number) => void;
  stepKey?: number;
  animating?: boolean;
}

export const BasicAnimatedStep: React.FC<IBasicAnimatedStepProps> = ({
  slideInDirection,
  children
}) => (
  <motion.div
    transition={{ duration: 0.375, type: 'tween' }}
    custom={slideInDirection}
    variants={variants}
    initial="enter"
    animate="center"
  >
    {children}
  </motion.div>
);

interface IAnimatedStateStep {
  stepComponent: React.FunctionComponentElement<any>;
  currentStepSelector: (state: any) => any;
  previousStepSelector: (state: any) => any;
  onStepChange: (toStep: number, fromStep: number) => void;
}

export const AnimatedStateStep: React.FC<IAnimatedStateStep> = ({
  stepComponent,
  currentStepSelector,
  previousStepSelector,
  onStepChange
}) => {
  const { setStep, activeStep } = useWizard();
  const [animating, setAnimating] = useState(true);

  const currentStep = useSelector(currentStepSelector);
  const previousStep = useSelector(previousStepSelector);

  const goToStep = (toStep: number, fromStep: number) => {
    onStepChange(toStep, fromStep);
    setStep(Number(toStep) + 1);
  };

  return (
    <motion.div
      transition={{ duration: 0.375, type: 'tween' }}
      custom={previousStep === null ? 0 : previousStep - currentStep}
      variants={variants}
      initial="enter"
      animate="center"
      style={{ height: '100%' }}
      onAnimationStart={() => setAnimating(true)}
      onAnimationComplete={() => setAnimating(false)}
    >
      {React.cloneElement(stepComponent, {
        goToStep,
        stepKey: activeStep,
        animating
      })}
    </motion.div>
  );
};
