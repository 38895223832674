import React from 'react';
import { ProdCard as ProdCardSkeleton } from '../Skeletons/ProdCard';

type TProductArticleSkeleton = {
  loading: boolean;
  productsLength: number;
  maxItems: number;
};

const ProductArticleSkeleton = ({
  loading,
  productsLength,
  maxItems
}: TProductArticleSkeleton) => {
  return (
    <>
      {[...Array(loading ? productsLength + 4 : maxItems)].map((x, index) => {
        return (
          <div className="b-productblock__card" key={index}>
            <ProdCardSkeleton className="col" />
          </div>
        );
      })}
    </>
  );
};

export default ProductArticleSkeleton;
