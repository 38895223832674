import { LOGOUT_SUCCESS } from '../../types/reducers/auth';
import {
  CART_SYNC,
  CART_REPLACE,
  CLEAR_ITEMS,
  CART_RECEIVED,
  CATERING_ADDED,
  CATERING_REMOVED,
  SET_CATERED_MEALS_REFS
} from '../../types/reducers/cart';
import { toLookup } from '../../lib/utils';
import makeReducer from '../makeReducer';
import { splitByType } from '../../lib/cart';

const initialState = {
    cateredMeals: [],
    cateredMealsRefs: {}
};

const sync = (state, { payload }) => {
  const { cateredMeals, ...rest } = splitByType(payload.cart);
  return {
    ...state,
    cateredMeals: cateredMeals
  };
};

const ACTION_HANDLERS = {
  [CATERING_REMOVED]: (state, action) => {
    return ({
    ...state,
    cateredMeals: state.cateredMeals.filter(i => i.id !== action.id)
  })},
  [CART_SYNC]: sync,
  [CART_RECEIVED]: sync,
  [CART_REPLACE]: sync,
  [CATERING_ADDED]: (state, action) => {
    const { cateredMeal } = action;
    return {
      ...state,
      cateredMealsRefs: {
        ...state.cateredMealsRefs,
         [cateredMeal.id]: {
          ...cateredMeal,
          image: cateredMeal?.image,
          name: cateredMeal?.variant
        }
      }
    };
  },
  [SET_CATERED_MEALS_REFS]: (state, { cateredMeals }) => {
    return {
      ...state,
      cateredMealsRefs: { ...state.cateredMealsRefs, ...toLookup(cateredMeals) }
    };
  },
  [CLEAR_ITEMS]: () => initialState,
  [LOGOUT_SUCCESS]: () => initialState
};

export default makeReducer(ACTION_HANDLERS, initialState);
