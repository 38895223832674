import { LOGOUT_SUCCESS } from '../../types/reducers/auth';
import {
  CLEAR_ITEMS,
  SET_HANDLE,
  CART_SYNC,
  CART_REPLACE,
  RECIPE_ADDED,
  RECIPE_REMOVED,
  SET_RECIPE_REFS,
  MAGIC,
  CART_RECEIVED
} from '../../types/reducers/cart';
import { toLookup } from '../../lib/utils';
import makeReducer from '../makeReducer';
import { splitByType } from '../../lib/cart';
const initialState = {
  [MAGIC.flex]: {
    itemNo: MAGIC.flex,
    id: null,
    recipes: [],
    amount: 0
  },
  [MAGIC.recipe]: {
    itemNo: MAGIC.recipe,
    id: null,
    recipes: [],
    amount: 0
  },
  recipeRefs: {}
};

const sync = (state, { payload: { cart } }) => {
  const { recipeRefs, ...rest } = state;
  const { recipes, ...args } = splitByType(cart);

  const items = Object.entries(rest).reduce((acc, [key, item]) => {
    const recipeItem = recipes.find(
      cr =>
        cr.itemNo === key ||
        (cr.itemNo.toLowerCase().includes('flexkassen') && key === MAGIC.flex)
    );
    acc[key] = recipeItem ? recipeItem : initialState[key];
    return acc;
  }, {});

  return {
    ...state,
    ...items
  };
};

const ACTION_HANDLERS = {
  [CART_SYNC]: (state, { payload: { cart } }) => {
    // The items known as 'recipe items' from the cart are grouped by cartitem type & merging our
    // representation with the carts.
    // If we cant find the item, it is likely removed and we should reset to initialState
    const { recipes, ...args } = splitByType(cart);

    const { recipeRefs, ...rest } = state;
    const cartItems = Object.entries(rest).reduce((acc, [key, item]) => {
      const recipeCartItem = recipes.find(cr => cr.itemNo === key);
      acc[key] = recipeCartItem
        ? { ...item, ...recipeCartItem }
        : initialState[key];
      return acc;
    }, {});

    return {
      ...state,
      ...cartItems
    };
  },
  [RECIPE_ADDED]: (state, action) => {
    const { recipeRef } = action;

    const newRecipeRefs = {};
    newRecipeRefs[recipeRef.id] = recipeRef;

    return {
      ...state,
      recipeRefs: {
        ...state.recipeRefs,
        ...newRecipeRefs
      }
    };
  },

  [SET_RECIPE_REFS]: (state, { recipes }) => {
    return {
      ...state,
      recipeRefs: toLookup(recipes)
    };
  },

  [RECIPE_REMOVED]: (state, { id }) => {
    // const { [id]: _recipe, ...refs } = state.recipeRefs;

    return {
      ...state
      // recipeRefs: refs
    };
  },

  [SET_HANDLE]: (state, action) => {
    const { recipesEAN, handle } = action;
    return {
      ...state,
      [recipesEAN]: {
        ...state[recipesEAN],
        id: handle
      }
    };
  },
  // TODO will CART_RECEIVED & CART_SYNC always be the same?
  // CART_SYNC merges on handle instead of EAN
  [CART_RECEIVED]: sync,
  [CART_REPLACE]: sync,
  [CLEAR_ITEMS]: (state, action) => initialState,
  [LOGOUT_SUCCESS]: (state, action) => initialState,
};

export default makeReducer(ACTION_HANDLERS, initialState);
