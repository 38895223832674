import {
  OPEN_NEWCART_MODAL,
  CLOSE_NEWCART_MODAL,
  LOADING,
  PREPARE_NEWCART,
  RESET_NEWCART,
  NewCartPrepState,
  SET_CART_TO_CHANGE
} from '../../types/reducers/newCartPrep';

const initialState: NewCartPrepState = {
  show: false,
  cartDiff: null,
  newCart: null,
  loading: false,
  isDifferent: false,
  newCartId: null,
  storeNoToBeSelected: null,
  cartToBeChanged: null
};

export default function newCartPrepReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //AssortmentsActionTypes
): NewCartPrepState {
  switch (action.type) {
    case OPEN_NEWCART_MODAL: {
      return {
        ...state,
        show: true
      };
    }
    case CLOSE_NEWCART_MODAL: {
      return {
        ...initialState,
        show: false
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case PREPARE_NEWCART: {
      return {
        ...state,
        ...action.opts
      };
    }
    case SET_CART_TO_CHANGE: {
      return {
        ...state,
        cartToBeChanged: action.cartToBeChanged
      };
    }
    case RESET_NEWCART: {
      return initialState;
    }
    default:
      return state;
  }
}
