import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LazyFadeIn } from '../Lazy';
import TempImage from '../assets/tempimage.png';

class Image extends Component {
  constructor(props) {
    super(props);

    this.state = { src: props.src };
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.setState(() => ({ src: this.props.src }));
    }
  }

  onError = () => {
    const { fallback = TempImage } = this.props;

    this.setState({
      src: fallback
    });
  };

  render() {
    const {
      src,
      alt = '',
      height,
      noLazy,
      onLoad = () => {},
      ...props
    } = this.props;

    return (
      <img
        src={this.state.src}
        onError={this.onError}
        alt={alt}
        onLoad={onLoad}
        {...props}
      />
    );
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.number,
  noLazy: PropTypes.bool
};

const WithLazyLoading = ({ height = 0, src, noLazy, ...rest }) =>
  noLazy ? (
    <Image src={src} {...rest} />
  ) : (
    <LazyFadeIn height={height} loadId={src}>
      {onLoad => <Image onLoad={onLoad} src={src} {...rest} />}
    </LazyFadeIn>
  );

export { Image };

export default WithLazyLoading;
