import React from 'react';
import classNames from 'classnames';
import Image from '../Image';

import './sticker.scss';

interface ISource {
  altText: string,
  height?: number | null,
  imageHeight?: number | null,
  imageWidth?: number | null,
  path: string
}

interface IAlignment {
  top?: number,
  right?: number,
  bottom?: number,
  left?: number
}

interface ISticker {
  sources: ISource[],
  alignment: IAlignment,
  direction?: 'row' | 'column'
}

export const Sticker : React.FC<ISticker> = ({ sources, alignment, direction }) => (
  <div
    className={classNames('c-sticker', `c-sticker--${direction}`)}
    style={{
      ...alignment
    }}
  >
    {sources.map(({ altText, imageHeight, imageWidth, path }) => (
      <Image
        alt={altText}
        height={imageHeight || 100}
        src={path}
        width={imageWidth || 100}
        key={path}
      />
    ))}
  </div>
);

Sticker.defaultProps = {
  alignment: { top: 0, right: 0, bottom: 0, left: 0 },
  direction: 'column'
};

export default Sticker;
