import { cachedRequest, esalesRequest } from '../../lib/request';
import queryParams from '../../lib/queryParams';

export const DEFAULT_RECIPEPAGE_SIZE = 20;

export const fetchRecipeById = recipeId =>
  esalesRequest(`/esales/recipe/${recipeId}`);

export const fetchRecipesByIds = recipeIds => {
  if (!recipeIds || !recipeIds.length > 0) {
    return;
  }
  return esalesRequest(`/esales/recipe/${recipeIds}`);
};
export const fetchRecipesByVariants = recipeIds => {
  // const modifiedIds = recipeIds.map(id => id.split('_')[0])
  return esalesRequest(`/esales/recipebyvariant/${recipeIds}`);
};

export const fetchRecipesByCategory = (categoryId, params = {}) => {
  if (!params.Sort) params.Sort = 'default';
  const opts = queryParams({
    size: DEFAULT_RECIPEPAGE_SIZE,
    ...params,
    categoryId
  });

  return esalesRequest(`/esales/recipes?${opts}`);
};

export const fetchRecipesByTags = (raw, storeId, page) => {
  return esalesRequest(`/esales/recipes?${raw}&store=${storeId ?? ''}&page=${page}`);
};

export const fetchRecipeVariantPrice = (
  recipeId,
  numberOfMeals = 4,
  organic = false,
  lactoseFree = false,
  glutenFree = false,
  vegetarian = false,
  vegan = false,
  storeId
) => {
  return cachedRequest(`/recipes/${recipeId}/articles?storeNumber=${storeId ?? ''}&lactoseFree=${lactoseFree ? lactoseFree : null}&glutenFree=${glutenFree ? glutenFree : null}&vegetarian=${vegetarian ? vegetarian : null}&vegan=${vegan ? vegan : null}&quantity=${numberOfMeals}`, {
    method: 'GET',
  });
};

export const fetchBatchRecipeVariantPrice = items => {
  return cachedRequest('/recipes/batchprices', {
    method: 'POST',
    body: JSON.stringify([...items])
  });
};
