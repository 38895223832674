import React from 'react';
import ForgotPassword from '../../containers/Login/ForgotPassword';

const layoutStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '2em 0',
  minHeight: '50vh'
};

const ForgotPasswordPage = props => (
  <div style={layoutStyle}>
    <div style={{ padding: '2em', backgroundColor: '#fff', width: '400px' }}>
      <ForgotPassword {...props} />
    </div>
  </div>
);

export default ForgotPasswordPage;
