import { connect } from 'react-redux';
import { loginUser, allUserInfo } from '../../actions/auth';
import { getUserOrders } from '../../actions/order';
import { authenticatedSelector } from '../../selectors/user';
import { appSettingsSelector } from '../../selectors/app';

import Login from '../../modules/Login';

function mapStateToProps(state, props) {
  const { loginSuccessCb, forgotPasswordClicked, createAccountClick } = props;

  const appSettings = appSettingsSelector(state);

  return {
    authenticated: authenticatedSelector(state),
    onLoginSuccess: loginSuccessCb,
    createAccountTitle:
      appSettings && appSettings.login && appSettings.login.createAccountTitle,
    createAccountParagraph:
      appSettings &&
      appSettings.login &&
      appSettings.login.createAccountParagraph,
    bottomText: appSettings && appSettings.login && appSettings.login.text,
    forgotPasswordClicked: forgotPasswordClicked,
    createAccountClick: createAccountClick
  };
}

const mapDispatchToProps = {
  loginUser,
  allUserInfo,
  getUserOrders
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
