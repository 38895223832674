import Cookies from 'universal-cookie';
import Cookie from 'js-cookie';
import { login, fetchCustomerToken } from '../../api/endpoints/auth';
import {
  fetchCurrentUser,
  requestPasswordReset,
  setNewEmail,
  setNewPhone,
  setFavoriteProduct,
  setFavoriteRecipe,
  deleteFavorite,
  setEmailRecieptCopyReq,
  fetchUserFavorites
} from '../../api/endpoints/customer';
import {
  ticketNotifierByFavoriteAdd,
  ticketNotifierByFavoriteRemove
} from '../../api/endpoints/notify';
import config from '../../config/config';
import { makeStorage } from '../../lib/storage';
import { configure } from '../../lib/request';
import {
  ga4FavoriteRecipeTracking,
  ga4FavoriteProductTracking,
  sendTracking
} from '../../lib/analytics/analytics';
import { requestRetry, REQUEST_IDS } from '../../lib/request/requestRetry';
import {
  setAssortment,
  setInitialAssortmentValues,
  toggleModalActive
} from '../assortments';
import {
  requestLogout,
  requestLogin,
  receiveLogout,
  receiveLogin,
  retrieveUser,
  retrieveUserContactChannel,
  loginError,
  passwordResetRequest,
  passwordResetSuccess,
  passwordResetFailure,
  phoneUpdateRequest,
  phoneUpdateSuccess,
  phoneUpdateFailure,
  requestAddProductFavorite,
  receiveAddProductFavorite,
  failedAddProductFavorite,
  requestAddRecipeFavorite,
  receiveAddRecipeFavorite,
  failedAddRecipeFavorite,
  requestRemoveFavorite,
  receiveRemoveFavorite,
  failedRemoveFavorite,
  requestCustomerToken,
  receiveCustomerToken,
  failedCustomerToken,
  requestSetEmailRecieptCopy,
  receiveSetEmailRecieptCopy,
  failedSetEmailRecieptCopy,
  LoginUserProps,
  retrieveFavorites
} from './sync';
import { Dispatch } from 'redux';
import { AuthResponse } from '../../types/auth/AuthResponse';
import { FavoriteModel } from '../../types/customer/SettingsData';
import { DeliveryMethods, AssortmentCookie } from '../../types/assortments';
import { UserData } from '../../types/customer/UserData';
import jwtDecode from 'jwt-decode';
import { DecodedToken } from '../../types/auth/DecodedToken';
import { setActiveDeliveryMethodAndStore } from '../../slices/deliveryPickerSlice';
import { fetchStoreNumberBySiteId } from '../../api/endpoints/delivery';
import { openSideModal, sideModalForceOpen } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../../components/SideModal';
import { getStores } from '../../api/endpoints/page';

const { STORAGE_TYPE, AUTH_STORAGE_KEY } = config;
export const storage = makeStorage(STORAGE_TYPE);
const cookieStorage = new Cookies();

export function logoutUser() {
  return (dispatch: Dispatch) => {
    return new Promise((resolve: any) => {
      dispatch(requestLogout());
      Cookie.remove(AUTH_STORAGE_KEY);
      Cookie.remove(AUTH_STORAGE_KEY + '_user');
      cookieStorage.remove('e_sk');

      configure({ accessToken: null, tokenType: null });

      dispatch(receiveLogout());
      resolve();
    });
  };
}

export function loginUser(creds: LoginUserProps) {
  return (dispatch: Dispatch) => {
    const loginSuccessFul = (user: AuthResponse) => {
      const decodedToken = jwtDecode<DecodedToken>(user.access_token);
      const item = {
        ...user,
        expires_in: Date.now() + user.expires_in * 1000,
        roles: decodedToken.role
      };

      Cookie.set(AUTH_STORAGE_KEY, JSON.stringify(item));
      configure({
        access_token: item.access_token,
        token_type: item.token_type
      });

      sendTracking({ event: 'login' });

      dispatch(receiveLogin(user));

      return user;
    };

    const loginFailed = (err: any) => {
      dispatch(loginError(err));
      return Promise.reject(err);
    };

    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));

    return login(creds.username, creds.password)
      .then(({ data }) => loginSuccessFul(data))
      .catch(loginFailed);
  };
}

export const fetchUserInfo = () => (dispatch: Dispatch) => {
  const userData = requestRetry(
    fetchCurrentUser,
    dispatch,
    REQUEST_IDS.customer
  )
    .then(({ data }) => {
      dispatch(retrieveUser(data));
      return data;
    })
    .catch(err => {
      console.error('Fetching userinfo failed', err); //eslint-disable-line
    });
  const favorites = requestRetry(
    fetchUserFavorites,
    dispatch,
    REQUEST_IDS.favorites
  )
    .then(({ data }) => {
      dispatch(retrieveFavorites(data));
      return data;
    })
    .catch(err => {
      console.error('Fetching userinfo failed', err); //eslint-disable-line
    });

  return userData;
};

export const passwordReset = (email: string) => (dispatch: Dispatch) => {
  dispatch(passwordResetRequest());
  return requestPasswordReset(email)
    .then(() => {
      dispatch(passwordResetSuccess(email));
    })
    .catch(err => {
      dispatch(passwordResetFailure());
      return Promise.reject(err);
    });
};

export const emailUpdate = (
  currentEmail: string,
  password: string,
  newEmail: string
) => (dispatch: Dispatch) =>
  setNewEmail(currentEmail, password, newEmail).then(resp => {
    const { data } = resp;
    dispatch(retrieveUser(data));
  });

export const phoneUpdate = (newPhone: string) => (dispatch: Dispatch) => {
  dispatch(phoneUpdateRequest());
  return setNewPhone(newPhone)
    .then(({ data }) => {
      dispatch(phoneUpdateSuccess());
      dispatch(retrieveUserContactChannel(data));
    })
    .catch(err => {
      dispatch(phoneUpdateFailure());
      return Promise.reject(err);
    });
};

export const storeForUser = (
  chosenDelivery: DeliveryMethods,
  zipCode: number,
  siteId: string | number | null
) => (dispatch: Dispatch, getState) => {
  if (!chosenDelivery || !siteId) {
    return dispatch(toggleModalActive(true));
  }
  // Populates state with all values set on customer.
  // TODO maybe not needed the initial set.?
  dispatch(setInitialAssortmentValues(chosenDelivery, zipCode, Number(siteId)));
  dispatch(
    setActiveDeliveryMethodAndStore({
      activeDeliveryMethod: chosenDelivery,
      activeStore: Number(siteId),
      postalCode: Number(zipCode)
    })
  );
  // Triggers lookup of assortment based on values

  return setAssortment(
    chosenDelivery,
    siteId,
    zipCode
  )(dispatch, getState).catch(e => {
    console.error('Failed to fetch store for deliverymethod', e);
  });
};

export const allUserInfo = (assortmentValues: AssortmentCookie) => async (
  dispatch: Dispatch,
  getState
) => {
  return await fetchUserInfo()(dispatch).then(async (user: UserData) => {
    sendTracking({
      userId: user?.contact?.id
    });
    if (user?.settings?.userSiteId) {
      try {
        const store = await fetchStoreNumberBySiteId(user.settings.userSiteId);
        const { selectableStores } = await getStores();

        const validStoreToPick = selectableStores?.find(
          storeData => storeData?.data?.siteId === user.settings.userSiteId
        );

        if (store.data && validStoreToPick) {
          const chosenDelivery =
            (user && user.settings && user.settings.userDeliveryMethod) ||
            assortmentValues?.deliveryType;

          const zipCode =
            (user && user.settings && user.settings.userPostalCode) ||
            assortmentValues?.userPostalCode;

          const siteId =
            (user && user.settings && user.settings.userSiteId) ||
            assortmentValues?.userSiteId ||
            null;

          storeForUser(
            chosenDelivery,
            Number(zipCode),
            siteId
          )(dispatch, getState);
        } else {
          // no storenumber found, force delivery picker
          dispatch(sideModalForceOpen(true));
          dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
        }
      } catch (e) {
        dispatch(sideModalForceOpen(true));
        dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
      }
    }
  });
};

export const setProductFavorite = (
  productId: string,
  item: { name: string; value: number }
) => (dispatch: Dispatch) => {
  dispatch(requestAddProductFavorite(productId));
  try {
    ticketNotifierByFavoriteAdd(productId, productId);
  } catch {
    console.error('Could not notify esales with favorite');
  }

  return setFavoriteProduct(productId)
    .then(({ data }) => {
      dispatch(receiveAddProductFavorite(data));
      ga4FavoriteProductTracking(item?.name, productId, item?.value);
    })
    .catch(err => {
      dispatch(failedAddProductFavorite(productId));
      return Promise.reject(err);
    });
};

export const setEmailRecieptCopy = (status: boolean | 'true' | 'false') => (
  dispatch: Dispatch
) => {
  dispatch(requestSetEmailRecieptCopy());

  return setEmailRecieptCopyReq(status)
    .then(({ data }) => {
      dispatch(receiveSetEmailRecieptCopy(data));
    })
    .catch(err => {
      dispatch(failedSetEmailRecieptCopy());
      return Promise.reject(err);
    });
};

export const setRecipeFavorite = (recipeId: string, item: any) => (
  dispatch: Dispatch
) => {
  dispatch(requestAddRecipeFavorite(recipeId));
  try {
    ticketNotifierByFavoriteAdd(recipeId, recipeId);
    ga4FavoriteRecipeTracking(item?.name, recipeId);
  } catch {
    console.error('Could not notify esales with favorite');
  }

  return setFavoriteRecipe(recipeId)
    .then(({ data }) => {
      dispatch(receiveAddRecipeFavorite(data));
    })
    .catch(err => {
      dispatch(failedAddRecipeFavorite(recipeId));
      return Promise.reject(err);
    });
};

export const removeFavorite = (favorite?: FavoriteModel) => (
  dispatch: Dispatch
) => {
  if (!favorite) {
    return null;
  }

  dispatch(requestRemoveFavorite(favorite));

  try {
    ticketNotifierByFavoriteRemove(favorite.itemNo, favorite.itemNo);
  } catch {
    console.error('Could not notify esales with favorite');
  }

  return deleteFavorite(favorite)
    .then(() => {
      dispatch(receiveRemoveFavorite(favorite));
    })
    .catch(err => {
      dispatch(failedRemoveFavorite(favorite));
      return Promise.reject(err);
    });
};

export const getCustomerToken = (token: string) => (dispatch: Dispatch) => {
  dispatch(requestCustomerToken());

  return fetchCustomerToken(token)
    .then(({ data }) => {
      dispatch(receiveCustomerToken());

      const item = {
        token_type: 'Bearer',
        expires_in: Date.now() + 1800000, // 30 mins
        access_token: data,
        as_customer: true
      };

      Cookie.set(AUTH_STORAGE_KEY, JSON.stringify(item));
      configure({
        access_token: item.access_token,
        token_type: item.token_type
      });

      return Promise.resolve(true);
    })
    .catch(err => {
      dispatch(failedCustomerToken());
      console.error(err); // eslint-disable-line
      return Promise.reject();
    });
};
