import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { Wizard } from '@citygross/react-use-bg-wizard';
import * as Steps from './Steps';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentStep,
  selectPreviousStep,
  setCurrentStep,
  setActivePostalCode,
  setActiveDeliveryMethodAndStore,
  setSaveDeliverySettingsLoading,
  selectSaveDeliverySettingsLoading
} from '../../../slices/deliveryPickerSlice';
import {
  selectedDeliverySettings,
  selectedPickupStore
} from '../../../selectors/assortments';
import { AppState } from '../../../reducers';
import {
  selectActiveDeliveryMethod,
  selectActivePostalCode,
  selectActiveStore
} from '../../../slices/deliveryPickerSlice';
import {
  openSideModal,
  selectModalForceOpen,
  selectTabHistory,
  sideModalForceOpen
} from '../../../slices/sideModalSlice';
import { setAssortment } from '../../../actions/assortments';
import { getAllCartItemsSelector } from '../../../selectors/cart/cartSelectorNew';
import { resetNewCart } from '../../../actions/newCartPrep';
import { fetchStoreNumberBySiteId } from '../../../api/endpoints/delivery';
import { prepareMoveCart } from '../../../actions/newCartPrep';
import { toast } from 'react-toastify';
import { Modal } from '@citygross/components';
import { UnavailableConfirm } from '../../Sortiment/SortimentPicker/UnavailableConfirm';
import { NewCartPreview } from '../../Sortiment/NewCartPreview/NewCartPreview';
import SideModalWizardHeader from '../../SideModal/SideModalWizardHeader';
import { AnimatedStateStep } from '../AnimatedStep';
import { handleStoreIdChange } from '../../../actions/cart';
import { setCartToChange } from '../../../actions/newCartPrep/sync';

type TDeliveryMethod = {
  HOME_DELIVERY: 'homeDelivery';
  PICKUP: 'pickupAtStore';
};

export const DeliveryMethods: TDeliveryMethod = {
  HOME_DELIVERY: 'homeDelivery',
  PICKUP: 'pickupAtStore'
};

export type TDeliveryPickerSteps = {
  ENTER_POSTAL_CODE: 0;
  CHOOSE_DELIVERY_METHOD: 1;
  SHOW_ALL_STORES: 2;
};

export const deliveryPickerSteps: TDeliveryPickerSteps = {
  ENTER_POSTAL_CODE: 0,
  CHOOSE_DELIVERY_METHOD: 1,
  SHOW_ALL_STORES: 2
};

const SideModalDeliveryChoicePicker = ({
  clientHeight,
  closeModal
}: {
  clientHeight?: string;
  closeModal: () => void;
}) => {
  const [homeDeliveryWarningModal, setHomeDeliveryWarningModal] = useState(
    false
  );
  const [newCartPreviewModal, setNewCartPreviewModal] = useState(false);
  const [savedNewStore, setSavedNewStore] = useState(false);
  const dispatch = useDispatch();

  const configuredDeliverySettings = useSelector((state: AppState) =>
    selectedDeliverySettings(state)
  );
  const configuredPickupStore = useSelector((state: AppState) =>
    selectedPickupStore(state)
  );

  const allProducts = useSelector((state: AppState) =>
    getAllCartItemsSelector(state)
  );
  const cart: any = useSelector((state: AppState) => state.cart);

  const activePostalCode = useSelector(selectActivePostalCode);
  const activeDeliveryMethod = useSelector(selectActiveDeliveryMethod);
  const activeStore = useSelector(selectActiveStore);

  const saveDeliverySettingsLoading = useSelector(
    selectSaveDeliverySettingsLoading
  );

  const saveNewDeliverySettings = async (storeNumber?: number) => {
    if (!saveDeliverySettingsLoading)
      dispatch(setSaveDeliverySettingsLoading(true));

    try {
      if (
        activeDeliveryMethod === DeliveryMethods.HOME_DELIVERY &&
        (configuredPickupStore || activeStore)
      ) {
        await dispatch(
          setAssortment(
            activeDeliveryMethod,
            activeStore.id,
            Number(activePostalCode)
          )
        );
      }

      if (activeDeliveryMethod === DeliveryMethods.PICKUP) {
        await dispatch(
          setAssortment(
            activeDeliveryMethod,
            activeStore.id,
            Number(activePostalCode)
          )
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(
        'Tyvärr gick det inte att välja valt leveranssätt. Vänligen försök igen eller kontakta kundtjänst'
      );
    } finally {
      dispatch(setSaveDeliverySettingsLoading(false));
      setSavedNewStore(true);
    }
  };

  const handleSaveNewDeliverySettings = async () => {
    if (activeDeliveryMethod === DeliveryMethods.HOME_DELIVERY) {
      if (allProducts?.length > 0) {
        setHomeDeliveryWarningModal(true);
      } else {
        saveNewDeliverySettings(activeStore?.storeNumber);
      }
    }

    if (activeDeliveryMethod === DeliveryMethods.PICKUP) {
      if (cart?.meta?.id) {
        dispatch(setSaveDeliverySettingsLoading(true));
        try {
          const data = await dispatch(
            prepareMoveCart(cart?.meta.id, activeStore.storeNumber, cart)
          );

          if (data && data.isDifferent) {
            setNewCartPreviewModal(true);
          } else {
            dispatch(
              handleStoreIdChange(cart?.meta?.id, activeStore.storeNumber)
            );
            saveNewDeliverySettings(activeStore.storeNumber);
          }
          dispatch(sideModalForceOpen(false));
          dispatch(setCartToChange(null));
        } catch (error) {
          console.error(error);
          toast.error(
            'Tyvärr gick det inte att välja valt leveranssätt. Vänligen försök igen eller kontakta kundtjänst'
          );
          dispatch(setSaveDeliverySettingsLoading(false));
        }
      } else {
        saveNewDeliverySettings();
      }
    }
  };

  useEffect(() => {
    dispatch(setActivePostalCode(configuredDeliverySettings.zipCode));
    dispatch(
      setActiveDeliveryMethodAndStore({
        activeDeliveryMethod: null,
        activeStore: null
      })
    );
  }, []);

  const onStepChange = (toStep: number, fromStep: number) => {
    dispatch(
      setCurrentStep({
        currentStep: toStep,
        previousStep: fromStep
      })
    );
  };
  const tabHistory = useSelector(selectTabHistory);
  const forceOpen = useSelector(selectModalForceOpen);
  const previousStep = useSelector(selectPreviousStep);

  const previousTab =
    tabHistory.length >= 2 && tabHistory[tabHistory.length - 1];

  interface IHeaderOptions {
    title?: string;
    onBackArrowClick?: () => void;
    showBackArrow?: boolean;
    goToStepOnBack?: number;
  }
  const currentStep = useSelector(selectCurrentStep);
  const headerOptions = (step: number): IHeaderOptions => {
    switch (step) {
      case deliveryPickerSteps.CHOOSE_DELIVERY_METHOD:
        return {
          title: 'Välj leveranssätt',
          onBackArrowClick: () => dispatch(openSideModal(previousTab)),
          showBackArrow: previousTab && !forceOpen
        };
      case deliveryPickerSteps.ENTER_POSTAL_CODE:
        return {
          title: 'Ange ditt postnummer',
          showBackArrow: previousStep !== 0 || activePostalCode,
          goToStepOnBack: deliveryPickerSteps.CHOOSE_DELIVERY_METHOD + 1,
          onBackArrowClick: () =>
            onStepChange(
              deliveryPickerSteps.CHOOSE_DELIVERY_METHOD,
              currentStep
            )
        };
      case deliveryPickerSteps.SHOW_ALL_STORES:
        return {
          title: 'Välj butik',
          showBackArrow: true,
          goToStepOnBack: deliveryPickerSteps.CHOOSE_DELIVERY_METHOD + 1,
          onBackArrowClick: () =>
            onStepChange(
              deliveryPickerSteps.CHOOSE_DELIVERY_METHOD,
              currentStep
            )
        };
      default:
        return {};
    }
  };

  useEffect(() => {
    onStepChange(
      configuredDeliverySettings.zipCode
        ? deliveryPickerSteps.CHOOSE_DELIVERY_METHOD
        : deliveryPickerSteps.ENTER_POSTAL_CODE,
      currentStep
    );
  }, []);

  return (
    <>
      <AnimatePresence>
        <Wizard
          startIndex={
            configuredDeliverySettings.zipCode
              ? deliveryPickerSteps.CHOOSE_DELIVERY_METHOD
              : deliveryPickerSteps.ENTER_POSTAL_CODE
          }
          header={
            <SideModalWizardHeader
              title={headerOptions(currentStep)?.title}
              closeSideModal={closeModal}
              backArrowOnClick={headerOptions(currentStep)?.onBackArrowClick}
              goToStepOnBack={headerOptions(currentStep)?.goToStepOnBack}
              showBackArrow={headerOptions(currentStep)?.showBackArrow}
            />
          }
        >
          <AnimatedStateStep
            currentStepSelector={selectCurrentStep}
            previousStepSelector={selectPreviousStep}
            onStepChange={onStepChange}
            stepComponent={<Steps.EnterPostalCode />}
          />
          <AnimatedStateStep
            currentStepSelector={selectCurrentStep}
            previousStepSelector={selectPreviousStep}
            onStepChange={onStepChange}
            stepComponent={
              <Steps.ChooseDeliveryMethodStep
                handleSaveNewDeliverySettings={handleSaveNewDeliverySettings}
                savedNewStore={savedNewStore}
              />
            }
          />
          <AnimatedStateStep
            currentStepSelector={selectCurrentStep}
            previousStepSelector={selectPreviousStep}
            onStepChange={onStepChange}
            stepComponent={
              <Steps.ChooseStoreStep
                handleSaveNewDeliverySettings={handleSaveNewDeliverySettings}
                savedNewStore={savedNewStore}
                clientHeight={clientHeight}
              />
            }
          />
        </Wizard>
      </AnimatePresence>
      {homeDeliveryWarningModal && (
        <Modal
          isConfirm={false}
          onBackdropClick={() => {
            setHomeDeliveryWarningModal(false);
            if (saveDeliverySettingsLoading)
              dispatch(setSaveDeliverySettingsLoading(false));
          }}
        >
          <UnavailableConfirm
            products={allProducts}
            goBack={() => {
              setHomeDeliveryWarningModal(false);
              if (saveDeliverySettingsLoading)
                dispatch(setSaveDeliverySettingsLoading(false));
            }}
            heading={'Hemleverans inte möjlig'}
            description={
              'Hemleverans till ditt valda postnummer stödjer inte leverans utav matvaror eller catering varor. Vänligen gå vidare eller byt till hämta i butik.'
            }
            postItemRemovalCallback={() => {
              setHomeDeliveryWarningModal(false);
              saveNewDeliverySettings();
            }}
          />
        </Modal>
      )}
      {newCartPreviewModal && (
        <Modal
          isConfirm={false}
          onBackdropClick={() => {
            setNewCartPreviewModal(false);
            dispatch(resetNewCart());

            if (saveDeliverySettingsLoading)
              dispatch(setSaveDeliverySettingsLoading(false));
          }}
        >
          <NewCartPreview
            inDeliveryModal
            handleSubmit={async cartId => {
              try {
                const storeNumberData = await fetchStoreNumberBySiteId(
                  activeStore.id
                );
                await saveNewDeliverySettings(storeNumberData.data.storeNumber);

                dispatch(
                  handleStoreIdChange(
                    cartId,
                    storeNumberData.data.storeNumber,
                    true
                  )
                );
                dispatch(setCartToChange(null));
              } catch (error) {
                console.error(error);
                toast.error(
                  'Tyvärr gick det inte att välja valt leveranssätt. Vänligen försök igen eller kontakta kundtjänst'
                );
                setNewCartPreviewModal(false);
                if (saveDeliverySettingsLoading)
                  dispatch(setSaveDeliverySettingsLoading(false));
              }
            }}
            goBack={() => {
              setNewCartPreviewModal(false);

              dispatch(resetNewCart());

              if (saveDeliverySettingsLoading)
                dispatch(setSaveDeliverySettingsLoading(false));
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default SideModalDeliveryChoicePicker;
