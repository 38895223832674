import React from 'react';
import { Leaflet } from './Leaflet/Leaflet';
import { TLeaflet } from '../../types/offers/offers';
import './Leaflets.scss';
import cs from 'classnames';

type TLeaflets = {
  leaflets: TLeaflet[];
};

export const Leaflets = ({ leaflets }: TLeaflets) => {
  return (
    <div className={cs({ 'leaflets-multiple': leaflets.length > 1 })}>
      {leaflets.slice(0, 2).map(leaflet => (
        <Leaflet key={leaflet.id} leaflet={leaflet} />
      ))}
    </div>
  );
};
