import React from 'react';

import { useWizard } from '@citygross/react-use-bg-wizard';
import SideModalHeader from './SideModalHeader';

type TSideModalHeader = {
  title?: string;
  closeSideModal?: () => void;
  leftElement?: React.ReactNode;
  hideCloseButton?: boolean;
  marginBottom?: number;
  showBackArrow?: boolean;
  backArrowOnClick?: () => void;
  goToStepOnBack?: number;
  isLogin?: boolean;
};
// @TODO move to components library?
const SideModalWizardHeader = ({
  title,
  closeSideModal,
  leftElement,
  hideCloseButton,
  marginBottom,
  showBackArrow,
  backArrowOnClick,
  goToStepOnBack,
  isLogin
}: TSideModalHeader) => {
  const { setStep, activeStep } = useWizard();
  return (
    <SideModalHeader
      title={title}
      closeSideModal={closeSideModal}
      leftElement={leftElement}
      backArrowOnClick={backArrowOnClick}
      hideCloseButton={hideCloseButton}
      showBackArrow={showBackArrow}
      marginBottom={marginBottom}
      isLogin={isLogin}
      setStep={() =>
        setStep(
          goToStepOnBack || goToStepOnBack === 0 ? goToStepOnBack : activeStep
        )
      }
    />
  );
};

export default SideModalWizardHeader;
