import { createSelector } from 'reselect';
import {
  determineMarkingVariant,
  determineSafetyDataSheetInformation,
  setImage,
  determinePrice,
  determineAvailable
} from '../../lib/product';
import { activeStoreNoSelector } from '../assortments';
import { AppState } from '../../reducers';
import { IEsalesFilterOptions, INiceFilter } from '../../types/esales';
import { Product, Marking } from '../../types/xim/product';
import { Marking as EpiserverMarking } from '../../types/episerver/marking';
import { SignalWords } from '../../types/episerver/signalWords';
import { WarningSymbol } from '../../types/episerver/warningSymbol';
import { FavoriteModel } from '../../types/customer/SettingsData';
import { calculateSellingUnitOfMeasure } from '../../lib/price';
import { IExtendedProduct } from '../../types/storefront/product';
import { markingFilterData } from '../../components/Marking/MarkingFilterData';

const getAllProducts = state => state.product.products;
const getCategoryProducts = (state: AppState) => state.product.category;
const filtersSelector = (state: AppState) => state.product.filters;
export const sweetenedFiltersSelector = (state: AppState) =>
  state.app.settings?.productMarkings;
export const sweetenedSignalWordsSelector = (state: AppState) =>
  state.app.settings?.signalWords;
export const sweetenedSymbolsSelector = (state: AppState) =>
  state.app.settings?.warningSymbols;
const authState = (state: AppState) => state.auth;

const userSettingsState = createSelector(
  [authState],
  authState => authState && authState.user && authState.user.settings
);

const favoriteProductsSelector = state => {
  return state.auth &&
    state.auth.productFavorites &&
    state.auth.productFavorites.length > 0
    ? state.auth.productFavorites
    : [];
};

export const favoriteProductsLoadingSelector = createSelector(
  [authState],
  authState => (authState ? authState.favoriteProductsLoading : [])
);

export const filtersCombinationSelector = createSelector(
  [filtersSelector, sweetenedFiltersSelector],
  (filters, sweetenedFilters) => {
    const combinedFilters = Object.values(filters || []).map(
      (filterType: IEsalesFilterOptions) => {
        // If marking we only iterate over sweetFilters because rest..
        // Should not show.
        if (filterType.id === 'marking') {
          let mergedFilters;
          if (sweetenedFilters) {
            mergedFilters = markingFilterData(sweetenedFilters, filterType);
          }

          return {
            ...filterType,
            options: mergedFilters
          };
        } else {
          const F = filterType.options.map(option => {
            const matchedFilter = sweetenedFilters?.find(
              item => item.code === option.id
            );

            return matchedFilter
              ? { ...option, niceName: matchedFilter.name }
              : option;
          });
          return {
            ...filterType,
            options: F
          };
        }
      }
    );
    return Object.values(combinedFilters);
  }
);

export const selectedFiltersSelector = createSelector(
  // combining with epi-values
  [filtersCombinationSelector],
  filters => {
    return Object.values(filters).reduce((acc: any, { id, options }) => {
      const selected =
        options
          ?.filter(opt => opt.selected)
          .map(opt => ({ parent: id, ...opt })) || [];

      return [...acc, ...selected];
    }, []);
  }
);

export const productDefaults = (
  p: Product,
  storeNumber: number | string | null,
  sweetenedFilters?: EpiserverMarking[] | null,
  sweetenedSignalWords?: SignalWords[],
  sweetenedSymbols?: WarningSymbol[]
): IExtendedProduct => {
  const shouldPromote =
    p.newProductFrom && p.newProductTo
      ? Boolean(
          new Date() > new Date(p.newProductFrom) &&
            new Date() < new Date(p.newProductTo)
        )
      : false;

  // Due to PIM not delivering this marking anymore
  const customMarkings: Marking[] | null = shouldPromote
    ? [
        {
          qualifier: 'https://www.citygross.se',
          agency: 'https://www.citygross.se',
          code: 'NYHET',
          codeListVersion: '1',
          descriptions: []
        },
        ...(p.markings ? p.markings : [])
      ]
    : p.markings
    ? [...p.markings]
    : [];

  if (
    p?.tags?.length &&
    p.tags.some(
      tag =>
        tag.namespace.includes('CUSTOM') && tag?.name?.toUpperCase() !== 'KLIPP'
    )
  ) {
    p.tags.forEach(tag => {
      const isMarking = sweetenedFilters?.find(
        filter => filter.code === tag.name && filter.ispublished
      );
      if (isMarking) {
        customMarkings?.push({
          qualifier: 'https://www.citygross.se',
          agency: 'https://www.citygross.se',
          code: tag.name,
          codeListVersion: '1',
          descriptions: []
        });
      }
    });
  }
  return {
    ...p,
    price: determinePrice(p.prices, p.defaultPrice, storeNumber),
    unit: calculateSellingUnitOfMeasure(p),
    mappedMarkings:
      determineMarkingVariant(customMarkings, sweetenedFilters) || [],
    // @TODO: handle this in loop54
    available: determineAvailable(p.availability, storeNumber),
    mappedSafetyDataSheetInformation: determineSafetyDataSheetInformation(
      p.safetyDataSheetInformation,
      sweetenedSignalWords,
      sweetenedSymbols
    )
  };
};

// TODO Refactor this so that we can type productGetter by a generic...
export const makeProductEnhancerSelector = productGetter =>
  createSelector(
    [
      productGetter,
      activeStoreNoSelector,
      sweetenedFiltersSelector,
      sweetenedSignalWordsSelector,
      sweetenedSymbolsSelector
    ],
    (
      products: Product[],
      store,
      sweetenedFilters,
      sweetenedSignalWords,
      sweetenedSymbols
    ) =>
      products
        ? products.map(product =>
            productDefaults(
              product,
              store,
              sweetenedFilters,
              sweetenedSignalWords,
              sweetenedSymbols
            )
          )
        : []
  );

export const productFavoritesSelector = createSelector(
  [favoriteProductsSelector],
  favorites => {
    return favorites;
  }
);

export const categoryProductsWithDefaults = makeProductEnhancerSelector(
  getCategoryProducts
);

export const allProductsWithDefaults = makeProductEnhancerSelector(
  getAllProducts
);
