import React from 'react';
import Spinner from '../Spinner';

import './LoadingBlock.scss';

interface ILoadingBlock {
  height?: number
}

const LoadingBlock : React.FC<ILoadingBlock> = ({ height }) => (
  <div className="c-loading-block" style={{ height: `${height}vh` }}>
    <Spinner dark />
  </div>
);

LoadingBlock.defaultProps = {
  height: 100
};

export default LoadingBlock;
