import React from 'react';
import PropTypes from 'prop-types';
import './ToolTip.scss';

interface IProps {
  label: string;
  alignment: 'left' | 'top' | 'bottom' | 'right';
}

const ToolTip: React.FC<IProps> = ({ label, alignment = 'top', children }) => {
  return (
    <div
      className="c-tooltip"
      data-tooltip={label}
      tooltip-position={alignment}
      onClick={e => {
        e.preventDefault();
      }}
    >
      {children}
    </div>
  );
};

export default ToolTip;
