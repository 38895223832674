import React, { Component } from 'react';
import { identity } from '../../lib/utils';
import { Error500 } from './500';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    const { componentName } = this.props;
    this.setState({ info });

    console.error(error, info.componentStack);
  }

  render() {
    return this.state.hasError ? (
      <Error500
        error={this.state.error}
        info={this.state.info}
        debug={false}
        componentName={this.props.componentName}
      />
    ) : (
      this.props.children
    );
  }
}

export const withErrorBoundary = WrappedComponent => props => (
  <ErrorBoundary componentName={WrappedComponent.name}>
    <WrappedComponent {...props} />
  </ErrorBoundary>
);

ErrorBoundary.defaultProps = {
  reportError: identity
};
