import {
  FETCH_SETTINGS_RECEIVED,
  FETCH_SETTINGS_FAILED,
  TOGGLE_SEARCH,
  TOGGLE_MODAL,
  TOGGLE_PORTAL_OVERLAY,
  TOGGLE_OVERLAY,
  CLEAR_OVERLAY_CBS,
  ISettingsReceivedAction,
  ISettingsFailedAction,
  IAppToggleSearchAction,
  IAppToggleOverlayAction,
  IAppTogglePortalOverlay,
  IAppToggleModalAction,
  AppState
} from '../../types/reducers/app';

const initialState: AppState = {
  settings: null,
  settingsLoading: true,
  mobileNav: { isToggled: false },
  search: { isToggled: false },
  serviceLayer: { isToggled: false },
  overlay: {
    isToggled: false,
    cbs: [],
    includeHeader: false
  },
  portalOverlay: { isToggled: false },
  modal: { isToggled: false },
  error: ''
};

export default function assortmentsReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //AppActionTypes
): AppState {
  switch (action.type) {
    case FETCH_SETTINGS_FAILED: {
      const { error }: ISettingsFailedAction = action;
      return {
        ...state,
        error: error
      };
    }
    case FETCH_SETTINGS_RECEIVED: {
      const { payload }: ISettingsReceivedAction = action;
      return {
        ...state,
        settingsLoading: false,
        settings: {
          ...payload
        }
      };
    }
    case TOGGLE_SEARCH: {
      const { payload }: IAppToggleSearchAction = action;
      return {
        ...state,
        search: {
          isToggled:
            typeof payload.active !== 'undefined'
              ? action.payload.active
              : !state.search.isToggled
        }
      };
    }
    case TOGGLE_OVERLAY: {
      const { payload }: IAppToggleOverlayAction = action;
      return {
        ...state,
        overlay: {
          isToggled: payload.active,
          includeHeader: payload.includeHeader,
          cbs: state.overlay.cbs.concat(payload.cb)
        }
      };
    }
    case CLEAR_OVERLAY_CBS: {
      return {
        ...state,
        overlay: initialState.overlay
      };
    }
    case TOGGLE_PORTAL_OVERLAY: {
      const { payload }: IAppTogglePortalOverlay = action;
      return {
        ...state,
        portalOverlay: {
          isToggled: payload.active
        }
      };
    }
    case TOGGLE_MODAL: {
      const { payload }: IAppToggleModalAction = action;
      return {
        ...state,
        modal: {
          isToggled: payload.active
        }
      };
    }
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        overlay: state.overlay,
        portalOverlay: state.portalOverlay,
        search: initialState.search
      };
    }
    default:
      return state;
  }
}
