import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userKdbIdSelector, authenticatedSelector } from '../../selectors/user';
import {
  fetchMostBoughtProducts,
  fetchProductsByIds
} from '../../api/endpoints';
import ProductList from '../../containers/ProductList';
import RichText from '../../containers/RichText/RichText';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { determineAvailable } from '../../lib/product';
import { FilterProducts } from '../../components/FilterProducts';
import { redirectToLogin } from '../../actions/app';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import * as ga4 from '@citygross/analytics';
import { generateGA4ImpressionsArrayFromEecImpressions } from '../../lib/analytics/analytics';

const DEFAULT_DAYS_TO_FETCH = 90;
const DEFAULT_MAX_ITEMS_TO_FETCH = 50;
const DEFAULT_NBR_OF_SKELETONS = 6;
const impressionListName = 'Most bought products';

class MostBoughtProducts extends Component {
  state = {
    fetching: true,
    products: [],
    fetchError: false,
    show: null,
    selectedSort: '',
    productIdsWithoutPaperBag: []
  };

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.getProducts();
    }

    this.setState({
      show: true
    });

    this.setState({
      selectedSort: ''
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAuthenticated && prevProps.kdbId !== this.props.kdbId) {
      this.getProducts();
    }

    if (
      this.props.isAuthenticated &&
      !this.state.fetching &&
      prevProps.storeId !== this.props.storeId
    ) {
      this.setState({
        products: [],
        fetching: true
      });
      this.getProducts();
    }
    if (prevState.selectedSort !== this.state.selectedSort) {
      this.getEsalesProducts();
    }
  }

  getEsalesProducts() {
    fetchProductsByIds(
      this.state.productIdsWithoutPaperBag,
      this.state.selectedSort,
      this.state.productIdsWithoutPaperBag.length
    ).then(({ data }) => {
      if (data && data.length > 0) {
        this.setState({
          products: data.filter(product =>
            determineAvailable(product?.availability, this.props.storeId)
          ),
          fetching: false
        });

        try {
          const impressionList = data.map((prod, index) => {
            const price =
              prod.prices?.find(x => x.storeNumber === this.props.storeId) ||
              prod.prices
                ? prod.prices[0]
                : null;
            return {
              id: prod.gtin ?? '',
              name: prod.name ?? '',
              category:
                prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                undefined,
              brand: prod.brand || 'City Gross',
              list: impressionListName,
              position: index,
              dimension8: price?.hasDiscount ? 'On Sale' : 'Not On Sale'
            };
          });
          ga4.viewItemList({
            items: generateGA4ImpressionsArrayFromEecImpressions(impressionList),
            item_list_id: impressionListName,
            item_list_name: impressionListName
          }); 
        } catch (error) {
          console.error(error)
        }
      } else {
        this.setState({
          products: [],
          fetching: false
        });
      }
    });
  }

  getProducts() {
    const { daysToFetch, maxItemsToFetch } = this.props;

    if (daysToFetch > 0 && maxItemsToFetch > 0) {
      fetchMostBoughtProducts(daysToFetch, maxItemsToFetch)
        .then(data => {
          const productsIds = data;
          if (productsIds.length > 0) {
            const paperBagIds = ['8837429', '101408629'];
            const productIdsWithoutPaperBag = productsIds.filter(
              c => !paperBagIds.includes(c)
            );
            this.setState({
              productIdsWithoutPaperBag: productIdsWithoutPaperBag
            });
            fetchProductsByIds(
              productIdsWithoutPaperBag,
              this.state.selectedSort,
              productIdsWithoutPaperBag.length
            )
              .then(({ data }) => {
                if (data && data.length > 0) {
                  this.setState({
                    products: data.filter(product =>
                      determineAvailable(
                        product?.availability,
                        this.props.storeId
                      )
                    ),
                    fetching: false
                  });
                  try {
                    const impressionList = data.map((prod, index) => {
                      const price =
                        prod.prices?.find(
                          x => x.storeNumber === this.props.storeId
                        ) || prod.prices
                          ? prod.prices[0]
                          : null;
                      return {
                        id: prod.gtin,
                        name: prod.name,
                        category:
                          prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                          undefined,
                        brand: prod.brand || 'City Gross',
                        list: impressionListName,
                        position: index,
                        dimension8: price?.hasDiscount ? 'On Sale' : 'Not On Sale'
                      };
                    });
                    ga4.viewItemList({
                      items: generateGA4ImpressionsArrayFromEecImpressions(impressionList),
                      item_list_id: impressionListName,
                      item_list_name: impressionListName
                    });
                  } catch (error) {
                    console.error(error)
                  }

                } else {
                  this.setState({
                    products: [],
                    fetching: false
                  });
                }
              })
              .catch(err => {
                console.log(err);
                this.setState({
                  fetchError: true,
                  products: null,
                  fetching: false
                });
              });
          } else {
            this.setState({
              products: [],
              fetching: false
            });
          }
        })
        .catch(err => {
          console.error(err); // eslint-disable-line no-console
          alert(2);
          this.setState({
            fetchError: true,
            products: null,
            fetching: false
          });
        });
    } else if (daysToFetch === 0 && maxItemsToFetch === 0) {
      alert(1);
      this.setState({
        fetching: false,
        products: []
      });
    }
  }

  render() {
    const { fetchError, fetching, products, show } = this.state;
    const {
      isAuthenticated,
      skeletons,
      notLoggedInText,
      missingDataText
    } = this.props;

    if (!isAuthenticated) {
      return (
        <div className="c-most-bought-products">
          <RichText text={notLoggedInText} />
        </div>
      );
    }

    return (
      show && (
        <div className="c-most-bought-products">
          {((!fetching && !products) || fetchError) && (
            <p>
              Just nu kan vi inte hämta dina mest köpta produkter. Försök igen
              senare.
            </p>
          )}
          {!fetching && products && products.length === 0 && (
            <div className="c-account--empty">
              <div className="mb-10">
                <RichText text={missingDataText} />
              </div>

              <Link className="c-cmdbtn primary" to="/matvaror">
                Börja handla
              </Link>
            </div>
          )}
          {(fetching || (products && products.length > 0)) && (
            <>
              <FilterProducts
                onChange={e => {
                  this.setState({ selectedSort: e });
                }}
              />
              <ProductList
                skeletons={skeletons}
                products={products}
                fetching={fetching}
                withoutPaginationAndFilter
                impressionListName={impressionListName}
              />
            </>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  kdbId: userKdbIdSelector(state),
  isAuthenticated: authenticatedSelector(state),
  storeId: selectCurrentAssortment(state)
});

const mapDispatchToProps = {
  redirectToLogin,
  setProductFavorite,
  removeFavorite
};

MostBoughtProducts.propTypes = {
  daysToFetch: PropTypes.number, // Defaults to DEFAULT_DAYS_TO_FETCH
  maxItemsToFetch: PropTypes.number, // Defaults to DEFAULT_MAX_ITEMS_TO_FETCH
  skeletons: PropTypes.number, // Defaults to DEFAULT_NBR_OF_SKELETONS
  notLoggedInText: PropTypes.string,
  missingDataText: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  kdbId: PropTypes.number,
  redirectToLogin: PropTypes.func,
  removeFavorite: PropTypes.func,
  setProductFavorite: PropTypes.func,
};

MostBoughtProducts.defaultProps = {
  daysToFetch: DEFAULT_DAYS_TO_FETCH,
  maxItemsToFetch: DEFAULT_MAX_ITEMS_TO_FETCH,
  skeletons: DEFAULT_NBR_OF_SKELETONS,
  notLoggedInText:
    '<p>Här kan du se dina mest köpta varor när du har loggat in.</p>',
  missingDataText:
    'Du har inte handlat några varor och vi kan därför inte visa dina mest köpta varor.'
};

export default connect(mapStateToProps, mapDispatchToProps)(MostBoughtProducts);
