import React, { useEffect, useRef, useState } from 'react';
import { CenteredSpinner } from '../Spinner/Spinner';
import { Button } from '../Buttons';
import './LoadMoreToolbar.scss';

const defFn = (str: string) => () => console.log(str);

interface ILoadMoreToolbar {
  fetching: boolean;
  requestMore: () => void;
  totalCount: number;
  count: number;
  entity?: string;
  hideArticleCount?: boolean;
}

export const LoadMoreToolbar: React.FC<ILoadMoreToolbar> = ({
  fetching,
  requestMore,
  totalCount,
  count,
  entity,
  hideArticleCount
}) => {
  const [isCountdown, setIsCountDown] = useState(false);
  const id = useRef<any>();
  const progressBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isCountdown) {
      countDown();
    } else if (!isCountdown) {
      clearInterval(id.current);
    }
  }, [isCountdown]);

  const RegularLoading = () => (
    <>
      {totalCount !== Infinity && (
        <>
          {hideArticleCount && (
            <span className="c-loadmore__status">
              Visar {count} av {totalCount} {entity}
            </span>
          )}
          {totalCount > count && (
            <div className="c-loadmore__button">
              <Button
                primary
                disabled={count === totalCount}
                onClick={() => {
                  requestMore();
                  setIsCountDown(false);
                }}
                onMouseEnter={() => {
                  setIsCountDown(true);
                }}
                onMouseLeave={() => {
                  setIsCountDown(false);
                }}
              >
                {isCountdown ? (
                  <div className="c-loadmore__bar">
                    <div
                      ref={progressBarRef}
                      id="progressBar"
                      className="c-loadmore__progress"
                    />
                  </div>
                ) : (
                  `Visa fler ${entity}`
                )}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );

  const InfiniteLoading = () => (
    <>
      {totalCount === Infinity && (
        <Button
          primary
          disabled={count === totalCount}
          onClick={() => {
            requestMore();
            setIsCountDown(false);
          }}
          onMouseEnter={() => {
            setIsCountDown(true);
          }}
          onMouseLeave={() => {
            setIsCountDown(false);
          }}
        >
          {isCountdown ? (
            <div className="c-loadmore__bar">
              <div
                ref={progressBarRef}
                id="progressBar"
                className="c-loadmore__progress"
              />
            </div>
          ) : (
            `Visa fler ${entity}`
          )}
        </Button>
      )}
    </>
  );

  function countDown() {
    let width = 1;
    id.current = setInterval(frame, 30);
    function frame() {
      if (width >= 100) {
        setIsCountDown(false);
        requestMore();
        clearInterval(id.current);
      } else {
        width++;
        if (progressBarRef?.current !== null) {
          progressBarRef.current.style.width = width + '%';
        }
      }
    }
  }

  return (
    <div className={`c-loadmore ${fetching ? 'loading' : ''}`}>
      {fetching ? (
        <CenteredSpinner dark />
      ) : (
        <>
          {RegularLoading()}
          {InfiniteLoading()}
        </>
      )}
    </div>
  );
};

LoadMoreToolbar.defaultProps = {
  requestMore: defFn('requestMore'),
  entity: 'varor',
  hideArticleCount: true
};
export default LoadMoreToolbar;
