import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({
  errors,
  showRequiredInfo,
  externalErrors,
  recaptchaNotChecked
}) => (
  <Fragment>
    {showRequiredInfo && (
      <div className="required-info">
        <em>* Obligatoriska uppgifter</em>
      </div>
    )}
    {(errors.length > 0 || externalErrors || recaptchaNotChecked) && (
      <div className="errors">
        {recaptchaNotChecked && (
          <ul>
            <li>
              Du måste genomföra reCAPTCHA verifieringen, försök igen eller
              kontakta kundservice.
            </li>
          </ul>
        )}
        {externalErrors &&
          externalErrors.length > 0 && (
            <ul>
              {externalErrors.map((err, index) => (
                <li key={`error_${index}`}>{err}</li>
              ))}
            </ul>
          )}
        {errors &&
          errors.length > 0 && (
            <ul>
              {errors.map((err, index) => (
                <li key={`error_${index}`}>
                  {err.validationHint || err.fallbackHint}
                </li>
              ))}
            </ul>
          )}
      </div>
    )}
  </Fragment>
);

ErrorMessage.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      validationHint: PropTypes.string,
      fallbackHint: PropTypes.string
    })
  ),
  showRequiredInfo: PropTypes.bool,
  externalErrors: PropTypes.arrayOf(PropTypes.string),
  recaptchaNotChecked: PropTypes.bool
};

export default ErrorMessage;
