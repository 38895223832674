import { Button, FormControl } from '@citygross/components';
import React, { useEffect } from 'react';
import * as styles from './SideModalLogin.styles';
import { BodyText } from '@citygross/typography';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import { loginSteps } from './SideModalLogin';
import { clearPasswordReset, passwordReset } from '../../../actions/auth';
import { connect, useDispatch } from 'react-redux';
import LoginInput from '../../LoginInput/LoginInput';
import { setInitialStep } from '../../../slices/loginSlice';

type TLoginPassword = {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  requestingPasswordReset?: boolean;
  passwordResetRequestSuccessfull?: string;
  passwordResetRequestError?: string;
  passwordResetRequestErrorMessage?: string;
} & IAnimatedStepsChildProps;

const LoginForgotPassword = ({
  setEmail,
  email,
  goToStep,
  stepKey,
  animating,
  ...props
}: TLoginPassword) => {
  const dispatch = useDispatch();

  const stepFn = (step: number) => {
    goToStep && (stepKey || stepKey === 0) && goToStep(step, stepKey);
  };

  const doRequest = () => {
    dispatch(passwordReset(email));
  };

  useEffect(() => {
    if (props?.passwordResetRequestSuccessfull) {
      stepFn(loginSteps.LOGIN_EMAIL);
    }
    return () => {
      dispatch(setInitialStep(0));
    };
  }, [props?.passwordResetRequestSuccessfull]);

  return (
    <styles.LoginWrapper>
      <styles.LoginInputContainer>
        <styles.LoginTitle>Återställ lösenord</styles.LoginTitle>
        <BodyText>
          Vi skickar instruktioner om hur du återställer ditt lösenord och
          kommer igång igen.
        </BodyText>
        <styles.LoginInputGroup>
          <styles.LoginInputLabel>E-postadress*</styles.LoginInputLabel>
          <FormControl
            errorMessage={
              <styles.ErrorText>
                Hoppsan, något gick fel! Kontrollera att e-postadressen stämmer.
              </styles.ErrorText>
            }
            isValid={!props?.passwordResetRequestError}
          >
            <LoginInput
              placeholder="Ange E-post"
              value={email}
              onChange={e => {
                if (props?.passwordResetRequestError) {
                  dispatch(clearPasswordReset());
                }
                setEmail(e.target.value);
              }}
              name={'email'}
              loading={animating}
              isValid={!props?.passwordResetRequestError}
            />
          </FormControl>
        </styles.LoginInputGroup>

        <Button
          color={'secondary'}
          fullWidth
          onClick={() => doRequest()}
          loading={props?.requestingPasswordReset}
        >
          Återställ lösenord
        </Button>
      </styles.LoginInputContainer>
    </styles.LoginWrapper>
  );
};
function mapStateToProps(state) {
  return {
    requestingPasswordReset: state.auth.requestingPasswordReset,
    passwordResetRequestSuccessfull: state.auth.passwordResetRequestSuccessfull,
    passwordResetRequestError: state.auth.passwordResetRequestError,
    passwordResetRequestErrorMessage:
      state.auth.passwordResetRequestErrorMessage
  };
}
export default connect(mapStateToProps)(LoginForgotPassword);
