import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import CartIcon from '../assets/cart-black.svg';
import { IncButton, DecButton } from '../Buttons/IconButton';
import Badge from '../Badge';
import Rollup from '../Rollup';
import './ChangeQuantity.scss';

class ChangeQuantity extends Component {
  constructor(props) {
    super(props);
    this.state = { animating: false, direction: '' };
    this.timeout = 300;
  }

  onCountChangeClicked(fn, direction = '') {
    this.setState({ animating: true, direction });
    fn();

    this.timeoutId = setTimeout(
      () => this.setState({ animating: false }),
      this.timeout
    );
  }

  maxAmountTimeout() {
    if (this.props.setShowMax) {
      this.props.setShowMax(true);
      setTimeout(() => {
        this.props.setShowMax(false);
      }, 3000)
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    const {
      onDecremental,
      onIncremental,
      count,
      noMargin,
      loading,
      countLimit,
      small,
    } = this.props;
    const { animating, direction } = this.state;
    const countLimitReached = countLimit ? count >= countLimit : false;
    return (
      <div
       className={cs('changeqty active', { noMargin, small: small })}
       onClick={(e) => e.stopPropagation()}
       >
        <DecButton
          onClick={e => {
            e.preventDefault();
            this.onCountChangeClicked(onDecremental, 'reverse');

          }}
          primary
          disabled={loading}
          small={small}
          //loading={loading && !!count}
        />
        <div className={cs('indicator', { 'no-icon': small })}>
          {!small && (
            <div className="icon">
              <img src={CartIcon} width="16" height="14" alt="Varukorg" />
            </div>
          )}
          <Badge>
            <Rollup in={animating} enterTimeout={200} exit exitTimeout={0}>
              <span className={`qty ${direction}`}>{count}</span>
            </Rollup>
          </Badge>
        </div>
        <div onClick={() => countLimitReached && this.maxAmountTimeout()}>
          <IncButton
            onClick={e => {
              e.preventDefault();
              this.onCountChangeClicked(onIncremental);
            }}
            primary
            disabled={loading || countLimitReached}
            small={small}
            //loading={loading && !!count}
          />
        </div>
      </div>
    );
  }
}

ChangeQuantity.propTypes = {
  count: PropTypes.number,
  countLimit: PropTypes.number,
  loading: PropTypes.bool,
  noMargin: PropTypes.bool,
  onDecremental: PropTypes.func,
  onIncremental: PropTypes.func,
  small: PropTypes.bool
};

ChangeQuantity.defaultProps = {
  countLimit: false,
  noIcon: false
};

export default ChangeQuantity;
