import { cachedRequest, request } from '../../lib/request';
import qs from 'query-string';
import { domSafe } from '../../lib/document';

import { INavigationResponse } from '../../types/endpoints/App';

export const fetchNavigation = (noCache?: boolean): Promise<INavigationResponse> => {
  if (domSafe() && qs.parse(window.location.search).noCache || noCache) {
    return request(`/navigation?nocache=true`);
  }
  return cachedRequest('/navigation');
};
