import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import RecipeList from '../../components/RecipeList';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import CategoryFilter from '../../components/CategoryFilter';
import ErrorInfo from '../../components/ErrorInfo';

import './RecipeCategory.scss';
import OverFlowContainer from '../../components/OverFlowContainer';

const RecipeCategory = props => {
  const {
    error,
    errorBadCategory,
    fetching,
    filterRecipes,
    filters,
    getPageContent,
    getRecipes,
    id,
    isAuthenticated,
    location,
    page,
    pageError,
    pagination,
    recipes,
    redirectToLogin,
    removeFavorite,
    requestMoreRecipes,
    selectedFilters,
    setRecipeFavorite,
    sortRecipes,
    storeId,
    title,
    sortOptions,
    impressionListName
  } = props;
  const setRecipeFavoriteFn = isAuthenticated
    ? setRecipeFavorite
    : redirectToLogin;
  const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

  return (
    <Fragment>
      <PageTitle {...title} sort />
      {pageError ? (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={getPageContent}
        />
      ) : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page.contentBlocks} />
        </OverFlowContainer>
      )}
      {error ? (
        <ErrorInfo
          title={!pageError && 'Hoppsan, något gick fel!'}
          text={
            errorBadCategory
              ? 'Sidan innehåller fel, receptkategorin saknas.'
              : 'Det går inte att hämta recepten just nu.'
          }
          retryButtonFn={getRecipes}
          sideMargins
        />
      ) : (
        <Fragment>
          <CategoryFilter
            id={id}
            filters={filters}
            selectedFilters={selectedFilters}
            pagination={pagination}
            sortProducts={(id, params) => {
              document.cookie = `sortArticles=${params.Sort};path=/`;
              sortRecipes(id, params, location, storeId);
            }}
            filterByPrice={(id, params) => {
              document.cookie = `sortPrices=${params.priceonly};path=/`;
              sortRecipes(id, params, location, storeId);
            }}
            filterAction={filter =>
              filterRecipes(
                id,
                filter,
                location,
                storeId ? { store: storeId } : null
              )
            }
            recipe
            sortOptions={sortOptions}
            activeAssortment={storeId}
          />

          <RecipeList
            fetching={fetching}
            categoryId={id}
            requestMoreRecipes={(id, params) =>
              requestMoreRecipes(id, params, location, storeId)
            }
            setRecipeFavorite={setRecipeFavoriteFn}
            removeFavorite={removeFavoriteFn}
            pagination={pagination}
            items={recipes}
            impressionListName={impressionListName}
          />
        </Fragment>
      )}
      {pageError ? null : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page?.bottomContentBlocks} />
        </OverFlowContainer>
      )}
    </Fragment>
  );
};

RecipeCategory.propTypes = {
  decreaseRecipeQuantity: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorBadCategory: PropTypes.bool,
  fetching: PropTypes.bool,
  filterRecipes: PropTypes.func.isRequired,
  filters: PropTypes.array,
  getPageContent: PropTypes.func,
  getRecipes: PropTypes.func,
  id: PropTypes.number.isRequired,
  increaseRecipeQuantity: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object.isRequired,
  page: PropTypes.object,
  pageError: PropTypes.bool,
  pagination: PropTypes.object,
  recipes: PropTypes.array,
  redirectToLogin: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func,
  requestMoreRecipes: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array,
  setRecipeFavorite: PropTypes.func.isRequired,
  sortRecipes: PropTypes.func.isRequired,
  storeId: PropTypes.number,
  title: PropTypes.object,
  sortOptions: PropTypes.array
};

RecipeCategory.defaultProps = {
  page: {},
  recipes: [],
  sortOptions: []
};

export default RecipeCategory;
