import React, { useState, useEffect, useRef } from 'react';

import ProductList from '../ProductList';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { cartItemsBlockProductCombinationSelector } from '../../selectors/cart/cartSelector';
import { authenticatedSelector } from '../../selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRelatedProducts } from '../../api/endpoints/product';
import { Product } from '../../types/xim/product';
import { AppState } from '../../reducers';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from '../../store/middleware/analytics';

interface IProps {
  categoryCode: string;
  bfabId: number;
  storeId: number;
}

const RelatedProducts: React.FC<IProps> = ({
  categoryCode,
  bfabId,
  storeId
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: AppState) =>
    authenticatedSelector(state)
  );
  const relatedProducts = useSelector(state =>
    cartItemsBlockProductCombinationSelector(state, products)
  );
  const activeStore = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );
  const componentMounted = useRef(true);
  const onAddToCart = item => {
    if (isAuthenticated) {
      return dispatch(addToCart(item));
    } else {
      return dispatch(redirectToLogin);
    }
  };

  useEffect(() => {
    if (!categoryCode || !activeStore) {
      return;
    }
    fetchRelatedProducts({
      bfcategorycode: categoryCode,
      size: 4,
      page: 0,
      excludeProductId: bfabId,
      store: activeStore
    }).then(({ data }) => {
      if (data.data && data.data.length > 0) {
        if (componentMounted.current) {
          setProducts([...data.data]);
        }
        try {
          const impressionListName = 'ProductSingle - Rekommenderade varor';

          const ga4ImpressionItems = data.data.map((prod, index) => {
            let unitPrice = prod?.defaultPrice?.ordinaryPrice?.price || 0;
            let discount = 0;
            let quantity = 1;

            const priceInfo = prod.prices?.find(x => x.storeNumber === storeId);

            if (priceInfo?.hasPromotion) {
              const promo = priceInfo.promotions2 && priceInfo.promotions2[0];
              quantity = promo?.minQuantity || 1;
              discount =
                quantity > 1
                  ? unitPrice * quantity - (promo?.value || 0)
                  : unitPrice - (promo?.value || 0);
            }

            return {
              item_id: prod.gtin,
              item_name: prod.name,
              item_brand: prod.brand || 'City Gross',
              item_category:
                prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
                undefined,
              item_list_name: impressionListName,
              item_list_id: impressionListName,
              index: index + 1,

              price: Number(formatPrice(unitPrice)),
              discount: Number(formatPrice(discount)),
              quantity: quantity
            };
          });

          ga4.viewItemList({
            // @ts-ignore
            items: ga4ImpressionItems,
            item_list_id: impressionListName,
            item_list_name: impressionListName
          });
        } catch (error) {
          console.error(error);
        }
      }
    });
  }, [activeStore]);

  if (!products || !products.length) {
    return null;
  }

  return (
    <div className="c-productdetails__related">
      <h2>Liknande varor</h2>
      <ProductList
        fetching={false}
        addToCart={onAddToCart}
        setProductFavorite={(id, item) =>
          dispatch(setProductFavorite(id, item))
        }
        removeFavorite={x => dispatch(removeFavorite(x))}
        increaseItemQuantity={x => dispatch(increaseItemQuantity(x))}
        decreaseItemQuantity={x => dispatch(decreaseItemQuantity(x))}
        items={relatedProducts}
        colStructure={'l-column-15_xs-30_sm-20_md-15_lg-15_xlg-15-mobileGutter'}
      />
    </div>
  );
};

export default RelatedProducts;
