import { ProductAvailability } from '../../types/xim/product';
import {
  findAvailabilityByStore,
  isAvailableAndNearExpiryDate,
  isAvailableInFuture,
  isExpired,
  isIncomingStock
} from './availabilityHelpers';

export const getNextAvailabilityDateForStore = (
  availabilityArray: ProductAvailability[],
  store: number | string | null,
  today = new Date()
) => {
  if (!availabilityArray || availabilityArray.length === 0) {
    return null;
  }

  const availability = findAvailabilityByStore(availabilityArray, store);

  if (!availability) {
    return null;
  }

  const { availableFrom, availableTo, stockStatus } = availability;

  if (!availableFrom && !availableTo) return null;

  const currentDate = new Date(today);
  currentDate.setHours(0, 0, 0, 0);
  currentDate.setDate(currentDate.getDate() + 1);

  const fromDate = new Date(availableFrom);
  const toDate = new Date(availableTo);
  fromDate.setHours(0, 0, 0, 0);
  toDate.setHours(0, 0, 0, 0);

  if (isAvailableInFuture(fromDate, currentDate)) {
    return { availableFrom };
  }

  if (isAvailableAndNearExpiryDate(fromDate, toDate, currentDate)) {
    return { availableTo };
  }

  if (isExpired(toDate, currentDate)) {
    return { expired: true };
  }

  if (isIncomingStock(stockStatus, fromDate, currentDate)) {
    return { availableFrom };
  }

  return null;
};
