import { ignore } from '../../lib/utils';

export const makeCalculateCombination = (finder, xtraProps = ignore({})) => (
  cartItems,
  entities
) => {
  const defaultItem = { quantity: { value: 0 } };
  return entities.map(entity => {
    const item = finder(cartItems, entity) || defaultItem;

    return Object.assign(
      {},
      { quantity: item.quantity.value },
      xtraProps(item),
      entity
    );
  });
};

export const productCombination = makeCalculateCombination(
  (items, product) => items.find(item => item.itemNo === product.id),
  item => ({ cartItemId: typeof item.id === 'undefined' ? null : item.id })
);
