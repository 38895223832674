import styled, { css } from 'styled-components';
import { H2 } from '@citygross/typography';
import { Input } from '@citygross/components';
import { Link } from 'react-router-dom';

export const RegisterNewsBoxTitle = styled(H2)`
  margin-top: 0;
  margin-bottom: 8px;
`;

type TRegisterTitle = {
  center?: boolean;
};

export const RegisterTitle = styled(H2)<TRegisterTitle>`
  margin: 0;
  text-align: ${props => (props?.center ? 'center' : 'start')};
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const RegisterWrapper = styled.div`
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  padding: 0 8px;
`;

export const RegisterInput = styled(Input)`
  width: 100%;
`;

export const RegisterColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RegisterInputContainer = styled(RegisterColumn)`
  gap: 24px;
  padding-bottom: 32px;
`;

export const RegisterInputLabel = styled.label`
  font-weight: 500;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
`;

export const RegisterInputSpan = styled.span`
  font-weight: 400;
  font-size: 13px;
  color: #979797;
  text-align: right;
`;

export const RegisterInputLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const RegisterInputGroup = styled(RegisterColumn)`
  gap: 8px;
`;
export const RegisterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RegisterTextCenter = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  > p {
    margin-top: 2px;
  }
`;

export const RegisterLinkStyles = styled(css)`
  font-weight: 500;
  font-size: 15px;
  color: #0069ae;
  text-decoration-line: underline;
`;

export const RegisterLink = styled(Link)`
  font-weight: 500;
  font-size: 13px;
  color: #0069ae;
  text-decoration-line: underline;
`;
export const RegisterLinkButton = styled.span`
  font-weight: 500;
  font-size: 15px;
  color: #0069ae;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const RegisterStyledLink = styled.p`
  ${RegisterLinkStyles}
`;
export const ListItemIcon = styled.div`
  flex: 1;
`;

export const ListBox = styled(RegisterColumn)`
  gap: 8px;
`;
export const ListItem = styled.div`
  display: flex;
  gap: 12px;
`;
export const VerifyBox = styled(RegisterColumn)`
  gap: 16px;
`;

export const VerifyBoxItem = styled(VerifyBox)`
  gap: 4px;
`;

export const RegisterSuccessContainer = styled(RegisterColumn)`
  gap: 16px;
  align-items: center;
`;

export const RegisterErrorContainer = styled.div`
  > h3 {
    margin-top: 0;
  }
`;
