import { request, authenticatedRequest } from '../../lib/request';

export const sendCustomerMail = (name, phone, email, body, kdbId, reCaptcha) =>
  request(`/mandrill/customerservice?g-recaptcha-response=${reCaptcha}`, {
    method: 'POST',
    body: JSON.stringify({
      kdbId,
      name,
      phone,
      email,
      body
    })
  });

export const sendComplainMail = (
  name,
  phone,
  email,
  products,
  body,
  kdbId,
  reCaptcha
) =>
  request(`/mandrill/complaint?g-recaptcha-response=${reCaptcha}`, {
    method: 'POST',
    body: JSON.stringify({
      kdbId,
      name,
      phone,
      email,
      products,
      body
    })
  });
