import { createSelector } from 'reselect';

const getNotificationStatus = (state: any) =>
  state.navigation.notificationStatus;
const getNotificationHeight = (state: any) =>
  state.navigation.notificationHeight;
const getSidebar = (state: any) => state.navigation.hasSidebar;

export const hasNotificationStatusSelector = createSelector(
  [getNotificationStatus],
  active => active
);

export const hasNotificationHeightSelector = createSelector(
  [getNotificationHeight],
  height => height
);

export const hasSideBarSelector = createSelector(
  [getSidebar],
  sidebar => sidebar
);
