import {
  SET_VARIANT,
  FETCH_CATEREDMEAL_RECEIVED,
  FETCH_CATEGORY_CATEREDMEALS_REQUESTED,
  FETCH_CATEGORY_CATEREDMEALS_RECEIVED,
  FETCH_CATEGORY_CATEREDMEALS_FAILED,
  FETCH_CATEREDMEAL_FAILED
} from '../../types/reducers/catering';

export const setVariantItem = selectedVariant => ({
  type: SET_VARIANT,
  selectedVariant
});

export function receiveCateredMeals(payload) {
  return {
    type: FETCH_CATEGORY_CATEREDMEALS_RECEIVED,
    payload: {
      products: payload.data,
      meta: payload.meta
    }
  };
}

export function receiveCateredMeal(payload) {
  return {
    type: FETCH_CATEREDMEAL_RECEIVED,
    payload: payload
  };
}

export function isFetchingCateredMeals(fetching) {
  return {
    type: FETCH_CATEGORY_CATEREDMEALS_REQUESTED,
    fetching
  };
}

export function fetchCateredMealError(error) {
  return {
    type: FETCH_CATEREDMEAL_FAILED,
    error
  };
}

export function fetchCateredMealsError(error) {
  return {
    type: FETCH_CATEGORY_CATEREDMEALS_FAILED,
    error
  };
}
