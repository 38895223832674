import { combineReducers, Action } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as responsiveReducer } from 'react-responsive-redux';
import appReducer from './app';
import pageReducer from './page';
import cartReducer from './cart';
import cateringReducer from './catering';
import authReducer from './auth';
import productReducer from './products';
import recipeReducer from './recipes';
import navigationReducer from './navigation';
import shoppingOrderReducer from './shoppingOrder';
import flyoutReducer from './flyout';
import confirmModalReducer from './confirmModal';
import customerReducer from './customer';
import orderCheckout from './orders';
import imboxReducer from './imbox';
import mailReducer from './mail';
import deliveryCitiesReducer from './deliveryCities';
import serviceWorkerReducer from './serviceWorker';
import requestReducer from './request';
import previewReducer from './preview';
import assortmentsReducer from './assortment';
import newCartPrep from './newCartPrep';
import { ThunkAction } from 'redux-thunk';
import { newSearchReducer } from '../slices/searchSlice';
import { sideModalReducer } from '../slices/sideModalSlice';
import { deliveryPickerReducer } from '../slices/deliveryPickerSlice';
import { loginReducer } from '../slices/loginSlice';
import { offersReducer } from '../slices/offersSlice';
import { offersWeekToggleReducer } from '../slices/offersWeekToggleSlice';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    page: pageReducer,
    navigation: navigationReducer,
    app: appReducer,
    cart: cartReducer,
    shoppingOrder: shoppingOrderReducer,
    product: productReducer,
    recipe: recipeReducer,
    auth: authReducer,
    flyout: flyoutReducer,
    confirmModal: confirmModalReducer,
    customer: customerReducer,
    order: orderCheckout,
    responsive: responsiveReducer,
    imbox: imboxReducer,
    mail: mailReducer,
    deliveryCities: deliveryCitiesReducer,
    request: requestReducer,
    sw: serviceWorkerReducer,
    preview: previewReducer,
    catering: cateringReducer,
    assortments: assortmentsReducer,
    newCartPrep: newCartPrep,
    newSearch: newSearchReducer,
    sideModal: sideModalReducer,
    deliveryPicker: deliveryPickerReducer,
    loginSteps: loginReducer,
    offers: offersReducer,
    offersWeekToggle: offersWeekToggleReducer
  });

/*eslint-disable */
export type AppState = ReturnType<ReturnType<typeof rootReducer>>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
/*eslint-enable */

export default rootReducer;
