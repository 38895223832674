import React from 'react';
import PropTypes from 'prop-types';

export const FlyoutBodyId = 'flyout-body';

const FlyoutBody = ({ children, isScrolled }) => {
  const handleScroll = e => {
    isScrolled(e.target.scrollTop !== 0);
  };
  return (
    <div id={FlyoutBodyId} className="pnlcontent" onScroll={handleScroll}>
      {children}
    </div>
  );
};

FlyoutBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isScrolled: PropTypes.func
};

FlyoutBody.defaultProps = {
  isScrolled: () => {}
};

export default FlyoutBody;
