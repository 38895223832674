import React from 'react';
import { connect } from 'react-redux';
import ConfirmModal from '../../components/ConfirmModal';
import { close } from '../../actions/confirmModal';

const ConfirmModalContainer = ({
  close,
  onCancelClick,
  onConfirmClick,
  ...rest
}) => (
  <ConfirmModal
    {...rest}
    onConfirmClick={() => {
      close();
      onConfirmClick();
    }}
    onCancelClick={() => {
      close();
      onCancelClick();
    }}
  />
);

const mapStateToProps = (state, props) => ({
  ...state.confirmModal
});

const mapDispatchToProps = {
  close
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmModalContainer);
