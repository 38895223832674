let cachedCookie;

export const setCookie = ({ cookie }) => {
  cachedCookie = cookie;
};

const inMemLs = () => {
  const store = {};
  return {
    getItem: key => store[key] || null,
    setItem: (key, value) => {
      store[key] = value;
    },
    removeItem: key => {
      delete store[key];
    }
  };
};

const cookie = {
  setItem: () => void 0,
  getItem: key => {
    // A bit backwards but it's to get a consitent API with LS
    return JSON.stringify(cachedCookie.get(key));
  },
  removeItem: () => void 0,
  configure: opts => setCookie(opts)
};

const storage = {
  ls: typeof localStorage !== 'undefined' ? localStorage : inMemLs(),
  cookie
};

export const configureStorage = (storageType, opts = {}) => {
  switch (storageType) {
    case 'ls':
      return storage.ls;
    case 'cookie':
      const cookieStorage = storage.cookie;
      cookie.configure(opts);
      return cookieStorage;
    default:
      return storage.ls;
  }
};

export const makeStorage = (storageType = 'ls', opts = {}) => {
  return storage[storageType];
};
