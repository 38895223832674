import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTopHoc extends Component {
  componentDidUpdate(prevProps) {
    // if flyout is defined we don't want to scroll because it's just an overlay
    const { flyout } = this.props.location.state || {};
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      !flyout
    ) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTopHoc);
