/* eslint-disable import/named */
// eslint-disable-next-line import/namespace
import { createSlice } from '@reduxjs/toolkit';
import { TSideModalTabs } from '../types/sideModalTypes';

interface ISideModalState {
  showModal: boolean;
  modalTab: null | TSideModalTabs;
  tabHistory: TSideModalTabs[];
  forceOpen: boolean;
}

const initialState: ISideModalState = {
  showModal: false,
  modalTab: null,
  tabHistory: [],
  forceOpen: false
};

const sideModalSlice = createSlice({
  name: 'sideModal',
  initialState: initialState,
  reducers: {
    closeSideModal: state => {
      state.showModal = false;
      state.tabHistory = [];
      state.modalTab = null;
    },
    sideModalForceOpen: (state, action) => {
      state.forceOpen = action.payload;
    },
    openSideModal: (state, action) => {
      state.showModal = true;
      state.modalTab = action.payload;
      state.tabHistory = [action.payload, ...state.tabHistory];
    }
  }
});

export const {
  closeSideModal,
  openSideModal,
  sideModalForceOpen
} = sideModalSlice.actions;
export const selectModalForceOpen = state => state.sideModal.forceOpen;
export const selectShowModal = state => state.sideModal.showModal;
export const selectModalTab = state => state.sideModal.modalTab;
export const selectTabHistory = state => state.sideModal.tabHistory;

export const sideModalReducer = sideModalSlice.reducer;
