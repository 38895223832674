import React from 'react';
import { StoreLocation } from '../../../../types/episerver/store';

interface IProps {
  name: string,
  storeLocation: StoreLocation
}

const StoreMap : React.FC<IProps> = ({ name, storeLocation }) => {
  const APIKey = 'AIzaSyAK-eeDDRux-DTNfHCqUVtIoEXl0IENSyc';
  const startString = `https://www.google.com/maps/embed/v1/place?key=${APIKey}`;
  let storeTitle = name ? `City Gross ${name}` : '';
  let placeString = '';
  let srcString = '';

  if (storeLocation) {
    if (storeLocation.googlePlaceId) {
      srcString = `${startString}&q=place_id:${storeLocation.googlePlaceId}`;
    } else if (storeLocation.coordinates) {
      srcString = `${startString}&q=${storeLocation.coordinates}`;
    } else if (name) {
      placeString = storeTitle.split(' ').join('+');
      srcString = `${startString}&q=${placeString}`;
    }
  }

  return srcString ? (
    <iframe
      className="b-store-contact__map"
      title={storeTitle}
      width="100%"
      height="400"
      frameBorder="0"
      src={srcString}
      allowFullScreen
    />
  ) : (
    null
  );
};

export default StoreMap;
