// public enum PriceComparisonContentType
// {
//   [EnumMember] PerKilogram,
//   [EnumMember] PerLitre,
//   [EnumMember] PerPiece,
//   [EnumMember] PerMetre,
//   [EnumMember] PerLoad,
//   [EnumMember] PerWash,
//   [EnumMember] ReadyToDrink,
//   [EnumMember] ReadyToEat,
//   [EnumMember] DrainedWeight,
// }

export function comparisonTypes(type: string) {
  switch(type) {
    case 'KGM':
      return 'kg';
    case 'G':
      return 'g';
    case 'LTR':
      return 'l';
    case 'PCE':
      return 'st';
    case 'H87':
      return 'st';
    case 'm':
      return 'm';
    case 'MM':
      return 'm';
    case 'MTR':
      return 'm';
    case 'DSK':
      return 'disk';
    case 'TVA':
      return 'tvätt';
  }
}
