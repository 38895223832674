import React from 'react';
import cs from 'classnames';
import ToolTip from '../ToolTip';
import Image from '../Image';

const DEFAULT_CLASSNAME = 'logos';

interface IProps {
  altText: string;
  className?: string;
  code: string;
  imageUrl: string;
  tooltipAlign: 'top' | 'right' | 'bottom' | 'left';
}

export const Marking: React.FC<IProps> = ({
  altText,
  className,
  code,
  imageUrl,
  tooltipAlign
}) => (
  <div
    className={cs({
      [DEFAULT_CLASSNAME]: !className,
      [className || '']: !!className
    })}
  >
    <ToolTip key={code} label={altText} alignment={tooltipAlign}>
      <Image src={imageUrl} alt={altText} />
    </ToolTip>
  </div>
);
