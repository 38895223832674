import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import './Checkbox.scss';

const Checkbox = ({
  id,
  label,
  hint,
  isChecked,
  placeholder,
  formLoading,
  children,
  value,
  className,
  ...rest
}) => {
  const inputAttrs = {};
  inputAttrs.checked = isChecked || value ? 'checked' : '';
  return (
    <label className={cs(`c-stdcheckbox ${className}`)}>
      <input type="checkbox" id={id} {...inputAttrs} {...rest} />
      <i />
      {label ||
        (children && (
          <span className="label">
            {label}
            {children}
          </span>
        ))}
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  formLoading: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  isChecked: false,
  className: ''
};

export default Checkbox;
