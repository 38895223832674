import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { pageLayoutSelector } from '../../selectors/page';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  fetchRecipesByTags,
  fetchRecipesByIds
} from '../../api/endpoints/recipe';

import withBaseBlock from '../../components/BaseBlock';
import RecipeGrid from '../../modules/RecipeGrid';
import { connect } from 'react-redux';
import * as ga4 from '@citygross/analytics';

const applyTagsToObjectValues = tags => {
  const modifiedObj = Object.keys(tags).map(key => {
    const newKey = `${key}`;
    return { [newKey]: tags[key] };
  });
  return Object.assign({}, ...modifiedObj);
};

class RecipeArticleBlock extends Component {
  state = {
    fetchedProducts: null,
    pagination: null,
    allRows: false,
    loading: true,
    fetchingFailed: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.storeId !== this.props.storeId) {
      this.fetchProducts();
    }
  }
  componentDidMount() {
    this.fetchProducts();
  }

  loadMoreProducts = () => {
    if (!this.state.allRows) {
      return this.setState({
        allRows: true
      });
    }
    this.setState({
      loading: true
    });
    const { tags, storeId } = this.props;

    // add pagination to this queryString
    const modifiedTags = qs.stringify(applyTagsToObjectValues(tags));
    const newPageIndex = this.state.pagination.pageIndex + 1;
    fetchRecipesByTags(modifiedTags, storeId, newPageIndex).then(({ data }) => {
      this.setState({
        fetchedProducts: [...this.state.fetchedProducts, ...data.data],
        pagination: data.meta,
        loading: false
      });
    });
  };

  fetchProducts = () => {
    this.setState({ loading: true });
    const { tags, ximEntitiesList, ximEntities, storeId } = this.props;
    if (
      (ximEntitiesList && ximEntitiesList.length > 0) ||
      (ximEntities && ximEntities.length > 0)
    ) {
      // should be removed hopefully soon
      const tempIds = ximEntities && ximEntities.map(entity => entity.id);
      fetchRecipesByIds(ximEntitiesList || tempIds)
        .then(data => {
          if (data.data.length === 0) {
            return this.setState({
              fetchingFailed: true
            });
          }
          this.setState({
            fetchedProducts: data.data,
            fetchingFailed: false,
            loading: false
          });

          try {
            const ga4ImpressionItems = data.data.map((recipe, index) => {
              let recipePrice = 0;
              if (storeId) {
                const variant = recipe.previews.find(
                  variant => variant.storeNumber == storeId
                );
                if (variant) recipePrice = variant.minimumPrice || 0;
              }
              return {
                item_id: recipe.id,
                item_name: recipe.name,
                item_brand: recipe.source || 'City Gross',
                item_category:
                  '/recept' +
                    recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) ||
                  undefined,
                item_variant: recipe.id,

                item_list_name: this.props.name,
                item_list_id: this.props.name,
                index: index + 1,

                price: recipePrice,
                quantity: 1,
                discount: 0
              };
            });

            ga4.viewItemList({
              items: ga4ImpressionItems,
              item_list_id: this.props.name,
              item_list_name: this.props.name
            });
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => this.setState({ fetchingFailed: true }));
    } else if (tags && tags.length > 0) {
      fetchRecipesByTags(
        qs.stringify(applyTagsToObjectValues({ tags: tags })),
        storeId
      )
        .then(({ data }) => {
          if (data.data.length === 0) {
            return this.setState({
              fetchingFailed: true
            });
          }
          this.setState({
            fetchedProducts: data.data,
            pagination: data.meta,
            fetchingFailed: false,
            loading: false
          });

          try {
            const ga4ImpressionItems = data.data.map((recipe, index) => {
              let recipePrice = 0;
              if (storeId) {
                const variant = recipe.previews.find(
                  variant => variant.storeNumber == storeId
                );
                if (variant) recipePrice = variant.minimumPrice || 0;
              }
              return {
                item_id: recipe.id,
                item_name: recipe.name,
                item_brand: recipe.source || 'City Gross',
                item_category:
                  '/recept' +
                    recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) ||
                  undefined,
                item_variant: recipe.id,

                item_list_name: this.props.name,
                item_list_id: this.props.name,
                index: index + 1,

                price: recipePrice,
                quantity: 1,
                discount: 0
              };
            });

            ga4.viewItemList({
              items: ga4ImpressionItems,
              item_list_id: this.props.name,
              item_list_name: this.props.name
            });
          } catch (error) {
            console.error(error);
          }
        })
        .catch(() => this.setState({ fetchingFailed: true }));
    }
  };
  render() {
    const { paginationEnabled, tags, ...rest } = this.props;
    const {
      pagination,
      fetchedProducts,
      allRows,
      loading,
      fetchingFailed
    } = this.state;

    if (fetchingFailed) {
      return null;
    }

    return (
      <RecipeGrid
        noPad
        recipes={fetchedProducts}
        impressionListName={this.props.name}
        {...rest}
      />
    );
  }
}

RecipeArticleBlock.propTypes = {
  id: PropTypes.number,
  storeId: PropTypes.number,
  tags: PropTypes.array,
  blockOptions: PropTypes.shape({
    marginTopBottom: PropTypes.string,
    useFlushContainer: PropTypes.bool,
    width: PropTypes.string,
    backgroundColor: PropTypes.string
  }),
  ispublished: PropTypes.bool,
  link: PropTypes.shape({
    internal: PropTypes.bool,
    url: PropTypes.string
  }),
  linkText: PropTypes.string,
  name: PropTypes.string,
  numRows: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string,
  ximEntitiesList: PropTypes.array,
  ximEntities: PropTypes.array,
  paginationEnabled: PropTypes.bool,
  __type__: PropTypes.string
};

const mapStateToProps = (state, props) => {
  return {
    storeId: selectCurrentAssortment(state),
    pageLayout: pageLayoutSelector(state, props)
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBaseBlock(RecipeArticleBlock));
