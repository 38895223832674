import { checkValidEmail } from '../api/endpoints/customer';

export const EMAIL_VALIDATION_TYPE = 'VALIDATOR_EMAIL_TYPE';
export const MINLENGTH_VALIDATION_TYPE = 'VALIDATOR_EMAIL_TYPE';
export const MOBILEPHONE_VALIDATION_TYPE = 'VALIDATOR_EMAIL_TYPE';
export const PERSONALNBR_VALIDATION_TYPE = 'VALIDATOR_EMAIL_TYPE';
export const REQUIRED_VALIDATION_TYPE = 'VALIDATOR_EMAIL_TYPE';

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PERSONAL_NUMBER_REGEX = /^(19|20)?[0-9]{6}[- ]?[0-9]{4}$/;
export const MOBILEPHONE_NUMBER_REGEX = /^(\+?46|0)\d{9}$/;
export const ZIP_CODE_REGEX = /^[0-9]{5}$/;
export const ONLY_NUMBERS_REGEX = /[^0-9]+/g;

export const validateMinLength = (str: string, minLength: number): boolean =>
  str !== undefined && String(str).length >= minLength;

export const validateMaxLength = (str: string, maxLength: number): boolean =>
  str !== undefined && String(str).length <= maxLength;

export const validateMobilePhoneNumber = (nbr: string): boolean =>
  MOBILEPHONE_NUMBER_REGEX.test(String(nbr).toLowerCase());

export const validatePersonalNumber = (nbr: string): boolean =>
  PERSONAL_NUMBER_REGEX.test(String(nbr).toLowerCase());

export const validateEmail = (email: string): boolean =>
  EMAIL_REGEX.test(String(email).toLowerCase());

export const validateSignupEmail = (email: string) => {
  // TODO TYPE request
  return checkValidEmail(email).then(({ data }) => {
    return data;
  });
};

export const validateRequired = (val?: any): boolean =>
  val !== undefined && val !== null && val !== '';

export const validateZipCode = (nbr: any): boolean =>
  ZIP_CODE_REGEX.test(String(nbr).toLowerCase());
