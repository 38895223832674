import { Product } from "../xim/product";
import { IEsalesFilter } from "../esales";

const NAME = 'product';

export const FETCH_PRODUCT_REQUESTED = `${NAME}/PRODUCT_REQUESTED`;
export const FETCH_PRODUCT_RECEIVED = `${NAME}/PRODUCT_RECEIVED`;
export const FETCH_PRODUCT_FAILED = `${NAME}/PRODUCT_FAILED`;

export const FETCH_CATEGORY_PRODUCTS_REQUESTED = `${NAME}/CATEGORY_PRODUCTS_REQUESTED`;
export const FETCH_CATEGORY_PRODUCTS_RECEIVED = `${NAME}/CATEGORY_PRODUCTS_RECEIVED`;
export const FETCH_CATEGORY_PRODUCTS_FAILED = `${NAME}/CATEGORY_PRODUCTS_FAILED`;

export const PAGINATE_CATEGORY_PRODUCTS_REQUESTED = `${NAME}/CATEGORY_PAGINATE_REQUESTED`;
export const PAGINATE_CATEGORY_PRODUCTS_RECEIVED = `${NAME}/CATEGORY_PAGINATE_RECEIVED`;

export const SORT_PRODUCTS = `${NAME}/SORT_PRODUCTS`;

export interface ProductState {
    fetching: boolean,
    sortOrder: string,
    filters: Map<string, IEsalesFilter[]>,
    category: Product[],
    products: Product[],
    pagination: { // Move this to the esales types
      count: number,
      pageIndex: number,
      pageSize: number,
      totalCount: number
    },
    error: string | null
}
