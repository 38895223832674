import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cs from 'classnames';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { MixCampaign } from '../MixCampaign';
import { PurchaseRule } from '../PurchaseRule';
import { LazyProgressivePicture, ImageFitTypes } from '../ProgressivePicture';
import { Marking } from '../Marking';
import PriceTag from '../PriceTag';
import { FavoriteButton } from '../Buttons';
import BuyButton from '../BuyButton';
import Availability from '../Availability';
import { getNextAvailabilityDateForStore } from '../../lib/availability/availability';

import './ProductCard.scss';
import { getProductDescription, setImage } from '../../lib/product';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { IExtendedProduct } from '../../types/storefront/product';
import { PriceRuleHelper } from '../../lib/price';
import {
  productFavoritesSelector,
  favoriteProductsLoadingSelector
} from '../../selectors/product/productSelectors';
import { comparisonTypes } from '../../types/comparisonTypes';
import WarningLabel from '../WarningLabel';
import { isLimitedStock } from '../../lib/limitedStock';
import { ga4ImpressionClickTracking_Product } from '../../lib/analytics/analytics';

const ProductPlaceholder = require('../assets/produkt-fallback.svg');

export const LIMITED_DEAL_TAG = { namespace: 'KLIPP', name: 'VARA' };

interface IProductCard {
  onClose: Function;
  esalesClickTrigger: Function;
  removeFavorite: Function;
  setProductFavorite: Function;
  esalesClickTriggerOnAddingToCart: Function;
  buttonText: string;
  product: IExtendedProduct;

  colStructure?: string;
  impressionListName?: string;
  impressionListPosition?: number;
  onCarousel?: boolean;
}

export const ProductCard: React.FC<IProductCard> = ({
  onClose,
  esalesClickTrigger,
  removeFavorite,
  setProductFavorite,
  buttonText,
  colStructure,
  esalesClickTriggerOnAddingToCart,
  product,
  onCarousel,
  impressionListName,
  impressionListPosition
}) => {
  const isFavorite = useSelector((state: AppState) =>
    productFavoritesSelector(state).find(x => x.itemNo === product.id)
  );
  const isFavoriteLoading = useSelector((state: AppState) =>
    Boolean(favoriteProductsLoadingSelector(state).find(x => x === product.id))
  );

  const storeNumber = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );
  const nextAvailabiltyDate =
    storeNumber &&
    getNextAvailabilityDateForStore(product.availability || [], storeNumber);
  const [showMax, setShowMax] = useState<boolean>(false);

  const shouldShowAvailability = nextAvailabiltyDate;
  const operationOnProductClick = () => {
    esalesClickTrigger();
    onClose();
  };

  const formattedPrice = PriceRuleHelper(product.price);

  const limitedStock = isLimitedStock(product);

  const isOnlyForMembers =
    (product.price?.promotions2 &&
      product.price?.promotions2[0]?.membersOnly) ||
    Boolean(product.price?.memberPrice);

  const hasReturnablePackageDeposit =
    product.returnablePackageDepositInformation &&
    product.returnablePackageDepositInformation.description === 'Pant';

  const hasLimitedDeal = !!product?.tags?.find(
    tag =>
      tag.name === LIMITED_DEAL_TAG.name &&
      tag.namespace === LIMITED_DEAL_TAG.namespace
  );
  return (
    <div
      className={cs(colStructure, {
        'p-carousel_card': onCarousel
      })}
      data-productid={product.gtin}
    >
      <Link
        className={'productcard-container'}
        to={{
          pathname: product.url,
          state: { flyout: true, title: ' ', nopadding: true, tight: false }
        }}
        onClick={() => {
          operationOnProductClick();
          if (impressionListName && impressionListPosition) {
            try {
              ga4ImpressionClickTracking_Product({
                product,
                impressionListName: impressionListName,
                impressionListPosition: impressionListPosition
              });
            } catch (error) {
              console.error(error);
            }
          }
        }}
        draggable={false}
      >
        <div className={'product-card__upper-container'}>
          <div className={'actions'}>
            <FavoriteButton
              active={isFavorite ? true : false}
              loading={isFavoriteLoading}
              handleClick={
                isFavorite
                  ? () => {
                      removeFavorite(isFavorite);
                    }
                  : () => {
                      setProductFavorite(product.id, {
                        name: product.name,
                        value: product.price?.currentPrice?.price
                      });
                    }
              }
            />
          </div>
          <div className={'availability'}>
            {shouldShowAvailability && (
              <div className="c-prodcard__availability">
                <Availability availability={product.availability || []} />
              </div>
            )}
          </div>
          <div className={'product-image'}>
            <link href={setImage(product.images, 0).url} />
            <LazyProgressivePicture
              largeImage={{
                url: setImage(product.images, 0).url,
                width: 150,
                height: 150
              }}
              fitType={ImageFitTypes.Contain}
              inheritHeight
              showThumb={false}
              maxWidth={{
                large: 300,
                small: 300
              }}
              placeholder={{
                image: ProductPlaceholder,
                bgColor: 'transparent'
              }}
            />
          </div>
          <div
            className={cs('markings', {
              'lower-position-top': !!shouldShowAvailability
            })}
          >
            {product.mappedMarkings
              ?.slice(0, 3)
              .map(
                marking =>
                  marking.image && (
                    <Marking
                      altText={marking.altText}
                      code={marking.code}
                      key={marking.id}
                      imageUrl={marking.image.url}
                      tooltipAlign={'left'}
                    />
                  )
              )}
          </div>
        </div>

        <div className={'product-card__lower-container'}>
          <div className={'details'}>
            <h2 className={'details__name h3 text-center mt-0'}>
              {product.name}
            </h2>
            <h3 className={'text-center mb-5 mt-0'}>
              {getProductDescription(product)}
            </h3>
          </div>

          <div
            className={'push-to-bottom push-to-bottom--ie-support full-width'}
          >
            <div
              className={cs('price-section', {
                'full-width': !product.price?.hasDiscount,
                'end-end': !product.price?.hasDiscount,
                'center-center': product.price?.hasDiscount,
                'mt-10': !isOnlyForMembers
              })}
            >
              {product.price?.promotions2 &&
                formattedPrice?.validPromotion &&
                product.price?.promotions2[0]?.minAmount && (
                  <MixCampaign
                    promotion={{
                      label: `Handla andra varor för ${product.price.promotions2[0].minAmount}:-*`
                    }}
                  />
                )}

              <PriceTag
                price={product.price}
                unit={product.unit}
                isOnlyForMembers={false}
                hasLimitedDeal={hasLimitedDeal}
                pant={
                  product.returnablePackageDepositInformation?.description ===
                  'Pant'
                }
              />

              {limitedStock && (
                <WarningLabel
                  label={'Begr. tillgänglighet'}
                  extraMargin={Boolean(
                    product.price?.hasDiscount && formattedPrice?.saveLabel
                  )}
                />
              )}
              {(!product.price?.hasDiscount ||
                !formattedPrice?.validPromotion) &&
                formattedPrice?.currentPrice?.comparativePrice &&
                formattedPrice?.currentPrice?.comparativePrice > 0 && (
                  <div
                    style={{ width: '100%' }}
                    className={'price-comparison text-center mt-10 mb-10'}
                  >
                    <span className={'grey compare-price'}>
                      {`Jfr pris ${formattedPrice.currentPrice?.comparativePrice?.toFixed(
                        2
                      )} kr/${formattedPrice?.currentPrice
                        .comparativePriceUnit &&
                        comparisonTypes(
                          formattedPrice?.currentPrice?.comparativePriceUnit
                        )}`}
                    </span>
                  </div>
                )}
            </div>
            {product.price?.hasDiscount && formattedPrice?.validPromotion && (
              <div className={'price-comparison text-center mt-10 mb-10'}>
                <span className={'bold ordinal-price'}>
                  {`Ord. pris ${formattedPrice?.formattedOrdinaryPrice?.value +
                    ',' +
                    formattedPrice?.formattedOrdinaryPrice?.decimal} kr${
                    hasReturnablePackageDeposit
                      ? product.unit
                      : `/${product.unit}`
                  }`}
                </span>
                {/* {formattedPrice?.ordinaryPrice?.comparativePrice &&
                  formattedPrice?.ordinaryPrice?.comparativePrice >
                    0 && (
                    <span className={'grey ordinal-compare-price'}>
                      {`Ord. ${
                        isBulkProduct ? 'ca pris' : 'jfr pris'
                      } ${formattedPrice.ordinaryComparisonPrice} kr/${comparisonTypes[formattedPrice?.ordinaryPrice.comparativePriceUnit]}`}
                    </span>
                  )} */}
                {formattedPrice?.currentPrice?.comparativePrice &&
                  formattedPrice?.currentPrice?.comparativePrice > 0 && (
                    <span className={'grey compare-price'}>
                      {`${'Jfr pris'}
                        ${formattedPrice.currentPrice?.comparativePrice?.toFixed(
                          2
                        )} kr/${formattedPrice?.currentPrice
                        ?.comparativePriceUnit &&
                        comparisonTypes(
                          formattedPrice?.currentPrice.comparativePriceUnit
                        )}`}
                    </span>
                  )}

                {product.price?.promotions2 &&
                product.price.promotions2[0] &&
                product.price.promotions2[0].maxAppliedPerReceipt &&
                Number(product.price.promotions2[0].maxAppliedPerReceipt) >
                  0 ? (
                  <PurchaseRule>
                    {`Erbjudande gäller ${
                      product.price.promotions2[0].maxAppliedPerReceipt
                    } ${product.unit === 'kg' ? 'kg' : 'köp'} per kund`}
                  </PurchaseRule>
                ) : null}
              </div>
            )}
            {!product.price?.hasDiscount &&
              !formattedPrice?.currentPrice?.comparativePrice && (
                <div
                  style={{ width: '100%' }}
                  className={'product-card-price-filler mt-10 mb-10'}
                />
              )}
            <span className="max-amount" style={{ opacity: showMax ? 1 : 0 }}>
              Max antal uppnått
            </span>
            <div className="cartactions">
              <BuyButton
                product={product}
                esalesClickTriggerOnAddingToCart={
                  esalesClickTriggerOnAddingToCart
                }
                buttonText={buttonText}
                fullWidth={true}
                setShowMax={setShowMax}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

ProductCard.defaultProps = {
  colStructure: 'l-column-30_xs-30_sm-20_md-15_lg-12_xlg-10-mobileGutter',
  onClose: () => {}
};
