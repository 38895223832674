import React, { useEffect } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import Navigation from '../../components/Navigation';

import './Sidebar.scss';
import { TOPNAV_HEIGHT } from '../../components/Header/Header';
import { connect, useDispatch } from 'react-redux';
import { setHasSideBar } from '../../actions/navigation';

const Sidebar = ({
  nav,
  activePath,
  notificationStatus,
  notificationHeight,
  setHasSideBar
}) => {
  const topHeight = notificationHeight + TOPNAV_HEIGHT;
  useEffect(() => {
    setHasSideBar(true);
    return () => {
      setHasSideBar(false);
    };
  }, []);
  return (
    <aside
      className={cs('b-secondary-nav', { noticeHidden: !notificationStatus })}
      style={{ top: topHeight }}
    >
      <Navigation nav={nav} activePath={activePath} />
    </aside>
  );
};

Sidebar.propTypes = {
  nav: PropTypes.object,
  activePath: PropTypes.string
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
  setHasSideBar: value => dispatch(setHasSideBar(value))
});
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
