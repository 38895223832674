import { externalCachedRequest } from '../../lib/request';
import { IImboxAnswerResponse, IImboxCategoryResponse } from '../../types/endpoints/Imbox';

export const fetchCategory = (catId: string) : Promise<IImboxCategoryResponse> =>
  externalCachedRequest(
    `https://apiv2.imbox.io/api/faq/1081/categories/${String(catId)}/articles`
  );

export const fetchAnswer = (qId: string) : Promise<IImboxAnswerResponse> =>
  externalCachedRequest(
    `https://apiv2.imbox.io/api/faq/1081/articles/${String(qId)}/view`
  );
