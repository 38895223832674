import React, { useState, useEffect } from 'react';
import * as styles from './CartLoading.styles';
import { Spinner } from '@citygross/components';
import { useTheme } from 'styled-components';

const CART_LOADING_TIMER = 4000;

export const CartLoading = ({ loading, defaultShow = false }) => {
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  const [show, setShow] = useState(defaultShow);
  const theme = useTheme();
  useEffect(() => {
    if (defaultShow) return;
    if (!loading) {
      setShow(false);
    } else {
      setLastUpdateTime(Date.now());
    }
  }, [loading]);

  useEffect(() => {
    if (defaultShow) return;
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const timeDifference = currentTime - lastUpdateTime;

      if (timeDifference > CART_LOADING_TIMER) {
        setShow(true);
      }
    }, CART_LOADING_TIMER);

    return () => clearInterval(interval);
  }, [lastUpdateTime]);

  return show ? (
    <styles.CartLoader>
      <div>
        <Spinner
          animationSpeedMs={1000}
          borderColor={theme.palette.alertBlue}
          spinnerColor="white"
        />
      </div>
    </styles.CartLoader>
  ) : null;
};
