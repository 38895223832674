import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './Suggestions.scss';

const Suggestions = ({ children, count, onClose, query, title, type, stopLink }) => {

  const link = stopLink ? 'c-suggestions__link': '';

  return (
    <div className={'c-suggestions'}>
      <Link to={`/sok?Q=${query}&type=${type}`} className={link} onClick={onClose}>
        <span className="c-suggestions__title">
          <h3>
            {title}{' '}
            {count >= 0 && (
              <span className={'c-suggestions__title--count'}>({count})</span>
            )}
          </h3>
        </span>
      </Link>
      <div className={'c-suggestions__children'}>{children}</div>
    </div>
  );
};

Suggestions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  count: PropTypes.number,
  onClose: PropTypes.func,
  query: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

export default Suggestions;
