import React, { useEffect } from 'react';
import cs from 'classnames';
import './BaseBlock.scss';
import { IBaseBlockProps } from '../../types/episerver/baseblock';

const BaseBlock: React.FC<IBaseBlockProps> = ({
  blockOptions,
  type,
  children
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      setTimeout(() => {
        const el = document.getElementById(window.location.hash.substring(1));
        el?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center'
        });
      }, 1000);
    }
  }, []);

  const baseBlockClassNames = cs(
    'c-base-block',
    {
      [`c-base-block--m-${blockOptions.marginTopBottom}`]: Boolean(
        blockOptions.marginTopBottom
      )
    },
    { 'c-base-block--m-flush': blockOptions.useFlushContainer }
  );

  const baseBlockInnerClassNames = cs(
    'c-base-block__inner',
    {
      [`c-base-block__inner--w-${blockOptions.width}`]: Boolean(
        blockOptions.width
      )
    },
    {
      'c-base-block__inner--padding-text':
        blockOptions.backgroundColor && type === 'RichTextGrid'
    },
    {
      'c-base-block__inner--padding-sides':
        blockOptions.backgroundColor &&
        (type === 'ProductArticleBlock' ||
          type === 'RecipeArticleBlock' ||
          type === 'RecommendationBlock' ||
          type === 'CateringArticleBlock')
    }
  );

  return (
    <div className={baseBlockClassNames}>
      <div
        style={{ backgroundColor: blockOptions.backgroundColor }}
        className={baseBlockInnerClassNames}
      >
        {children}
      </div>
    </div>
  );
};

export default BaseBlock;
