import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setMode } from '../../actions/shoppingOrder';
import * as customerServiceActions from '../../actions/page';
import { userSelector } from '../../selectors/user';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';
import CustomerService from '../../modules/CustomerService';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { allUserInfo } from '../../actions/auth';
import { fetchCategories } from '../../actions/imbox';
import { sendCustomerEmail, sendComplainEmail } from '../../actions/mail';
import { appSettingsSelector } from '../../selectors/app';
import { authenticatedSelector } from '../../selectors/user';
import { selectedStore } from '../../selectors/assortments';

class CustomerServicePage extends Component {
  state = {
    csSent: false,
    cpSent: false,
    formLoading: false,
    formError: null,
    pageError: false
  };
  componentDidMount() {
    const { fetchCategories, faqCategories, setMode, page, id } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }
    if (faqCategories && faqCategories.length) {
      fetchCategories(faqCategories);
    }
    setMode(true, false, UNTOGGLE_BEHAVIOUR.none);
  }

  componentWillReceiveProps(nextProps) {
    const { fetchCategories } = this.props;
    if (
      nextProps.faqCategories !== this.props.faqCategories &&
      nextProps.faqCategories
    ) {
      fetchCategories(nextProps.faqCategories);
    }
  }

  getPageContent = () => {
    this.setState({
      pageError: false
    });
    const { getPageById, id } = this.props;
    getPageById(id).catch(err => {
      this.setState({
        pageError: true
      });
      /* eslint-disable no-console */
      console.error('getPageById failed', err);
      /* eslint-enable no-console */
    });
  };

  onFormCsSubmit = (data, reCaptcha) => {
    const { sendCustomerEmail } = this.props;
    this.setState({ formLoading: true });
    sendCustomerEmail(data, reCaptcha)
      .then(() =>
        this.setState({ csSent: true, formLoading: false, error: null })
      )
      .catch(err => {
        this.setState({
          formLoading: false,
          formError: 'Något gick fel, försök igen eller kontakta kundservice.'
        });
      });
  };

  onFormCpSubmit = (data, reCaptcha) => {
    const { sendComplainEmail } = this.props;
    this.setState({ formLoading: true });
    sendComplainEmail(data, reCaptcha)
      .then(() =>
        this.setState({ cpSent: true, formLoading: false, error: null })
      )
      .catch(err => {
        this.setState({
          formLoading: false,
          formError: 'Något gick fel, försök igen eller kontakta kundservice.'
        });
      });
  };

  render() {
    const { pageError } = this.state;
    return (
      <CustomerService
        {...this.props}
        onFormCsSubmit={this.onFormCsSubmit}
        onFormCpSubmit={this.onFormCpSubmit}
        cpSent={this.state.cpSent}
        csSent={this.state.csSent}
        formLoading={this.state.formLoading}
        formError={this.state.formError}
        pageError={pageError}
        getPageContent={this.getPageContent}
      />
    );
  }
}

CustomerServicePage.required = [
  (state, params, noCache) => {
    return customerServiceActions.getPageById(
      params.id,
      null,
      noCache,
      params.userSiteId
    );
  }
];

function mapStateToProps(state, props, ownProps) {
  return {
    titel: breadCrumbSelector(state, props),
    user: userSelector(state),
    authenticated: authenticatedSelector(state),
    favoriteStore: selectedStore(state),
    settings: appSettingsSelector(state),
    page: pageSelector(state, props),
    faqCategories: state.app.settings?.faqCats,
    id: pageIdSelector(state, props)
  };
}

const mapDispatchToProps = {
  getPageById: customerServiceActions.getPageById,
  fetchCategories,
  allUserInfo,
  sendCustomerEmail,
  sendComplainEmail,
  setMode
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerServicePage)
);
