import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Sidebar from '../../containers/Sidebar';
import { ROUTE_OFFER_PATTERN } from '../../lib/route/defaults.ts';
import pathToRegexp from 'path-to-regexp';
import cs from 'classnames';
class RoutingRenderer extends PureComponent {
  render() {
    const {
      isSidebarActive,
      isFlyoutActive,

      flyoutRoutes,
      baseRoutes,

      flyoutLocation,
      baseLocation,

      activeBaseRouteMatch,

      flyoutRouteProps,

      mapFlyoutLocationProps,

      onFlyoutClose,
      onFlyoutAnimationExit
    } = this.props;
    const offerRoute = pathToRegexp(ROUTE_OFFER_PATTERN).test(
      this.props?.flyoutLocation?.pathname
    );
    return (
      <React.Fragment>
        <Switch location={baseLocation}>{baseRoutes}</Switch>

        {isSidebarActive && <Sidebar match={activeBaseRouteMatch} />}

        {flyoutRoutes && (
          <CSSTransition
            mountOnEnter
            unmountOnExit
            in={isFlyoutActive}
            classNames={cs({
              ['flyout-animation']: !offerRoute
            })}
            onExited={onFlyoutAnimationExit}
            timeout={200}
          >
            <Switch location={flyoutLocation}>
              {(flyoutRoutes || []).map(
                ({ path, component: Component, ...routeProps }, i) => (
                  <Route
                    key={i + path}
                    path={path}
                    render={props => (
                      <Component
                        close={onFlyoutClose}
                        back={onFlyoutClose}
                        showOverlay={isFlyoutActive}
                        {...mapFlyoutLocationProps(flyoutLocation)}
                        {...routeProps}
                        {...flyoutRouteProps}
                        {...props}
                      />
                    )}
                  />
                )
              )}
            </Switch>
          </CSSTransition>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  // TODO : Logic should preferably be moved to selectors and memoized
  const { baseLocation, baseRoutes, flyoutRoutes } = props;

  const allRouteProps = baseRoutes
    .map(route => route.props)
    .concat(flyoutRoutes);

  const activeRouteProps = allRouteProps.find(
    routeProps =>
      Boolean(routeProps.path) &&
      matchPath(baseLocation.pathname, routeProps) !== null
  );

  const isSidebarActive =
    activeRouteProps &&
    activeRouteProps.layoutProps &&
    activeRouteProps.layoutProps.hasSidebar === true;

  const activeBaseRouteMatch =
    activeRouteProps && matchPath(baseLocation.pathname, activeRouteProps);

  return {
    isSidebarActive,
    activeBaseRouteMatch
  };
};

export default connect(mapStateToProps)(RoutingRenderer);
