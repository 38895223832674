import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import RichText from '../../containers/RichText';

import PrioBadge from '../../components/assets/prio_badge.svg';
import './Login.scss';
import { Button, Link as LinkButton } from '@citygross/components';
import { connect, useDispatch } from 'react-redux';
import { openSideModal } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../../components/SideModal';
import {  setInitialStep } from '../../slices/loginSlice';
import { loginSteps } from '../../components/SideModalTabs/SideModalLogin/SideModalLogin';
import { ga4OpenRegister } from '../../lib/analytics/analytics';


export const ForgotPassword = ({ children, onClick }) => (
  <Link
    className="c-login-forgotpassword"
    to={{
      pathname: '/glomt-losenord',
      state: { flyout: true, title: '', tight: true }
    }}
    onClick={onClick}
  >
    {children}
  </Link>
);

export const CreateAccount = ({
  createAccountTitle,
  createAccountParagraph,
}) => {
  const dispatch = useDispatch()
  return (
    <div className="c-login-createaccount">
      <div className="c-login-createaccount__boxwrap">
        <div className="c-login-createaccount__box">

          <span>
            <h3>{createAccountTitle}</h3>
            <div className="c-login-createaccount__explain">
              {createAccountParagraph}
            </div>
          </span>
          <span style={{ marginLeft: 25 }}>
            <Image src={PrioBadge} noLazy/>
          </span>
        </div>
        <Button tabIndex={0} color={'white'} onClick={() =>{
          ga4OpenRegister()
           dispatch(openSideModal(SideModalTabs.REGISTER))}}>
          Skapa konto
        </Button>
       
      </div>
    </div>
  );
};

CreateAccount.propTypes = {
  path: PropTypes.string.isRequired
};


class Login extends Component {

  render() {
    const {
      createAccountTitle,
      createAccountParagraph,
      bottomText,
      openSideModal,
      setInitialStep
    } = this.props;

    return (
      <div className="c-login">
        <div className="box">
        <div style={{ marginBottom: 15 }}>
      <Button 
      tabIndex={0}
      color={'brandPurple'} fullWidth onClick={() => openSideModal(SideModalTabs.LOGIN)}>
        Logga in
      </Button>
  
    </div>
    <LinkButton
    tabIndex={0}
    onClick={() => {
     openSideModal(SideModalTabs.LOGIN)
   setInitialStep(loginSteps.LOGIN_FORGOT)
    }}
    >
      Glömt lösenord?
    </LinkButton>
        </div>
        <CreateAccount
          createAccountTitle={createAccountTitle}
          createAccountParagraph={createAccountParagraph}
        />

        {bottomText && (
          <div className="box">
            <RichText text={bottomText} />
          </div>
        )}
      </div>
    );
  }
}

Login.propTypes = {
 
  // from appSettings.login. those properties come from episerver settings
  bottomText: PropTypes.string,
  createAccountTitle: PropTypes.string,
  createAccountParagraph: PropTypes.string
  //
};

Login.defaultProps = {
  createAccountTitle: 'Är du inte PRIO-kund?',
  createAccountParagraph: 'Skapa ett konto idag!'
};

const mapDispatchToProps = {
  setInitialStep,
  openSideModal
};

const mapStateToProps = (state, props) => {
};

export default 
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
;
