/* eslint-disable react/display-name */
import React from 'react';
import { useDispatch } from 'react-redux';

import { removeItemsFromCart } from '../../../../actions/cart';
import { CartItem } from '../../../../types/cart/CartItem';
import { Product } from '../../../../types/xim/product';
import { Button } from '../../../Buttons';
import { setImage } from '../../../../lib/product';
import './UnavailableConfirm.scss';

// @ts-ignore
import defaultImg from '../../../assets/produkt-fallback.svg';
import { formatPriceWithDecimalsReduced } from '../../../../lib/price';
import cs from 'classnames';
import CartGroup from '../../../CartList/CartGroup/index.js';

// TODO: Should not be site coupled, adapt for zip.
interface CartItemProduct extends CartItem {
  product: Product;
}
interface IProps {
  products?: CartItemProduct[];
  goBack: Function;
  heading: string;
  description: string;
  postItemRemovalCallback?: () => void;
}

const UnavailableConfirm: React.FC<IProps> = ({
  products,
  goBack,
  heading,
  description,
  postItemRemovalCallback
}) => {
  const dispatch = useDispatch();

  return (
    <div className="c-unavailableconfirm">
      <h2>{heading}</h2>
      <p>{description}</p>
      <div className="c-unavailableconfirm__productwrap">
        <div className="c-unavailableconfirm__products">
          {products && products?.length > 0 && (
            <CartGroup name={'Vara är borttagen'}>
              {products?.map((product: any) => {
                const imgPath = product?.product?.images
                  ? setImage(product?.product?.images, 0).url
                  : defaultImg;

                const formattedPrice = formatPriceWithDecimalsReduced(
                  product?.amount
                );

                const priceString = `${formattedPrice?.value},${formattedPrice?.decimal}
                    kr`;

                return (
                  <div
                    className="c-unavailableconfirm__product"
                    key={product.id}
                  >
                    <div className="c-unavailableconfirm__productimage">
                      <img src={imgPath} alt="Product" />
                    </div>
                    <div className="c-unavailableconfirm__description">
                      <p>{product?.name}</p>
                      {product?.product?.descriptiveSize ? (
                        <p className="c-unaviableconfirm__descriptive">
                          {product?.product?.descriptiveSize}
                        </p>
                      ) : null}
                    </div>
                    <div className="c-unavailableconfirm__priceSection ">
                      <div
                        className={cs('c-unavailableconfirm__description', {
                          ['c-unavailableconfirm__priceDiff']: true
                        })}
                      >
                        {product.subType !== 'RecipeBag' && (
                          <p>
                            {product?.quantity?.value}{' '}
                            {product?.quantity?.originalUnit}
                          </p>
                        )}
                        <p className="c-unavailableconfirm__price">
                          {priceString}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </CartGroup>
          )}

          <div className="c-unavailableconfirm__disclaimer">
            <div className="c-unavailableconfirm__disclaimerinner">
              Om du väljer att gå vidare kommer ovanstående varor att tas bort
            </div>
          </div>
        </div>
      </div>
      <div className="c-unavailableconfirm__buttons">
        <Button lg fullwidth onClick={() => goBack()}>
          Gå tillbaka
        </Button>
        <Button
          primary
          fullwidth
          lg
          onClick={() => {
            dispatch(removeItemsFromCart(products?.map(p => p.id.toString())));
            if (postItemRemovalCallback) postItemRemovalCallback();
          }}
        >
          Gå vidare, ta bort varor
        </Button>
      </div>
    </div>
  );
};

export default UnavailableConfirm;
