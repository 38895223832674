import styled from 'styled-components';

type TReplacementWrapper = {
  paddingTop?: boolean;
};

export const ReplacementOrder = styled.div`
  display: flex;
  gap: 8px;
`;

export const ReplacementWrapper = styled.div<TReplacementWrapper>`
  background: white;
`;

export const ReplacementBody = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`;

export const ReplacementOrderCancel = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
`;
