import {
  OPEN_IN,
  CLOSE,
  TOGGLE_CONFIRMATION
} from '../../types/reducers/flyout';
import { toggleOverlay, clearOverlay } from '../app';
import { open as openModal } from '../confirmModal';

import { identity } from '../../lib/utils';

export const close = () => {
  return {
    type: CLOSE,
    show: false
  };
};

export const closeAll = withConfirm => dispatch => {
  if (withConfirm) {
    dispatch(
      openModal({
        title: 'Är du säker?',
        body: 'Är du säker på att du vill gå vidare utan att spara?',
        confirmLabel: 'Bekräfta',
        cancelLabel: 'Gå tillbaka',
        onConfirmClick: () => {
          dispatch(close());
        },
        onCancelClick: () => console.log('cancel')
      })
    );
  } else {
    dispatch(close());
  }
};

export const toggleConfirm = needConfirmation => {
  return {
    type: TOGGLE_CONFIRMATION,
    payload: needConfirmation
  };
};

export const open = (title, component, opts) => {
  return {
    type: OPEN_IN,
    title,
    show: true,
    component,
    opts
  };
};

export const openInFlyout = (
  title,
  component,
  opts = {},
  cb = identity
) => dispatch => {
  dispatch(
    toggleOverlay(true, true, () => {
      cb();
      dispatch(closeAll());
    })
  );

  dispatch(open(title, component, opts));
};
