function sortByKey(array, key) {
  return array.sort((first, second) => {
    const x = first[key];
    const y = second[key];

    return x - y;
  });
}

export const makeQuantityLabel = (list = [], label) => {
  const quantities = [];
  for (let index = 0; list.length > index; index++) {
    quantities.push({
      value: list[index].id,
      label: !list[index].quantityTo
        ? `${list[index].quantityFrom} ${label}`
        : `${list[index].quantityFrom} - ${
            list[index].quantityTo
          } ${'portioner'}`,
      ...list[index]
    });
  }
  return sortByKey(quantities, 'quantityFrom');
};

export const makeSidesLabel = list =>
  list.map(side => ({
    value: side.id,
    label: side.name,
    ...side
  }));

export const shouldDisplayPrice = price => {
  return price?.value === 10000 || price?.value === 9999 || price?.value === 0;
};
