import { authenticatedRequest, request } from '../../lib/request';
import { UserData, CustomerData } from '../../types/customer/UserData';
import { SettingsData, FavoriteModel } from '../../types/customer/SettingsData';
import { DeliveryMethods } from '../../types/assortments';
import { UserChannelData } from '../../types/customer/ContactData';

interface FullCustomerRequest {
  data: UserData;
}

interface SettingsRequest {
  data: SettingsData;
}
interface ValidEmailRequest {
  data: {
    isAvailable: boolean;
  };
}
export const checkValidEmail = (email: string): Promise<ValidEmailRequest> =>
  request(`/customer/email/${email}`);

interface CheckCustomerExistRequest {
  data: CustomerData;
}

export const fetchCustomerById = (
  customerId: string | number
): Promise<CheckCustomerExistRequest> =>
  request(`/customer/status/${customerId}`,  {
    headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization:""
  }});

export interface NewCustomerData {
  pnr: string | number;
  emailAddress: string;
  password: string;
  mobilePhone: string;
}
export const setNewCustomer = (
  newCustomer: NewCustomerData
): Promise<FullCustomerRequest> =>
  request('/customer/createcustomer', {
    method: 'POST',
    body: JSON.stringify(newCustomer),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:""
    }
  });

export const fetchCurrentUser = (): Promise<FullCustomerRequest> =>
  authenticatedRequest('/customer');

interface UserDeliverySettings {
  userDeliveryMethod: DeliveryMethods;
  userPostalCode?: string | null;
  userSiteId?: number | string | null;
}

export const setUserDeliverySettings = (
  data: UserDeliverySettings
): Promise<SettingsRequest> => {
  return authenticatedRequest('/customer/userdeliverysettings', {
    method: 'PUT',
    body: JSON.stringify(data)
  });
};

export const setNewEmail = (
  currentEmail: string,
  password: string,
  newEmail: string
): Promise<FullCustomerRequest> =>
  authenticatedRequest('/customer/email', {
    method: 'PUT',
    body: JSON.stringify({
      currentEmailAddress: currentEmail,
      password: password,
      newEmail: newEmail
    })
  });

interface NewPhoneRequest {
  data: UserChannelData;
}
export const setNewPhone = (newPhone: string): Promise<NewPhoneRequest> =>
  authenticatedRequest('/customer/mobilephone', {
    method: 'PUT',
    body: JSON.stringify({
      mobilePhoneNumber: newPhone
    })
  });

export const requestPasswordReset = (email: string): Promise<null> =>
  request('/customer/requestpasswordreset', {
    method: 'POST',
    body: JSON.stringify({
      email: email
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:""
    }
  });

export const setNewPassword = (
  newPassword: string,
  token: string
): Promise<null> =>
  request('/customer/changepasswordwithtoken', {
    method: 'PUT',
    body: JSON.stringify({
      authToken: token,
      password: newPassword,
      passwordConfirmation: newPassword
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization:""
    }
  });

interface FavoriteRequest {
  data: FavoriteModel;
}

export const fetchUserFavorites = (): Promise<FavoriteModel[]> =>
  authenticatedRequest('/favorites', {
    method: 'GET'
  });

export const setFavoriteProduct = (
  productId: string
): Promise<FavoriteRequest> =>
  authenticatedRequest('/favorites/products', {
    method: 'POST',
    body: JSON.stringify({
      itemNo: productId
    })
  });

export const setFavoriteRecipe = (recipeId: string): Promise<FavoriteRequest> =>
  authenticatedRequest('/favorites/recipes', {
    method: 'POST',
    body: JSON.stringify({
      itemNo: recipeId
    })
  });

export const deleteFavorite = (favorite: FavoriteModel): Promise<null> =>
  authenticatedRequest('/favorites', {
    method: 'DELETE',
    body: JSON.stringify({
      id: favorite.id
    })
  });

export const setEmailRecieptCopyReq = (
  status: boolean | 'true' | 'false'
): Promise<FullCustomerRequest> =>
  authenticatedRequest(`/customer/setemailrecieptcopy/${status}`, {
    method: 'PUT'
  });
