import React from 'react';
import qs from 'query-string';
import className from 'classnames';
import NotFound from '../../NotFound';
import { Switch, Route } from 'react-router-dom';

import './ServiceLayer.scss';

const defaultComp = name => ({ component: () => <NotFound name={name} /> });
const ServiceLayer = ({ location, navSection = [], toggleServiceLayer }) => {
  const query = qs.parse(location.search);

  return query.open ? (
    <div className={className('servicelayer', { active: query.open })}>
      <div className="b-container">
        <div
          className="c-cross dark"
          onClick={() => toggleServiceLayer(location.pathname)}
        />

        <Switch>
          <Route
            to={location.pathname}
            render={props => {
              const search = qs.parse(props.location.search);
              const isActive = search.open;

              if (!isActive) {
                return null;
              }

              const { component: Component } =
                navSection.find(
                  section => search.serviceName === section.name
                ) || defaultComp(search.serviceName);

              return Component ? <Component {...props} /> : null;
            }}
          />
        </Switch>
      </div>
    </div>
  ) : null;
};

export default ServiceLayer;
