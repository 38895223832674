import React, { useEffect } from 'react';
import { setMode } from '../../actions/shoppingOrder';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';
import { useDispatch, useSelector } from 'react-redux';
import { getPageById } from '../../actions/page';
import { pageIdSelector, pageSelector } from '../../selectors/page';
import { useRouteMatch } from 'react-router-dom';
import { AppState } from '../../reducers';
import { Store } from '../../types/episerver/store';
import SingleStore from '../../modules/SingleStore';
import { CenteredSpinner } from '../../components/Spinner/Spinner';

const StoreContainer: React.FC = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const pageId = useSelector(state => pageIdSelector(state, { match }));
  const storeServices = useSelector(
    (state: AppState) => state.app.settings?.storeServices
  );

  // due to page selector not being typed, we can inherit from Store instead.
  //            |
  //            V
  const store: Store | null = useSelector((state: AppState) =>
    pageSelector(state, { match })
  );

  useEffect(() => {
    dispatch(setMode(true, false, UNTOGGLE_BEHAVIOUR.none));
    dispatch(getPageById(pageId));
  }, []);

  if (!store?.id) {
    return <CenteredSpinner />;
  }

  return (
    <div>
      <SingleStore store={store} storeServices={storeServices} />
    </div>
  );
};

// StoreContainer.required = [
//   (state, params) => {
//     return pageActions.getPageById(state.navigation.pathLookup[params.path].id);
//   }
// ];

export default StoreContainer;
