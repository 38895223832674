import { MutableRefObject, useEffect, useRef } from "react";
import useCookieConsent from "./useCookieConsent";
import { getCookiebotConsentCookie } from "../lib/cookiebot";
import { CookieCategories, CookieConsent } from "../types/cookiebotTypes";

function cookiesToDelete(
	cookieConsent: CookieConsent, 
	prevCookieConsentRef: MutableRefObject<CookieConsent | undefined>
): number[] {
	const cookieCategories: number[] = [];

	if (!prevCookieConsentRef || !prevCookieConsentRef.current) return cookieCategories;
	if(!cookieConsent.preferences && prevCookieConsentRef.current.preferences) {
		cookieCategories.push(CookieCategories.Preferences);
	}
	if(!cookieConsent.statistics && prevCookieConsentRef.current.statistics) {
		cookieCategories.push(CookieCategories.Statistics);
	}
	if(!cookieConsent.marketing && prevCookieConsentRef.current.marketing) {
		cookieCategories.push(CookieCategories.Marketing);
	}

	return cookieCategories;
}

export default function useClearCookiesOnConsentChange(): {
	cookieCategoriesToClear: number[];
	clearCookies: boolean;
} {
  const cookieConsent = useCookieConsent();

	let prevCookieConsentRef: MutableRefObject<CookieConsent | undefined> = useRef();

	useEffect(() => {
    prevCookieConsentRef.current = cookieConsent;
  }, [cookieConsent]);
    
	useEffect(() => {
		prevCookieConsentRef.current = getCookiebotConsentCookie();
	}, []);

	const cookieCategoriesToClear = cookiesToDelete(cookieConsent, prevCookieConsentRef);

	const returnObject = {
		cookieCategoriesToClear,
		clearCookies: cookieCategoriesToClear.length > 0
	}

	return returnObject;
}
