import React from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

export const WithAnimation = classNames => ({
  children,
  enterTimeout: enter = 1000,
  exitTimeout: exit = 0,
  ...props
}) => (
  <CSSTransition {...props} timeout={{ enter, exit }} classNames={classNames}>
    {children}
  </CSSTransition>
);

export default WithAnimation;
