import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const MobilePhoneInput = ({
  id,
  label,
  hint,
  placeholder,
  children,
  formLoading,
  ...rest
}) => (
  <Fragment>
    <label htmlFor={id}>
      {label}
      {children}
      {hint && <span className="hint">{hint}</span>}
    </label>
    <input
      type="tel"
      id={id}
      readOnly={formLoading}
      placeholder={placeholder}
      {...rest}
    />
  </Fragment>
);

MobilePhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  formLoading: PropTypes.bool,
  children: PropTypes.any
};

export default MobilePhoneInput;
