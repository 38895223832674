import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const StandardInput = ({
  type,
  id,
  label,
  hint,
  formLoading,
  children,
  autoFocus,
  ...rest
}) => (
  <Fragment>
    <label htmlFor={id}>
      {label}
      {children}
      {hint && <span className="hint">{hint}</span>}
    </label>
    {autoFocus ? (
      <input
        type={type}
        id={id}
        autoFocus="autofocus"
        readOnly={formLoading}
        {...rest}
      />
    ) : (
      <input type={type} id={id} readOnly={formLoading} {...rest} />
    )}
  </Fragment>
);

StandardInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password', 'email', 'signupEmail'])
    .isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  formLoading: PropTypes.bool,
  children: PropTypes.any,
  autoFocus: PropTypes.bool
};

export default StandardInput;
