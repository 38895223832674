import React from 'react';
import { Helmet } from 'react-helmet';
import {
  MinutesToIsoDuration,
  recipeHtmlInstructionsToArray
} from '../../lib/recipe.js';

interface ISchemaMeta {
  name: string;
  url: string;
  imageUrl: string;
  description: string;
  id: string;
  price: string;
  brand: string;
}

interface IRecipeSchemaMeta {
  name: string;
  imageUrl: string;
  cookingTime: string;
  recipeYield: string;
  ingredients: any[];
  recipeInstructions: string;
  url: string;
  description: string;
}

export const SchemaMeta: React.FC<ISchemaMeta> = ({
  name,
  url,
  imageUrl,
  description,
  id,
  price,
  brand
}) => {
  return (
    <div
      style={{ display: 'none' }}
      itemScope
      itemType="http://schema.org/Product"
    >
      <meta itemProp="brand" content={brand} />
      <meta itemProp="name" content={name} />
      <a itemProp="url" href={'https://www.citygross.se' + url}>
        Till
      </a>
      <img
        itemProp="image"
        src={'https://www.citygross.se' + imageUrl}
        alt={name}
      />
      <span itemProp="description">{description}</span>
      <meta itemProp="productID" content={id} />
      <span itemProp="offers" itemScope itemType="http://schema.org/Offer">
        <link itemProp="availability" href="http://schema.org/InStock" />
        <meta
          itemProp="itemCondition"
          itemType="http://schema.org/OfferItemCondition"
          content="http://schema.org/NewCondition"
        />
        <div className="product_price" itemProp="price">
          {price}
        </div>
        <meta itemProp="priceCurrency" content="SEK" />
      </span>
    </div>
  );
};

export const RecipeSchemaScript: React.FC<IRecipeSchemaMeta> = ({
  name,
  imageUrl,
  cookingTime,
  recipeYield,
  ingredients,
  recipeInstructions,
  description,
  url
}) => {
  const ingre = ingredients.map(ing => {
    let ingString = '';
    if (ing?.quantity) ingString += `${ing.quantity} `;

    if (ing.unit) ingString += `${ing.unit} `;

    ingString += ing.name;
    return ingString;
  });

  const steps = recipeHtmlInstructionsToArray(recipeInstructions)?.[0]?.steps;
  const makeSchemaSteps = () =>
    steps?.map((step, index) => {
      return {
        '@type': 'HowToStep',
        text: step,
        url: `https://www.citygross.se${url}#how-to-step${index + 1}`
      };
    });

  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
        "@context": "https://schema.org/",
        "@type": "Recipe",
        "name": "${name}",
        "image": [
          "${'https://www.citygross.se' + imageUrl}"
        ],
        "author": {
          "@type": "Organization",
          "name": "City Gross"
        },
        "description": "${description}",
        "prepTime": "PT10M",
        "cookTime": "${MinutesToIsoDuration(cookingTime)}",
        "totalTime": "${MinutesToIsoDuration(cookingTime)}",
        "keywords": "${name}",
        "recipeYield": "${recipeYield}",
        "recipeIngredient": "${ingre}",
        "recipeInstructions": ${JSON.stringify(makeSchemaSteps())}
        }
      `}
      </script>
    </Helmet>
  );
};

export const RecipeSchemaList = ({ items }: any) => {
  if (!items || items?.length < 1) {
    return null;
  }
  const recipes = items?.map((r, index) => ({
    '@type': 'ListItem',
    position: `${index + 1}`,
    url: `https://citygross.se${r?.url}`
  }));
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": ${JSON.stringify(recipes)}
      `}
      </script>
    </Helmet>
  );
};
