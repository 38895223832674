import React from 'react';
import ProgressivePicture from '../ProgressivePicture';
import { LazyLoadSSR } from '../../Lazy';

const LazyProgressivePicture = props => (
  <LazyLoadSSR once offset={500} height={0}>
    <ProgressivePicture {...props} />
  </LazyLoadSSR>
);

export default LazyProgressivePicture;
