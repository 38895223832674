import React from 'react';
import SimpleTeaser from './SimpleTeaser';
import DoubleTeaserContainer from './DoubleTeaserContainer';

const Teaser = ({ type, onClick, ...rest }) => {
  switch (type) {
    case 'DoubleTeaserContainer':
      const { teasers = [] } = rest;
      return (
        <DoubleTeaserContainer onClick={onClick}>
          {teasers.map(({ id, ...rest }) => (
            <Teaser key={id} {...rest} />
          ))}
        </DoubleTeaserContainer>
      );
    case 'SimpleTeaser':
      return <SimpleTeaser {...rest} onClick={onClick} />;
    default:
      console.error('Unkown teasertype', type);
      return null;
  }
};

export default Teaser;
