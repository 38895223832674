import React from 'react';
import './TriggerLink.scss';

const TriggerLink = ({ children, ...rest }) => {
  return (
    <span className="c-trigger-link" {...rest}>
      {children}
    </span>
  );
};

export default TriggerLink;
