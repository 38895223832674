import { connect } from 'react-redux';
import { passwordReset, clearPasswordReset } from '../../actions/auth';

import ForgotPasswordModule from '../../modules/Login/ForgotPassword';

function mapStateToProps(state) {
  return {
    requestingPasswordReset: state.auth.requestingPasswordReset,
    passwordResetRequestSuccessfull: state.auth.passwordResetRequestSuccessfull,
    passwordResetRequestError: state.auth.passwordResetRequestError,
    passwordResetRequestErrorMessage:
      state.auth.passwordResetRequestErrorMessage
  };
}

const mapDispatchToProps = {
  passwordReset,
  clearPasswordReset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModule);
