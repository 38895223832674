import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import * as pageActions from '../../actions/page';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { LinkablePageTitle } from '../../components/PageTitle';
import { AlertBox } from '@citygross/components';
import { Icons } from '@citygross/icons';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  OfferListingStoreSelected,
  OfferListingNoStoreSelected
} from '../../modules/OfferListing';
import ContentBlocksArea from '../../components/ContentBlocksArea';

type TOfferFolderPage = {
  getPageById: (id: number) => Promise<void>;
  id: number;
  page: {
    id: number;
    upperContentBlocks: any[];
    bottomContentBlocks: any[];
  };
  title: any;
};

const OfferFolderPage = ({
  getPageById,
  id,
  page,
  title
}: TOfferFolderPage) => {
  const [pageError, setPageError] = useState(false);

  const getPageContent = () => {
    setPageError(false);

    getPageById(id).catch(err => {
      // eslint-disable-next-line no-console
      console.error('getPageById failed', err);
      setPageError(true);
    });
  };

  const store = useSelector(selectCurrentAssortment);

  useEffect(() => {
    if (page.id !== id) {
      getPageContent();
    }
  }, []);

  if (pageError)
    return (
      <AlertBox icon={<Icons.Alert />}>
        Något gick fel vid inladdning av sidan
      </AlertBox>
    );

  return (
    <Fragment>
      <LinkablePageTitle {...title} />

      <ContentBlocksArea blocks={page.upperContentBlocks} />

      {store ? <OfferListingStoreSelected /> : <OfferListingNoStoreSelected />}

      <ContentBlocksArea blocks={page.bottomContentBlocks} />
    </Fragment>
  );
};

const mapStateToProps = (state, props) => ({
  title: breadCrumbSelector(state, props),
  page: pageSelector(state, props),
  id: pageIdSelector(state, props)
});

const mapDispatchToProps = {
  getPageById: pageActions.getPageById
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferFolderPage)
);
