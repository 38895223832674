import { authenticatedRequest, requestRaw } from '../../lib/request';
import queryParams from '../../lib/queryParams';
import { dateAsFormat } from '../../lib/delivery';

export const getOrderForUser = (
  from = `${dateAsFormat(new Date(), 'yyyy-MM-dd')}T00:00:00`
) => {
  return authenticatedRequest(
    `/orders?${queryParams({ afterDeliveryDate: from })}`,
    null,
    requestRaw
  );
};

// export const moveToAxfood = orderId =>
//   authenticatedRequestToast(
//     `/orders/${orderId}/moveToAxfood`,
//     {
//       method: 'POST'
//     },
//     requestRawToast
//   );
