import {
  authenticatedRequest,
  cachedRequest,
  esalesRequest
} from '../../lib/request';

export const search = (searchQuery : string) =>
  esalesRequest(`/esales/search/?${searchQuery}`);

export const quickSearch = (searchQuery : string) =>
  esalesRequest(`/esales/search/quick/?${searchQuery}`);

// TODO: Move to esales
export const searchXimEntitiesByVariant = (idArr : string[]) =>
  authenticatedRequest(
    '/esales/entities_variant',
    {
      method: 'POST',
      body: JSON.stringify({
        items: idArr
      })
    },
    cachedRequest
  );
