/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/namespace
import { Icons } from '@citygross/icons'
import {NavButton  } from '@citygross/components';
import { useDispatch } from 'react-redux';
import { SideModalTabs } from '../SideModal'
import { openSideModal } from '../../slices/sideModalSlice';
import { BodyText, TextTypes } from '@citygross/typography';

export const UserLoginState = ({
  authenticated,
  closeSearch
}) => {
  const dispatch = useDispatch();
  const [href, setHref] = useState('/login')

  useEffect(() => {
    if(authenticated){
      setHref('/mina-sidor')
    }
  },[authenticated])



    return ( 
      <>
      {authenticated && 
      <a href={href} tabIndex={0} key={'mina-sidor'}>

      <NavButton
        color={'white'}
        icon={<Icons.User/>}
        size={'header'}
        href={href}
        borderRadius={100}
        noWrap
        onClick={() => {}}
        noShadow
        hoverBackground="#F1F1F1"
        width={42}
        height={42}
        >
        <BodyText size={TextTypes.TextSize.SMALL} fontWeight={'medium'}>
            Mina Sidor
        </BodyText>
      </NavButton>
        </a>
      }
      {!authenticated && 
       <NavButton
       tabIndex={0}
       key={'login'}
       color={'white'}
       icon={<Icons.User/>}
       size={'header'}
       borderRadius={100}
       noWrap
       onClick={(e) => { 
         e.preventDefault()
        dispatch(openSideModal(SideModalTabs.LOGIN))
        closeSearch()
       }}
       noShadow
       hoverBackground="#F1F1F1"
       width={42}
       height={42}
     >
       <BodyText size={TextTypes.TextSize.SMALL} fontWeight={'medium'} >
           Logga in
       </BodyText>
     </NavButton>
      }
        </>
    )
  
};

UserLoginState.propTypes = {
  userName: PropTypes.string,
  authenticated: PropTypes.bool.isRequired,
  isLoggedInAsCustomer: PropTypes.bool,
};


export default UserLoginState;
