import React, { useEffect, useState } from 'react';
import { Page, FacebookProvider } from 'react-facebook';

interface IProps {
  facebookLink: string
}

const StoreSocial : React.FC<IProps> = ({ facebookLink }) => {
  return (
    <div className="cookieconsent-optin-preferences 
      cookieconsent-optin-statistics 
      cookieconsent-optin-marketing"
    >
      <h2>Facebook</h2>
      <FacebookProvider
        appId="1958351174198631"
        className="fb-page-wrapper"
        language="sv_SE"
      >
        <Page
          href={facebookLink}
          tabs="timeline"
          smallHeader="true"
          adaptContainerWidth="true"
          hideCover="true"
          showFacepile="false"
          height="600"
          width="980"
          hideCTA="true"
        />
      </FacebookProvider>
    </div>
  );
};

export { StoreSocial };
