import ProductList from '../../components/ProductList';
import { connect } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';
import { cartItemsBlockProductCombinationSelector } from '../../selectors/cart/cartSelector';
import { sortedCartItemsProductCombinationSelector } from '../../selectors/cart/cartSelector';
import { push } from 'connected-react-router';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { setProductFavorite, removeFavorite } from '../../actions/auth';

const mapStateToProps = (state, props) => {
  let products;

  if (props.withoutPaginationAndFilter) {
    products = cartItemsBlockProductCombinationSelector(state, props.products);
  } else {
    products = sortedCartItemsProductCombinationSelector(state);
  }

  return {
    isAuthenticated: authenticatedSelector(state),
    items: products
  };
};

const mapDispatchToProps = {
  increaseItemQuantity,
  decreaseItemQuantity,
  push,
  addToCart,
  setProductFavorite,
  removeFavorite
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);
