import { createSelector } from 'reselect';
import { AppState } from '../reducers';

// TODO @Stefan
const getFaqCats = (state: AppState) => state.app.settings?.faqCats;
const getFaqQuestions = (state: AppState) => state.imbox.questions;

export const faqQuestionsSelector = createSelector(
  [getFaqCats, getFaqQuestions],
  (categories, questions) =>
    questions.map(question => {
      const matchedCategory = categories?.find(
        category => parseInt(category.categoryId, 10) === question?.cat
      );
      return {
        ...question,
        category: matchedCategory?.categoryName
      };
    })
);
