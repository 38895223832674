import Cookies from "js-cookie";
import { CookieConsent } from "../types/cookiebotTypes";

export const getCookiebotConsentCookie = (): CookieConsent => {
	const cookie = Cookies.get('CookieConsent');

	const cookieObject: CookieConsent = {
		preferences: null,
		statistics: null,
		marketing: null
	}
	
	if (cookie) {
		cookie
			.split(',')
			.filter(value => /^(preferences|statistics|marketing):(true|false)/.test(value))
			.forEach(consentCategory => {
				const nameValueSplit = consentCategory.split(':');
        // @ts-ignore: Unreachable code error
				cookieObject[nameValueSplit[0]] = (nameValueSplit[1] === 'true');
			});
	}

	return cookieObject;
}