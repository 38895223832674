import { FlyoutBodyId } from '../components/Flyout/FlyoutComponents/FlyoutBody';

export const getElementScrollContainer = (element: HTMLElement | null) => {
  if (!element) {
    return null;
  }

  const flyoutBodyContainer = getElementClosest(element, `#${FlyoutBodyId}`);
  if (flyoutBodyContainer !== null) {
    return flyoutBodyContainer;
  }

  return document.body;
};

const getElementClosest = (element: HTMLElement | null, selector?: any | null) => {
  if (!element) {
    return null;
  }

  if (element.closest) {
    return element.closest(selector);
  }

  if (element.matches(selector)) {
    return element;
  }

  return getElementClosest(element.parentElement);
};
