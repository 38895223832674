const flatten = (tree, current) => {
  const { children, ...rest } = current;
  tree[current.link.url] = rest;
  return children.reduce(flatten, tree);
};

export const pathLookup = (tree = {}) => {
  if (!Object.keys(tree).length) {
    return {};
  }

  return flatten({}, tree);
};
