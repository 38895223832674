import React from 'react';
import {
  validateMinLength,
  validateRequired,
  validateEmail,
  validateMobilePhoneNumber,
  validatePersonalNumber,
  validateZipCode,
  validateMaxLength
} from '../../lib/validator';

export const recursiveChildrenMap = (children, fn) =>
  React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return child;
    }

    if (child.props.children) {
      child = React.cloneElement(child, {
        children: recursiveChildrenMap(child.props.children, fn)
      });
    }

    return fn(child);
  });

// Adds props to a react element
export const addPropsToChild = (child, props = {}) => {
  const { type } = child;

  if (!props[type]) {
    return child;
  }

  return React.cloneElement(child, {
    ...props[type]
  });
};

export const propagatePropsToFormChildren = (children, props) =>
  recursiveChildrenMap(children, child => addPropsToChild(child, props));

export const validateFormItem = (value, props) => {
  const {
    type = '',
    label,
    minLength,
    maxLength,
    required,
    getValueById,
    repeatItemId
  } = props;

  if (repeatItemId) {
    const valueToMatch = getValueById(repeatItemId);

    if (value !== valueToMatch) {
      return {
        isValid: false,
        fallbackHint: `${label} måste matcha.`
      };
    }
  }

  // Required validation
  if (required) {
    if (
      validateRequired(value) === false ||
      (type === 'checkbox' && value !== true)
    ) {
      return {
        isValid: false,
        fallbackHint: `${label} är ett obligatoriskt fält.`
      };
    }
  } else {
    if (!value || value === '') {
      return {
        isValid: true
      };
    }
  }

  // Minimum length validation
  if (minLength) {
    if (validateMinLength(value, minLength) === false) {
      return {
        isValid: false,
        fallbackHint: `${label} måste vara minst ${minLength} tecken.`
      };
    }
  }

  if (maxLength) {
    if (validateMaxLength(value, maxLength) === false) {
      return {
        isValid: false,
        fallbackHint: `${label} får högst vara ${maxLength} tecken.`
      };
    }
  }

  // Type validation
  switch (type) {
    case 'email':
      if (validateEmail(value) === false) {
        return {
          isValid: false,
          fallbackHint: `${label} har ett felaktigt format, använd namn@doman.com.`
        };
      }
      break;
    case 'signupEmail':
      if (validateEmail(value) === false) {
        return {
          isValid: false,
          fallbackHint: `${label} har ett felaktigt format, använd namn@doman.com.`
        };
      }
      break;
    case 'loginEmail':
      if (validateEmail(value) === false) {
        return {
          isValid: false,
          fallbackHint: `${label} har ett felaktigt format, använd namn@doman.com.`
        };
      }
      break;
    case 'personalNumber':
      if (validatePersonalNumber(value) === false) {
        return {
          isValid: false,
          fallbackHint: `${label} har ett felaktigt format, använd YYMMDD-XXXX.`
        };
      }
      break;
    case 'mobilePhone':
      if (validateMobilePhoneNumber(value) === false) {
        return {
          isValid: false,
          fallbackHint: `${label} har ett felaktigt format, använd 0701234567`
        };
      }
      break;
    case 'zipCode':
      if (validateZipCode(value) === false) {
        return {
          isValid: false,
          fallbackHint: `${label} har ett felaktigt format, använd 12345`
        };
      }
      break;
    default:
      return {
        isValid: true
      };
  }

  return {
    isValid: true
  };
};
