import React from 'react';

import { Tag } from '../Tag';

import './SelectedFilters.scss';

interface ISelectedFilterItem {
  id: number;
  parent: string;
  niceName: string;
}

interface ISelectedFilters {
  filters: ISelectedFilterItem[];
  label: string;
  deselectFilter: Function;
}

const SelectedFilters: React.FC<ISelectedFilters> = ({
  filters,
  label = 'Valda filter:',
  deselectFilter = () => console.log('unregister filter')
}) => {
  return (
    <div className="c-selectedfilters">
      <span className="header">{label}</span>

      {filters?.map(({ id, parent, niceName }: ISelectedFilterItem) => {
        const displayName = niceName || id;

        return (
          <Tag
            key={id}
            active
            cross
            onClick={() => deselectFilter({ [`${parent}`]: id })}
          >
            {displayName}
          </Tag>
        );
      })}
    </div>
  );
};

export default SelectedFilters;
