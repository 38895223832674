import React, { Fragment } from 'react';
// import {} from '../../components/'
import { PageTitle } from '../../components/PageTitle';

export const Error500 = ({ error, info, debug, componentName }) => (
  // TODO: make a better design / ui for this
  <Fragment>
    <h2>Något har gått fel! </h2>
    <PageTitle
      current={error && error.toString() + ` in component: ${componentName}`}
    />
    {debug && (
      <div>
        <br />
        <small>
          Where it occured: <i>{info && info.componentStack}</i>
        </small>
      </div>
    )}
  </Fragment>
);
