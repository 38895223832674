import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { domSafe } from '../../../lib/document';

class LazyLoadSSR extends PureComponent {
  state = {
    hasMounted: domSafe()
  };

  componentDidMount() {
    this.setState({
      hasMounted: true
    });
  }

  render() {
    const { children, ...passthroughProps } = this.props;
    const { hasMounted } = this.state;

    return hasMounted ? (
      <LazyLoad {...passthroughProps}>{children}</LazyLoad>
    ) : (
      children
    );
  }
}

LazyLoadSSR.propTypes = {
  children: PropTypes.any.isRequired
};

export default LazyLoadSSR;
