import React from 'react';
import { Store } from '../../types/episerver/store';
import RichText from '../../containers/RichText';
import './StoreOpeningHours.scss';

type TStoreOpeningHours = {
  store: Store;
};

const StoreOpeningHours: React.FC<TStoreOpeningHours> = ({ store }) => {
  return store && store.openingHours.extraInfo ? (
    <div className="c-singlestore__info">
      <div className="c-singlestore__infocolumn c-singlestore__infocolumn--gray">
        <RichText text={store.openingHours.extraInfo} />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default StoreOpeningHours;
