import React from 'react';
import classNames from 'classnames';

import './CenterElement.scss';

interface ICenterElement {
  className?: string
}

const CenterElement: React.FC<ICenterElement> = ({ children, className }) => (
  <div className={classNames('c-center', className)}>{children}</div>
);

CenterElement.defaultProps = {
  className: ''
};

export default CenterElement;
