// TODO: Is shoppingOrder a good name for this type? Check type, state reference, actions if thats not the case or remove this comment.
const NAME = 'shoppingOrder';

export const TOGGLE_ACTIVE = `${NAME}/TOGGLE_ACTIVE`;
export const ADD_ACTIVE_RECIPE = `${NAME}/ADD_ACTIVE_RECIPE`;
export const REMOVE_ACTIVE_RECIPE = `${NAME}/REMOVE_ACTIVE_RECIPE`;
export const SET_VISIBILITY = `${NAME}/SET_VISIBILITY`;
export const SET_MODE = `${NAME}/SET_MODE`;

export const TABS = { cart: 'cart', delivery: 'delivery' };

export const UNTOGGLE_BEHAVIOUR = { active: 'active', none: 'none' };
