import React from 'react';
import './RoundSpinner.scss';
const SpinnerIcon = require('../assets/round-spinner.svg');

interface IProps extends React.DetailedHTMLProps<
React.HTMLAttributes<HTMLDivElement>,
HTMLDivElement> {

}

const RoundSpinner : React.FC<IProps> = ({ ...rest }) => {
    return (
        <div {...rest} className="c-roundspinner">
            <img src={SpinnerIcon} alt="laddar" />
        </div>
    )
}

export default RoundSpinner;
