/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import * as styles from './SideModalLogin.styles';
import LoginEmail from './LoginEmail';
import { Wizard } from '@citygross/react-use-bg-wizard';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import LoginPassword from './LoginPassword';
import { AnimatedStateStep } from '../AnimatedStep';
import LoginForgotPassword from './LoginForgotPassword';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentStep,
  selectInitialStep,
  selectPreviousStep,
  setCurrentStep
} from '../../../slices/loginSlice';
import SideModalWizardHeader from '../../SideModal/SideModalWizardHeader';

type TSideModalLogin = {
  closeModal?: () => void;
};

export const loginSteps = {
  LOGIN_EMAIL: 0,
  LOGIN_PASSWORD: 1,
  LOGIN_FORGOT: 2
};

const SideModalLogin = ({ closeModal }: TSideModalLogin) => {
  const [email, setEmail] = useState<string>('');
  const [prefilledPassword, setPrefilledPassword] = useState<string>('');

  const dispatch = useDispatch();

  const initialStep = useSelector(selectInitialStep);

  const currentStep = useSelector(selectCurrentStep);

  const onStepChange = (toStep: number, fromStep: number) => {
    dispatch(
      setCurrentStep({
        currentStep: toStep,
        previousStep: fromStep
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setCurrentStep({ currentStep: 0, previousStep: null }));
    };
  }, []);
  return (
    <styles.LoginWrapper>
      <AnimatePresence>
        <Wizard
          startIndex={initialStep}
          header={
            <SideModalWizardHeader
              closeSideModal={closeModal}
              isLogin={true}
              backArrowOnClick={() =>
                dispatch(
                  setCurrentStep({
                    currentStep: currentStep - 1,
                    previousStep: currentStep
                  })
                )
              }
              showBackArrow={Boolean(currentStep)}
            />
          }
        >
          <AnimatedStateStep
            stepComponent={
              <LoginEmail
                setEmail={setEmail}
                email={email}
                prefilledPassword={prefilledPassword}
                setPrefilledPassword={setPrefilledPassword}
              />
            }
            currentStepSelector={selectCurrentStep}
            previousStepSelector={selectPreviousStep}
            onStepChange={onStepChange}
          />
          <AnimatedStateStep
            stepComponent={
              <LoginPassword
                prefilledPassword={prefilledPassword}
                email={email}
              />
            }
            currentStepSelector={selectCurrentStep}
            previousStepSelector={selectPreviousStep}
            onStepChange={onStepChange}
          />

          <AnimatedStateStep
            stepComponent={
              <LoginForgotPassword setEmail={setEmail} email={email} />
            }
            currentStepSelector={selectCurrentStep}
            previousStepSelector={selectPreviousStep}
            onStepChange={onStepChange}
          />
        </Wizard>
      </AnimatePresence>
    </styles.LoginWrapper>
  );
};
export default SideModalLogin;
