import {
  SEND_MAIL_FAILED,
  SEND_MAIL_PENDING,
  SEND_MAIL_COMPLETED
} from '../../types/reducers/mail';

import makeReducer from '../makeReducer';

const initialState = {
  loading: false
};

const error = (state, action) => ({
  ...state,
  error: action.error
});

const ACTION_HANDLERS = {
  [SEND_MAIL_FAILED]: error,
  [SEND_MAIL_PENDING]: (state, action) => {
    return {
      ...state,
      loading: true
    };
  },
  [SEND_MAIL_COMPLETED]: (state, action) => {
    return {
      ...state,
      loading: false
    };
  }
};

export default makeReducer(ACTION_HANDLERS, initialState);
