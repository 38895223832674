import styled from 'styled-components';

export const CartLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.4);
  z-index: 10;
  display: grid;
  place-content: center;
`;
