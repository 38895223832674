'use strict'; // core-js polyfills, add more if needed
// https://github.com/zloirock/core-js

import 'core-js/es6/array';
import 'core-js/es6/string';
import 'core-js/es6/object';
import 'core-js/es6/math';
import 'core-js/es6/reflect';
import 'core-js/es7/array';
import 'core-js/es7/string';
import 'core-js/es7/object';
import 'core-js/modules/es7.promise.finally';
import 'core-js/es7/reflect';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();

  window.Promise = require('promise/lib/es6-extensions.js');
} // fetch() polyfill for making API calls.


require('whatwg-fetch'); // Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.


Object.assign = require('object-assign'); // In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.

if (process.env.NODE_ENV === 'test') {
  require('raf').polyfill(global);
}

window.matchMedia = window.matchMedia || function () {
  return {
    matches: false,
    addListener: function addListener() {},
    removeListener: function removeListener() {}
  };
};

if (window && window.Element && !window.Element.prototype.matches) {
  window.Element.prototype.matches = window.Element.prototype.msMatchesSelector || window.Element.prototype.webkitMatchesSelector;
}

if (window && window.Element && !window.Element.prototype.closest) {
  window.Element.prototype.closest = function (s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);

    return null;
  };
}