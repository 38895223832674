import { esalesRequest } from '../../lib/request';
import queryParams from '../../lib/queryParams';
import { Product } from '../../types/xim/product';
import { Recipe } from '../../types/xim/recipe';
import {
  PanelTypes,
  ProductTypes,
  ProductsRequestParams,
  EsalesProductPanelResponse,
  IEsalesRelatedRequestParams,
  IEsalesTopSellerRequestParams
} from '../../types/esales';

export const DEFAULT_PRODUCTPAGE_SIZE = 24;

export interface ProductPanelResponse {
  data: EsalesProductPanelResponse<Product>;
}

interface RecipePanelResponse {
  data: EsalesProductPanelResponse<Recipe>;
}

interface SingleProductResponse {
  data: Product[];
}

export const fetchProductById = (
  productId: string
): Promise<SingleProductResponse> =>
  esalesRequest(`/esales/pdp/${productId}/product`);

export const fetchProductsByIds = (
  productIds: string,
  sort?: string,
  size?: number
): Promise<SingleProductResponse> => {
  if (!productIds || productIds.length === 0) {
    return Promise.resolve({
      data: []
    });
  }
  const url =
    sort === 'mostBought'
      ? `/esales/products/${productIds}?Size=${size ?? ''}&keepOrder=true`
      : `/esales/products/${productIds}?Size=${size ?? ''}&Sort=${sort ?? ''}`;

  return esalesRequest(url);
};

export const fetchProductsByCategory = (
  categoryId: string,
  params: ProductsRequestParams = {}
): Promise<ProductPanelResponse> => {
  const opts = queryParams({
    size: DEFAULT_PRODUCTPAGE_SIZE,
    ...params,
    categoryId
  });
  // } as ProductsRequestParams);

  return esalesRequest(`/esales/products?${opts}`);
};

export const fetchProductsByTags = (
  raw: string,
  storeId: number,
  page: number
): Promise<ProductPanelResponse> => {
  return esalesRequest(
    `/esales/products?${raw}&store=${storeId ?? ''}&page=${page}`
  );
};

// we should replace this with a custom related endpoint for better result :)
export const fetchRelatedProducts = (
  params: IEsalesRelatedRequestParams,
  signal?: any
): Promise<ProductPanelResponse> => {
  const opts = queryParams({
    ...params
  });
  return esalesRequest(
    `/esales/pdp/${params.excludeProductId}/relatedproducts?${opts}`,
    signal
  );
};

export const fetchTopSellers = (
  params: IEsalesTopSellerRequestParams
): Promise<ProductPanelResponse> => {
  const opts = queryParams({
    ...params
  });
  return esalesRequest(`/esales/topsellers?${opts}`);
};

// TODO: pass the optional values into a settings object instead of queueing them in the func call.
// One should not need to pass in everything?

export const fetchProductsByPanel = (
  panelType: PanelTypes,
  productType: ProductTypes,
  storeId,
  page: number,
  size: number,
  productId?: string,
  excludeCategoryByCode?: number
): Promise<ProductPanelResponse> => {
  if (productId) {
    return esalesRequest(
      `/esales/pdp/${productId}/recommendedproducts?store=${storeId ??
        ''}&page=${page}&size=${size}&categorycode=${excludeCategoryByCode}`
    );
  }
  return esalesRequest(
    `/esales/recommendations/${panelType}/${productType}?store=${storeId ??
      ''}&page=${page}&size=${size}`
  );
};

export const fetchRecommendedProducts = (
  storeId,
  page: number,
  size: number,
  productId?: string,
  excludeCategoryByCode?: number,
  signal?: any
): Promise<ProductPanelResponse> => {
  return esalesRequest(
    `/esales/pdp/${productId}/recommendedproducts?store=${storeId ??
      ''}&page=${page}&size=${size}&categorycode=${excludeCategoryByCode}`,
    signal
  );
};

export const fetchRecipesByPanel = (
  panelType: PanelTypes,
  storeId,
  page: number,
  size: number
): Promise<RecipePanelResponse> => {
  return esalesRequest(
    `/esales/recommendations/${panelType}/recipes?store=${storeId ??
      ''}&page=${page}&size=${size}`
  );
};
