import config from '../../config/config';
import { identity } from '../../lib/utils';
import { makeCache, wrapRequest } from './cache';
import {
  request as jsonRequest,
  requestWithErrorToast as jsonRequestToast,
  defaultOpts
} from './json';
import { domSafe } from '../document';

let cachedAccessToken;
let cachedTokenType;
// TODO The timeout is required due to a noticed problem when unexpected network issues occur during server rendering
// The server basically hangs forever with no way to cancel the ongoing request.
// Needs investigation.
const TIMEOUT = 15000;

const cache = domSafe() ? null : makeCache();

const createError = (type, message) => {
  const err = new Error(message);
  err.name = type;
  err.code = type;

  return err;
};

const timeout = (url, timeout = 1000) =>
  new Promise((resolve, reject) => {
    let id = setTimeout(() => {
      clearTimeout(id);
      reject(
        createError(
          'ETIMEDOUT',
          `Request to ${url} took longer than ${timeout} to complete`
        )
      );
    }, timeout);
  });

const withTimeout = (req, reqUrl = '') =>
  domSafe() ? req : Promise.race([req, timeout(reqUrl, TIMEOUT)]);

export const configure = ({ access_token, token_type }) => {
  cachedAccessToken = access_token;
  cachedTokenType = token_type;
};

export const requestToast = (url, opts) => {
  const requestUrl = `${config.BASE_URL}${url}`;
  return withTimeout(jsonRequestToast(requestUrl, opts), requestUrl);
};

export const request = (url, opts) => {
  const requestUrl = `${config.BASE_URL}${url}`;
  return withTimeout(jsonRequest(requestUrl, opts), requestUrl);
};

export const externalRequestToast = (url, opts) =>
  withTimeout(jsonRequestToast(url, opts), url);
export const externalRequest = (url, opts) =>
  withTimeout(jsonRequest(url, opts), url);

export const requestRawToast = (url, opts) =>
  withTimeout(jsonRequestToast(url, opts), url);
export const requestRaw = (url, opts) =>
  withTimeout(jsonRequest(url, opts), url);

// Passthrough version of request raw
export const requestRawPassToast = (url, opts) =>
  withTimeout(jsonRequestToast(url, opts, identity), url);
export const requestRawPass = (url, opts) =>
  withTimeout(jsonRequest(url, opts, identity), url);

export const cachedRequestToast = wrapRequest(cache, requestToast);
export const cachedRequest = wrapRequest(cache, request);
export const externalCachedRequestToast = wrapRequest(
  cache,
  externalRequestToast
);
export const externalCachedRequest = wrapRequest(cache, externalRequest);

export const authenticatedRequestToast = (
  url,
  opts = {},
  req = requestToast
) => {
  // Skip checks when using debug flag
  opts = opts || {};
  if (config.DEBUG) {
    return req(url, opts);
  }

  if (!cachedTokenType || !cachedAccessToken) {
    return Promise.reject(
      new Error(
        'Missing token_type & access_token needed to perform this request'
      )
    );
  }

  const headers = { ...defaultOpts.headers, ...(opts.headers || {}) };

  const authedOpts = {
    ...opts,
    headers: {
      ...headers,
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
      Pragma: 'no-cache',
      'Cache-Control': 'no-store',
      Authorization: `${cachedTokenType} ${cachedAccessToken}`
    }
  };

  return req(url, authedOpts);
};

export const esalesRequest = (url, opts = {}, req = request) =>
  cachedAccessToken ? authenticatedRequest(url, opts, req) : request(url, opts);

export const offerRequest = (url, opts = {}, req = request) =>
  cachedAccessToken ? authenticatedRequest(url, opts, req) : request(url, opts);

export const authenticatedRequest = (url, opts = {}, req = request) => {
  // Skip checks when using debug flag
  opts = opts || {};
  if (config.DEBUG) {
    return req(url, opts);
  }

  if (!cachedTokenType || !cachedAccessToken) {
    return Promise.reject(
      new Error(
        'Missing token_type & access_token needed to perform this request'
      )
    );
  }

  const headers = { ...defaultOpts.headers, ...(opts.headers || {}) };

  const authedOpts = {
    ...opts,
    headers: {
      ...headers,
      'If-Modified-Since': 'Mon, 26 Jul 1997 05:00:00 GMT',
      Pragma: 'no-cache',
      'Cache-Control': 'no-store',
      Authorization: `${cachedTokenType} ${cachedAccessToken}`
    }
  };

  return req(url, authedOpts);
};

export default requestToast;
