import React, { useEffect, useState, useMemo } from 'react';
import ProductCarousel from '../../components/ProductCarousel';
import ProductArticleSkeleton from '../../components/ProductArticleSkeleton';
import OfferCard from '../../components/OfferCard';
import { TOffer } from '../../types/offers/offers';

type TOfferCardList = {
  allRows?: boolean;
  colStructure?: string;
  isCarousel?: boolean;
  loading: boolean;
  maxItems: number;
  offers?: TOffer[];
  storeId?: number;
};

export function OfferCardList({
  allRows,
  colStructure,
  isCarousel,
  loading,
  maxItems,
  offers
}: TOfferCardList) {
  const [offersToShow, setOffersToShow] = useState<TOffer[]>([]);

  useEffect(() => {
    if (!offers?.length) {
      setOffersToShow([]);
      return;
    }
    setOffersToShow(allRows ? offers : offers.slice(0, maxItems));
  }, [offers]);

  const offerCard = (offer: TOffer) => (
    <OfferCard
      colStructure={colStructure}
      key={offer.id}
      onCarousel={isCarousel}
      offer={offer}
    />
  );

  const ItemCardList = useMemo(() => {
    if (offersToShow.length === 0) return null;

    return offersToShow.map(offer => offerCard(offer));
  }, [offersToShow]);

  const Carousel = useMemo(() => {
    if (offersToShow.length === 0) return null;

    return (
      <ProductCarousel productsLength={offersToShow.length}>
        {ItemCardList}
      </ProductCarousel>
    );
  }, [isCarousel, ItemCardList]);

  if (offers?.length === 0 || loading)
    <ProductArticleSkeleton
      loading={loading}
      productsLength={offers?.length || 0}
      maxItems={maxItems}
    />;

  return isCarousel ? <>{Carousel}</> : <>{ItemCardList}</>;
}

export default OfferCardList;
