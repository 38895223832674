import Cookies from 'universal-cookie';
import { DeliveryMethods } from '../types/assortments';
import { addYears } from 'date-fns';
import { isNumber } from './number';
export const MIN_POSTAL_CODE_LENGTH = 4;

const cookieStorage = new Cookies();
export const ASSORTMENT_COOKIE = 'assortment_values';
export const STORE_COOKIE = 'store';

export const getCookie = (name: string) => {
  return cookieStorage.get(name);
};

interface IAssormentCookie {
  storeId?: number;
  deliveryType?: DeliveryMethods;
  siteId?: number | string;
  zipCode?: string;
}
const DEFAULT_ASSORTMENT_COOKIE = {
  userSiteId: '',
  deliveryType: '',
  userPostalCode: ''
};

export const getAssortmentCookie = () => {
  return getCookie(ASSORTMENT_COOKIE) ?? DEFAULT_ASSORTMENT_COOKIE;
};

export const getStoreCookie = () => {
  return getCookie(STORE_COOKIE);
};

export const setAssortmentCookie = async ({
  storeId,
  deliveryType,
  siteId,
  zipCode
}: IAssormentCookie) => {
  try {
    const cacheValues = getAssortmentCookie();
    if (deliveryType) {
      cacheValues.deliveryType = deliveryType;
    }
    if (siteId) {
      cacheValues.userSiteId = siteId;
    }
    if (
      zipCode ||
      !isNumber(cacheValues.userPostalCode) ||
      cacheValues.userPostalCode.length < MIN_POSTAL_CODE_LENGTH
    ) {
      delete cacheValues.userPostalCode;
      cacheValues.userPostalCode = zipCode || '';
    }
    if (storeId) {
      await cookieStorage.set(STORE_COOKIE, storeId, {
        path: '/',
        expires: addYears(new Date(), 2)
      });
    }
    await cookieStorage.set(ASSORTMENT_COOKIE, cacheValues, {
      path: '/',
      expires: addYears(new Date(), 2)
    });
  } catch (e) {
    console.error('error setting assortment');
  }
};
