/**
 * Calculates amount of kilometers between two coordinates
 *
 * @param {number} lat1 First pointer latitude
 * @param {number} lon1 First pointer longitude
 * @param {number} lat2 Second pointer latitude
 * @param {number} lon2 Second pointer longitude
 * @return {number} The calculated distance in kilometers
 */

export const distanceBetweenCoordinates = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    var R = 6371; // Radius of the earth in km
    var dLat = (lat2 - lat1) * Math.PI / 180;  // deg2rad below
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = 
       0.5 - Math.cos(dLat)/2 + 
       Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
       (1 - Math.cos(dLon))/2;
  
    return R * 2 * Math.asin(Math.sqrt(a));
}
