import {
  TABS,
  TOGGLE_ACTIVE,
  ADD_ACTIVE_RECIPE,
  SET_MODE,
  SET_VISIBILITY,
  REMOVE_ACTIVE_RECIPE,
  UNTOGGLE_BEHAVIOUR
} from '../../types/reducers/shoppingOrder';

export const setMode = (
  toggleable,
  visible,
  behaviour = UNTOGGLE_BEHAVIOUR.none
) => {
  return {
    type: SET_MODE,
    toggleable,
    visible,
    unToggleBehaviour: behaviour
  };
};

export function setVisibility(visible, behaviour = UNTOGGLE_BEHAVIOUR.none) {
  return {
    type: SET_VISIBILITY,
    visible,
    unToggleBehaviour: behaviour
  };
}

export function toggleActive(active) {
  if (active !== TABS.cart && active !== TABS.delivery) {
    console.error(`${active} not a supported tab`);
  }

  return {
    type: TOGGLE_ACTIVE,
    active
  };
}

export function toggleActiveRecipe(active, id) {
  return active ? addActiveRecipe(id) : removeActiveRecipe(id);
}

export function addActiveRecipe(id) {
  return {
    type: ADD_ACTIVE_RECIPE,
    id
  };
}

export function removeActiveRecipe(id) {
  return {
    type: REMOVE_ACTIVE_RECIPE,
    id
  };
}
