import React from 'react';
import { hydrate } from 'react-dom';
import config from './config/config';
import { ConnectedRouter } from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
import { Provider } from 'react-redux';
import App from './containers/App';
import ScrollToTopHoc from './components/ScrollToTopHoc';
import configureStore from './store/configure-store';
import { configure as configureAPI } from './lib/request';
import { configure as configureAuth } from './lib/auth';
import authReducer from './reducers/auth';
import { sw_update_activated } from './actions/serviceWorker';
import serviceWorker from './registerServiceWorker';
import { sendTracking } from './lib/analytics/analytics';
import { ErrorBoundary } from './components/Error';
import './index.scss';
import { polyfill } from 'es6-promise';
import 'isomorphic-fetch';
import { ThemeProvider } from 'styled-components';
import { theme } from '@citygross/design-tokens'
require('intersection-observer');
// run promsie polyfill which will attach to global
// the promise polyfill & fetch lib will be used on the server as well
polyfill();

const { AUTH_STORAGE_KEY, SERVICE_WORKER_ACTIVE } = config;

const authState = configureAuth(
  AUTH_STORAGE_KEY,
  configureAPI,
  authReducer(undefined, {})
);

const initialState = Object.assign(window['SERVER_DATA'] || {}, {
  auth: authState
});

const history = createHistory();
// Remove double(or more) slashes in the url
history.location.pathname = history.location.pathname.replace(/\/{2,}/g, '/');
const store = configureStore(history, initialState);

hydrate(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTopHoc>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>

          <App />
          </ThemeProvider>
        </ErrorBoundary>
      </ScrollToTopHoc>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

console.log(
  `Service worker is: ${SERVICE_WORKER_ACTIVE ? 'enabled' : 'disabled'}`
);

SERVICE_WORKER_ACTIVE
  ? serviceWorker.register({
      onRegistration: () =>
        sendTracking({
          event: 'sw_registered',
          currentVersion: config.version
        }),
      onUpdateFound: () =>
        sendTracking({
          event: 'sw_update_found',
          currentVersion: config.version
        }),
      onUpdateInstalled: () =>
        sendTracking({
          ecent: 'sw_update_installed',
          currentVersion: config.version
        }),
      onActivated: () => store.dispatch(sw_update_activated())
    })
  : serviceWorker.unregister();

console.log(
  `%cCITYGROSS%c VERSION: ${config.VERSION}`,
  'background: #ffe522;color:#0067B2;padding:5px;border-radius: 5px;font-weight:800;line-height: 26px;',
  ''
);
