import React, { useEffect, useRef } from "react"
import withBaseBlock from "../../components/BaseBlock"
import { TagAttributeRegex } from "../../constants/TagAttributeRegex"
import { IScriptBlockProps } from "../../types/episerver/blocks/ScriptBlock"

type TKeyValuePair = {
    key: string,
    value?: string
}

const getScriptBody = (script: string) => {
    var scriptBody = new DOMParser().parseFromString(script, 'text/html');
    var scriptContent = scriptBody.head.firstChild?.textContent;
    return scriptContent ?? '';
}

const getScriptAttributes = (script: string) => {
    let m;
    let kvpArray: TKeyValuePair[] = [];
    let keyIndex = 0;

    while ((m = TagAttributeRegex.exec(script)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === TagAttributeRegex.lastIndex) {
            TagAttributeRegex.lastIndex++;
        }
        
        m.forEach((match: string, groupIndex: number) => {
            if (groupIndex === 1) {
                kvpArray.push({ key: match });
            } else if (groupIndex === 2) {
                kvpArray[keyIndex].value = match;
                keyIndex++;
            }
        });
    }
    return kvpArray;
}

const ScriptBlock: React.FC<IScriptBlockProps>  = ({ script }) => {
    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!script || !ref.current)
            return;
        const docScript = document.createElement('script');
        const kvpArray = getScriptAttributes(script);

        kvpArray.forEach(item => {
            docScript.setAttribute(item.key, item.value ?? '');
        })
        
        docScript.async = true;
        docScript.innerHTML = getScriptBody(script);

        ref.current.appendChild(docScript);
        return () => {
          ref.current?.removeChild(docScript);
        }
    }, [script, ref]);
    return (
        <div ref={ref} />
    );
}

export default withBaseBlock(ScriptBlock);

