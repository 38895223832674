import React, { useEffect, useState } from 'react';
import { fetchLeaflets } from '../../api/endpoints/offer';
import { useSelector } from 'react-redux';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { TLeaflet } from '../../types/offers/offers';
import { Leaflets, Leaflet } from '../../components/Leaflets';

export const LeafletBlock = () => {
  const store = useSelector(selectCurrentAssortment);
  const [leaflets, setLeaflets] = useState<TLeaflet[]>([]);

  const fetchBlockLeaflets = async () => {
    try {
      const leafletResponse = await fetchLeaflets();

      if (leafletResponse?.data) {
        setLeaflets(leafletResponse.data);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e, 'error fetching leaflets');
    }
  };

  useEffect(() => {
    if (!store) return;
    fetchBlockLeaflets();
  }, [store]);

  return store ? <Leaflets leaflets={leaflets} /> : <Leaflet />;
};
