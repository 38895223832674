import React from 'react';
import RecipeList from '../../../components/RecipeList';
const RecipeView = ({
  items,
  pagination,
  requestMoreRecipes,
  fetching,
  isAuthenticated,
  setRecipeFavorite,
  removeFavorite,
  redirectToLogin
}) => {
  const setRecipeFavoriteFn = isAuthenticated
    ? setRecipeFavorite
    : redirectToLogin;
  const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

  return (
    <RecipeList
      fetching={fetching}
      requestMoreRecipes={requestMoreRecipes}
      setRecipeFavorite={setRecipeFavoriteFn}
      removeFavorite={removeFavoriteFn}
      pagination={pagination}
      items={items}
    />
  );
};
export default RecipeView;
