import React from 'react';
import Breadcrumbs from '../../Breadcrumbs';
import './AccountHeader.scss';

const AccountHeader : React.FC = ({ children }) => (
  <header className="c-account-header">
    <Breadcrumbs />
    <div className="c-account-header-inner">{children}</div>
  </header>
);

export default AccountHeader;
