import { esalesRequest } from '../../lib/request';
import queryParams from '../../lib/queryParams';
export const DEFAULT_PRODUCTPAGE_SIZE = 120;

export const fetchCateringProductById = (productId: string) =>
  esalesRequest(`/esales/cateredmeal/${productId}`);

export const fetchCateringProductsByCategory = (categoryId:string, params = {}) => {
  const opts = queryParams({
    size: DEFAULT_PRODUCTPAGE_SIZE,
    ...params,
    categoryId
  });

  return esalesRequest(`/esales/cateredmeals?${opts}`);
};

export const fetchCateringProductsByVariant = (ids: string) => {
  return esalesRequest(`/esales/cateredmeals?variantKey=${ids}`);
}
