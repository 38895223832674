import { LOGOUT_SUCCESS } from '../../types/reducers/auth';
import {
  FETCH_ORDERS,
  FETCH_ORDERS_RECEIVED,
  FETCH_ORDERS_FAILED,
  ORDER_CREATED,
  ORDER_DELETED,
  ORDER_INITIAL_LOAD,
  ORDERS_LOADED
} from '../../types/reducers/orders';
import { CART_RECEIVED, DELETE_USABLE_CARTS } from '../../types/reducers/cart';

import { CLEAR_ITEMS } from '../../types/reducers/cart';
import { OrdersState } from '../../types/reducers/orders';

const initialState: OrdersState = {
  orders: [],
  carts: [],
  loading: false,
  initialLoad: true,
  replacementOrder: null
};

export default function ordersReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //AssortmentsActionTypes
): OrdersState {
  switch (action.type) {
    case FETCH_ORDERS: {
      return {
        ...state,
        loading: true
      };
    }
    case ORDERS_LOADED: {
      return {
        ...state,
        loading: false
      };
    }
    case ORDER_INITIAL_LOAD: {
      return {
        ...state,
        initialLoad: false
      };
    }
    case FETCH_ORDERS_RECEIVED: {
      const { orders, carts } = action;
      return {
        ...state,
        orders,
        carts,
        loading: action.loading
      };
    }
    case FETCH_ORDERS_FAILED: {
      return {
        ...state,
        loading: false
      };
    }
    case CART_RECEIVED: {
      return {
        ...state,
        carts: [action.payload.cart, ...state.carts],
        loading: false
      };
    }
    case CLEAR_ITEMS: {
      return {
        ...state,
        carts: state.carts.filter(cart => cart.id !== action.id)
      };
    }
    case DELETE_USABLE_CARTS: {
      return {
        ...state,
        carts: []
      };
    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
}
