import { createSelector } from 'reselect';
import compareAsc from 'date-fns/compareAsc';
import { findNextAfterThis } from '../lib/cart';

const getOrders = state => state.order.orders;
const getCarts = state => state.order.carts;
const getCurrentCart = state => state.cart.meta.id;
const getWantedOrder = (_, orderId) => orderId;
export const isFetchingOrders = state => state.order.loading === true;

export const totalOrderAmount = ({ cartReferences }) => {
  return cartReferences.reduce((sum, { paymentDetails }) => {
    const filteredPaymentDetails = paymentDetails.sort(
      (a, b) => b.orderVersionIndex - a.orderVersionIndex
    );

    return (
      sum +
      (filteredPaymentDetails.length
        ? filteredPaymentDetails[0].totalAmount
        : 0)
    );
  }, 0);
};

const sortOrder = ({ date: a }, { date: b }) => {
  return compareAsc(new Date(a), new Date(b));
};

const mapOrder = order => ({
  ...order,
  totalAmount: totalOrderAmount(order)
});

export const orderByIdSelector = createSelector(
  [getOrders, getWantedOrder],
  (orders, wantedId) => {
    return orders.find(order => order.orderNo === wantedId);
  }
);
export const orderByOrderNoSelector = createSelector(
  [getOrders, getWantedOrder],
  (orders, wantedId) => {
    return orders.find(order => order.orderNo === wantedId);
  }
);

export const completedOrders = createSelector([getOrders], orders =>
  orders.map(mapOrder).sort(sortOrder)
);

export const upcomingOrders = createSelector([getOrders], orders =>
  orders
    .filter(order => order.checkoutCompletedDate)
    .map(mapOrder)
    .sort(sortOrder)
);

export const upcomingOrderCount = createSelector(
  [upcomingOrders],
  orders => orders.length
);

export const nextCartSelector = createSelector(
  [getCarts, getCurrentCart],
  findNextAfterThis
);
