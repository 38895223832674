import * as styles from '../SideModalDeliveryChoicePicker.styles';
import React, { useEffect, useState } from 'react';
import {
  Spacer,
  Link as CgLink,
  RadioBoxItem as RadioBox,
  SaveButton
} from '@citygross/components';
// eslint-disable import/named
// eslint-disable-next-line import/namespace
import { Icons } from '@citygross/icons';
import { theme } from '@citygross/design-tokens';
import { BodyText, TextTypes } from '@citygross/typography';
import {
  DeliveryMethods,
  deliveryPickerSteps
} from '../SideModalDeliveryChoicePicker';
import { useWizard } from '@citygross/react-use-bg-wizard';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../reducers';

import {
  selectActiveDeliveryMethod,
  selectActivePostalCode,
  selectActiveStore,
  selectInitialLoading,
  selectSaveDeliverySettingsLoading,
  setActiveDeliveryMethodAndStore
} from '../../../../slices/deliveryPickerSlice';
import { StoreMeta } from '../../../../types/assortments';
import { closeSideModal } from '../../../../slices/sideModalSlice';
import { userSelector } from '../../../../selectors/user';
import { fetchClosestStoreByZip } from '../../../../api/endpoints/delivery';

const formatZipCode = zipCode => {
  if (zipCode) {
    const zipcode = zipCode.toString();
    return zipcode.match(/^[0-9]{3} [0-9]{2}$/gm)
      ? zipcode
      : `${zipcode.slice(0, 3)} ${zipcode.slice(3, 5)}`;
  }
  return zipCode;
};

const generateLoadingRadioBoxes = (amount: number) => {
  return Array.from(Array(amount).keys()).map(i => (
    <RadioBox name={i.toString()} key={i} padding={16} hideRadioButton>
      <div style={{ width: '100%' }}>
        <>
          <BodyText textAlign="center" size={TextTypes.TextSize.SMALL}>
            <Skeleton />
          </BodyText>
          <BodyText textAlign="center" size={TextTypes.TextSize.SMALL}>
            <Skeleton />
          </BodyText>
        </>
      </div>
    </RadioBox>
  ));
};

interface IDeliveryMethodOptionsProps {
  goToStep: any;
  handleSaveNewDeliverySettings: () => void;
  savedNewStore?: boolean;
}

const DeliveryMethodOptions: React.FC<IDeliveryMethodOptionsProps> = ({
  goToStep,
  handleSaveNewDeliverySettings,
  savedNewStore
}) => {
  const [recommendedStoresLoading, setRecommendedStoresLoading] = useState(
    false
  );
  const [storesList, setStoresList] = useState<StoreMeta[]>([]);

  const dispatch = useDispatch();

  const stores = useSelector((state: AppState) => state.assortments.stores);

  const activePostalCode = useSelector(selectActivePostalCode);
  const activeDeliveryMethod = useSelector(selectActiveDeliveryMethod);
  const activeStore = useSelector(selectActiveStore);
  const user = useSelector(userSelector);
  const initialLoading = useSelector(selectInitialLoading);
  const saveDeliverySettingsLoading = useSelector(
    selectSaveDeliverySettingsLoading
  );

  const { activeStep: stepKey } = useWizard();
  const buttonDisabled =
    !activeDeliveryMethod ||
    (activeDeliveryMethod === DeliveryMethods.PICKUP && !activeStore);

  const populateStoresList = stores => {
    if (stores) {
      let storeList = stores?.filter(store => store?.storeNumber);

      storeList = storeList.slice(0, 3);
      setStoresList(storeList);
    }
  };

  const getStores = async (postcode: string) => {
    if (postcode || user?.deliveryAddress?.zip) {
      setRecommendedStoresLoading(true);

      const closestStores = await fetchClosestStoreByZip(
        postcode || user?.deliveryAddress?.zip?.toString()
      );
      let recStores: StoreMeta[] = [];
      const { data } = closestStores;
      data.forEach(recStore => {
        const storeData = stores?.find(
          store => Number(store.storeNumber) === Number(recStore.store)
        );

        if (storeData) {
          recStores.push(storeData);
        }
      });

      if (recStores.length === 0 || !closestStores?.data)
        recStores = stores || [];
      populateStoresList(recStores);

      setRecommendedStoresLoading(false);
    } else {
      populateStoresList(stores);
      setRecommendedStoresLoading(false);
    }
  };

  useEffect(() => {
    getStores(activePostalCode || user?.deliveryAddress?.zip);
  }, [activePostalCode, stores]);

  return (
    <>
      <styles.DeliveryMethodOptionsWrapper>
        <styles.DeliveryMethodOptionsColumn>
          <BodyText textAlign="center" fontWeight={'medium'}>
            Hämta Själv
          </BodyText>
          <Spacer lgSpacing="xs" />
          <styles.DeliveryDetail>
            <Icons.CheckMark
              width={16}
              height={16}
              color={theme.palette?.alertGreen}
            />{' '}
            <BodyText textAlign="center" size={TextTypes.TextSize.SMALL}>
              Matvaror
            </BodyText>
          </styles.DeliveryDetail>
          <styles.DeliveryDetail>
            <Icons.CheckMark
              width={16}
              height={16}
              color={theme.palette?.alertGreen}
            />{' '}
            <BodyText textAlign="center" size={TextTypes.TextSize.SMALL}>
              Recept
            </BodyText>
          </styles.DeliveryDetail>
          <styles.DeliveryDetail>
            <Icons.CheckMark
              width={16}
              height={16}
              color={theme.palette?.alertGreen}
            />{' '}
            <BodyText textAlign="center" size={TextTypes.TextSize.SMALL}>
              Catering
            </BodyText>
          </styles.DeliveryDetail>

          <Spacer lgSpacing="sm" />
          <styles.StoreGrid>
            {(initialLoading || !storesList || recommendedStoresLoading) &&
              generateLoadingRadioBoxes(3)}
            {!initialLoading &&
              !recommendedStoresLoading &&
              storesList &&
              storesList.map(store => (
                <RadioBox
                  name={'pickupAtStore'}
                  key={store.id}
                  checked={
                    activeDeliveryMethod === DeliveryMethods.PICKUP &&
                    activeStore?.id === store.id
                  }
                  setActive={() => {
                    dispatch(
                      setActiveDeliveryMethodAndStore({
                        activeDeliveryMethod: DeliveryMethods.PICKUP,
                        activeStore: store
                      })
                    );
                  }}
                  padding={16}
                  hideRadioButton
                  isDisabled={saveDeliverySettingsLoading}
                >
                  <div style={{ width: '100%' }}>
                    <>
                      <BodyText
                        textAlign="center"
                        size={TextTypes.TextSize.SMALL}
                      >
                        City Gross
                      </BodyText>
                      <BodyText
                        textAlign="center"
                        size={TextTypes.TextSize.SMALL}
                      >
                        {store.name}
                      </BodyText>
                    </>
                  </div>
                </RadioBox>
              ))}
          </styles.StoreGrid>
          <Spacer lgSpacing="sm" />
          <CgLink
            onClick={() =>
              goToStep(deliveryPickerSteps.SHOW_ALL_STORES, stepKey)
            }
            isDisabled={initialLoading || saveDeliverySettingsLoading}
          >
            Alla butiker
          </CgLink>
        </styles.DeliveryMethodOptionsColumn>
      </styles.DeliveryMethodOptionsWrapper>
      <Spacer xsSpacing={'lg'} lgSpacing={'sm'} />
      <styles.DeliverySaveButtonContainer position={'absolute'}>
        <SaveButton
          onSavedColor={theme.palette?.alertGreen}
          fullWidth
          onClick={handleSaveNewDeliverySettings}
          afterSaveText="Sparat"
          beforeSaveText="Spara"
          onAnimationComplete={() => dispatch(closeSideModal())}
          icon={<Icons.CheckMark width={16} height={16} color={'white'} />}
          saved={savedNewStore}
          isDisabled={buttonDisabled}
          loading={saveDeliverySettingsLoading && !savedNewStore}
        />
      </styles.DeliverySaveButtonContainer>
    </>
  );
};

const ChooseDeliveryMethodStep = ({
  handleSaveNewDeliverySettings,
  savedNewStore,
  ...props
}) => {
  const { goToStep, stepKey } = props;

  const activePostalCode = useSelector(selectActivePostalCode);

  const initialLoading = useSelector(selectInitialLoading);
  const user = useSelector(userSelector);
  return (
    <>
      <styles.ChooseDeliveryMethodSideModalContainer>
        <div style={{ maxWidth: 220 }}>
          {user && (activePostalCode || user?.deliveryAddress?.zip) ? (
            <BodyText textAlign="center">
              Till postnummer{' '}
              {initialLoading ? (
                <Skeleton width={45} />
              ) : (
                <CgLink
                  onClick={() =>
                    goToStep(deliveryPickerSteps.ENTER_POSTAL_CODE, stepKey)
                  }
                >
                  {formatZipCode(
                    activePostalCode || user?.deliveryAddress?.zip
                  )}
                </CgLink>
              )}{' '}
              erbjuder vi följande
            </BodyText>
          ) : (
            <BodyText textAlign="center">
              {initialLoading ? (
                <Skeleton width={45} />
              ) : (
                <CgLink
                  onClick={() =>
                    goToStep(deliveryPickerSteps.ENTER_POSTAL_CODE, stepKey)
                  }
                >
                  Ange postnummer
                </CgLink>
              )}
            </BodyText>
          )}
        </div>
        <Spacer xsSpacing={'lg'} lgSpacing={'lg'} />
        <DeliveryMethodOptions
          goToStep={goToStep}
          handleSaveNewDeliverySettings={handleSaveNewDeliverySettings}
          savedNewStore={savedNewStore}
        />
      </styles.ChooseDeliveryMethodSideModalContainer>
    </>
  );
};

export default ChooseDeliveryMethodStep;
