import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './DropArrow.scss';

interface IProps {
  direction?: string | null;
  small?: boolean;
  onClick?: React.EventHandler<React.MouseEvent>;
  clickable?: boolean;
  className?: string;
}

export const DropArrow: React.FC<IProps> = ({
  direction,
  small,
  onClick,
  clickable,
  className
}) => (
    <span
      onClick={onClick}
      className={classNames(
        'c-droparrow',
        direction,
        { small, clickable },
        className
      )}
    />
  );

export default DropArrow;
