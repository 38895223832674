import {
  TOGGLE_MODAL,
  TOGGLE_ASSORTMENT_INFO_MODAL,
  TOGGLE_LAST_SELECTED,
  RECEIVE_STORE_INFORMATIONS,
  TOGGLE_MENU_TAB,
  AssortmentsState,
  SET_ACTIVE_ASSORTMENT,
  SET_INITIAL_ASSORTMENT_VALUES,
  SET_ACTIVE_DELIVERYMETHOD,
  SetAssortmentAction,
  SetInitialAssortmentValuesAction,
  SetToggleModalActiveAction,
  SetToggleInfoModalActiveAction,
  SetDeliveryMethodAction,
  SetAssortmentLoadingAction,
  ASSORTMENTS_LOADING,
  SetSitesLoadingAction,
  SITES_LOADING,
  SetLastSelectedAction
} from "../../types/reducers/assortments";

const initialState: AssortmentsState = {
  siteId: null,
  homeSiteId: null,
  deliveryMethod: null,
  homeDeliveryAssortmentValues: null,
  zipCode: null,
  storeNo: null,
  storeProvider: null,
  menuTabActive: false,
  storePickerModalActive: false,
  assortmentInfoModalActive: false,
  stores: null,
  epiStores: null,
  assortmentLoading: false,
  sitesLoading: false,
  lastSelectedActive: false
};

export default function assortmentsReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //AssortmentsActionTypes
): AssortmentsState {
  switch (action.type) {
    case SITES_LOADING: {
      const { loading }: SetSitesLoadingAction = action;
      return {
        ...state,
        sitesLoading: loading
      }
    }
    case ASSORTMENTS_LOADING: {
      const { loading }: SetAssortmentLoadingAction = action;
      return {
        ...state,
        assortmentLoading: loading
      }
    }
    case TOGGLE_MODAL: {
      const { active }: SetToggleModalActiveAction = action;
      return {
        ...state,
        storePickerModalActive: active
      }
    }

    case TOGGLE_ASSORTMENT_INFO_MODAL: {
      const { active }: SetToggleInfoModalActiveAction = action;

      return {
        ...state,
        assortmentInfoModalActive: active
      }
    }

    case TOGGLE_LAST_SELECTED: {
      const { active }: SetLastSelectedAction = action;

      return {
        ...state,
        lastSelectedActive: active
      }
    }

    case TOGGLE_MENU_TAB: {
      return {
        ...state,
        menuTabActive: !state.menuTabActive,
        lastSelectedActive: false
      }
    }
    case RECEIVE_STORE_INFORMATIONS: {
      return {
        ...state,
        stores: action.stores,
        epiStores: action.epiStores
      }
    }
    case SET_INITIAL_ASSORTMENT_VALUES: {
      const { deliveryType, zipCode, siteId }: SetInitialAssortmentValuesAction = action;
      return {
        ...state,
        deliveryMethod: deliveryType,
        zipCode,
        siteId
      }
    }
    case SET_ACTIVE_ASSORTMENT: {
      // TODO, type the entire reducer.... Need to upgrade typescript
      const { deliveryType, siteId, homeSiteId, zipCode, storeNo, storeProvider, sortimentOptions }: SetAssortmentAction = action;

      return {
        ...state,
        deliveryMethod: deliveryType,
        siteId,
        homeSiteId,
        zipCode,
        storeNo,
        storeProvider,
        homeDeliveryAssortmentValues: sortimentOptions ? sortimentOptions : null,
      }
    }
    case SET_ACTIVE_DELIVERYMETHOD: {
      const { deliveryType }: SetDeliveryMethodAction = action;

      return {
        ...state,
        deliveryMethod: deliveryType
      }

    }
    default:
      return state;
  }
}
