import { IExtendedProduct } from "../types/storefront/product";

export const isLimitedStock = (product: IExtendedProduct ) => {
    
    if(product.tags){
        return Boolean(product.tags.filter(item => 
            item.namespace === 'LIMITED' && item.name === 'STOCK')
            .length)
    }
    return false
}