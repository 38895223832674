import React from 'react';

import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { pageSelector } from '../../selectors/page';
import { useRouteMatch } from 'react-router-dom';
import { Store } from '../../types/episerver/store';
import StoreOpeningHours from '../../components/StoreOpeningHours';

const OpeningHoursPlaceholderBlock: React.FC = () => {
  const match = useRouteMatch();
  const store: Store | null = useSelector((state: AppState) =>
    pageSelector(state, { match })
  );

  return store ? <StoreOpeningHours store={store} /> : <></>;
};

export default OpeningHoursPlaceholderBlock;
