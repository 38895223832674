import React from 'react';
import CartGroup from '../CartGroup';
import { normalizeRecipeProps } from '../utils';
import { RecipeCartItem } from './RecipeCartItem.tsx';

const RecipeGroup = ({ recipes, showHeader = true }) => {
  return recipes && recipes.length ? (
    <CartGroup name={showHeader && 'Recept'}>
      {recipes.map((recipe, idx) => {
        const normalizedRecipe = normalizeRecipeProps(recipe);
        return (
          <RecipeCartItem
            {...normalizedRecipe}
            quantity={normalizedRecipe.quantity}
            isLast={idx === recipes?.length - 1}
          />
        );
      })}
    </CartGroup>
  ) : null;
};

export default RecipeGroup;
