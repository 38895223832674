import React from 'react';
import PropTypes from 'prop-types';
import { ImagePropType, LinkPropType } from '../../../prop-types';
import {
  LazyProgressivePicture,
  ImageFitTypes
} from '../../ProgressivePicture';
import Link from '../../Link';
import './SimpleTeaser.scss';

const SimpleTeaser = ({ title, subtitle, image, link, onClick }) =>
  link && link.url ? (
    <Link
      link={link}
      className="c-simple-teaser c-simple-teaser--has-link"
      onClick={onClick}
    >
      <div className="c-simple-teaser__image">
        <LazyProgressivePicture
          largeImage={image}
          fitType={ImageFitTypes.Cover}
          inheritHeight
          maxWidth={{
            large: 930,
            small: 767
          }}
        />
      </div>
      {title && (
        <div className="c-simple-teaser__text">
          <div className="c-simple-teaser__title">{title}</div>
          {subtitle ? (
            <div className="c-simple-teaser__subtitle">{subtitle}</div>
          ) : null}
        </div>
      )}
    </Link>
  ) : (
    <div className="c-simple-teaser">
      <div className="c-simple-teaser__image">
        <LazyProgressivePicture
          largeImage={image}
          fitType={ImageFitTypes.Cover}
          inheritHeight
          maxWidth={{
            large: 930,
            small: 767
          }}
        />
      </div>
      {title && (
        <div className="c-simple-teaser__text">
          <div className="c-simple-teaser__title">{title}</div>
          {subtitle ? (
            <div className="c-simple-teaser__subtitle">{subtitle}</div>
          ) : null}
        </div>
      )}
    </div>
  );

SimpleTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: ImagePropType.isRequired,
  link: LinkPropType,
  onClick: PropTypes.func
};

export default SimpleTeaser;
