export const ImageFitTypes = Object.freeze({
  Cover: 'cover',
  Contain: 'contain'
});

export const ImageFitPositions = Object.freeze({
  Left: 'left',
  Right: 'right',
  Bottom: 'bottom',
  Top: 'top',
  Center: 'center'
});
