import React, { useEffect, useState, useRef } from 'react';
import { withRouter, RouteComponentProps, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import OverlayRenderer from './OverlayRenderer';
import { isIE11 } from '../../lib/utils';

import './Overlay.scss';

interface IProps extends RouteComponentProps {
  show: boolean;
  onClick: Function;
  includeHeader: boolean;
  modal: {
    isToggled: boolean;
  };
}

const Overlay: React.FC<IProps> = ({ includeHeader, modal, show, onClick }) => {
  const [prevPosition, setPrevPosition] = useState(0);
  const location = useLocation();
  const locationWhenOpenRef = useRef<string | null>(null);

  const disableBodyScroll = position => {
    const rootSelector = document.querySelector('body');
    if (rootSelector) {
      rootSelector.style.overflowY = 'scroll';
      rootSelector.style.position = 'fixed';
      rootSelector.style.top = -position + 'px';
    }
  };

  const enableBodyScroll = position => {
    const rootSelector = document.querySelector('body');

    if (rootSelector) {
      rootSelector.style.removeProperty('overflowY');
      rootSelector.style.removeProperty('position');
      rootSelector.style.removeProperty('top');
      if (
        location.pathname === locationWhenOpenRef.current ||
        !locationWhenOpenRef.current
      ) {
        window.scrollTo(0, position);
      } else {
        window.scrollTo(0, 0);
      }
      locationWhenOpenRef.current = location.pathname;
    }
  };

  useEffect(() => {
    const position = window.scrollY;

    if (!isIE11()) {
      if (show) {
        setPrevPosition(position);
        disableBodyScroll(position);
      } else {
        enableBodyScroll(prevPosition);
      }
    }
  }, [show]);

  useEffect(() => {
    locationWhenOpenRef.current = location.pathname;
  }, []);

  return (
    <CSSTransition
      key="overlay"
      in={show}
      timeout={200}
      classNames="c-overlay"
      appear={true}
      unmountOnExit
    >
      <OverlayRenderer
        includeHeader={includeHeader}
        includeFlyout={modal && modal.isToggled}
        onClick={onClick}
      />
    </CSSTransition>
  );
};

export default withRouter(Overlay);
