import React from 'react';
import './Badge.scss';

export const Badge : React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({ children, ...rest }) => (
  <span className="badge" {...rest}>
    {children}
  </span>
);

export default Badge;
