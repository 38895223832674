import { createSelector } from 'reselect';
import { AppState } from '../reducers';

const getAppSettings = (state: AppState) => state.app.settings;
const getAppSettingsLoading = (state: AppState) => state.app.settingsLoading;
export const selectPortalOverlayToggle = (state: AppState) =>
  state.app.portalOverlay.isToggled;

// TODO @Stefan
// Add AppState when navigation state is typed
const getNavigationHasFetched = (state: any) =>
  state.navigation.hasFetched === true || false;

export const appSettingsSelector = createSelector(
  [getAppSettings],
  settings => settings
);
export const appSettingsLoadingSelector = createSelector(
  [getAppSettingsLoading],
  loading => loading
);

export const hasFetchedNavigationSelector = createSelector(
  [getNavigationHasFetched],
  hasFetched => hasFetched
);

export const getAppSettingsPromotion = (state: AppState) =>
  state.app.settings?.promotion;
