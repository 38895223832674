import React, { Fragment } from 'react';

import ContentBlocksArea from '../../components/ContentBlocksArea';
import ProgressivePicture from '../../components/ProgressivePicture';
import ErrorInfo from '../../components/ErrorInfo';

import './DeliveryDistrict.scss';

const DeliveryDistrict = ({
  city,
  templateBlock = {},
  zipCodes,
  pageError,
  getPageContent
}) => {
  const { contentBlocks, image } = templateBlock;

  return (
    <div className="b-delivery-district">
      {image && (
        <ProgressivePicture
          largeImage={image}
          maxWidth={{
            large: 1372,
            small: 747
          }}
        />
      )}
      <h1 className="b-delivery-district__title">
        Hemleverans av mat i{' '}
        <span className="b-delivery-district__city-name">{city}</span>
      </h1>
      <p className="b-delivery-district__text">
        Handla recept, matkassar och matvaror. Vi har ett stort antal matkassar
        och massor av spännande recept för hela familjen.
      </p>
      {zipCodes &&
        zipCodes.length > 0 && (
          <Fragment>
            <h2>Postnummer vi levererar till</h2>
            <p className="b-delivery-district__text">
              I <span className="b-delivery-district__city-name">{city}</span>{' '}
              levererar vi matkasse, matvaror och recept till följande
              postnummer:
            </p>
            <div className="b-delivery-district__zipcodes">
              {zipCodes.join(', ')}
            </div>

            {pageError ? (
              <ErrorInfo
                title="Hoppsan, något gick fel!"
                text="Det går inte att hämta denna sidan just nu."
                retryButtonFn={getPageContent}
              />
            ) : (
              contentBlocks &&
              contentBlocks.length > 0 && (
                <ContentBlocksArea blocks={contentBlocks} />
              )
            )}
          </Fragment>
        )}
    </div>
  );
};

export default DeliveryDistrict;
