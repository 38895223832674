import {
  Box,
  Button,
  FormControl,
  Link as CgLink
} from '@citygross/components';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { LoginNewsBoxText } from '../../../constants/InformationText';
import * as styles from './SideModalLogin.styles';
import { BodyText } from '@citygross/typography';
import { validateFormItem } from '../../Form/lib';
import { loginSteps } from './SideModalLogin';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import LoginInput from '../../LoginInput/LoginInput';
import { clearPasswordReset } from '../../../actions/auth';
import { connect, useDispatch } from 'react-redux';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal/SideModal';
import { fireCallbackOnEnter } from '../../../lib/utils';
import { ga4OpenRegister } from '../../../lib/analytics/analytics';

type TLoginEmail = {
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email?: string;
  prefilledPassword?: string;
  passwordResetRequestSuccessfull?: string;
  setPrefilledPassword: React.Dispatch<React.SetStateAction<string>>;
} & IAnimatedStepsChildProps;

const LoginEmail = ({
  setEmail,
  email,
  passwordResetRequestSuccessfull,
  setPrefilledPassword,
  prefilledPassword,
  ...props
}: TLoginEmail) => {
  const { goToStep, stepKey, animating } = props;
  const theme = useTheme();
  const [error, setError] = useState<string | null>('');
  const dispatch = useDispatch();

  const submit = e => {
    e.preventDefault();
    const validEmail = validateFormItem(email, { type: 'email', label: email });
    if (email && email?.length > 3 && validEmail.isValid) {
      goToStep &&
        (stepKey || stepKey === 0) &&
        goToStep(loginSteps.LOGIN_PASSWORD, stepKey);
    } else if (validEmail?.fallbackHint) {
      setError(validEmail.fallbackHint);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearPasswordReset());
    };
  }, []);

  return (
    <styles.LoginWrapper>
      <styles.LoginInputContainer onSubmit={e => submit(e)}>
        <styles.LoginTitle>Logga in</styles.LoginTitle>
        {passwordResetRequestSuccessfull && (
          <Box background={theme?.palette?.blueLight}>
            <styles.EmailText>
              Vi har skickat instruktioner till din e-post{' '}
              {passwordResetRequestSuccessfull}
            </styles.EmailText>
          </Box>
        )}
        <styles.LoginEmailContainer onSubmit={submit}>
          <styles.LoginEmailLabel>E-postadress*</styles.LoginEmailLabel>
          <FormControl
            errorMessage={<styles.ErrorText>{error}</styles.ErrorText>}
            isValid={!error}
          >
            <styles.HiddenInputContainer>
              <input
                type={'password'}
                value={prefilledPassword}
                onChange={e => setPrefilledPassword(e.target.value)}
                autoComplete={'current-password'}
              />
            </styles.HiddenInputContainer>
            <LoginInput
              type={'email'}
              placeholder="Ange E-post"
              value={email}
              onChange={e => {
                setError(null);
                setEmail(e.target.value);
              }}
              name={'username'}
              loading={animating}
              isValid={!error}
              onSubmit={submit}
            />
          </FormControl>
        </styles.LoginEmailContainer>
        <Button color={'secondary'} fullWidth onClick={submit}>
          Nästa
        </Button>

        <styles.LoginTextCenter>
          <BodyText textAlign="center">Inget konto? </BodyText>
          <CgLink
            asButton
            tabIndex={0}
            onClick={() => {
              ga4OpenRegister();
              dispatch(openSideModal(SideModalTabs.REGISTER));
            }}
          >
            Skapa konto
          </CgLink>
        </styles.LoginTextCenter>
      </styles.LoginInputContainer>

      <Box
        background={theme.palette?.yellowLight}
        rounded={false}
        padding={theme.spacings?.md}
      >
        <styles.LoginNewsBoxTitle>
          {LoginNewsBoxText.title}
        </styles.LoginNewsBoxTitle>
        <BodyText>{LoginNewsBoxText.body}</BodyText>
      </Box>
    </styles.LoginWrapper>
  );
};
function mapStateToProps(state) {
  return {
    passwordResetRequestSuccessfull: state.auth.passwordResetRequestSuccessfull
  };
}
export default connect(mapStateToProps)(LoginEmail);
