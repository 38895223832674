import React from 'react';

import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { pageSelector } from '../../selectors/page';
import { useRouteMatch } from 'react-router-dom';
import { Store } from '../../types/episerver/store';
import StoreMapContainer from '../../components/StoreMapContainer';

const MapPlaceholderBlock: React.FC = () => {
  const match = useRouteMatch();
  const store: Store | null = useSelector((state: AppState) =>
    pageSelector(state, { match })
  );

  return store ? <StoreMapContainer store={store} /> : <></>;
};

export default MapPlaceholderBlock;
