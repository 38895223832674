import React from 'react';
// eslint-disable-next-line import/namespace
import { Redirect } from 'react-router';
import qs from 'query-string';
import { LoginFlyout, ForgotPasswordFlyout } from '../../containers/Login';
import DefaultLayout from '../../containers/Layouts/DefaultLayout';
import CreateAccountFlyout from '../../components/Login/CreateAccount/CreateAccountFlyout';
import PasswordReset from '../../modules/PasswordReset';
import LoginCustomer from '../../modules/LoginCustomer';
import NotFound404 from '../../pages/404';

// TODO: move all those to staticRoutes.js
export const STATIC_ROUTES = [
  {
    key: 'reset-password-old',
    exact: true,
    path: '/login/glomt-losenord/resetpassword',
    component: props => {
      const { reset_password_token } = qs.parse(props.location.search);
      return (
        <Redirect
          to={`/glomt-losenord/${encodeURIComponent(reset_password_token)}`}
        />
      );
    },
    layout: DefaultLayout,
    isProtected: false,
    layoutProps: {},
    subRoutes: []
  },
  {
    key: 'reset-password',
    exact: true,
    path: '/glomt-losenord/:token',
    component: PasswordReset,
    layout: DefaultLayout,
    isProtected: false,
    layoutProps: {},
    subRoutes: []
  },
  {
    key: 'login-customer',
    exact: true,
    path: '/_cl_/:token',
    component: LoginCustomer,
    layout: DefaultLayout,
    isProtected: false,
    layoutProps: {},
    subRoutes: []
  },
  {
    key: '404',
    component: NotFound404,
    layout: DefaultLayout,
    isProtected: false,
    layoutProps: { errorCode: 404 },
    subRoutes: []
  }
];

export const STATIC_FLYOUT = [
  {
    id: 'registrera',
    exact: true,
    path: '/registrera',
    type: '',
    component: CreateAccountFlyout,
    subRoutes: []
  },
  {
    id: 'login',
    exact: true,
    path: '/login',
    type: '',
    component: LoginFlyout,
    subRoutes: []
  },
  {
    id: 'glomtLosenord',
    exact: true,
    path: '/glomt-losenord',
    type: '',
    component: ForgotPasswordFlyout,
    subRoutes: []
  }
];
