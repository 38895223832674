module.exports = {
  BASE_URL: process.env.API_HOST || '/api/v1',
  DEBUG: false,
  AUTH_STORAGE_KEY: 'cg_reloaded',
  CATERING_STORAGE_KEY: 'catering',
  PICTURE_BASE_URL: process.env.PICTURE_BASE_URL || '/images',
  VERSION: __BUILD_VERSION__,
  STORAGE_TYPE: 'ls',
  RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
  SERVICE_WORKER_ACTIVE: false,
  HOST: 'https://www.citygross.se',
  USE_RECAPTCHA: true,
  DAY_TO_SHOW_WEEK_TOGGLE: process.env.DAY_TO_SHOW_WEEK_TOGGLE || '3'
};
