import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Buttons';
import ProgressivePicture, {
  ImageFitTypes
} from '../../components/ProgressivePicture';

import './StoreSelect.scss';
import { convertToReadableTime } from '../../lib/time';
import { Image } from '../../components/Image/Image';
import { distanceBetweenCoordinates } from '../../lib/distanceBetweenTwoCoordinates';
import FlipMove from 'react-flip-move';
import { Store } from '../../types/episerver/store';
import { isToday } from 'date-fns';
const SearchIcon = require('../../components/assets/search.svg');
const AIcon = require('../../components/assets/b_house.svg');

interface IProps {
  stores: Store[] | null;
  page: any;
}

const dayNames = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

const StoreSelect: React.FC<IProps> = ({ stores, page }) => {
  const [search, setSearch] = useState('');
  const [geoLocating, setGeoLocating] = useState(false);
  const [geoPos, setGeoPos] = useState({
    latitude: null,
    longitude: null
  });

  const filteredStores = stores
    ? stores
        .filter(store =>
          store.name.toLowerCase().includes(search.toLowerCase())
        )
        .sort((a, b) => {
          if (!geoPos.latitude || !geoPos.longitude) {
            return 0;
          }
          if (!a || !a.storeLocation.coordinates) {
            return 1;
          } else if (!b || !b.storeLocation.coordinates) {
            return -1;
          }

          const [latA, longA] = a.storeLocation.coordinates.split(',');
          const [latB, longB] = b.storeLocation.coordinates.split(',');

          const distanceA = distanceBetweenCoordinates(
            Number(latA),
            Number(longA),
            Number(geoPos.latitude),
            Number(geoPos.longitude)
          );
          const distanceB = distanceBetweenCoordinates(
            Number(latB),
            Number(longB),
            Number(geoPos.latitude),
            Number(geoPos.longitude)
          );

          return distanceA - distanceB;
        })
    : [];

  const getGeoPos = () => {
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      setGeoPos({
        latitude,
        longitude
      });
      setGeoLocating(false);
    }

    function error() {
      setGeoLocating(false);
      // status.textContent = 'Unable to retrieve your location';
    }

    if (!navigator.geolocation) {
      console.log('Geolocation is not supported by your browser');
    } else {
      // status.textContent = 'Locating…';
      setGeoLocating(true);
      navigator.geolocation.getCurrentPosition(success, error);
    }
  };
  const today = new Date();

  return (
    <div className="c-findstore">
      {page && page.mainImage && (
        <div className="c-findstore__image">
          <ProgressivePicture
            largeImage={page.mainImage}
            smallImage={page.mobileImage}
            inheritHeight
            fitType={ImageFitTypes.Cover}
          />
        </div>
      )}
      {page && (
        <div className="c-findstore__links">
          <div className="c-findstore__container">
            <div className="c-findstore__header">
              <div className="c-findstore__headercol">
                <Image
                  className="searchicon"
                  src={AIcon}
                  width="45"
                  height="45"
                  alt="Sök"
                />
                <h1>Butiker & öppettider</h1>
                <p>
                  Nedan hittar du öppettider, erbjudanden och detaljerad
                  information om din närmsta City Gross-butik
                </p>
              </div>
            </div>
            <div className="c-findstore__search">
              <div className="c-findstore__searchbar">
                <input
                  type="text"
                  placeholder="Sök butik"
                  onChange={e => setSearch(e.target.value)}
                  autoFocus
                />
                <div className="c-findstore__searchicon">
                  <Image
                    className="searchicon"
                    src={SearchIcon}
                    width={21}
                    height={21}
                    alt="Sök"
                    noLazy
                  />
                </div>
              </div>
              <Button lg onClick={() => getGeoPos()} loading={geoLocating}>
                Visa närmsta
              </Button>
            </div>

            <FlipMove>
              {filteredStores &&
                filteredStores.map(
                  ({
                    id,
                    name,
                    openingHours,
                    contactInformation,
                    mobileImage,
                    storeLocation,
                    url
                  }) => {
                    let todayOpeningHours =
                      openingHours[dayNames[today.getDay()]];
                    const holidayTime = openingHours?.holidays?.find(x =>
                      isToday(new Date(x.date))
                    );
                    let todayOpens =
                      holidayTime?.opens || new Date(todayOpeningHours.opens);
                    let todayCloses =
                      holidayTime?.closes || new Date(todayOpeningHours.closes);
                    // const currentlySelected = selectedStore && selectedStore.id === id;

                    let distanceInKm;
                    if (storeLocation.coordinates && geoPos.latitude) {
                      const [lat, long] = storeLocation.coordinates.split(',');
                      distanceInKm = distanceBetweenCoordinates(
                        Number(lat),
                        Number(long),
                        Number(geoPos.latitude),
                        Number(geoPos.longitude)
                      );
                    }
                    return (
                      <div className="c-findstore__store" key={id}>
                        <div className="c-findstore__storecolumn">
                          <div className="c-findstore__storeimage">
                            <ProgressivePicture
                              largeImage={mobileImage}
                              smallImage={mobileImage}
                              fitType={ImageFitTypes.Cover}
                            />
                          </div>
                          <div>
                            <h2>{name}</h2>
                            <p>
                              Öppet idag: {convertToReadableTime(todayOpens)} -{' '}
                              {convertToReadableTime(todayCloses)}
                            </p>
                            {contactInformation?.phone && (
                              <p>
                                Telefon:{' '}
                                <a href={`tel:${contactInformation.phone}`}>
                                  {' '}
                                  {contactInformation.phone}{' '}
                                </a>
                              </p>
                            )}
                            {contactInformation?.email && (
                              <p>
                                E-post:{' '}
                                <a href={`mailto:${contactInformation.email}`}>
                                  {contactInformation.email}
                                </a>
                              </p>
                            )}
                            {distanceInKm && (
                              <p className="c-findstore__distance">
                                Distans: {distanceInKm.toFixed(0)} km
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="c-findstore__storecolumn">
                          <div className="c-findstore__actionbtn">
                            <Link to={url}>
                              <Button lg fullwidth>
                                Till butikssidan
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </FlipMove>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreSelect;
