import {
  CartSubTotal,
  ECartSubTotalTypes,
  ProgressBar
} from '@citygross/components';
import React, { useEffect, useMemo, useState } from 'react';
import { responsiveWrapper } from 'react-responsive-redux';
import { ReplacementBox } from '../ReplacementBox';
import { connect } from 'react-redux';
import { clearCartGetNext } from '../../../actions/cart/async';
import {
  FreePickingFee,
  PickingFeeLimit,
  PromotionText
} from '../../../constants/InformationText';
import * as styles from './CartExtraInfo.styles';
import { useTheme } from 'styled-components';
const MediaQuery = responsiveWrapper({ minWidth: 1360 });
const DEFAULT_PICKING_FEE_LIMIT = 1500;
const PICKING_FEE_CONTAINER_HEIGHT = 95;

const CartExtraInfo = ({
  summary,
  cart,
  isAuthenticated,
  isReplacement,
  shoppingOrder,
  nextCart,
  clearCartGetNext
}) => {
  const pickingFee =
    (cart &&
      (summary?.totalItemPrice >= cart?.lowestPickingFeeLimit ||
      cart?.cateredMeals?.length
        ? 0
        : cart.originalPickingFee || cart.lowestPickingFee)) ??
    cart?.charges?.find(charge => charge?.subType === 'ChargePickingFee')
      ?.amount;

  const pickingFeeInfoText = useMemo(() => {
    return PromotionText(
      pickingFee,
      cart?.lowestPickingFeeLimit || DEFAULT_PICKING_FEE_LIMIT
    );
  }, [pickingFee, cart?.lowestPickingFeeLimit]);

  const showPickingFeeLimit = useMemo(() => {
    return (
      summary?.totalItemPrice &&
      !cart?.bags?.length &&
      !cart?.cateredMeals?.length &&
      isAuthenticated &&
      summary.totalItemPrice <
        (cart?.lowestPickingFeeLimit || DEFAULT_PICKING_FEE_LIMIT)
    );
  }, [
    summary?.totalItemPrice,
    !cart?.bags?.length,
    !cart?.cateredMeals?.length,
    isAuthenticated,
    summary.totalItemPrice,
    cart?.lowestPickingFeeLimit
  ]);

  const showReplacementAlert = useMemo(() => {
    return cart?.replacementForOrderId && isReplacement;
  }, [cart?.replacementForOrderId, isReplacement]);

  const theme = useTheme();
  const [progressOverFlow, setProgressOverflow] = useState(true);

  useEffect(() => {
    if (!showPickingFeeLimit) {
      setProgressOverflow(false);
    }
    const timeout = setTimeout(() => {
      setProgressOverflow(showPickingFeeLimit);
    }, 1500);
    return () => clearTimeout(timeout);
  }, [showPickingFeeLimit]);

  return (
    <styles.CartExtraInfoContainer isReplacement={showReplacementAlert}>
      {showReplacementAlert && (
        <MediaQuery>
          {matches => {
            const isNotInModal =
              matches && !shoppingOrder.toggleable
                ? true
                : shoppingOrder.visible;
            return (
              <ReplacementBox
                isInModal={isNotInModal}
                onClick={() => {
                  clearCartGetNext(cart, nextCart);
                }}
              />
            );
          }}
        </MediaQuery>
      )}
      <styles.CartProgressWrapper
        height={showPickingFeeLimit ? PICKING_FEE_CONTAINER_HEIGHT : 0}
        overflow={progressOverFlow}
      >
        <styles.CartProgressContainer isReplacement={showReplacementAlert}>
          <CartSubTotal
            type={ECartSubTotalTypes.items}
            info={pickingFeeInfoText}
            label={showPickingFeeLimit ? PickingFeeLimit : FreePickingFee}
            value={
              cart?.cateredMeals?.length
                ? 0
                : Math.max(
                    (cart?.lowestPickingFeeLimit || DEFAULT_PICKING_FEE_LIMIT) -
                      summary.totalItemPrice,
                    0
                  )
            }
            priceColor={
              showPickingFeeLimit
                ? theme.palette?.darkest
                : theme.palette?.alertRed
            }
            infoOnBottom
          />

          <ProgressBar
            maxValue={cart?.lowestPickingFeeLimit || DEFAULT_PICKING_FEE_LIMIT}
            currentValue={summary?.totalItemPrice}
            small
          />
        </styles.CartProgressContainer>
      </styles.CartProgressWrapper>
    </styles.CartExtraInfoContainer>
  );
};
const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  clearCartGetNext: (cart, nextCart) =>
    dispatch(clearCartGetNext(cart, nextCart))
});
export default connect(mapStateToProps, mapDispatchToProps)(CartExtraInfo);
