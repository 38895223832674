import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CloseButton, Button } from '../Buttons';
import FlyoutBody from './FlyoutComponents/FlyoutBody';
import FlyoutFooter from './FlyoutComponents/FlyoutFooter';
import './Flyout.scss';

export class FlyoutComp extends Component {
  constructor(props) {
    super(props);
    this.state = { isScrolled: false };
  }

  handleScroll = scrolled => this.setState({ isScrolled: scrolled });

  render() {
    const {
      tight,
      nopadding,
      title,
      children,
      onCloseClicked,
      hasFooter,
      confirmModalRef,
      match: { params }
    } = this.props;
    const { isScrolled } = this.state;
    const isOffer = params?.hasOwnProperty('offerId');
    const childrenWithProps = React.Children.map(children, child =>
      React.cloneElement(child, { confirmModalRef })
    );

    return (
      <div
        className={classNames('c-panel', {
          tight,
          nopadding,
          ['k-panel']: isOffer
        })}
      >
        <div
          className={classNames('c-stdpnlcnt', {
            'has-foot': hasFooter,
            'offer-header': isOffer,
            nohead: !title
          })}
        >
          {title ? (
            <div
              className={classNames(
                'pnlhead',
                isScrolled ? 'pnlhead--is-scrolled' : ''
              )}
            >
              <div className="closepanel">
                <CloseButton onClick={onCloseClicked} />
              </div>
              <h2>{title}</h2>
            </div>
          ) : (
            <div className="closepanel">
              <CloseButton onClick={onCloseClicked} />
            </div>
          )}
          <FlyoutBody isScrolled={this.handleScroll}>
            {childrenWithProps}
          </FlyoutBody>
          {hasFooter && (
            <FlyoutFooter>
              <Button light onClick={onCloseClicked}>
                Avbryt
              </Button>
            </FlyoutFooter>
          )}
        </div>
      </div>
    );
  }
}

FlyoutComp.propTypes = {
  tight: PropTypes.bool.isRequired,
  nopadding: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
  onCloseClicked: PropTypes.func.isRequired,
  hasFooter: PropTypes.bool.isRequired,
  confirmModalRef: PropTypes.any.isRequired
};

FlyoutComp.defaultProps = {
  tight: true,
  nopadding: false,
  hasFooter: false
};

const Flyout = props => {
  return props.show && <FlyoutComp {...props} />;
};

export default Flyout;
