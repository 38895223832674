import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getCustomerToken } from '../../actions/auth';
import { setMode } from '../../actions/shoppingOrder';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';
import { CenteredSpinner } from '../../components/Spinner/Spinner';

import './LoginCustomer.scss';

class LoginCustomer extends Component {
  state = {
    loading: false,
    error: null
  };

  componentDidMount() {
    const { match, getCustomerToken, setMode } = this.props;
    const { params } = match;
    const { token } = params;

    if (token) {
      this.setState({
        loading: true
      });

      getCustomerToken(token)
        .then(res => {
          if (res) {
            window.location = '/';
          } else {
            this.setState({
              error: true
            });
          }
        })
        .catch(err => {
          this.setState({
            error: true
          });
        })
        .finally(() => {
          this.setState({
            loading: false
          });
        });
    }

    setMode(true, false, UNTOGGLE_BEHAVIOUR.none);
  }

  render() {
    const { loading, error } = this.state;

    return (
      <div className="b-login-customer">
        {loading && (
          <Fragment>
            <CenteredSpinner dark />
            <p style={{ marginTop: '40px' }}>
              Loggar in, sidan kommer automatiskt att uppdateras!
            </p>
          </Fragment>
        )}
        {error && <strong>Något gick fel, kontrollera token!</strong>}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = {
  getCustomerToken,
  setMode
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCustomer);
