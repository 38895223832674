import React from 'react';
import classNames from 'classnames';
import './RoundButton.scss';
const RoundSpinner = require('../assets/round-spinner.svg');


interface IProps {
  className?: string,
  theme?: 'light' | 'primary' | 'transparent',
  small?: boolean,
  mini?: boolean,
  onClick: any,
  disabled?: boolean,
  loading?: boolean
}
export const RoundButton : React.FC<IProps> = ({
  className = '',
  theme,
  small = false,
  mini = false,
  onClick = () => console.log('default func'),
  disabled = false,
  loading = false
}) => {
  const action = disabled ? e => e.preventDefault() : onClick;
  const defClass = classNames(
    'c-btn-round',
    className,
    theme,
    { small },
    { mini },
    { disabled },
    { loading }
  );
  return (
    <div
      className={defClass}
      onClick={action}
    >
      {loading && (
        <img src={RoundSpinner} alt="Laddar" className="c-btn-round__spinner" />
      )}
    </div>
  );
};

export default RoundButton;
