import * as endpoints from '../../api/endpoints/cateredmeals';
import {
  setVariantItem,
  receiveCateredMeals,
  receiveCateredMeal,
  isFetchingCateredMeals,
  fetchCateredMealsError,
  fetchCateredMealError
} from './sync';

import { isNumber } from '../../lib/number';

const getProducts = (
  endpoint,
  receiveFunc = receiveCateredMeals,
  isFetching = isFetchingCateredMeals
) => dispatch => {
  dispatch(isFetching(true));
  return endpoint()
    .then(({ data }) => {
      dispatch(receiveFunc(data));

      return data;
    })
    .catch(err => {
      dispatch(fetchCateredMealsError(err));

      return Promise.reject(err);
    });
};

export const setSelectedVariant = selectedVariant => dispatch =>
  dispatch(setVariantItem(selectedVariant));

export const getCateringProductById = productId => dispatch => {
  // dispatch(isFetchingCateredMeals(true));
  return endpoints
    .fetchCateringProductById(productId)
    .then(({ data }) => {
      dispatch(receiveCateredMeal(data));

      return data;
    })
    .catch(err => {
      dispatch(fetchCateredMealError(err));

      return Promise.reject(err);
    });
};

export const getCateringProductsByCategory = (categoryId, params) =>
  getProducts(
    () => endpoints.fetchCateringProductsByCategory(categoryId, params),
    response => {
      if (params && isNumber(params.size)) {
        response.meta = {
          ...response.meta,
          pageIndex: Math.max(
            0,
            Math.floor(params.size / endpoints.DEFAULT_PRODUCTPAGE_SIZE) - 1
          )
        };
      }

      return receiveCateredMeals(response);
    }
  );
