import React from 'react';
import './WarningLabel.scss';
const infoSvg = require('../assets/infoSmall.svg');

export type TWarningLabel = {
  extraMargin?: boolean;
  label?: string;
};

export const WarningLabel = ({ label, extraMargin }: TWarningLabel) => {
  return (
    <div className={`limitedStock ${extraMargin && 'mt-10'}`}>
      <img src={infoSvg} alt="information icon" />
      <p>{label}</p>
      <div className={'limitedStockTooltip'}>
        Vara är begränsad och kontroll av tillgänglighet görs i kassan
      </div>
    </div>
  );
};
