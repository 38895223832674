const NAME = 'newCartPrep';

export const CLOSE_NEWCART_MODAL = `${NAME}/CLOSE`;
export const OPEN_NEWCART_MODAL = `${NAME}/OPEN`;
export const LOADING = `${NAME}/LOADING`;
export const PREPARE_NEWCART = `${NAME}/PREPARE_NEWCART`;
export const RESET_NEWCART = `${NAME}/RESET_NEWCART`;
export const SET_CART_TO_CHANGE = `${NAME}/SET_CART_TO_CHANGE`;

export interface NewCartPrepState {
  show: boolean;
  cartDiff: any;
  newCart: any;
  loading: boolean;
  isDifferent: boolean;
  newCartId: string | null;
  storeNoToBeSelected: string | number | null;
  cartToBeChanged: string[] | null;
}
