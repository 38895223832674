import {
  FETCH_DELIVERY_CITIES_REQUESTED,
  FETCH_DELIVERY_CITIES_RECEIVED,
  FETCH_DELIVERY_CITIES_FAILED,
  FETCH_DELIVERY_ZIPCODES_REQUESTED,
  FETCH_DELIVERY_ZIPCODES_RECEIVED,
  FETCH_DELIVERY_ZIPCODES_FAILED
} from '../../types/reducers/deliveryCities';

export const fetchingCities = () => ({
  type: FETCH_DELIVERY_CITIES_REQUESTED
});

export const receiveCities = payload => ({
  type: FETCH_DELIVERY_CITIES_RECEIVED,
  cities: payload
});

export const failedReceivingCities = () => ({
  type: FETCH_DELIVERY_CITIES_FAILED
});

export const fetchingZipcodes = () => ({
  type: FETCH_DELIVERY_ZIPCODES_REQUESTED
});

export const receiveZipcodes = payload => ({
  type: FETCH_DELIVERY_ZIPCODES_RECEIVED,
  zipCodes: payload
});

export const failedReceivingZipcodes = () => ({
  type: FETCH_DELIVERY_ZIPCODES_FAILED
});
