import config from '../config/config';
import qs from 'query-string';
import queryParams from './queryParams';
import { push, replace } from 'connected-react-router';
export const zip = (a1, a2) => a1.map((x, i) => [x, a2[i]]);

export const flatten = arr => {
  const flat = [];

  arr.forEach(item => {
    if (Array.isArray(item)) {
      flat.push(...flatten(item));
    } else {
      flat.push(item);
    }
  });

  return flat;
};

export const groupBy = (prop, items) =>
  items.reduce((acc, item) => {
    const itemProp = item[prop];

    acc[itemProp] = acc[itemProp] ? acc[itemProp].concat(item) : [item];

    return acc;
  }, {});

export const pick = (items, obj) =>
  Object.assign({}, ...items.map(key => ({ [key]: obj[key] })));
export const identity = (...args) => args;

export const ignore = returnValue => () => returnValue;

export const arrayToObject = (arr, keyField) =>
  Object.assign({}, ...arr.map(item => ({ [item[keyField]]: item })));

// assumes the objects has the same shape. Basically to check props for equality.
export const objEqual = (left, right) =>
  Object.keys(left).every(k => left[k] === right[k]);

export const passThroughUpdate = (list, compare, cb) =>
  list.map(item => (compare(item) ? item : cb(item)));

export const time = (label, fn) => {
  const t0 = performance.now();
  const res = fn();
  const t1 = performance.now();

  console.log(`[TIMING] ${label} took: ` + (t1 - t0) + ' ms.');

  return res;
};

export const Enum = class {
  constructor(...fields) {
    for (let i = 0; i < fields.length; i++) {
      this[i] = [fields[i]];
      this[fields[i]] = i;
    }
  }
};


export const toLookup = (list, prop = 'id', asList = false) =>
  asList
    ? list?.map(item => ({ [item[prop]]: item }))
    : list?.reduce(
        (acc, item) => Object.assign(acc, { [item[prop]]: item }),
        {}
      );
    

export const shuffle = list =>
  [...Array(list.length).keys()].sort(() => Math.random() - 0.5);
export const takeNElements = (list, n = 0) =>
  list.slice(0, Math.min(Math.max(list.length - 1, 0), n));
export const partitionByIdRandom = (list, id, n) => {
  // uncommented
  // const active = list.find(p => p.id === id) || list[0];
  const active = list.find(p => p.id === id);
  const tail = list.filter(p => p !== active);
  const randoms = shuffle(tail);
  const randomN = takeNElements(randoms, n);

  return active ? [active, ...randomN.map(i => tail[i])] : [];
};

export const setImage = (images, type, base = '') => {
  const image = (images || []).find(image => image.type === type);

  return image
    ? { ...image, url: `${config.PICTURE_BASE_URL}/${base}${image.url}` }
    : { url: '', alt: '', type: 0 };
};

// Check the query string and see if we should replace or push the new url
export const updateHistory = (query = {}, location, whitelist) => dispatch => {
  let oldParams = qs.parse(location.search);
  let replaceUrl = true;

  Object.keys(query).forEach(function(key, index) {
    if (whitelist?.indexOf(key) === -1) {
      if (query?.[key] !== oldParams?.[key]) {
        replaceUrl = false;
      }
    }
  });

  if (replaceUrl) {
    dispatch(replace(`${location.pathname}?${queryParams(query)}`));
  } else {
    dispatch(push(`${location.pathname}?${queryParams(query)}`));
  }
};

export const fireCallbackOnEnter = callback => event => {
  if (event.keyCode === 13) {
    return callback(event);
  }
  return false;
};

export const fireCallbackOnKeyCode = (code, callback) => event => {
  if (event.keyCode === code) {
    return callback(event);
  }
  return false;
}
export const prettyArrayToString = (
  arr,
  separator = ', ',
  lastSeparator = ' och '
) => {
  if (!arr || arr.length === 0) {
    return '';
  }
  return arr.reduce(
    (acc, str, index, initialArr) =>
      (acc += (index < initialArr.length - 1 ? separator : lastSeparator) + str)
  );
};
export const capitalizeOnlyFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const capitalizeWord = x => {
  if (!x) {
    return '';
  }
  const str = x.charAt(0).toUpperCase() + x.substring(1).toLowerCase();
  if (str.startsWith('Ca') && !isNaN(str.charAt(2))) {
    const string = str.substr(0, 2) + ' ' + str.substr(2);
    return string;
  } else {
    const word = str.replace(/\w*[A-Ö]\S*/gi, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    return word;
  }
};

// Finding index that match value
export const findIndexByKeyAndValue = (theArray, key, value) => {
  for (var i = 0; i < theArray.length; i++) {
    if (theArray[i][key] == value) {
      return i;
    }
  }
  return -1;
};

export const concatAll = list => {
  const results = [];
  list.forEach(function(subArray) {
    subArray.forEach(function(subArrayValue) {
      results.push(subArrayValue);
    });
  });

  return results;
};
export const isIE11 = () => {
    var sAgent = window.navigator.userAgent;
    var version = 0;
    var Idx = sAgent.indexOf("MSIE");
    if (Idx > 0) {
        version = parseInt(sAgent.substring(Idx+ 5, sAgent.indexOf(".", Idx)));
    } else if (navigator.userAgent.match(/Trident\/7\./)) {
        version = 11;
    }
    return version === 11;
}

export const formatRecipeUnit = (unit) => {
  switch (unit.toLowerCase()) {
    case 'portioner':
      return 'portion';
    case 'bitar':
      return 'bit';
    case 'limpor':
      return 'limpa';
    default:
      return unit;
  }
}

export const graphQlQuery = async (query, variables = {}) => {
  const response = await fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    })
  });

  const res = await response.json();
  return res.data;
}

export const getElementByIdAsync = id => new Promise(resolve => {
  const getElement = () => {
    const element = document.getElementById(id);
    if(element) {
      resolve(element);
    } else {
      requestAnimationFrame(getElement);
    }
  };
  getElement();
});

