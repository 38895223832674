/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import CartGroup from '../CartGroup';
import cs from 'classnames';
import CateringCartCard from './CateringCartCard';

const CateringGroup = ({ cart }) => {
  return !cart?.cateredMeals?.length ? null : (
    <CartGroup name={'Catering'}>
      {cart?.cateredMeals?.map((item, idx) => {
        return (
          <div
            key={idx}
            className={cs('cart-card', {
              ['cart-card__last-card']: idx === cart?.cateredMeals?.length - 1
            })}
          >
            <CateringCartCard {...item} />
          </div>
        );
      })}
    </CartGroup>
  );
};

export default CateringGroup;
