import React, { useState } from 'react';
import * as styles from './SideModalRegister.styles';
import { Wizard } from '@citygross/react-use-bg-wizard';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { AnimatedStateStep } from '../AnimatedStep';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRegisterCurrentStep,
  selectRegisterPreviousStep,
  setCurrentRegisterStep
} from '../../../slices/loginSlice';
import RegisterId from './RegisterId';
import RegisterVerify from './RegisterVerify';
import RegisterCreate from './RegisterCreate';
import RegisterSuccess from './RegisterSuccess';
import SideModalWizardHeader from '../../SideModal/SideModalWizardHeader';

type TSideModalRegister = {
  closeModal?: () => void;
};

export const registerSteps = {
  REGISTER_ID: 0,
  REGISTER_VERIFY: 1,
  REGISTER_CREATE: 2,
  REGISTER_SUCCESS: 3
};

const SideModalRegister = ({ closeModal }: TSideModalRegister) => {
  const [pnr, setPnr] = useState<string>('');

  const dispatch = useDispatch();
  const currentStep = useSelector(selectRegisterCurrentStep);

  const onStepChange = (toStep: number, fromStep: number) => {
    dispatch(
      setCurrentRegisterStep({
        currentStep: toStep,
        previousStep: fromStep
      })
    );
  };

  return (
    <styles.RegisterWrapper>
      <AnimatePresence>
        <Wizard
          startIndex={0}
          header={
            <SideModalWizardHeader
              isLogin={true}
              closeSideModal={closeModal}
              backArrowOnClick={() =>
                onStepChange(currentStep - 1, currentStep)
              }
              showBackArrow={Boolean(currentStep)}
            />
          }
        >
          <AnimatedStateStep
            stepComponent={<RegisterId pnr={pnr} setPnr={setPnr} />}
            currentStepSelector={selectRegisterCurrentStep}
            previousStepSelector={selectRegisterPreviousStep}
            onStepChange={onStepChange}
          />
          <AnimatedStateStep
            stepComponent={<RegisterVerify />}
            currentStepSelector={selectRegisterCurrentStep}
            previousStepSelector={selectRegisterPreviousStep}
            onStepChange={onStepChange}
          />
          <AnimatedStateStep
            stepComponent={<RegisterCreate pnr={pnr} setPnr={setPnr} />}
            currentStepSelector={selectRegisterCurrentStep}
            previousStepSelector={selectRegisterPreviousStep}
            onStepChange={onStepChange}
          />

          <AnimatedStateStep
            stepComponent={<RegisterSuccess />}
            currentStepSelector={selectRegisterCurrentStep}
            previousStepSelector={selectRegisterPreviousStep}
            onStepChange={onStepChange}
          />
        </Wizard>
      </AnimatePresence>
    </styles.RegisterWrapper>
  );
};
export default SideModalRegister;
