import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {
  StandardInput,
  PersonalNumberInput,
  MobilePhoneInput,
  TextArea,
  Checkbox,
  Select,
  Radio,
  ZipCodeInput
} from './FormItems';
import { validateFormItem } from '../lib';
import { validateSignupEmail } from '../../../lib/validator';

import { FormControl, Input } from '@citygross/components';

const SelectType = ({ type, ...rest }) => {
  switch (type) {
    case 'text':
      return <StandardInput type={type} {...rest} />;
    case 'number':
      return <StandardInput type={type} {...rest} />;
    case 'mobilePhone':
      return <MobilePhoneInput {...rest} />;
    case 'password':
      return <StandardInput type={type} {...rest} />;
    case 'email':
      return <StandardInput type={type} {...rest} />;
    case 'signupEmail':
      return <StandardInput type={type} {...rest} />;
    case 'personalNumber':
      return <PersonalNumberInput {...rest} />;
    case 'textarea':
      return <TextArea type={type} {...rest} />;
    case 'checkbox':
      return <Checkbox type={type} {...rest} />;
    case 'select':
      return <Select {...rest} />;
    case 'radio':
      return <Radio {...rest} />;
    case 'zipCode':
      return <ZipCodeInput {...rest} />;
    case 'hidden':
      return <input type={type} id={rest.id} value={rest.value} maxLength={rest.maxLength} />;

    case 'loginEmail':
      return <FormControl title={'E-postadress*'}>
        <Input type={'email'} {...rest} />
      </FormControl>
    case 'loginPassword':
      return <FormControl title={'Lösenord*'}>
        <Input type={'password'} {...rest} />
      </FormControl>

    default:
      return (
        <p>
          Invalid or not supported formfield type: <strong>{type}</strong>
        </p>
      );
  }
};

SelectType.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'mobilePhone',
    'password',
    'email',
    'personalNumber',
    'textarea',
    'checkbox',
    'select',
    'radio',
    'zipCode',
    'hidden',
    'loginEmail',
    'loginPassword'
  ])
};

class FormItem extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      valid: null,
      value: props.defaultValue || props.value || '',
      error: false,
      hasChanged: false
    };
  }

  componentDidMount() {
    // Tell Form about the item and pass validate callback
    const { id, setItem = () => {} } = this.props;
    setItem(id, this.validate, this.getProps, this.getValue);
  }

  componentWillUnmount() {
    // If unmount tell Form
    const { id, removeItem } = this.props;
    removeItem(id);
  }

  componentDidUpdate(prevProps, prevState) {
    // If required change, tell Form
    if (prevProps.required !== this.props.required) {
      const { id, setItem } = this.props;
      setItem(id, this.validate, this.getProps, this.getValue);
    }
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({
        value: this.props.defaultValue
      });
    }
    if (prevState.value !== this.state.value && this.state.valid === false) {
      this.validate();
    }
  }

  validate = async newValue => {
    const { value } = this.state;
    const { id, type, label, validationCb } = this.props;

    const validationResult = validateFormItem(newValue || value, this.props);

    if (validationResult.isValid && type === 'signupEmail') {
      return await validateSignupEmail(newValue || value).then(res => {
        this.setState({
          valid: res.isAvailable,
          error: `${label} är redan upptaget, vänligen välj ett annat`
        });
        if (!res.isAvailable) {
          validationCb(id, {
            isValid: res.isAvailable,
            fallbackHint: `${label} är redan upptaget, vänligen välj ett annat`
          });
          return res.isAvailable;
        }
        validationCb(id, validationResult);
        return validationResult.isValid;
      });
    } else {
      this.setState({
        valid: validationResult.isValid,
        error: !validationResult.isValid && validationResult
      });

      validationCb(id, validationResult);
      return validationResult.isValid;
    }
  };

  getValue = () => this.state.value;
  getProps = () => this.props;

  onChange = event => {
    const { value, type, checked } = event.target;
    const val =
      type === 'checkbox' || type === 'radio'
        ? checked
        : type === 'email' || type === 'signupEmail'
        ? value.replace(/\s/g, '')
        : value;

    this.setState({
      value: val,
      hasChanged: true
    });

    const { setUnsavedChanges = () => {}, id, defaultValue } = this.props;
    setUnsavedChanges(id, val !== defaultValue);
  };

  onBlur = () => {
    if (this.state.hasChanged) {
      this.validate();
    }
  };

  render() {
    const {
      type,
      required,
      label,
      showRequiredInfo,
      autoComplete,
      autoFocus,
      // Prevent these from reaching the DOM element
      validationHint,
      repeatItemId,
      validationCb,
      setItem,
      removeItem,
      getValueById,
      setSubmitted,
      defaultValue,
      value: propValue,
      setUnsavedChanges,
      ...rest
    } = this.props;
    const { value, valid } = this.state;
    const useTextTypeStyle =
      type === 'number' ||
      type === 'personalNumber' ||
      type === 'password' ||
      type === 'email' ||
      type === 'signupEmail' ||
      type === 'mobilePhone' ||
      type === 'zipCode'
        ? 'text'
        : type;
    return (
      <div
        className={cs('formfield', useTextTypeStyle, {
          invalid: valid === false
        })}
      >
        <SelectType
          type={type}
          required={required}
          autoFocus={autoFocus}
          label={
            label &&
            `${label}${label && required && showRequiredInfo ? '*' : ''}`
          }
          autoComplete={autoComplete || 'new-password'}
          value={value}
          onBlur={this.onBlur}
          onChange={this.onChange}
          {...rest}
        />
      </div>
    );
  }
}

FormItem.propTypes = {
  id: PropTypes.string.isRequired, // Unique in the <Form /> ctx
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  validationHint: PropTypes.string, // Set a validationHint, if not set fallbackhints will be used
  showRequiredInfo: PropTypes.bool, // Hide/Show required info '*' and hint in ErrorMessage
  repeatItemId: PropTypes.string // Speical repeat validation, must match FormItem with `id`
};

FormItem.defaultProps = {
  required: false,
  showRequiredInfo: true,
  autoFocus: false
};

export default FormItem;
