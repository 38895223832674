import { createSlice } from '@reduxjs/toolkit';
import { StoreMeta } from '../types/assortments';
interface IDeliveryPickerState {
  initialLoading: boolean;
  saveDeliverySettingsLoading: boolean;
  currentStep: number;
  previousStep: number | null;
  activePostalCode: string | number | null;
  activeDeliveryMethod: 'homeDelivery' | 'pickupAtStore' | null;
  activeStore: StoreMeta | null;
  fullCateringSlots: string[];
}

const initialState: IDeliveryPickerState = {
  initialLoading: false,
  saveDeliverySettingsLoading: false,
  currentStep: 0,
  previousStep: null,
  activePostalCode: null,
  activeDeliveryMethod: null,
  activeStore: null,
  fullCateringSlots: []
};

const deliveryPickerSlice = createSlice({
  name: 'deliveryPicker',
  initialState: initialState,
  reducers: {
    setInitialLoading: (state, action) => {
      state.initialLoading = action.payload;
    },
    setSaveDeliverySettingsLoading: (state, action) => {
      state.saveDeliverySettingsLoading = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload.currentStep;
      state.previousStep = action.payload.previousStep;
    },
    setActivePostalCode: (state, action) => {
      state.activePostalCode = action.payload;
    },
    setActiveDeliveryMethodAndStore: (state, action) => {
      state.activeDeliveryMethod = action.payload.activeDeliveryMethod;
      state.activeStore = action.payload.activeStore;
      if (action.payload?.postalCode) {
        state.activePostalCode = action.payload.postalCode;
      }
    },
    setFullCateringSlots: (state, action) => {
      state.fullCateringSlots = action.payload.fullCateringSlots;
    }
  }
});

export const {
  setInitialLoading,
  setSaveDeliverySettingsLoading,
  setCurrentStep,
  setActivePostalCode,
  setActiveDeliveryMethodAndStore,
  setFullCateringSlots
} = deliveryPickerSlice.actions;

export const selectInitialLoading = state =>
  state.deliveryPicker.initialLoading;
export const selectSaveDeliverySettingsLoading = state =>
  state.deliveryPicker.saveDeliverySettingsLoading;

export const selectCurrentStep = state => state.deliveryPicker.currentStep;
export const selectPreviousStep = state => state.deliveryPicker.previousStep;

export const selectActivePostalCode = state =>
  state.deliveryPicker.activePostalCode;
export const selectActiveDeliveryMethod = state =>
  state.deliveryPicker.activeDeliveryMethod;
export const selectActiveStore = state => state.deliveryPicker.activeStore;
export const selectFullCateringSlots = state =>
  state.deliveryPicker.fullCateringSlots;

export const deliveryPickerReducer = deliveryPickerSlice.reducer;
