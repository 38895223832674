import qs from 'query-string';
import * as endpoints from '../../api/endpoints/product';
import {
  isFetchingProduct,
  isFetchingProducts,
  receiveProduct,
  receiveProducts,
  paginateProducts,
  isPaginatingProducts,
  fetchProductError,
  fetchProductsError
} from './sync';
import { isNumber } from '../../lib/number';
import { updateHistory } from '../../lib/utils';
import { ThunkDispatch } from 'redux-thunk';
import { AppThunk } from '../../reducers';

const ignoredKeys = ['page', 'f.brand', 'Sort'];

/* Kind of a toggle, if there's already an entry in the current query with the same
   value. We remove it otherwise we just add it with the rest */
const uniqueEntries = (currentFilter, val) => {
  const current = currentFilter.split(',');
  return current.includes(val)
    ? current.filter(v => v !== val)
    : [].concat(current, val);
};

/* Merges query parameters, array values are unique */
const mergeQuery = (next: Map<string, string>, { search } : Location) => {
  const current = qs.parse(search, { arrayFormat: 'index' });
  const merged = Object.entries(next).reduce((acc, [key, val]) => {
    acc[key] = current[key]
      ? [...uniqueEntries(current[key], val)].join()
      : [].concat(val).join();

    return acc;
  }, {});

  return Object.assign({}, current, merged);
};

export const getProductById = (productId: string) => dispatch => {
  dispatch(isFetchingProduct(true));

  return endpoints
    .fetchProductById(productId)
    .then(({ data }) => {
      dispatch(receiveProduct(data));

      return data;
    })
    .catch(err => {
      dispatch(fetchProductError(err));
      dispatch(isFetchingProduct(false));
      return Promise.reject(err);
    });
};

export const getProductsByIds = (productIds: string) => dispatch => {
  dispatch(isFetchingProduct(true));

  return endpoints.fetchProductsByIds(productIds).then(({ data }) => data);
};

const getProducts = (
  endpoint: Function,
  receiveFunc: Function = receiveProducts,
  isFetching: Function = isFetchingProducts
) : AppThunk => dispatch => {
  dispatch(isFetching(true));
  return endpoint()
    .then(({ data }) => {
      dispatch(receiveFunc(data));

      return data;
    })
    .catch((err: string) => {
      dispatch(fetchProductsError(err));

      return Promise.reject(err);
    });
};

type TProductsByCategoryParams = {
  size: number,
  page: number
}

export const getProductsByCategory = (categoryId: string, params: TProductsByCategoryParams) =>
  getProducts(
    () => endpoints.fetchProductsByCategory(categoryId, params),
    response => {
      if (params && isNumber(params.size)) {
        response.meta = {
          ...response.meta,
          pageIndex: params.page
        };
      }

      return receiveProducts(response);
    }
  );

export const requestMoreProducts = (
  id: string,
  params: Map<string, any>,
  location: Location,
  store: number
) => dispatch => {
  const query = qs.parse(location.search);
  const queryP = Object.assign({}, query, params);
  const page = queryP?.page ? Number(queryP?.page) + 1 : 1
  dispatch(updateHistory({...queryP, page}, location, ignoredKeys));

  return dispatch(
    getProducts(
      () => endpoints.fetchProductsByCategory(id, { ...queryP, store, page : page -1 }),
      paginateProducts,
      isPaginatingProducts
    )
  );
};

export const filterProducts = (
  id: string,
  filters: Map<string, any>,
  location: Location,
  params: Map<string, any>
) : AppThunk => dispatch => {
  const query = {
    ...mergeQuery(filters, location),
    page: 1
  };

  dispatch(updateHistory(query, location, ignoredKeys));
  return dispatch(getProductsByCategory(id, { ...query, ...params, page: 0 }));
};

export const sortProducts = (id: string, params: Map<string, any>, location: Location, store: number) : AppThunk => dispatch => {
  const query = qs.parse(location.search);
  const queryP = Object.assign({}, query, params);
  dispatch(updateHistory({...queryP, page : 1}, location, ignoredKeys));

  return dispatch(
    getProducts(
      () => endpoints.fetchProductsByCategory(id, { ...queryP, store ,page : 0  }),
      receiveProducts
    )
  );
};
