import { createSlice } from '@reduxjs/toolkit';
import config from '../config/config';

type TOffersWeekToggleState = {
  dayToShowWeekToggle: string | null;
};

const initialState: TOffersWeekToggleState = {
  dayToShowWeekToggle: null
};

const offersWeekToggleSlice = createSlice({
  name: 'offersWeekToggle',
  initialState,
  reducers: {}
});

export const selectDayToShowWeekToggle = (state): string | null =>
  state.dayToShowWeekToggle || config.DAY_TO_SHOW_WEEK_TOGGLE;

export const offersWeekToggleReducer = offersWeekToggleSlice.reducer;
