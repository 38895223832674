import React from 'react';
import OpeningHours from '../../components/OpeningHours';
import { Store } from '../../types/episerver/store';
import './StoreInfo.scss';

type TStoreInfo = {
  store: Store;
};

const StoreInfo: React.FC<TStoreInfo> = ({ store }) => {
  return store ? (
    <div className="c-singlestore__info">
      <div className="c-singlestore__infocolumn">
        <h2>Öppettider</h2>
        <OpeningHours openingHours={store.openingHours} />
      </div>
      <div className="c-singlestore__infocolumn">
        <h2>Kontakta oss</h2>
        <p>
          Telefon:{' '}
          <a href={`tel:${store.contactInformation.phone}`}>
            {store.contactInformation.phone}
          </a>
          <br />
          E-post:{' '}
          <a href={`mailto:${store.contactInformation.email}`}>
            {store.contactInformation.email}
          </a>
          <br />
        </p>
        <p>
          {store.address.streetAddress} {store.address.zipCode}{' '}
          {store.address.city}
        </p>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default StoreInfo;
