import { UserData } from "../types/customer/UserData";
import Cookie from 'js-cookie';
import config from '../config/config';

export const userAddress = (user: UserData | null) =>
  user && user.contact && user.contact.addresses
    ? user.contact.addresses[1]
      ? user.contact.addresses[1]
      : user.contact.addresses[0]
    : null;

export const userHasRole = (role: string) => {
  const { AUTH_STORAGE_KEY } = config;
  const authCookie = Cookie.get(AUTH_STORAGE_KEY);
  
  let userHasRole = false;
  if (authCookie) {
    const parsedCookie = JSON.parse(authCookie);
    userHasRole = Array.isArray(parsedCookie?.roles) 
      ? parsedCookie?.roles?.some(c => c === role)
      : parsedCookie?.roles === role;
  }
  return userHasRole;
}
