import React from 'react';
import AnimateHeight from '../AnimateHeight';
import { TransitionGroup } from 'react-transition-group/'; // ES6
const defaultValue = false;
export const withCollapse = (
  WrappedComponent,
  defaultCollapsed = defaultValue
) => {
  class Collapseable extends React.Component {
    constructor(props) {
      super(props);
      this.state = { collapsed: defaultCollapsed };
    }

    onCollapseClicked = () => {
      const collapsed = !this.state.collapsed;
      const { onCollapse } = this.props;

      this.setState({
        collapsed
      });

      onCollapse && onCollapse(collapsed);
    };

    render() {
      const { collapsed } = this.state;
      const { onCollapse, className, ...rest } = this.props;

      const nextProps = {
        collapse: this.onCollapseClicked,
        collapsed,
        ...rest
      };

      return <WrappedComponent {...nextProps} />;
    }
  }

  return Collapseable;
};

/*
 * Can manage collections of collapseable children, the API is the same as the Collapseable HOC.
 * It relias on the keys of the elements to be unique in order to compare which is the active item.
 * Using indices will not work since removing a child will cause the
 */
export class CollapseableManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeItem: null };
  }

  onCollapseClicked = key => {
    const activeItem = this.state.activeItem === key ? null : key;

    this.setState({ activeItem });
  };

  renderChildren() {
    // TODO this is probably really ineffective.
    // Should compute the diff, instead of cloning all elements
    return React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        collapsed: child.key === this.state.activeItem,
        collapse: this.onCollapseClicked.bind(this, child.key)
      });
    });
  }

  render() {
    const { collapsed } = this.props;
    const animateHeight = collapsed ? 'auto' : 0;
    return (
      <React.Fragment>
        <AnimateHeight duration={500} height={animateHeight}>
          <TransitionGroup className="cart-items">
            {this.renderChildren()}
          </TransitionGroup>
        </AnimateHeight>
      </React.Fragment>
    );
  }
}

export default withCollapse;
