import React, { useEffect, useState } from 'react';
import { CenteredSpinner } from '../../../components/Spinner/Spinner';
import './SearchLoader.scss';
const SearchLoader = loading => {
  const [longLoading, setLongLoading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      loading && setLongLoading(true);
    }, 1000);
  }, [loading]);
  return longLoading ? (
    <div className="search-loader">
      <CenteredSpinner />
    </div>
  ) : null;
};

export default SearchLoader;
