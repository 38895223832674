import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { redirectToLogin } from '../../actions/app';
import {
  setProductFavorite,
  removeFavorite,
  setRecipeFavorite
} from '../../actions/auth';
import { authenticatedSelector } from '../../selectors/user';
import SearchResultModule from '../../modules/Search/SearchResultPage/SearchResultPage.tsx';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  fetchExtendedSearch,
  fetchPaginatedSearch,
  selectSearchResultPageProducts,
  selectSearchResultPageRecipes
} from '../../slices/searchSlice';
class SearchPage extends Component {
  state = {
    loading: true,
    currentStoreId: null
  };
  componentDidMount() {
    const { location, storeId, fetchExtendedSearch } = this.props;
    const query = qs.parse(location.search);
    fetchExtendedSearch({
      location: location,
      ...query,
      store: storeId
    })
      .then(d => {
        this.setState({ loading: false, currentStoreId: storeId });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  }
  componentDidUpdate(prevProps) {
    if (this.state.loading || this.props?.newSearch?.loading) return;
    const query = qs.parse(this.props.location.search);
    const prevQuery = qs.parse(prevProps.location.search);
    if (
      query.Q !== prevQuery.Q ||
      // query['type'] !== prevQuery['type'] ||
      (this.state.currentStoreId !== this.props.storeId && !this.state.loading)
    ) {
      const { location, fetchExtendedSearch } = this.props;
      this.setState({ loading: true });
      fetchExtendedSearch({
        location: location,
        ...query,
        page: 0,
        store: this.props.storeId
      })
        .then(() => {
          this.setState({ loading: false, currentStoreId: this.props.storeId });
        })
        .catch(err => {
          this.setState({ loading: false });
        });

      document.body.style.top = 0;
    }
  }
  render() {
    return <SearchResultModule {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  const location = props.location;
  return {
    isAuthenticated: authenticatedSelector(state),
    storeId: selectCurrentAssortment(state),
    location,
    searchString: qs.parse(location.search).Q,

    newSearch: {
      ...state.newSearch.searchResult,
      cmscontent: state.newSearch.searchResult.cmscontent.items,
      recipe: selectSearchResultPageRecipes(state),
      product: selectSearchResultPageProducts(state)
    }
  };
};

const mapDispatchToProps = {
  increaseItemQuantity,
  decreaseItemQuantity,
  redirectToLogin,
  addToCart,
  setProductFavorite,
  setRecipeFavorite,
  removeFavorite,
  fetchExtendedSearch,
  fetchPaginatedSearch
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchPage)
);
