import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cs from 'classnames';

import RichText from '../../containers/RichText';
import { CloseButton } from '../Buttons';

import { Message } from '../../types/episerver/message';
import {
  toggleNotification,
  setNotificationHeight
} from '../../actions/navigation';
import {
  addResizeListener,
  removeResizeListener
} from '../../lib/windowEvents';

import './NoticeBar.scss';

const NoticeBar: React.FC<Message> = ({
  colorHex,
  message,
  status,
  visible
}) => {
  const dispatch = useDispatch();

  // visible from episerver
  // status from redux state

  if (!visible) {
    return null;
  }
  const setHeight = () => {
    let notice = document.getElementById('noticeBar');

    if (notice) {
      dispatch(setNotificationHeight(notice.offsetHeight));
    }
  };

  useEffect(() => {
    dispatch(toggleNotification(visible));
  }, []);

  useEffect(() => {
    setHeight();
  }, [status, message]);

  useEffect(() => {
    addResizeListener(setHeight);

    return function cleanup() {
      removeResizeListener(setHeight);
    };
  });

  return (
    <div
      id="noticeBar"
      style={{ backgroundColor: colorHex }}
      className={cs('c-notice-bar', { active: status })}
    >
      <div className="c-notice-bar__container">
        <RichText text={message} />

        <div className="c-notice-bar__button">
          <CloseButton onClick={() => dispatch(toggleNotification(false))} />
        </div>
      </div>
    </div>
  );
};

export default NoticeBar;
