import React from 'react';
import PropTypes from 'prop-types';
import './Radio.scss';

const Radio = ({
  id,
  label,
  hint,
  isChecked,
  placeholder,
  formLoading,
  children,
  ...rest
}) => {
  return (
    <label className="c-stdradiobtn large">
      <input type="radio" name={id} id={id} readOnly={formLoading} {...rest} />
      <i />
      <span className="label">
        {label}
        {children}
      </span>
    </label>
  );
};

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  isChecked: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  formLoading: PropTypes.bool,
  children: PropTypes.any
};

export default Radio;
