import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { cartItemsBlockProductCombinationSelector } from '../../selectors/cart/cartSelector';
import { authenticatedSelector } from '../../selectors/user';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { redirectToLogin } from '../../actions/app';
import ProductTableView from '../../components/ProductTableView';
import ProductTableItem from '../../components/ProductTableView/ProductTableItem';
import './HomeList.scss';
import { Image } from '../../components/Image/Image';
import DefaultIcon from '../../components/assets/varor.svg';
import { Button } from '../../components/Buttons';
import Link from '../../components/Link';
import * as ga4 from '@citygross/analytics';
import { ga4ImpressionClickTracking_Product } from '../../lib/analytics/analytics';
import { formatPrice } from '../../store/middleware/analytics';

const impressionListName = 'Homelist';

class HomeList extends React.PureComponent {
  componentDidMount() {
    try {
      const { products } = this.props;

      const ga4ProductImpressions = products.map((prod, index) => {
        const ga4Item = {
          item_id: prod.gtin,
          item_name: prod.name,
          item_brand: prod.brand || 'City Gross',
          item_category:
            prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) || undefined,
          item_variant: prod.variant || undefined,

          item_list_name: impressionListName,
          item_list_id: impressionListName,
          index: index + 1,

          price: prod?.price?.ordinaryPrice?.price || 0,
          quantity: prod.quantity || 1,
          discount: 0
        };

        if (prod?.price?.hasPromotion && prod?.price.promotion) {
          ga4Item.quantity = prod?.price.promotion.minQuantity || 1;
          ga4Item.discount = Number(
            formatPrice(
              (prod?.price?.promotion?.minQuantity || 0) *
                prod?.price.ordinaryPrice.price -
                (prod?.price?.promotion?.value || 0) || 0
            )
          );
        }

        return ga4Item;
      });

      ga4.viewItemList({
        items: ga4ProductImpressions,
        item_list_id: impressionListName,
        item_list_name: impressionListName
      });
    } catch (error) {
      console.error(error);
    }
  }
  componentDidUpdate(prevProps) {
    const { products } = this.props;
    if (products && prevProps.products.length !== products.length) {
      try {
        const ga4ProductImpressions = products.map((prod, index) => {
          const ga4Item = {
            item_id: prod.gtin,
            item_name: prod.name,
            item_brand: prod.brand || 'City Gross',
            item_category:
              prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
              undefined,
            item_variant: prod.variant || undefined,

            item_list_name: impressionListName,
            item_list_id: impressionListName,
            index: index + 1,

            price: prod?.price?.ordinaryPrice?.price || 0,
            quantity: prod.quantity || 1,
            discount: 0
          };

          if (prod?.price?.hasPromotion && prod?.price.promotion) {
            ga4Item.quantity = prod?.price.promotion.minQuantity || 1;
            ga4Item.discount = Number(
              formatPrice(
                (prod?.price?.promotion?.minQuantity || 0) *
                  prod?.price.ordinaryPrice.price -
                  (prod?.price?.promotion?.value || 0) || 0
              )
            );
          }

          return ga4Item;
        });

        ga4.viewItemList({
          items: ga4ProductImpressions,
          item_list_id: impressionListName,
          item_list_name: impressionListName
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
  render() {
    const {
      products,
      storeId,
      noStoreAction,
      addToCart,
      increaseItemQuantity,
      decreaseItemQuantity,
      isAuthenticated,
      redirectToLogin,
      nonBoundProducts
    } = this.props;

    const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;

    return storeId ? (
      <>
        {products.length > 0 && (
          <div className="c-homelist u-bg-color-white">
            <h3 className="c-homelist__title">Bra att ha hemma</h3>
            <ProductTableView>
              {products
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((product, index) => (
                  <ProductTableItem
                    key={product.gtin}
                    product={product}
                    addToCart={() => addToCartFn(product)}
                    increaseItemQuantity={quantity =>
                      increaseItemQuantity(product.cartItemId, quantity)
                    }
                    decreaseItemQuantity={quantity =>
                      decreaseItemQuantity(product.cartItemId, quantity)
                    }
                    onProductTableItemClick={() => {
                      try {
                        ga4ImpressionClickTracking_Product({
                          product,
                          impressionListPosition: index,
                          impressionListName
                        });
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                ))}
              {nonBoundProducts?.map((product, index) => (
                <div className={'c-homelist__nonbound'} key={product}>
                  <div className="img table-column-xs-sm-4-24 table-column-md-lg-3-24 u-text-center">
                    <Image
                      noLazy={false}
                      src={DefaultIcon}
                      alt={product}
                      className={'u-img-responsive'}
                    />
                  </div>
                  <span
                    className="table-column-xs-sm-12-24 table-column-md-lg-9-24"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {product}
                  </span>
                  <span
                    className={
                      'c-producttable-product__unit-price table-column-xs-sm-6-24 table-column-md-lg-7-24 u-text-right'
                    }
                  ></span>
                  <span
                    className={'table-column-md-lg-5-24'}
                    style={{ color: '#a9a9a9', textAlign: 'right' }}
                  >
                    Tillfälligt slut i lager
                  </span>
                </div>
              ))}
            </ProductTableView>
          </div>
        )}
      </>
    ) : (
      <div className="c-recipe__assortmentinfo">
        <h2>Varor från hemmalistan</h2>
        <div className="c-recipe__assortmentinfoinner">
          <h3>Välj butik för att handla från ditt lokala sortiment</h3>
          <p>Tänk på att sortimentet kan variera mellan butikerna</p>
          <div className="c-recipe__assortmentinfoaction">
            <Button lg primary onClick={() => noStoreAction && noStoreAction()}>
              Välj butik
            </Button>
            <Link to={'/kundservice'}>Mer info & villkor</Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const products = props.products;
  const combineWithCartItems = cartItemsBlockProductCombinationSelector(
    state,
    products
  );

  return {
    products: combineWithCartItems,
    isAuthenticated: authenticatedSelector(state)
  };
}

const mapDispatchToProps = {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity,
  redirectToLogin
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeList)
);
