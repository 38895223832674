import styled from 'styled-components';
import Scrollable from '../../../components/Scrollable';
export const CartScroll = styled(Scrollable)`
  overflowy: auto;
  border-top: ${props => (props.showBorder ? '1px solid #E8E8E8' : 'none')};
  @supports (height: 100dvh) {
    height: ${props => `calc(100dvh - 64px - ${props.bottomOffset}px)`};
  }
  @supports not (height: 100dvh) {
    height: ${props =>
      `calc(${props.clientHeight}px - 64px - ${props.bottomOffset}px)`};
  }
  .scrollable__container {
    background: #f7f7f7;
  }
`;
