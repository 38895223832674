import { IImboxQuestion } from "../imbox/Question";
import { IImboxAnswer } from "../imbox/Answer";

const NAME = 'imbox';

export const FETCH_IMBOXCATEGORIES_RECEIVED = `${NAME}/FETCH_IMBOXCATEGORIES_RECEIVED`;
export const FETCH_IMBOXANSWER_RECEIVED = `${NAME}/FETCH_IMBOXANSWER_RECEIVED`;

export interface ImboxState {
    questions: IImboxQuestion[],
    answers: IImboxAnswer[],
    loading: boolean
}

export interface ImboxCategoriesReceivedAction {
    type: typeof FETCH_IMBOXCATEGORIES_RECEIVED;
    payload: IImboxQuestion[]
}

export interface ImboxAnswerReceivedAction {
    type: typeof FETCH_IMBOXANSWER_RECEIVED;
    payload: IImboxAnswer
}
