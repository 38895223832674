import React, { Component } from 'react';
import CreateAccount from '../../components/Login/CreateAccount/CreateAccount';
import { setMode } from '../../actions/shoppingOrder';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';
import { connect } from 'react-redux';

const registerLayoutStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '2em 0',
  minHeight: '50vh'
};

class RegisterPage extends Component {
  componentDidMount() {
    const {  setMode} = this.props;
    setMode(true, false, UNTOGGLE_BEHAVIOUR.none);
  }

  render() {
    return (
      <div style={registerLayoutStyle}>
        <div
          style={{ padding: '2em', backgroundColor: '#fff', width: '400px' }}
        >
          <CreateAccount {...this.props} canClose={() => {}} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = () => ({

});
const mapDispatchToProps = {
  setMode
};

export default  connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
;

