import React from 'react';
import { Store } from '../../types/episerver/store';
import StoreMap from '../Stores/SingleStore/StoreMap';
import './StoreMapContainer.scss';

type TStoreMapContainer = {
  store: Store;
};

const StoreMapContainer: React.FC<TStoreMapContainer> = ({ store }) => {
  return store ? (
    <div className="c-singlestore__storemap">
      <StoreMap storeLocation={store.storeLocation} name={store.name} />
    </div>
  ) : (
    <></>
  );
};

export default StoreMapContainer;
