import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
// ToastOptions

interface IProps {
  title?: string | null,
  message?: string | null,
  richText?: string | null,
  toastOpts: any // ToastOptions
}

export const makeToast = ({ title, message, richText, toastOpts }: IProps) : void => {
  const toastContent = (
    <div className="c-toast">
      {title && <strong>{title}</strong>}
      {message && (
        <Fragment>
          <hr />
          <p>{message}</p>
        </Fragment>
      )}
      {richText && (
        <Fragment>
          <hr />
          <div className="c-toast__content">{richText}</div>
        </Fragment>
      )}
    </div>
  );
  toast.error(toastContent, {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 20000,
    ...toastOpts
  });
};
