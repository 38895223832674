import { getDateInfo } from '@citygross/utils';
import { TOffer } from '../types/offers/offers';
import { EPriceVariant } from '@citygross/components';
import { IExtendedProduct } from '../types/storefront/product';

export const formatOfferAvailabilityDate = (
  dateStr: string,
  showYear?: boolean
): string => {
  if (!dateStr) return '';
  const date = getDateInfo(dateStr);
  const dayName = date.isTomorrow ? 'imorgon' : date.day?.name;
  return `${dayName} ${date.day.number}/${date.month.number}${
    showYear ? ` ${date.year}` : ''
  }`;
};

export const getOfferVariant = (offer: TOffer): EPriceVariant => {
  return offer?.membersOnly
    ? EPriceVariant.PRIO
    : offer?.displayKlipp
    ? EPriceVariant.KLIPP
    : EPriceVariant.REGULAR_OFFER;
};

export const getOfferLowestPrice = (product: IExtendedProduct, storeNumber) => {
  // @TODO need to adjust this when we implement loop54
  return product?.prices?.find(
    p =>
      p.storeNumber &&
      p.storeNumber === storeNumber &&
      p.displayLowestPriceLast30Days &&
      p.lowestPriceLast30Days
  )?.lowestPriceLast30Days;
};

export const offersAmount = '999';

export const offerWeek = {
  current: 'currentweekoffer',
  next: 'nextweekoffer'
};
