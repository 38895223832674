import styled from 'styled-components';

export const SideMenuContainer = styled.div`
  position: relative;
  padding-bottom: 32px;
  > * h2 {
    margin: 4px 0;
  }
  > * > * > h2 {
    margin: 4px 0;
  }
`;

export const SecondaryLinksContainer = styled.ul`
  padding: 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  svg {
    min-width: 16px;
  }
`;

export const SecondaryLink = styled.li`
  font-size: 13px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
`;

export const MainLinkContainer = styled.div`
  padding: 8px;
  color: #333333;
  &:hover {
    background: ${props => props.theme.palette?.lightest};
  }
`;

export const StyledMainLink = styled.h2`
  font-size: 18px;
`;

export const MainLinksWrapper = styled.div`
  @media (min-width: 1160px) {
    display: none;
  }
`;
