import { Navigation } from '../episerver/navigation/Navigation';

const NAME = 'navigation';

export const FETCH_NAVIGATION_REQUESTED = `${NAME}/FETCH_NAVIGATION_REQUESTED`;
export const FETCH_NAVIGATION_RECEIVED = `${NAME}/FETCH_NAVIGATION_RECEIVED`;
export const FETCH_NAVIGATION_FAILED = `${NAME}/FETCH_NAVIGATION_FAILED`;
export const TOGGLE_NOTIFICATION = `${NAME}/TOGGLE_NOTIFICATION`;
export const SET_NOTIFICATION_HEIGHT = `${NAME}/SET_NOTIFICATION_HEIGHT`;
export const SET_SIDEBAR = `${NAME}/SET_SIDEBAR`;

export interface INavigationReceivedAction {
  type: typeof FETCH_NAVIGATION_RECEIVED;
  payload: Navigation;
}
