import React, { useMemo } from 'react';
import {
  PaginationButton,
  Pagination as PaginationComponent
} from '@citygross/components';
import { useWindowSize } from '@citygross/utils';
import { Icons } from '@citygross/icons';
import './Pagination.scss';

type TPagination = {
  pageIndex?: number;
  totalCount?: number;
  pageCount?: number;
  itemsPerPage?: number;
  fetchPage?: (page: number) => void;
  parentRef?: React.RefObject<HTMLInputElement>;
};

const Pagination = ({
  pageCount,
  pageIndex,
  totalCount,
  fetchPage,
  itemsPerPage = 20
}: TPagination) => {
  if (!Number(totalCount) || totalCount === Infinity) return null;

  const size = useWindowSize();
  const maxPagesToShow = useMemo(() => {
    return (
      (pageCount || pageCount === 0) &&
      Math.min(
        pageCount,
        size && size.width < 576 ? (size.width > 480 ? 8 : 6) : 10
      )
    );
  }, [size]);

  return (
    <div className="c-pagination">
      <PaginationComponent
        currentPage={Number(pageIndex ?? 0) + 1}
        itemsPerPage={itemsPerPage}
        totalItems={totalCount}
        fetchItems={page => {
          fetchPage && fetchPage(page - 1);
          window?.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        }}
        numOfPaginationsToShow={maxPagesToShow || 1}
        rightIcon={<Icons.ChevronRight width={16} height={16} />}
        leftIcon={<Icons.ChevronLeft width={16} height={16} />}
      />
    </div>
  );
};

export default Pagination;
