import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { recipeFavoritesSelector } from '../../selectors/recipe/recipeSelectors';
import { fetchRecipesByIds } from '../../api/endpoints/recipe';
import RecipeList from '../../containers/RecipeList';
import * as ga4 from '@citygross/analytics';
import { fetchStoreNumberBySiteId } from '../../api/endpoints/delivery';
import Cookie from 'js-cookie';

const impressionListName = 'Favorite recipes';
class FavoriteRecipes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recipes: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getFavoriteRecipes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favoriteRecipes !== this.props.favoriteRecipes) {
      this.getFavoriteRecipes();
    }
  }

  getFavoriteRecipes() {
    const { favoriteRecipes } = this.props;
    const recipeIds = favoriteRecipes.map(favorite => favorite.itemNo);
    if (recipeIds.length > 0) {
      fetchRecipesByIds(recipeIds).then(({ data }) => {
        this.setState({
          recipes: data,
          loading: false
        });
        try {
          const assortmentValuesCookie = Cookie.get('assortment_values');
          const assortmentValues = JSON.parse(assortmentValuesCookie);

          fetchStoreNumberBySiteId(assortmentValues.userSiteId)
            .then(response => {
              const storeNumber = response.data?.storeNumber;
              const ga4ImpressionItems = data.map((recipe, index) => {
                let recipePrice = 0;

                const preview = recipe.previews?.find(
                  x => x.storeNumber == storeNumber
                );

                if (preview) recipePrice = preview.minimumPrice || 0;

                return {
                  item_id: recipe.id,
                  item_name: recipe.name,
                  item_brand: recipe.source || 'City Gross',
                  item_category:
                    '/recept' +
                      recipe.url?.substring(
                        0,
                        recipe.url.lastIndexOf('/') + 1
                      ) || undefined,
                  item_variant: recipe.id,

                  item_list_name: impressionListName,
                  item_list_id: impressionListName,
                  index: index + 1,

                  price: recipePrice,
                  quantity: 1,
                  discount: 0
                };
              });

              ga4.viewItemList({
                items: ga4ImpressionItems,
                item_list_id: impressionListName,
                item_list_name: impressionListName
              });
            })
            .catch(err => console.error(err));
        } catch (error) {
          console.error(error);
        }
      });
    } else {
      this.setState({
        recipes: [],
        loading: false
      });
    }
  }

  render() {
    const { recipes, loading } = this.state;

    return loading || (recipes && recipes.length > 0) ? (
      <RecipeList
        recipes={recipes}
        skeletons={12}
        fetching={loading}
        withoutPaginationAndFilter
        impressionListName={impressionListName}
      />
    ) : (
      <div className="c-account--empty">
        <p className="mb-10">
          Du har inte valt några favoriter bland våra recept än.
        </p>

        <Link className="c-cmdbtn primary" to="/recept">
          Kolla recept
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  favoriteRecipes: recipeFavoritesSelector(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteRecipes);
