import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';
import { getNavigation } from '../../actions/navigation';
import { getApplicationSettings } from '../../actions/app';
import { allUserInfo } from '../../actions/auth';
import {
  getSitesWithMeta,
  setAssortment,
  setInitialAssortmentValues
} from '../../actions/assortments';
import { getUserOrders } from '../../actions/order';
import { authenticatedSelector } from '../../selectors/user';
import { sendTracking } from '../../lib/analytics/analytics';
import config from '../../config/config';

import App from '../../components/App';
import { AppState } from '../../reducers';
import { AssortmentCookie } from '../../types/assortments';
import { activeStoreNoSelector } from '../../selectors/assortments';
import { getAssortmentCookie } from '../../lib/assortments';

const cookieStorage = new Cookies();

interface HydratableFC extends React.FC {
  required: Function[];
}

const AppContainer: HydratableFC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authenticated = useSelector((state: AppState) =>
    authenticatedSelector(state)
  );
  const activeStoreNo = useSelector((state: AppState) =>
    activeStoreNoSelector(state)
  );
  const assortments = useSelector((state: AppState) => state.assortments);
  const newCartPrep = useSelector((state: AppState) => state?.newCartPrep);
  const order = useSelector((state: AppState) => state?.order);

  useEffect(() => {
    if (activeStoreNo && authenticated) {
      userOrdersFunc();
    }
  }, [activeStoreNo, authenticated]);

  useEffect(() => {
    sendTracking({
      reactVersion: config.VERSION
    });

    const assortmentValues: AssortmentCookie = getAssortmentCookie();

    if (authenticated) {
      try {
        dispatch(allUserInfo(assortmentValues));
      } catch (e) {
        console.error(e);
      }
    } else {
      if (assortmentValues) {
        const { deliveryType, userPostalCode, userSiteId } = assortmentValues;
        dispatch(
          setInitialAssortmentValues(
            deliveryType,
            Number(userPostalCode),
            Number(userSiteId)
          )
        );
        if (deliveryType) {
          dispatch(setAssortment(deliveryType, userSiteId, userPostalCode));
        }
      }
    }

    const reqs = [
      dispatch(getNavigation()),
      dispatch(getApplicationSettings()),
      dispatch(getSitesWithMeta())
    ];

    // Set up event handler to poll new orders/cart on focus.
    Promise.all(reqs).catch(err =>
      console.error('Error during application bootstrap', err)
    );
  }, []);

  useEffect(() => {
    window.addEventListener('focus', handleRefetchingOrders, false);
    return () => {
      window.removeEventListener('focus', handleRefetchingOrders);
    };
  }, [
    location,
    assortments?.storePickerModalActive,
    newCartPrep?.show,
    order?.loading
  ]);

  const userOrdersFunc = () => {
    // dont uptade cart when storepicker modal is open
    if (
      assortments?.storePickerModalActive ||
      newCartPrep?.show ||
      order?.loading
    ) {
      return;
    }
    return new RegExp('/confirmation').test(location.pathname)
      ? console.log('Wait with orderfetching')
      : dispatch(getUserOrders());
  };

  const handleRefetchingOrders = () => {
    if (location.pathname !== '/checkout' && authenticated) {
      userOrdersFunc();
    }
  };

  return <App />;
};

AppContainer.required = [
  (state, params) => {
    return Promise.all([getNavigation(), getApplicationSettings()]).catch(err =>
      console.error('Error during application bootstrap', err)
    );
  }
];

export default AppContainer;
