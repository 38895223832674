import React, { useState } from 'react';
import {Button} from '../Buttons/Button';
import RichText from '../../containers/RichText';
import { convertToReadableTime } from "../../lib/time";
import { OpeningHours as OpeningHoursModel } from '../../types/episerver/store';
import './OpeningHours.scss';
import { isBefore, isToday } from 'date-fns';

interface IProps {
  openingHours: OpeningHoursModel,
  showHolidaysAmount?: number
}

interface Hour {
  day: string,
  time: string
}

const OpeningHours : React.FC<IProps> = ({ openingHours, showHolidaysAmount = 3 }) => {
    let openingHoursString : Hour[] = [];

    const [showAll, setShowAll] = useState(false);

    if (openingHours.monday) {
      const days = [
        openingHours.monday,
        openingHours.tuesday,
        openingHours.wednesday,
        openingHours.thursday,
        openingHours.friday,
        openingHours.saturday,
        openingHours.sunday
      ];
  
      const dayNamesSV = [
        'Måndag',
        'Tisdag',
        'Onsdag',
        'Torsdag',
        'Fredag',
        'Lördag',
        'Söndag'
      ];
      let tempTime = days[0];
      let tempDay = dayNamesSV[0];
  
      for (let i = 0; i <= days.length; i++) {
        if (i === days.length) {
          const day = `${tempDay === dayNamesSV[i - 1]
            ? tempDay : tempDay + ' - ' + dayNamesSV[i - 1]}:`;

          openingHoursString.push({day, time: `${convertToReadableTime(tempTime.opens)} - ${convertToReadableTime(tempTime.closes)}`});

        } else if (
          !(
            days[i].opens === tempTime.opens && days[i].closes === tempTime.closes
          )
        ) {
          const day = `${tempDay === dayNamesSV[i - 1]
            ? tempDay : tempDay + ' - ' + dayNamesSV[i - 1]}:`

            openingHoursString.push({day, time: `${convertToReadableTime(tempTime.opens)} - ${convertToReadableTime(tempTime.closes)}`});

          tempTime = days[i];
          tempDay = dayNamesSV[i];
        }
      }
    }

    // const test = openingHours.holidays.map(holiday => )

    const maxHolidaysLength = showAll ? openingHours.holidays.length : showHolidaysAmount;
    const filteredOpeningHours = openingHours.holidays.filter(x => (!isBefore(new Date(x.date), new Date()) || isToday(new Date(x.date))));
  
    return (
      <div className="c-openinghours">

          {openingHoursString.map(hour => (
            <h4 key={hour.day+hour.time}>
              <span>{hour.day}</span> <span>{hour.time}</span>
            </h4>
          ))}

          { filteredOpeningHours.slice(0, maxHolidaysLength).map(holiday =>
            <h4 className="c-openinghours__holiday" key={holiday.date+holiday.name}>
              <span>{holiday.name}</span> <span>{convertToReadableTime(holiday.opens)} - {convertToReadableTime(holiday.closes)}</span>
            </h4>
          )}

          {filteredOpeningHours.length > showHolidaysAmount &&
            <div className="c-openinghours__foldout">
              <Button 
                primary 
                fullwidth 
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? 'Visa färre' : 'Visa fler'}
              </Button>
            </div>
          }
      </div>
    )

    ;
};


export default OpeningHours;
