import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';
import LoadBlock from '../../components/LoadingBlock';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  getSingleOffer,
  selectODP,
  selectODPFetching
} from '../../slices/offersSlice';
import { OfferSingle } from '../../modules/OfferSingle/OfferSingle';
import { TOffer } from '../../types/offers/offers';

type Props = {
  getOfferById: (id: string) => void;
  id: string;
  isAuthenticated: boolean;
  isFetching: boolean;
  isFlyout: boolean;
  offer: TOffer;
};

const OfferSinglePage = (props: Props) => {
  const dispatch = useDispatch();
  const storeId = useSelector(selectCurrentAssortment);

  const getOffer = () => {
    const { isFetching, id } = props;

    if (id && !isFetching) {
      dispatch(getSingleOffer({ offerId: id, ignoreStore: true }));
    }
  };

  useEffect(() => {
    getOffer();
  }, [props.id, storeId]);

  return props.offer && !props.isFetching ? (
    <OfferSingle
      offer={props.offer}
      currentBreadCrumb={{
        name: props.offer.name,
        link: { url: props.offer.url }
      }}
      storeId={storeId}
      isFetching={props.isFetching}
      isAuthenticated={props.isAuthenticated}
      isFlyout={props.isFlyout}
    />
  ) : (
    <LoadBlock height={40} />
  );
};

const mapStateToProps = (state, props) => {
  const offerId = props.match.params.offerId;

  return {
    id: offerId,
    storeId: selectCurrentAssortment(state),
    isFetching: selectODPFetching(state),
    isAuthenticated: authenticatedSelector(state),
    offer: selectODP(state)
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferSinglePage)
);
