import React from 'react';
import PropTypes from 'prop-types';

const FlyoutFooter = ({ children }) => (
  <div className="pnlfoot">{children}</div>
);

FlyoutFooter.propTypes = {
  children: PropTypes.any
};

export default FlyoutFooter;
