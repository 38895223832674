import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import cs from 'classnames';
import Link from '../../components/Link';
import './CardGrid.scss';

import * as pagelayouts from '../../prop-types/PageTypePropType';

type TCardGrid = {
  fontColor: string;
  link: any;
  linkText: string;
  loading: boolean;
  noPad: boolean;
  pageLayout: string;
  title: string;
  renderItem: (maxItems: number) => React.ReactNode;
  isCarousel?: boolean;
  backgroundColor?: string;
};

const CardGrid = ({
  fontColor,
  link,
  linkText,
  noPad = false,
  pageLayout = pagelayouts.FULL,
  title,
  renderItem,
  isCarousel,
  backgroundColor
}: TCardGrid) => {
  return (
    <div
      className={cs(
        'b-productblock',
        { 'b-productblock--no-pad': noPad },
        { 'b-productblock--carousel': isCarousel },
        pageLayout.toLowerCase()
      )}
      style={{ backgroundColor: backgroundColor ? backgroundColor : 'unset' }}
    >
      {title && <h2 style={{ color: fontColor }}>{title}</h2>}
      {isCarousel ? (
        renderItem(6)
      ) : (
        <div className={'grid-container-mobileGutter'}>
          <MediaQuery maxWidth={600}>{renderItem(2)}</MediaQuery>
          <MediaQuery minWidth={601} maxWidth={788}>
            {renderItem(3)}
          </MediaQuery>
          <MediaQuery minWidth={789} maxWidth={1212}>
            {renderItem(4)}
          </MediaQuery>
          <MediaQuery minWidth={1213} maxWidth={1700}>
            {renderItem(5)}
          </MediaQuery>
          <MediaQuery minWidth={1701}>{renderItem(6)}</MediaQuery>
        </div>
      )}

      {link && linkText && (
        <div className="b-productblock__moreprods">
          <Link link={link} style={{ color: fontColor }}>
            {linkText}
          </Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({});

export default withRouter(connect(mapStateToProps)(CardGrid));
