import qs from 'query-string';
import { requestRaw, request } from '../../lib/request';
import { AuthResponse } from '../../types/auth/AuthResponse';

const opts = {
  grant_type: 'password',
  scope: 'customerserviceapi epiapi'
};

interface LoginResponse {
  data: AuthResponse;
}
export const login = (
  username: string,
  password: string
): Promise<LoginResponse> => {
  const reqOpts = {
    method: 'POST',
    headers: {
      'Content-type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
      Authorization: ''
    },
    body: qs.stringify({ ...opts, username, password })
  };

  return requestRaw('/connect/token', reqOpts);
};

interface AdminLoginRequest {
  data: {
    id: string;
    token: string;
  };
}

export const fetchCustomerToken = (token: string): Promise<AdminLoginRequest> =>
  request(`/adminlogin/${token}`);
