import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userKdbIdSelector, authenticatedSelector } from '../../selectors/user';
import { fetchMostBoughtRecipes, fetchRecipesByIds } from '../../api/endpoints';
import RecipeList from '../../containers/RecipeList';
import RichText from '../../containers/RichText';

const DEFAULT_DAYS_TO_FETCH = 90;
const DEFAULT_MAX_ITEMS_TO_FETCH = 50;
const DEFAULT_NBR_OF_SKELETONS = 6;

class MostBoughtRecipes extends Component {
  state = {
    fetching: true,
    recipes: [],
    fetchError: false,
    show: null
  };

  componentDidMount() {
    const { isAuthenticated } = this.props;

    if (isAuthenticated) {
      this.getRecipes();
    }

    this.setState({
      show: true
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated && prevProps.kdbId !== this.props.kdbId) {
      this.getRecipes();
    }
  }

  getRecipes() {
    const { daysToFetch, maxItemsToFetch } = this.props;

    if (daysToFetch > 0 && maxItemsToFetch > 0) {
      fetchMostBoughtRecipes(daysToFetch, maxItemsToFetch)
        .then(data => {
          const recipeIds = data;
          if (recipeIds.length > 0) {
            fetchRecipesByIds(recipeIds)
              .then(({ data }) => {
                if (data && data.length > 0) {
                  this.setState({
                    recipes: data,
                    fetching: false
                  });
                } else {
                  this.setState({
                    recipes: [],
                    fetching: false
                  });
                }
              })
              .catch(() => {
                this.setState({
                  fetchError: true,
                  recipes: null,
                  fetching: false
                });
              });
          } else {
            this.setState({
              recipes: [],
              fetching: false
            });
          }
        })
        .catch(err => {
          console.error(err); // eslint-disable-line no-console
          this.setState({
            fetchError: true,
            recipes: null,
            fetching: false
          });
        });
    } else if (daysToFetch === 0 && maxItemsToFetch === 0) {
      this.setState({
        fetching: false,
        recipes: []
      });
    }
  }

  render() {
    const { fetchError, fetching, recipes, show } = this.state;
    const {
      isAuthenticated,
      skeletons,
      notLoggedInText,
      missingDataText
    } = this.props;

    if (!isAuthenticated) {
      return (
        <div className="c-most-bought-recipes">
          <RichText text={notLoggedInText} />
        </div>
      );
    }

    return (
      show && (
        <div className="c-most-bought-recipes">
          {((!fetching && !recipes) || fetchError) && (
            <p>
              Just nu kan vi inte hämta dina mest köpta recept. Försök igen
              senare.
            </p>
          )}
          {!fetching && recipes && recipes.length === 0 && (
            <RichText text={missingDataText} />
          )}
          {(fetching || (recipes && recipes.length > 0)) && (
            <RecipeList
              skeletons={skeletons}
              recipes={recipes}
              fetching={fetching}
              withoutPaginationAndFilter
            />
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = state => {
  return {
    kdbId: userKdbIdSelector(state),
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {};

MostBoughtRecipes.propTypes = {
  daysToFetch: PropTypes.number,
  maxItemsToFetch: PropTypes.number,
  skeletons: PropTypes.number,
  missingDataText: PropTypes.string,
  notLoggedInText: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  kdbId: PropTypes.number
};

MostBoughtRecipes.defaultProps = {
  daysToFetch: DEFAULT_DAYS_TO_FETCH,
  maxItemsToFetch: DEFAULT_MAX_ITEMS_TO_FETCH,
  skeletons: DEFAULT_NBR_OF_SKELETONS,
  notLoggedInText:
    '<p>Här kan du se dina mest köpta recept när du har loggat in.</p>',
  missingDataText:
    '<p>Du har inte handlat några recept och vi kan därför inte visa dina mest köpta recept.</p>'
};

export default connect(mapStateToProps, mapDispatchToProps)(MostBoughtRecipes);
