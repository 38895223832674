import { Box, Button } from '@citygross/components';
import React from 'react';
import { useTheme } from 'styled-components';
import * as styles from './SideModalRegister.styles';
import * as registerStyles from './SideModalRegister.styles';
import { BodyText } from '@citygross/typography';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import { connect, useDispatch } from 'react-redux';
import { setCurrentRegisterStep } from '../../../slices/loginSlice';
import { registerSteps } from './SideModalRegister';
import { ga4RegisterVerify } from '../../../lib/analytics/analytics';

type TLoginEmail = {} & IAnimatedStepsChildProps;

const RegisterVerify = ({ ...props }: TLoginEmail) => {
  // @ts-ignore
  const { goToStep, stepKey, customer } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const submit = e => {
    e.preventDefault();
    ga4RegisterVerify();
    goToStep &&
      (stepKey || stepKey === 0) &&
      goToStep(registerSteps.REGISTER_CREATE, stepKey);
    dispatch(
      setCurrentRegisterStep({
        currentStep: registerSteps.REGISTER_CREATE,
        previousStep: stepKey
      })
    );
  };

  return (
    <styles.RegisterWrapper>
      <styles.RegisterInputContainer onSubmit={e => submit(e)}>
        <styles.RegisterTitle>
          Verifiera dina folkbokföringsuppgifter
        </styles.RegisterTitle>

        <Box
          background={theme.palette?.yellowLight}
          rounded={false}
          padding={theme.spacings?.md}
        >
          <styles.RegisterNewsBoxTitle>
            Fördelar som Prio-kund
          </styles.RegisterNewsBoxTitle>
          <registerStyles.VerifyBox>
            <registerStyles.VerifyBoxItem>
              <BodyText fontWeight={'semiBold'}>Personnummer*</BodyText>
              <BodyText>{customer?.parInfo?.personalNumber}</BodyText>
            </registerStyles.VerifyBoxItem>
            <registerStyles.VerifyBoxItem>
              <BodyText fontWeight={'semiBold'}>Fullständiga namn*</BodyText>
              <BodyText>
                {customer?.parInfo?.firstName} {customer?.parInfo?.lastName}
              </BodyText>
            </registerStyles.VerifyBoxItem>
            <registerStyles.VerifyBoxItem>
              <BodyText fontWeight={'semiBold'}>Adress*</BodyText>
              <BodyText>{customer?.parInfo?.streetAddress}</BodyText>
              <BodyText>
                {customer?.parInfo?.zipCode} {customer?.parInfo?.city}
              </BodyText>
            </registerStyles.VerifyBoxItem>
          </registerStyles.VerifyBox>
        </Box>

        <Button color={'secondary'} fullWidth onClick={submit}>
          Fortsätt
        </Button>
      </styles.RegisterInputContainer>
    </styles.RegisterWrapper>
  );
};
function mapStateToProps(state) {
  return {
    ...state.customer
  };
}
export default connect(mapStateToProps)(RegisterVerify);
