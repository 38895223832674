import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LazyProgressivePicture, ImageFitTypes } from '../ProgressivePicture';
import { TOffer } from '../../types/offers/offers';
import './OfferCard.scss';
import { Icons } from '@citygross/icons';
import { CgButton, ECgButtonSize, PriceTag } from '@citygross/components';
import { MixCampaign } from '../MixCampaign';
import { comparisonTypes } from '@citygross/utils';
import { getOfferVariant } from '../../lib/offerInfo';
import ProductPlaceholder from '../assets/produkt-fallback.svg';
import cs from 'classnames';

type TOfferCard = {
  colStructure?: string;
  offer: TOffer;
  onCarousel?: boolean;
};

export const OfferCard = ({ colStructure, offer, onCarousel }: TOfferCard) => {
  const router = useHistory();
  const { pathname } = useLocation();

  const linkTo = {
    // TODO: revisit this path when we have a proper url from api. also revisit ROUTE_OFFER_PATTERN in lib/route/defaults.ts
    pathname: `${pathname}/-k${offer.id}`,
    state: { flyout: true, title: 'Erbjudande', nopadding: true, tight: false }
  };

  return (
    <div
      className={cs(colStructure, {
        'p-carousel_card': onCarousel
      })}
    >
      <Link className="offer-card-container" draggable={false} to={linkTo}>
        <div className="offer-card-upper">
          <div className="image-container">
            <div className="image">
              <LazyProgressivePicture
                fitType={ImageFitTypes.Contain}
                inheritHeight
                largeImage={{ url: offer.imageUrl }}
                placeholder={{ image: ProductPlaceholder }}
                showThumb={false}
              />
            </div>
          </div>

          <div className="header">
            <h3 className="title">{offer.name}</h3>

            {offer.brand && offer.weightVolume && (
              <div className="brand">
                {offer.brand}, {offer.weightVolume}
              </div>
            )}
          </div>
        </div>

        <div className="offer-card-content">
          {!!offer.minAmount && (
            <MixCampaign
              promotion={{
                label: `Handla andra varor för ${offer.minAmount}:-*`
              }}
            />
          )}

          <PriceTag
            multiPrice={offer.products?.[0]?.minQuantity}
            pant={offer.requiresReturnablePackageDeposit}
            price={offer.price}
            priceVariant={getOfferVariant(offer)}
            unit={comparisonTypes(offer.products[0].priceDetails.unit) || ''}
          />

          <div className="price-details">
            {offer.regularRetailPrice && (
              <div className="ord-price">
                Ord. pris {offer.regularRetailPrice}{' '}
                {offer.requiresReturnablePackageDeposit && '+ pant'}
              </div>
            )}

            {offer.comparisonPrice && (
              <div className="comp-price">Jfr pris {offer.comparisonPrice}</div>
            )}
          </div>

          {!!offer.maxAppliedPerReceipt && (
            <div className="max-per-receipt">
              Erbjudande gäller {offer.maxAppliedPerReceipt} köp per kund
            </div>
          )}

          <CgButton
            flexReverse
            fullWidth
            icon={<Icons.ChevronRight width={18} height={18} />}
            onClick={() => {
              router.push(linkTo);
            }}
            size={ECgButtonSize.small}
            variant={1}
          >
            Välj
          </CgButton>
        </div>
      </Link>
    </div>
  );
};

OfferCard.defaultProps = {
  colStructure: 'l-column-30_xs-30_sm-20_md-15_lg-12_xlg-10-mobileGutter'
};
