import NotFound from './components/NotFound';

export const makeRegistry = (reg, defaultWith = NotFound) => {
  return {
    lookup: (name, defaultValue = defaultWith) => {
      const comp = reg[name] || defaultValue;
      return comp;
    },
    registry: () => reg,
    keys: () => Object.keys(reg),
    components: () => Object.values(reg)
  };
};
