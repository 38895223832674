import React from 'react';
import classNames from 'classnames';
import './ToggleButton.scss';

interface IProps {
  fullwidth?: boolean,
  highlighted?: boolean,
  active?: boolean,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  icon?: string,
  className?: string,
  // for the ...rest params
  [x:string]: any
}

export const ToggleButton : React.FC<IProps> = ({
  fullwidth,
  highlighted,
  active,
  children,
  onClick,
  icon,
  className = '',
  ...rest
}) => {
  return (
    <button
      {...rest}
      onClick={onClick}
      className={classNames('c-togglebtn', className, {
        active,
        fullwidth,
        highlighted
      })}
    >
      <span className="c-togglebtn__content">
        {children}
        {icon && (
          <img
            src={icon}
            className={classNames('c-togglebtn__icon', { active })}
            alt="truck"
            width="25"
            style={{ display: 'inline', marginLeft: '5px' }}
          />
        )}
      </span>
    </button>
  );
};
