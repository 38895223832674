import Cookie from "js-cookie";

const wrap = (fn, defaultValue) => {
  try {
    return fn();
  } catch (err) {
    return defaultValue;
  }
};

const doConfigure = (auth, configureApi) =>
  wrap(() => configureApi(JSON.parse(auth)), null);

const isAuthenticated = item =>
  wrap(() => !!(item && JSON.parse(item)['expires_in'] > Date.now()), false);

const isLoggedInAsCustomer = item =>
  wrap(() => !!(item && JSON.parse(item)['as_customer']), false);

export const configure = (key, configureApi, initialState) => {
  const auth = Cookie.get(key);

  if (!auth) {
    return initialState;
  }
  
  doConfigure(auth, configureApi);

  return {
    ...initialState,
    isAuthenticated: isAuthenticated(auth),
    isLoggedInAsCustomer: isLoggedInAsCustomer(auth)
  };
};
