import React from 'react';
import { Link } from 'react-router-dom';
import { Store } from '../../types/episerver/store';
import RichText from '../../containers/RichText';
import ProgressivePicture, {
  ImageFitTypes
} from '../../components/ProgressivePicture';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import './SingleStore.scss';
import Image from '../../components/Image';
import { StoreSocial } from '../../components/Stores/SingleStore/StoreSocial';
import { StoreService } from '../../types/episerver/storeservice';
import StoreInfo from '../../components/StoreInfo';
import StoreMapContainer from '../../components/StoreMapContainer';
import StoreOpeningHours from '../../components/StoreOpeningHours';

interface IProps {
  store: Store;
  storeServices?: StoreService[] | null;
}

const SingleStore: React.FC<IProps> = ({ store, storeServices }) => {
  const hasInfoPlaceholderBlock = store.mainContent.find(
    block => block.type == 'InfoPlaceholderBlock'
  );
  const hasMapPlaceholderBlock = store.mainContent.find(
    block => block.type == 'MapPlaceholderBlock'
  );
  const hasOpeningHoursPlaceholderBlock = store.mainContent.find(
    block => block.type == 'OpeningHoursPlaceholderBlock'
  );
  return (
    <div className="p-singlestore">
      <div className="p-singlestore__storeimage">
        <ProgressivePicture
          largeImage={store.mainImage}
          smallImage={store.mobileImage}
          inheritHeight
          fitType={ImageFitTypes.Cover}
        />
      </div>
      <div className="p-singlestore__wrapper">
        <div className="p-singlestore__headerlink">
          <Link to={'/butiker'}>Se alla våra butiker</Link>
        </div>

        <div className="p-singlestore__container">
          <div className="p-singlestore__header">
            <h1>{store.name}</h1>
            {store.mainBody && <RichText text={store.mainBody} />}
          </div>
          {!hasInfoPlaceholderBlock && <StoreInfo store={store} />}
          {!hasOpeningHoursPlaceholderBlock && (
            <StoreOpeningHours store={store} />
          )}
          {!hasMapPlaceholderBlock && <StoreMapContainer store={store} />}

          <ContentBlocksArea blocks={store.mainContent} />

          <div className="p-singlestore__info">
            <div className="p-singlestore__infocolumn p-singlestore__infocolumn--gray">
              <h2>Vårt serviceutbud</h2>
              {storeServices
                ?.filter(x => store.services[x.code])
                .map(service => (
                  <div className="p-singlestore__storeservice">
                    <Image
                      className="c-store__service-list-image"
                      src={service.image.url}
                      alt={service.altText}
                    />
                    {service.name}
                  </div>
                ))}
            </div>
            {store.facebookLink && (
              <div className="p-singlestore__infocolumn">
                <StoreSocial facebookLink={store.facebookLink} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleStore;
