import styled from 'styled-components';

export const CartExtraInfoContainer = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: ${props => (props.isReplacement ? `0 16px 16px` : '0 16px')};
  @media (min-width: 1360px) {
    padding: ${props => (props.isReplacement ? `16px` : '0 16px')};
  }
`;

type TCartProgressContainer = {
  height: number;
};

export const CartProgressWrapper = styled.div<TCartProgressContainer>`
  transition: all 1000ms ease 1000ms;
  max-height: ${props => props.height}px;
  visibility: ${props => (props.height ? 'visible' : 'hidden')};
  overflow: ${props => (props.overflow ? 'visible' : 'hidden')};
`;
export const CartProgressContainer = styled.div<TCartProgressContainer>`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  background: #f1f1f1;
  border-radius: 4px;
  ${props =>
    props.isReplacement
      ? `
    margin-top: 12px ;
    `
      : `
    margin: 16px 0;
    `}
`;
