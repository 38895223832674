import {
  FETCH_ORDERS,
  FETCH_ORDERS_RECEIVED,
  FETCH_ORDERS_FAILED,
  ORDER_CREATED,
  ORDER_DELETED,
  ORDER_INITIAL_LOAD,
  ORDERS_LOADED
} from '../../types/reducers/orders';
import { Order } from '../../types/order/Order';
import { Cart } from '../../types/order/Cart';

export const fetchOrders = () => {
  return { type: FETCH_ORDERS };
};
export const ordersLoaded = () => {
  return { type: ORDERS_LOADED };
};
export const setOrderInitialLoad = () => {
  return { type: ORDER_INITIAL_LOAD };
};

// TODO: Remove null[] when we updated TS
export const recieveOrders = (orders: Order[], carts: (Cart | null)[]) => {
  return {
    type: FETCH_ORDERS_RECEIVED,
    orders,
    carts,
    loading: carts?.length > 0
  };
};

export const fetchOrdersFailed = () => {
  return { type: FETCH_ORDERS_FAILED };
};
