import React from 'react';
import ReactModal from 'react-modal';

import './Modal.scss';
import { Button } from '../Buttons';
import { IconButton } from '@citygross/components';
import { Icons } from '@citygross/icons';

interface IModal {
  isOpen: boolean;
  closeModal: () => void;
  modalStyles?: string;
  overlayStyles?: string;
  buttonText: string;
  children: React.ReactNode;
  onButtonClick?: () => void;
}

const Modal: React.FC<IModal> = ({
  isOpen,
  closeModal,
  modalStyles,
  overlayStyles,
  buttonText,
  children,
  onButtonClick
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={modalStyles}
      overlayClassName={overlayStyles}
      ariaHideApp={false}
    >
      <div className={'c-newModal'}>
        <div className={'c-newModal__close'}>
          <IconButton
            icon={<Icons.Cross />}
            color={'lightest'}
            borderRadius={100}
            onClick={() => (onButtonClick ? onButtonClick() : closeModal())}
          />
        </div>
        <div className={'c-newModal__body'}>{children}</div>
        <div className={'c-newModal__buttonContainer'}>
          <Button
            onClick={() => (onButtonClick ? onButtonClick() : closeModal())}
            buttonType={'blue'}
            lg
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

Modal.defaultProps = {
  isOpen: false,
  closeModal: () => {},
  modalStyles: 'NewModal',
  overlayStyles: 'Overlay'
};

export default Modal;
