import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import Spinner from '../../Spinner';
import { propagatePropsToFormChildren } from '../lib';
import FormItem from '../FormItem';
import ErrorMessage from '../ErrorMessage';
import SubmitButton from '../SubmitButton';
import './Form.scss';

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [], // Array of all FormItems
      errors: [], // Array of FormItem IDs
      hasUnsavedChanges: [], // Array of FormItem IDs
      recaptchaResponse: null,
      recaptchaNotChecked: false
    };
  }

  handleItemValidation = (id, validationResult) => {
    const { isValid } = validationResult;

    this.setState(prevState => {
      const errors = prevState.errors.filter(error => id !== error.id);

      if (isValid === false) {
        errors.push({
          id,
          ...validationResult
        });
      }

      return {
        errors: errors
      };
    });
  };

  setItem = (id, validate, getProps, getValue) => {
    this.setState(prevState => {
      const items = prevState.items.filter(prevItem => id !== prevItem.id);

      items.push({
        id: id,
        validate: validate,
        getProps: getProps,
        getValue: getValue
      });

      return {
        items: items
      };
    });
  };

  getValueById = id => {
    const { items } = this.state;
    const item = items.find(item => item.id === id);

    return item.getValue();
  };

  removeItem = id => {
    this.setState(prevState => ({
      errors: prevState.errors.filter(errorId => id !== errorId),
      items: prevState.items.filter(item => id !== item.id)
    }));
  };

  ensureValid = async () => {
    const { items } = this.state;
    const { useRecaptcha } = this.props;
    const { recaptchaResponse } = this.state;
    let hasErrors = false;

    if (useRecaptcha) {
      if (!recaptchaResponse) {
        hasErrors = true;
      }
      if (!recaptchaResponse) {
        this.setState({
          recaptchaNotChecked: true
        });
      }
    }

    await Promise.all(
      items.map(item => {
        return item.validate();
      })
    ).then(res => {
      hasErrors = res.some(r => !r);
    });

    if (hasErrors) {
      return false;
    }

    return true;
  };

  setUnsavedChanges = (id, toggle) => {
    this.setState(prevState => {
      const newState = prevState.hasUnsavedChanges.filter(
        unsavedChangesId => unsavedChangesId !== id
      );

      if (toggle) {
        newState.push(id);
      }

      if (newState.length > 0) {
        this.props.setSubmitted(false);
      } else {
        this.props.setSubmitted(true);
      }

      return newState;
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const {
      onSubmit = () => {},
      setSubmitted = () => {},
      useRecaptcha
    } = this.props;
    const { recaptchaResponse } = this.state;
    const { items } = this.state;
    const isValid = await this.ensureValid();

    if (isValid) {
      setSubmitted(true);
      const data = {};
      items.forEach(item => {
        data[item.id] = item.getValue();
      });

      if (useRecaptcha) {
        onSubmit(data, recaptchaResponse);
      } else {
        onSubmit(data);
      }
    } else {
      setSubmitted(false);
    }
  };

  onRecaptchaChange = token => {
    if (token) {
      this.setState({
        recaptchaResponse: token,
        recaptchaNotChecked: false
      });
    } else {
      this.setState({
        recaptchaResponse: null,
        recaptchaNotChecked: false
      });
    }
  };

  render() {
    const {
      children,
      showRequiredInfo = true,
      loading,
      errorList: externalErrors,
      useRecaptcha
    } = this.props;
    const { items, errors, recaptchaNotChecked } = this.state;

    const errorList = errors.map(error => {
      const errorItem = items.find(item => item.id === error.id);

      return {
        ...error,
        id: errorItem.id,
        validationHint: errorItem.getProps().validationHint
      };
    });

    const childProps = {
      [FormItem]: {
        validationCb: this.handleItemValidation,
        setItem: this.setItem,
        removeItem: this.removeItem,
        getValueById: this.getValueById,
        setUnsavedChanges: this.setUnsavedChanges,
        formLoading: loading
      },
      [ErrorMessage]: {
        errors: errorList,
        showRequiredInfo: showRequiredInfo,
        externalErrors: externalErrors,
        recaptchaNotChecked: useRecaptcha ? recaptchaNotChecked : false
      },
      [SubmitButton]: {
        onSubmit: this.handleSubmit,
        onRecaptchaChange: useRecaptcha && this.onRecaptchaChange
      }
    };

    return (
      <form
        method="post"
        // POST is not really used, but needed to avoid tracking user
        // sensitive data in analytics in case js breaks for some reason.
        className={cs('c-stdform', { loading: loading })}
        onSubmit={this.handleSubmit}
        onChange={this.props.onChange}
        autoComplete="off"
        noValidate
      >
        {loading && (
          <div className="c-stdform__spinner">
            <Spinner dark />
          </div>
        )}
        {propagatePropsToFormChildren(children, childProps)}
      </form>
    );
  }
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  setSubmitted: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  showRequiredInfo: PropTypes.bool.isRequired,
  useRecaptcha: PropTypes.bool,
  errorList: PropTypes.arrayOf(PropTypes.string)
};

Form.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
  setSubmitted: () => {},
  loading: false,
  showRequiredInfo: true
};

export default Form;
