import { moveCartToNewStoreId, clearCart } from '../../api/endpoints';
import { CartItem } from '../../types/cart/CartItem';
import {
  prepareNewCart,
  resetNewCart,
  togglePrepareNewCartLoading
} from './sync';

const itemType = 'Item';
const recipeType = 'RecipeBag';
const cateredMealType = 'CateredMeal';

const checkOnlyItemNo = (item, arr, isRecipe?) =>
  arr?.some(old => {
    return isRecipe
      ? old?.recipeId === item?.recipeId
      : old.itemNo === item.itemNo;
  });

const checkIsItemReplaced = (item, newArr) => {
  // check for same gtin or by name... currently no other way to know if they are related?
  return newArr?.some(
    _item => _item.gtin === item.gtin || _item.name === item.name
  );
};
const sortByName = arr => arr.sort((a, b) => a?.name?.localeCompare(b?.name));

const checkCartDiff = (newCartItems, oldCartItems, isRecipe, cartDiffObj) => {
  sortByName(oldCartItems)?.forEach(oldItem => {
    !checkOnlyItemNo(oldItem, newCartItems) &&
      !checkIsItemReplaced(oldItem, newCartItems) &&
      cartDiffObj.removedItems.push(
        isRecipe ? { ...oldItem, subType: 'RecipeBag' } : oldItem
      );
  });
};

type TMapItems = {
  items: CartItem[];
  recipes: CartItem[];
  cateredMeals: CartItem[];
};

const mapItems = arr => {
  const mapItems: TMapItems = {
    items: [],
    recipes: [],
    cateredMeals: []
  };
  if (!arr?.items) return mapItems;
  arr.items.forEach(item => {
    if (item.subType === itemType) mapItems.items.push(item);
    if (item.subType === recipeType) mapItems.recipes.push(item);
    if (item.subType === cateredMealType) mapItems.cateredMeals.push(item);
  });
  return mapItems;
};

type TCartDiff = {
  removedItems: CartItem[];
};

const newCartIsDiff = (newCart, oldCart, isFromEndpoint): TCartDiff => {
  let newItems;
  let oldItems;
  const cartDiff: TCartDiff = {
    removedItems: []
  };

  if (!newCart?.items) return cartDiff;
  newItems = mapItems(newCart);
  if (isFromEndpoint) {
    oldItems = mapItems(oldCart);
  }

  const oldGroceries = isFromEndpoint ? oldItems?.items : oldCart?.items?.items;
  const oldRecipes = isFromEndpoint
    ? oldItems?.recipes?.length > 0 && oldItems?.recipes[0]?.editableRecipes
    : oldCart?.recipes?.receptkassen?.editableRecipes;
  const oldCateredMeals = isFromEndpoint
    ? []
    : oldCart?.cateredMeals?.cateredMeals;

  if (newItems?.cateredMeals && oldCateredMeals) {
    checkCartDiff(newItems?.cateredMeals, oldCateredMeals, false, cartDiff);
  }

  if (newItems?.items && oldGroceries) {
    checkCartDiff(newItems?.items, oldGroceries, false, cartDiff);
  }
  if (
    newItems?.recipes?.length > 0 &&
    newItems?.recipes[0]?.editableRecipes?.length > 0 &&
    oldRecipes &&
    oldRecipes?.length > 0
  ) {
    checkCartDiff(
      newItems?.recipes[0]?.editableRecipes,
      oldRecipes,
      true,
      cartDiff
    );
  }
  return cartDiff;
};

export const prepareMoveCart = (
  currentCartId,
  storeNoToBeSelected,
  oldCart,
  openStandAloneModal
) => async dispatch => {
  dispatch(togglePrepareNewCartLoading());
  const data = await moveCartToNewStoreId(
    currentCartId,
    storeNoToBeSelected,
    true
  )
    .then(data => {
      //   openStandAlone, will check if we should check for redux state cart or cart endpoint
      if (!data) {
        return { data: null, isDifferent: false };
      }
      const diff = newCartIsDiff(data?.data, oldCart, openStandAloneModal);
      let isDifferent = false;

      if (diff?.removedItems?.length > 0) {
        isDifferent = true;
      }
      dispatch(
        prepareNewCart({
          show: openStandAloneModal && isDifferent,
          cartDiff: diff,
          isDifferent,
          loading: false,
          newCart: data?.data,
          newCartId: data?.data?.id,
          storeNoToBeSelected
        })
      );

      return { data, isDifferent };
    })
    .catch(e => {
      console.log(e?.message, 'move cart error');
      dispatch(resetNewCart());
    });
  if (data?.data?.data?.id) {
    // delete preview cart after we put it in redux state
    await clearCart(data?.data?.data?.id);
  }
  return { data: data?.data, isDifferent: data?.isDifferent };
};
