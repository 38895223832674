import makeReducer from '../makeReducer';
import {
  FETCH_CUSTOMER_REQUESTED,
  FETCH_CUSTOMER_RECEIVED,
  FETCH_CUSTOMER_FAILED,
  REGISTER_CUSTOMER_REQUESTED,
  REGISTER_CUSTOMER_RECEIVED,
  REGISTER_CUSTOMER_FAILED,
  REGISTER_CUSTOMER_CLEAR,
  REGISTER_CUSTOMER_CLEAR_ERROR
} from '../../types/reducers/customer';

const initialState = {
  fetching: false,
  customer: null,
  error: null
};

const fetching = (state, action) => ({
  ...state,
  fetching: action.fetching
});

const error = (state, action) => ({
  ...state,
  error: action,
  fetching: false
});

const ACTION_HANDLERS = {
  [FETCH_CUSTOMER_FAILED]: error,
  [FETCH_CUSTOMER_REQUESTED]: fetching,
  [FETCH_CUSTOMER_RECEIVED]: (state, action) => ({
    ...state,
    fetching: false,
    customer: action.payload
  }),
  [REGISTER_CUSTOMER_FAILED]: error,
  [REGISTER_CUSTOMER_REQUESTED]: fetching,
  [REGISTER_CUSTOMER_RECEIVED]: (state, action) => ({
    ...state,
    fetching: false,
    customerRegisteredSuccess: true
  }),
  [REGISTER_CUSTOMER_CLEAR]: state => ({
    ...state,
    customer: null
  }),
  [REGISTER_CUSTOMER_CLEAR_ERROR]: state => ({
    ...state,
    error: null
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
