import React from 'react';
import './BrowserDetector.scss';
// @ts-ignore
import LogoLandscape from '../Header/assets/citygross-logotype.svg';

const BrowserDetector = () => {
    return (
			<div>
				<div className="container">
					<div className="square">
							<h1 className="header">
									citygross.se stödjer inte din webbläsare
							</h1>
							<p>
								Du använder Internet Explorer, en gammal webbläsare som inte
								möter kraven för citygross.se. Internet Explorer får inte den
								senaste designen eller funktionerna.
							</p>
							<p>
								Vill du ta del av citygross.se så rekommenderar vi att du byter till
								<a href="https://bestvpn.org/outdatedbrowser/en"> Google Chrome</a>, 
								<a href="https://bestvpn.org/outdatedbrowser/en"> Firefox</a>, 
								<a href="https://bestvpn.org/outdatedbrowser/en"> Microsoft Edge</a> eller
								<a href="https://bestvpn.org/outdatedbrowser/en"> Safari</a>.
							</p>
							<p>Med vänliga hälsningar,</p>
							<span>City Gross</span>
							<img
								className="logo-landscape"
								alt="City Gross"
								width="160"
								height={75}
								src={LogoLandscape}
								/>
					</div>
				</div>
			</div>
    )
}

export default BrowserDetector;