import React from 'react';
import Grid from '../Grid';
import RecipeCard from '../RecipeCard';
import { RecipeCard as SkeletonRecipeCard } from '../../components/Skeletons';
import Card from '../Card';
import Spot from '../../components/Spot';
import './RecipeList.scss';
import Pagination from '../Pagination';
import { RecipeSchemaList } from '../SchemaMeta';

const RecipeList = ({
  fetching,
  categoryId,
  requestMoreRecipes,
  pagination,
  setRecipeFavorite,
  removeFavorite,
  items = [],
  listTeaser,
  skeletons = 20,
  withoutPaginationAndFilter,
  minimal,
  impressionListName
}) => {
  return (
    <>
    <RecipeSchemaList items={items}/>
  <div className="c-recipelist">
    <Grid className="cards">
      {listTeaser && listTeaser.image && <Spot {...listTeaser} />}
      {items.map((item, index) => (
        <Card key={item.key || item.id}>
          <RecipeCard
            {...item}
            setRecipeFavorite={setRecipeFavorite}
            removeFavorite={removeFavorite}
            minimal={minimal}
            
            impressionListName={impressionListName}
            impressionListPosition={index + 1}
            />
        </Card>
      ))}
      {fetching && items?.length === 0 && 
        [...Array(skeletons)].map((e, i) => (
          <SkeletonRecipeCard key={i} className="card" />
          ))}
    </Grid>

    {!withoutPaginationAndFilter && pagination && items?.length > 0 &&(
      <Pagination
      fetchPage={(page) =>{requestMoreRecipes(categoryId, {
        page:  page
      })}}
      pageCount={pagination?.pageCount}
      pageIndex={pagination?.pageIndex}
      totalCount={pagination?.totalCount}
      />
      )}
 
  </div>
      </>
)};

export default RecipeList;
