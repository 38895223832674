import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class TextArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      length: 0
    };
  }

  handleOnChange = event => {
    this.setState({
      length: event.target.value.length
    });

    const { onChange } = this.props;
    if (onChange) {
      onChange(event);
    }
  };

  render() {
    const {
      id,
      label,
      hint,
      maxLength,
      placeholder,
      formLoading,
      children,
      onChange,
      ...rest
    } = this.props;

    return (
      <Fragment>
        <label htmlFor={id}>
          {label}
          {children}
          {hint && <span className="hint">{hint}</span>}
        </label>
        <textarea
          id={id}
          type="text"
          onChange={this.handleOnChange}
          maxLength={maxLength}
          placeholder={placeholder}
          readOnly={formLoading}
          {...rest}
        />
        {maxLength ? (
          <span className="textarea-counter">
            {this.state.length}/{maxLength}
          </span>
        ) : null}
      </Fragment>
    );
  }
}

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  formLoading: PropTypes.bool,
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default TextArea;
