import React, { Component } from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line import/namespace
import { withRouter, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { appSettingsSelector } from '../../../selectors/app';
import RichText from '../../../containers/RichText';
import Image from '../../Image';
import PrioBadge from '../../assets/prio_badge.svg';
import queryString from 'query-string';
import './CreateAccount.scss';
import { Button } from '@citygross/components';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal';
import { ga4OpenRegister } from '../../../lib/analytics/analytics';

class CreateAccount extends Component {

  render() {
    const {
    
      registerText,
      location,
      openSideModal,
    } = this.props;

    const { search } = location;
    if (search) {
      const qs = queryString.parse(search);
      if (qs) {
        const { prio } = qs;
        if (prio === 'sms') {
          return (
            <Redirect to="/registrera?utm_source=SMS&utm_medium=SMS&utm_campaign=registrering" />
          );
        }
      }
    }

    return (
      <div className="c-createaccount">
        {registerText &&
          (registerText.title || registerText.text) && (
            <div className="c-createaccount__head">
              <div className="c-createaccount__box">
                {registerText.title && <h3>{registerText.title}</h3>}
                {registerText.text && <RichText text={registerText.text} />}
              </div>
              <div
                className="c-createaccount__box"
                style={{ marginLeft: '10px' }}
              >
                <Image src={PrioBadge} />
              </div>
            </div>
          )}
          <Button color='brandPurple' size={'medium'} onClick={() => {
            ga4OpenRegister()
            openSideModal(SideModalTabs.REGISTER)}}>
            Bli prio kund
          </Button>
       
      </div>
    );
  }
}

CreateAccount.propTypes = {
  registerText: PropTypes.object,
  getCustomerById: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  openSideModal
};

const mapStateToProps = (state, props) => {
  const appSettings = appSettingsSelector(state);
  return {
    ...state.customer,
    ...props,
    registerText: appSettings?.register
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateAccount)
);
