import React from 'react';
import Spinner from '../../components/Spinner';

import './LoadingPage.scss';

const LoadingPage = () => (
  <div className="c-loading-page">
    <Spinner dark />
  </div>
);

export default LoadingPage;
