import { FETCH_PREVIEW_BLOCK_RECEIVED } from '../../types/reducers/preview';

import makeReducer from '../makeReducer';

const initialState = {
  block: null
};

const ACTION_HANDLERS = {
  [FETCH_PREVIEW_BLOCK_RECEIVED]: (state, action) => ({
    ...state,
    block: action.payload
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
