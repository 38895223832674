import React from 'react';
import { Box, CgButton, ECgButtonSize } from '@citygross/components';
import { theme } from '@citygross/design-tokens';
import './OfferListingNoStoreSelected.scss';
import { useDispatch } from 'react-redux';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../../components/SideModal';

export const OfferListingNoStoreSelected = () => {
  const dispatch = useDispatch();

  return (
    <div className="no-store-container">
      <div className="weekly-offers">
        <Box
          background={theme.palette?.yellowLight}
          padding={40}
          paddingVertical={24}
          rounded={false}
        >
          <img src="/offers-weekly.png" alt="Offers Weekly" />
        </Box>

        <Box background={theme.palette?.white} padding={16} rounded={false}>
          <span className="title">Välj butik</span>

          <span className="subtitle">
            Välj butik för att se aktuella erbjudanden
          </span>

          <CgButton
            onClick={() =>
              dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER))
            }
            size={ECgButtonSize.medium}
          >
            <span className="button-text">Välj butik</span>
          </CgButton>
        </Box>
      </div>
    </div>
  );
};
