import { useEffect, useState } from "react";
import { CookieConsent } from "../types/cookiebotTypes";
import { getCookiebotConsentCookie } from "../lib/cookiebot";

export default function useCookieConsent(): CookieConsent {
  const [cookieConsent, setCookieConsent] = useState<CookieConsent>(getCookiebotConsentCookie());
  
  function handleOnAccept(): void {
    setCookieConsent(getCookiebotConsentCookie());
  }
      
  function handleOnDecline(): void {
    setCookieConsent(getCookiebotConsentCookie());
  }
      
  useEffect(() => {
    window.addEventListener('CookiebotOnAccept', handleOnAccept);
    window.addEventListener('CookiebotOnDecline', handleOnDecline);
  }, []);
  
  useEffect(() => () => window.removeEventListener('CookiebotOnAccept', handleOnAccept), []);
  useEffect(() => () => window.removeEventListener('CookiebotOnDecline', handleOnDecline), []);
      
	return cookieConsent;
}