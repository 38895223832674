import React, { Fragment } from 'react';
import RichText from '../../../containers/RichText';
import { recipeHtmlInstructionsToArray } from '../../../lib/recipe';
import './RecipeDirections.scss';

interface IProps {
  recipeDirections: string;
}

const RecipeDirections: React.FC<IProps> = ({ recipeDirections }) => {
  const directionsArray = recipeHtmlInstructionsToArray(recipeDirections);
  return (
    <div className="c-recipe-directions">
      <h3>Gör så här</h3>
      {directionsArray && directionsArray.length > 0 ? (
        <RichText>
          {directionsArray.map(({ headline, steps }, groupIndex) => (
            <Fragment key={groupIndex}>
              {headline && <p>{headline}</p>}
              <ol>
                {steps.map((step, stepIndex) => (
                  <li key={stepIndex} id={`how-to-step${stepIndex + 1}`}>
                    {step}
                  </li>
                ))}
              </ol>
            </Fragment>
          ))}
        </RichText>
      ) : (
        <RichText text={recipeDirections} />
      )}
    </div>
  );
};

export default RecipeDirections;
