import { createSlice } from '@reduxjs/toolkit'
interface IloginStepsState {
  currentStep: number,
  previousStep: number | null,
  initialStep: number,
  registerStep: number,
  registerPrevStep: number,

}

const initialState: IloginStepsState = {
  currentStep: 0,
  previousStep: null,
  registerStep: 0,
  registerPrevStep: 0,
  initialStep: 0,
}

const loginSlice = createSlice({
  name: 'loginSteps',
  initialState: initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload.currentStep 
      state.previousStep = action.payload.previousStep 
    },
    setCurrentRegisterStep: (state, action) => {
      state.registerStep = action.payload.currentStep 
      state.registerPrevStep = action.payload.previousStep 
    },
    setInitialStep: (state, action)=> {
        state.initialStep = action.payload
    }
  }
})

export const { 
  setCurrentStep, 
  setCurrentRegisterStep,
  setInitialStep
} = loginSlice.actions


export const selectCurrentStep = (state) => state.loginSteps?.currentStep
export const selectPreviousStep = (state) => state.loginSteps?.previousStep
export const selectRegisterCurrentStep = (state) => state.loginSteps?.registerStep
export const selectRegisterPreviousStep = (state) => state.loginSteps?.registerPrevStep
export const selectInitialStep = (state) => state.loginSteps?.initialStep



export const loginReducer = loginSlice.reducer
