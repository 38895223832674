export const domSafe = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );

export const isObjectFitSupported =
  !domSafe() || // Treat server rendered as supported
  !document.documentElement ||
  'objectFit' in document.documentElement.style;

export const EMPTY_PIXEL =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

export const querySelector = (...args) =>
  domSafe() ? window.document.querySelector.apply(window, args) : null;

export const location = () =>
  domSafe ? window.location : { origin: 'http://127.0.0.1' };

export const localStorage = () => {
  const store = {};
  const ls = {
    getItem: key => store[key] || null,
    setItem: (key, value) => {
      store[key] = value;
    },
    removeItem: key => {
      delete store[key];
    }
  };

  return domSafe() ? window.localStorage : ls;
};
