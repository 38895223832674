import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import './BlockGrid.scss';

const BlockGrid = ({ children, colsInSmall }) => (
  <div
    className={cs('c-block-grid', {
      [`c-block-grid--cols-sm-${colsInSmall}`]: Boolean(colsInSmall)
    })}
  >
    {children}
  </div>
);

BlockGrid.propTypes = {
  children: PropTypes.any,
  colsInSmall: PropTypes.oneOf([1, 2])
};

BlockGrid.defaultProps = {
  colsInSmall: 1
};

const BlockGridItem = ({ children }) => (
  <div className="c-block-grid__item">{children}</div>
);

BlockGridItem.propTypes = {
  children: PropTypes.any
};

export default BlockGrid;

export { BlockGridItem };
