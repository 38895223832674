import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import './Column.scss';

const Column = ({ width, align, children }) => (
  <div
    className={cs(
      'c-frm-col',
      width ? `c-frm-col--${width}` : 'c-frm-col--auto',
      { 'c-frm-col--flex': align },
      { 'c-frm-col--align-bottom': align === 'bottom' },
      { 'c-frm-col--align-top': align === 'top' },
      { 'c-frm-col--align-center': align === 'center' }
    )}
  >
    {children}
  </div>
);

Column.propTypes = {
  // Add as needed
  width: PropTypes.oneOf([
    'sm',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    'sm-1',
    'sm-2',
    'sm-3',
    'sm-4',
    'sm-5',
    'sm-6',
    'sm-7',
    'sm-8',
    'sm-9',
    'sm-10'
  ]),
  // Add as needed
  align: PropTypes.oneOf(['top', 'bottom', 'center']),
  children: PropTypes.any
};

export default Column;
