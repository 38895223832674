/* eslint-disable import/named */
/* eslint-disable linebreak-style */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cs from 'classnames';
import { withErrorBoundary } from '../Error/ErrorBoundary';
import {
  addScrollListener,
  addResizeListener,
  removeResizeListener,
  removeScrollListener
} from '../../lib/windowEvents';
import NoticeBar from '../NoticeBar';
import ServiceLayer from './ServiceLayer';
import * as pageActions from '../../actions/page';
import { appSettingsSelector } from '../../selectors/app';
import {
  userSelector,
  loggedInAsCustomerSelector,
  authenticatedSelector
} from '../../selectors/user';
import {
  hasNotificationHeightSelector,
  hasNotificationStatusSelector,
  hasSideBarSelector
} from '../../selectors/navigation';
import { setVisibility } from '../../actions/shoppingOrder';
import { clearOverlay } from '../../actions/app';
import { isIE11 } from '../../lib/utils';
import './Header.scss';
import { selectCurrentAssortment } from '../../selectors/assortments';
import TopNav from './TopNav';
import DeliverySummary from '../CartList/DeliverySummary';
import { upcomingOrders } from '../../selectors/order';
import OrderAlert from '../OrderAlert';
import CateringDeliveryAlert from '../CateringOrderAlert';
import { selectFullCateringSlots } from '../../slices/deliveryPickerSlice';
import { isMatch } from '../../lib/route/defaults';

export const DEFAULT_HEADER_HEIGHT = 116;
export const DELIVERY_TAB_HEIGHT = 50;
export const TOPNAV_HEIGHT = 66;
export const HEADER_BREAKPOINT = 991;
export const HEADER_MAX_WIDTH = 1860;
export const HEADER_MENU_BREAKPOINT = 1160;

const productPages = ['matvaror', 'recept', 'matkassar', 'catering', '/sok'];

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewOrderInfo: true
    };
  }
  componentDidMount() {
    const {
      setStickyHeaderHeight,
      setDefaultHeaderHeight
    } = this.props.headerHeightContext;

    addScrollListener(this.onScroll);
    addResizeListener(this.onResize);

    window.innerWidth < HEADER_BREAKPOINT
      ? setStickyHeaderHeight()
      : setDefaultHeaderHeight();
  }

  componentWillUnmount() {
    removeScrollListener(this.onScroll);
    removeResizeListener(this.onResize);
  }

  onResize = () => {
    const {
      setStickyHeaderHeight,
      stickyHeader
    } = this.props.headerHeightContext;

    if (window.innerWidth < HEADER_BREAKPOINT && !stickyHeader) {
      setStickyHeaderHeight();
    }

    this.onScroll();
  };

  onScroll = () => {
    if (!isIE11()) {
      const { overlay, portalOverlay } = this.props;
      const {
        stickyHeader,
        setDefaultHeaderHeight,
        setStickyHeaderHeight,
        constants
      } = this.props.headerHeightContext;
      const scrolledPastTriggerPoint =
        window.pageYOffset > constants.triggerPoint;
      const overlayIsToggled =
        (overlay && overlay.isToggled) ||
        (portalOverlay && portalOverlay.isToggled);
      if (scrolledPastTriggerPoint && !stickyHeader) {
        setStickyHeaderHeight();
      } else if (
        !scrolledPastTriggerPoint &&
        stickyHeader &&
        !overlayIsToggled
      ) {
        setDefaultHeaderHeight();
      }
    }
  };

  render() {
    const {
      location,
      isCheckout,
      navSection,
      serviceLayerToggled,
      toggleServiceLayer,
      isAuthenticated,
      notificationStatus,
      menuTabActive,
      settings,
      isReplacement,
      cartId,
      notificationHeight,
      upcommingOrders,
      fullCateringSlots,
      hasSidebar
    } = this.props;
    const newUpcommingOrders = upcommingOrders?.filter(order => {
      const now = new Date();
      // @TODO: add for catering
      const deliveryTime = new Date(
        order?.deliveryLockDateWithGroceryBag ||
          order?.deliveryLockDateWithoutGroceryBag
      );
      return deliveryTime >= now;
    });
    const { stickyHeader } = this.props.headerHeightContext;

    const isProductPage =
      productPages?.some(page => location?.pathname?.includes(page)) ||
      isMatch(location?.pathname);

    return (
      <div>
        <header
          className={cs(
            'b-header',
            isCheckout && ' is-checkout',
            !menuTabActive && stickyHeader && 'sticky-header'
          )}
          ref={ref => (this.wrapper = ref)}
        >
          <NoticeBar
            colorHex={settings?.message?.colorHex}
            visible={settings?.message?.visible}
            message={settings?.message?.message}
            status={notificationStatus}
          />

          <TopNav
            notificationStatus={this.props.notificationStatus}
            notificationHeight={this.props.notificationHeight}
            navTop={this.props?.navTop}
            location={this.props?.location}
            storeId={this.props?.storeId}
          />

          <ServiceLayer
            location={location}
            navSection={navSection}
            toggleServiceLayer={toggleServiceLayer}
            serviceLayerToggled={serviceLayerToggled}
          />
        </header>
        {/*@TODO: is this needed somehwere? <div className={cs(
          isCheckout && settings?.message.visible ? 'header-notice-placeholder' : 'header-placeholder'
        )} >
      </div> */}
        <div
          className={'header-summary-info'}
          style={{
            margin: `${notificationHeight + TOPNAV_HEIGHT + 4 + 'px'} auto 0`
          }}
        >
          <div
            className={cs(
              'header-summary-info__wrapper',
              {
                'header-summary-info__wrapperLarge': this.state.showNewOrderInfo
              },
              {
                'header-summary-info__wrapperSmall': !this.state
                  .showNewOrderInfo
              },
              {
                'header-summary-info__wrapperExtraLarge':
                  location?.pathname?.includes('catering') &&
                  fullCateringSlots?.length
              }
            )}
          >
            <div
              className={cs('header-summary-info__container', {
                'header-summary-info__productContainer': isProductPage,
                'header-summary-info__with-sidebar':
                  !isProductPage && hasSidebar
              })}
            >
              {newUpcommingOrders &&
                newUpcommingOrders?.length > 0 &&
                this.state.showNewOrderInfo && (
                  <OrderAlert
                    notificationHeight={notificationHeight}
                    newUpcommingOrders={newUpcommingOrders}
                    show={this.state.showNewOrderInfo}
                    setShow={val =>
                      this.setState({
                        showNewOrderInfo: val
                      })
                    }
                  />
                )}
              {location?.pathname?.includes('catering') && (
                <CateringDeliveryAlert />
              )}
              <div>
                <DeliverySummary
                  cartId={cartId}
                  isReplacement={isReplacement}
                  isLoggedInAsCustomer={isAuthenticated}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  overlay: state.app.overlay,
  portalOverlay: state.app.portalOverlay,
  user: userSelector(state),
  settings: appSettingsSelector(state),
  isLoggedInAsCustomer: loggedInAsCustomerSelector(state),
  isAuthenticated: authenticatedSelector(state),
  notificationStatus: hasNotificationStatusSelector(state),
  notificationHeight: hasNotificationHeightSelector(state),
  topNav: state.navigation.top,
  assortmentActive:
    state.assortments.menuTabActive | state.assortments.lastSelectedActive,
  storeId: selectCurrentAssortment(state),
  isReplacement: state.cart.meta.isReplacement,
  cartId: state.cart.meta?.id,
  upcommingOrders: upcomingOrders(state),
  fullCateringSlots: selectFullCateringSlots(state),
  hasSidebar: hasSideBarSelector(state)
});

const mapDispatchToProps = {
  getStoreById: pageActions.getPageById,
  clearOverlay,
  setVisibility
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withErrorBoundary(Header))
);
