import React, { Component } from 'react';
import Flyout from '../../Flyout';
import CreateAccount from './CreateAccount';
import {
  withConfirmModal,
  withFlyoutPortal,
  withCloseableOverlay
} from '../../Flyout/withFlyout';

const ClosableFlyout = withConfirmModal(
  withFlyoutPortal(withCloseableOverlay(Flyout))
);

class CreateAccountFlyout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canClose: true,
      modalProps: {
        title: 'Är du säker?',
        body: 'Om du stänger nu måste du börja om från början.',
        show: false,
        onConfirmClick: this.onModalConfirmClick
      }
    };
  }

  onCloseClicked = () => {
    const { back } = this.props;
    if (this.state.canClose) {
      back();
    } else {
      this.setState({
        modalProps: {
          ...this.state.modalProps,
          show: true
        }
      });
    }
  };

  setCanClose = change => {
    this.setState({
      canClose: change
    });
  };

  onModalConfirmClick = () => {
    this.setState({
      modalProps: {
        ...this.state.modalProps,
        show: false
      }
    });
  };

  render() {
    const { modalProps } = this.state;

    return (
      <ClosableFlyout
        {...this.props}
        title="Skapa konto"
        onClose={this.onCloseClicked}
        modalProps={modalProps}
      >
        <CreateAccount canClose={this.setCanClose} />
      </ClosableFlyout>
    );
  }
}

export default CreateAccountFlyout;
