import React, { Component, Fragment } from 'react';
import Form, {
  FormItem,
  ErrorMessage,
  SubmitButton
} from '../../../components/Form';
import './ForgotPassword.scss';

class ForgotPasswordModule extends Component {
  state = {};

  componentWillUnmount() {
    const { clearPasswordReset } = this.props;
    clearPasswordReset();
  }

  onSubmit = ({ emailAddress }) => {
    const { passwordReset } = this.props;
    passwordReset(emailAddress);
  };

  render() {
    const {
      requestingPasswordReset,
      passwordResetRequestError,
      passwordResetRequestSuccessfull
    } = this.props;

    return (
      <div className="m-forgotpassword">
        <div className="m-forgotpassword__box">
          <Form
            onSubmit={data => {
              this.onSubmit({ ...data });
            }}
            loading={requestingPasswordReset}
            showRequiredInfo={false}
          >
            {!passwordResetRequestSuccessfull && (
              <Fragment>
                <FormItem
                  id="emailAddress"
                  type="email"
                  label="E-postadress"
                  placeholder="namn.fornamn@doman.se"
                  hint="Används vid inloggning"
                  required
                  autoComplete="off"
                />

                <ErrorMessage />
              </Fragment>
            )}

            {passwordResetRequestError && (
              <div className="warning">
                Hoppsan, något gick fel! Kontrollera att e-postadressen stämmer.
              </div>
            )}
            {passwordResetRequestSuccessfull && (
              <div className="success">
                Ett mail med instruktioner har skickats till{' '}
                {passwordResetRequestSuccessfull}!
              </div>
            )}

            {!passwordResetRequestSuccessfull && (
              <SubmitButton primary fullwidth>
                Återställ lösenord
              </SubmitButton>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordModule;
