import React, { useEffect, useRef, useState } from 'react';
import { AlertBox, Spacer } from '@citygross/components';
import { useWindowSize } from '@citygross/utils';
import { BodyText } from '@citygross/typography';
import { Icons } from '@citygross/icons';
import { getCateredDeliveryDates } from '../../lib/cart.js';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFullCateringSlots,
  setFullCateringSlots
} from '../../slices/deliveryPickerSlice';
import { getCartMeta } from '../../selectors/cart/cartSelector.js';
import { siteIdSelector } from '../../selectors/assortments';
import { AppState } from '../../reducers';
import moment from 'moment';

const CateringDeliveryAlert = () => {
  const { width } = useWindowSize();
  const cart = useSelector(state => getCartMeta(state));
  const siteId = useSelector((state: AppState) => siteIdSelector(state));
  const dispatch = useDispatch();
  const fullCateringSlots = useSelector(state =>
    selectFullCateringSlots(state)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const oldStore = useRef<string | number>();
  const data = async () => {
    const slots = await getCateredDeliveryDates(cart?.id, siteId, null);
    if (slots?.length) {
      dispatch(
        setFullCateringSlots({
          fullCateringSlots: slots?.map(slot => slot?.date)
        })
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    if (
      cart.id &&
      siteId &&
      (!fullCateringSlots || fullCateringSlots?.length === 0) &&
      oldStore?.current !== siteId &&
      !loading
    ) {
      oldStore.current = siteId;
      setLoading(true);
      data();
    }
  }, [cart.id, siteId, loading, fullCateringSlots]);
  return fullCateringSlots?.length ? (
    <>
      <Spacer lgSpacing={'xs2'} />
      <div className="header-delivery-notice">
        <AlertBox
          icon={width > 768 ? <Icons.Info color="#0072BB" /> : undefined}
        >
          <div>
            <BodyText>
              Leverans av catering inte möjlig:{' '}
              {fullCateringSlots?.map((date, i) =>
                i === fullCateringSlots.length - 1 &&
                fullCateringSlots?.length >= 2
                  ? ` eller ${moment(date).format('DD/MM')}`
                  : `${moment(date).format('DD/MM')}${
                      fullCateringSlots?.length > 2 &&
                      i !== fullCateringSlots.length - 2
                        ? ', '
                        : ''
                    }`
              )}
              .
            </BodyText>
          </div>
        </AlertBox>
      </div>
    </>
  ) : null;
};

export default CateringDeliveryAlert;
