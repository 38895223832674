import React from 'react';
import classNames from 'classnames';
import RoundButton from '../../RoundButton';
import './DecButton.scss';

interface IProps {
  onClick: Function,
  theme?: 'primary' | 'light',
  small?: boolean,
  mini?: boolean,
  disabled?: boolean,
  loading?: boolean  
}

export const DecButton : React.FC<IProps> = ({
  onClick,
  theme,
  small,
  disabled,
  loading
}) => (
  <RoundButton
    className={classNames('c-minusbtn', { loading })}
    theme={theme}
    small={small}
    onClick={onClick}
    disabled={disabled}
    loading={loading}
  />
);
