import { receiveCategories, receiveArticle } from './sync';
import { fetchCategory, fetchAnswer } from '../../api/endpoints/imbox';
import { flatten } from '../../lib/utils';
import { Dispatch } from 'redux';
import { FaqCategories } from '../../types/episerver/category';

export const fetchCategories = (categories: FaqCategories[]) => (
  dispatch: Dispatch
) => {
  Promise.all(
    categories.map(category => {
      return fetchCategory(category.categoryId)
        .then(({ data }) => flatten(data))
        .catch(err => console.log('err', err));
    })
  ).then(res => {
    const flattenedList = flatten(res);
    dispatch(receiveCategories(flattenedList));
    return flattenedList;
  });
};

export const fetchArticle = (id: string | number) => (dispatch: Dispatch) => {
  return fetchAnswer(String(id)).then(({ data }) =>
    dispatch(receiveArticle(data))
  );
};
