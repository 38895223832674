import React, { Fragment } from 'react';
import ProgressivePicture, {
  ImageFitTypes,
  ImageFitPositions
} from '../ProgressivePicture';
import { WithLink } from '../Link';
import './Spot.scss';
import { EpiserverLink } from '../../types/episerver/link';
import { EpiserverImage } from '../../types/episerver/image';

interface ISpot {
  image: EpiserverImage,
  link: EpiserverLink,
  text: string
}

export const Spot : React.FC<ISpot> = ({ image, link, text }) => (
  <div className="l-column-30_xs-60_sm-40_md-30_lg-48_xlg-30-mobileGutter">
    <WithLink link={link}>
      <div className="c-spotcard">
        <div className="c-spotcard__image">
          <ProgressivePicture
            largeImage={image}
            fitType={ImageFitTypes.Cover}
            fitPosition={ImageFitPositions.Left}
            maxWidth={{
              large: 540,
              small: 540
            }}
            inheritHeight
          />
        </div>
        {text && (
          <Fragment>
            <div className="gradient" />
            <div className="text">{text}</div>
          </Fragment>
        )}
      </div>
    </WithLink>
  </div>
);

export default Spot;
