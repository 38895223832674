import React from 'react';
import { CgButton, EButtonVariant, ECgButtonSize } from '@citygross/components';
import { Icons } from '@citygross/icons';

interface IProps {
  onClick: () => void;
}

export const CloseButton: React.FC<IProps> = ({ onClick }) => (
  <CgButton
    variant={EButtonVariant.secondary}
    size={ECgButtonSize.small}
    round={true}
    icon={<Icons.Cross height={16} width={16} />}
    onClick={onClick}
  />
);
