import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import Cart from './Cart';
import './Shopping.scss';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from '../../store/middleware/analytics';
class Shopping extends Component {
  componentDidUpdate(previousProps) {
    const switchedBetweenLargeAndSmall =
      previousProps.isLargeDesktop !== this.props.isLargeDesktop;

    if (switchedBetweenLargeAndSmall) {
      this.props.clearActiveTab(this.props.shoppingOrder.activeTab);
    }
  }

  onCartClick = () => {
    this.props.closeSearch();
    if (!this.props?.auth?.isAuthenticated) {
      this.props.toggleLogin();
      return;
    }
    try {
      const currentCart = this.props.cart;

      const items = currentCart?.items?.items?.map(item => {
        const itemUrl = currentCart?.items?.productRefs[item.itemNo]?.url;

        return {
          item_id: item.gtin,
          item_name: item.name,
          price: Number(formatPrice(item.price?.gross || item.normalPrice)),
          discount: Number(formatPrice(item.totalDiscountAmount || 0)),
          quantity: item.quantity.value,
          item_brand: item.brand || 'City Gross',
          item_category:
            itemUrl?.substring(0, itemUrl.lastIndexOf('/') + 1) || '',
          item_variant: item.variant || null
        };
      });

      const recipes = currentCart?.recipes?.recipeRefs
        ? Object.values(currentCart?.recipes?.recipeRefs)
        : [];

      const recipeProducts = recipes.map(recipeRef => {
        return {
          item_id: recipeRef.id,
          item_name: recipeRef.name,
          price: 0,
          quantity: 1,
          discount: 0,
          item_variant: recipeRef.id,
          item_brand: recipeRef.source || 'City Gross',
          item_category:
            '/recept' +
              recipeRef.url?.substring(0, recipeRef.url.lastIndexOf('/') + 1) ||
            '/'
        };
      });
      const cateringItems =
        currentCart.cateredMeals?.cateredMeals?.map(item => {
          const cateredRef =
            currentCart?.cateredMeals?.cateredMealsRefs[item?.itemNo];
          const productCategory =
            cateredRef.url?.substring(0, cateredRef.url.lastIndexOf('/') + 1) ||
            undefined;
          const isCake =
            productCategory?.includes('tartor') &&
            !productCategory?.includes('personer');
          return {
            item_id: item?.gtin,
            item_name:
              item?.name +
              `${
                isCake
                  ? ` - ${cateredRef?.quantity?.quantityFrom ??
                      cateredRef?.quantityFrom} portioner`
                  : ''
              }`,
            quantity: Number(item?.quantity?.value),
            price: Number(formatPrice(item?.netPrice)),
            item_brand: 'City Gross',
            item_variant: cateredRef?.name,
            item_category: productCategory || cateredRef?.parentId
          };
        }) || [];
      const ga4Cart = {
        value: Number(formatPrice(currentCart.totalValue)),
        currency: 'SEK',
        items: [...items, ...recipeProducts, ...cateringItems]
      };
      ga4.viewCart(ga4Cart);
    } catch (error) {
      console.error(error);
    }
    if (
      (this.props.isLargeDesktop && !this.props.shoppingOrder?.toggleable) ||
      this.props.sideModal.modalTab !== null
    ) {
      return;
    }
    this.props.toggleActiveCart();
  };

  render() {
    const {
      isReplacement,
      cart,
      setPickingFeeRange,
      setPickingFee,
      promotion
    } = this.props;

    return (
      <div className={'cart-header__button'}>
        <Cart
          {...cart}
          {...promotion}
          isReplacement={isReplacement}
          handleClick={this.onCartClick}
          setPickingFeeRange={setPickingFeeRange}
          setPickingFee={setPickingFee}
        />
      </div>
    );
  }
}

const ShoppingMediaQueryWrapper = props => (
  <MediaQuery minWidth={1360}>
    {isLargeDesktop => <Shopping isLargeDesktop={isLargeDesktop} {...props} />}
  </MediaQuery>
);

export default ShoppingMediaQueryWrapper;
