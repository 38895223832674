import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../Buttons';

const ErrorMessage = ({ errorCode, clearError, opts = {}, onForgotPassword , dontShowButton}) => {
  const error_codes = {
    email_in_use: (
      <Fragment>
        <p>E-postadressen är upptagen och används av ett annat konto.</p>
        <br />
        <p>Klicka nedan för att försöka igen med en annan e-postadress.</p>
      </Fragment>
    ),
    not_of_age: (
      <Fragment>
        <p>Du måste vara minst 18 år gammal för att bli kund hos City Gross.</p>
      </Fragment>
    ),
    not_found_in_par: (
      <Fragment>
        <p>Kontrollera att ditt personnummer stämmer.</p>
        <br />
        <p>Om du fortfarande har problem, kontakta kundservice.</p>
      </Fragment>
    ),
    unknown_error: (
      <Fragment>
        <p>Kontrollera att ditt personnummer stämmer.</p>
        <br />
        <p>Om du fortfarande har problem, kontakta kundservice.</p>
      </Fragment>
    ),
    personal_number_in_use: (
      <Fragment>
        <h3>Personnummer redan registrerat</h3>
        <p>Det finns redan ett konto för personnumret.</p>
        <br />
        <p>
          Har du glömt ditt lösenord, så{' '}
          <Link
            className="forgotpass"
            to={{
              pathname: '/glomt-losenord',
              state: { flyout: true, title: '', tight: true }
            }}
            onClick={() => onForgotPassword && onForgotPassword()}
          >
            klicka här
          </Link>{' '}
          för att återställa det.
        </p>
      </Fragment>
    )
  };

  return (
    <Fragment>
      {error_codes[errorCode] || (
        <Fragment>
          <p>Hoppsan, något verkar ha blivit fel!</p>
          <br />
          <p>
            Kontrollera att du angett korrekta uppgifter, eller kontakta
            kundservice för att slutföra din registrering.
          </p>
        </Fragment>
      )}
      <br />
      {!dontShowButton && 
      <Button primary fullwidth onClick={clearError}>
        Försök igen
      </Button>
      }
    </Fragment>
  );
};

export default ErrorMessage;
