import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchArticle } from '../../actions/imbox';
import { faqQuestionsSelector } from '../../selectors/faq';
import FaqFilter from './FaqFilter';
import ToggleableText from '../ToggleableText';
import { CenteredSpinner } from '../Spinner/Spinner';

import './FaqModule.scss';
import { AppState } from '../../reducers';

interface IFaqModule {

}

const FaqModule : React.FC<IFaqModule> = () => {
  const dispatch = useDispatch();
  const answers = useSelector((state: AppState) => state.imbox.answers);
  const [activeFilter, setActiveFilter] = useState<string>("Alla");
  const questions = useSelector((state: AppState) => 
    faqQuestionsSelector(state)
      .filter(question => 
        question.status === 'published' &&
        question.category === activeFilter ||
        activeFilter === 'Alla'
      )
    ); 

  const categories = questions
    .map(cat => cat.category)
    .filter((questions, index, array) => array.indexOf(questions) === index);

  const _filterByCategory = (selectedCategory?: string) =>
    setActiveFilter(selectedCategory || 'Alla');

  return !questions || questions.length === 0 ? (
    <CenteredSpinner dark />
  ) : (
    <div className="c-faqmodule">
      <div className="c-faqmodule__filters">
        <FaqFilter
          category="Alla"
          activeFilter={activeFilter}
          onClick={() => _filterByCategory()}
        />
        {categories.map(category => (
          <FaqFilter
            key={category}
            category={category || "Alla"}
            activeFilter={activeFilter}
            onClick={() => _filterByCategory(category)}
          />
        ))}
      </div>
      <div className="c-faqmodule__items">
        {questions.map(question => (
          <ToggleableText
            key={question.id}
            shownText={question.qSub}
            async
            asyncFetch={() => dispatch(fetchArticle(question.id))}
            hiddenText={answers.find(answer => answer.id === question.id)?.qBod}
          />
        ))}
      </div>
    </div>
  );
}

export default FaqModule;
