import { createSelector } from 'reselect';
import { userAddress } from '../lib/user';
import { AppState } from '../reducers';

const getUser = (state: AppState) => state.auth.user;
const getAuthenticated = (state: AppState) => state.auth.isAuthenticated;
const getIsLoggedInAsCustomer = (state: AppState) =>
  state.auth.isLoggedInAsCustomer;

export const fullNameSelector = createSelector([getUser], user =>
  user ? `${user.contact.firstname} ${user.contact.lastname}` : null
);

export const firstNameSelector = createSelector([getUser], user =>
  user ? user.contact.firstname : null
);

export const parAddressSelector = createSelector([getUser], user =>
  user ? user.contact.addresses[0] : {}
);

export const deliveryAddressSelector = createSelector([getUser], userAddress);

export const userKdbIdSelector = createSelector(
  [getUser],
  user => (user && user.settings && user.settings.id) || null
);

export const userSelector = createSelector(
  fullNameSelector,
  parAddressSelector,
  deliveryAddressSelector,
  getUser,
  (fullName, parAddress, deliveryAddress, user) => ({
    kdbId: user ? user.contact.id : null,
    fullName: fullName,
    firstName: user ? user.contact.firstname : '',
    lastName: user ? user.contact.lastname : '',
    parAddress: parAddress,
    deliveryAddress: deliveryAddress,
    email: user ? user.contact?.login?.email : null,
    phone: user
      ? (
          user.contact.channels.find(
            item =>
              item.channelTypeId &&
              `${item.channelTypeId}`.toLowerCase() === 'phone'
          ) || {}
        ).channelValue
      : null,
    mobilePhone: user
      ? (
          user.contact.channels.find(
            item =>
              item.channelTypeId &&
              `${item.channelTypeId}`.toLowerCase() === 'mobilephone'
          ) || {}
        ).channelValue
      : null,
    favoriteStorePageId:
      user && user.settings && user.settings.storePageId
        ? user.settings.storePageId
        : null,
    zipCode:
      (user && user.settings && user.settings.userPostalCode) ||
      userAddress(user)?.zip,
    siteId: (user && user.settings && user.settings.userSiteId) || null,
    deliveryMethod:
      (user && user.settings && user.settings.userDeliveryMethod) || null,
    hasSettings: Boolean(user && user.settings),
    emailRecieptCopy: Boolean(user && user.contact.emailRecieptCopy)
    // Add more as needed
  })
);

export const authenticatedSelector = createSelector(
  [getAuthenticated],
  isAuthenticated => {
    return isAuthenticated;
  }
);

export const loggedInAsCustomerSelector = createSelector(
  [getIsLoggedInAsCustomer],
  isLoggedInAsCustomer => {
    return isLoggedInAsCustomer || false;
  }
);
