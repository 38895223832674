import React from 'react';
import { connect } from 'react-redux';
import { Overlay } from '../../components/Overlay';

const makeOnClose = cbs => () => {
  cbs.forEach(cb => cb());
};

const OverlayContainer = props => {
  const { cbs, ...rest } = props;

  return <Overlay {...rest} onClick={makeOnClose(cbs)} />;
};

function mapStateToProps(state, props) {
  const { overlay } = state.app;

  return {
    show: overlay.isToggled || state.confirmModal.show,
    includeHeader: overlay.includeHeader,
    cbs: overlay.cbs
  };
}

export default connect(mapStateToProps)(OverlayContainer);
