import React, { useState } from 'react'

import Select from '../Select';
import '../CategoryFilter/CategoryFiltersSort/CategoryFiltersSort.scss';

const options: SortValue[] = [
    {
      value: 'mostBought',
      label: 'Mest köpta varor'
    },    
    {
      value: '',
      label: 'Popularitet'
    },
    {
      value: 'name',
      label: 'Namn A till Ö'
    },
    {
      value: 'nameDesc',
      label: 'Namn Ö till A'
    },
    { 
      value: 'price',
      label: 'Lägsta pris'
    },
    {
      value: 'priceDesc',
      label: 'Högsta pris'
    },
    {
      value: 'comparisonPrice',
      label: 'Jämförelsepris'
    },
    
  ];

  type SortValue = {
    value: string,
    label: string
  }

type TFilterProductsProps = {
    onChange: () => void;
}

export const FilterProducts = ({ onChange }: TFilterProductsProps) => {
    return (
    <div style={{ padding: '12px', marginLeft: 'auto', display: 'flex', justifyContent: 'flex-end' }} className="c-category-filters-sort">
    <div className="c-category-filters-sort__label">Sortera efter</div>
        <div className="c-category-filters-sort__select-wrap">
        <Select
            options={options}
            onChange={onChange}
        />
      </div>
    </div>
    )
}
