import { receiveSettings, fetchSettingsError } from './sync';
import * as endpoints from '../../api/endpoints/app';
import { push } from 'connected-react-router';
import { requestRetry, REQUEST_IDS } from '../../lib/request/requestRetry';

import { Dispatch } from 'redux';
import { openSideModal } from '../../slices/sideModalSlice';
import { SideModalTabs } from '../../components/SideModal';

export const getApplicationSettings = (noCache?: boolean) => (dispatch: Dispatch) => {
  const cReq = noCache ? () => endpoints.fetchSettings(noCache) : () => endpoints.fetchSettings();

  return requestRetry(cReq, dispatch, REQUEST_IDS.settings)
    .then(({data}) => {
      let res = data.data.settingsPage;
      dispatch(receiveSettings(res));
      return data;
    })
    .catch(err => {
      dispatch(fetchSettingsError(err));
      return Promise.reject(err);
    });
};

// TODO change this to a link to, location push event when routable flyouts are merged
export const redirectToLogin = () => (dispatch: Dispatch) => {
  dispatch(
    /* push('/login', {
      flyout: true,
      title: 'Logga in',
      tight: true
    }) */
    openSideModal(SideModalTabs.LOGIN)
  );
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  return new Promise((resolve:any) => resolve());
};

export const toggleServiceLayer = (pathname: string) => (dispatch: Dispatch) =>
  dispatch(push(pathname));
