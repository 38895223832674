import { FavoriteModel } from '../customer/SettingsData';
import { UserData } from '../customer/UserData';
const NAME = 'auth';

// Logout
export const LOGOUT_REQUEST = `${NAME}/LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${NAME}/LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${NAME}/LOGOUT_FAILURE`;
export const USER_RECEIVED = `${NAME}/USER_RECEIVED`;
export const USER_FAVORITES_RECEIVED = `${NAME}/USER_FAVORITES_RECEIVED`;
export const USER_CHANNEL_RECEIVED = `${NAME}/USER_CHANNEL_RECEIVED`;

// Login
export const LOGIN_REQUEST = `${NAME}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${NAME}/LOGIN_FAILURE`;

// Password reset
export const PASSWORD_RESET = `${NAME}/PASSWORD_RESET`;

// Update user email
export const PHONE_UPDATE = `${NAME}/PHONE_UPDATE`;

// Add favorite product
export const ADD_CUSTOMER_FAVORITE_PRODUCT_REQUESTED = `${NAME}/ADD_CUSTOMER_FAVORITE_PRODUCT_REQUESTED`;
export const ADD_CUSTOMER_FAVORITE_PRODUCT_RECEIVED = `${NAME}/ADD_CUSTOMER_FAVORITE_PRODUCT_RECEIVED`;
export const ADD_CUSTOMER_FAVORITE_PRODUCT_FAILED = `${NAME}/ADD_CUSTOMER_FAVORITE_PRODUCT_FAILED`;

// Add favorite recipe
export const ADD_CUSTOMER_FAVORITE_RECIPE_REQUESTED = `${NAME}/ADD_CUSTOMER_FAVORITE_RECIPE_REQUESTED`;
export const ADD_CUSTOMER_FAVORITE_RECIPE_RECEIVED = `${NAME}/ADD_CUSTOMER_FAVORITE_RECIPE_RECEIVED`;
export const ADD_CUSTOMER_FAVORITE_RECIPE_FAILED = `${NAME}/ADD_CUSTOMER_FAVORITE_RECIPE_FAILED`;

// Remove favorite product/recipe
export const REMOVE_CUSTOMER_FAVORITE_REQUESTED = `${NAME}/REMOVE_CUSTOMER_FAVORITE_REQUESTED`;
export const REMOVE_CUSTOMER_FAVORITE_RECEIVED = `${NAME}/REMOVE_CUSTOMER_FAVORITE_RECEIVED`;
export const REMOVE_CUSTOMER_FAVORITE_FAILED = `${NAME}/REMOVE_CUSTOMER_FAVORITE_FAILED`;

// Customer login
export const GET_CUSTOMER_TOKEN_REQUESTED = `${NAME}/GET_CUSTOMER_TOKEN_REQUESTED`;
export const GET_CUSTOMER_TOKEN_RECEIVED = `${NAME}/GET_CUSTOMER_TOKEN_RECEIVED`;
export const GET_CUSTOMER_TOKEN_FAILED = `${NAME}/GET_CUSTOMER_TOKEN_FAILED`;

// Toggle email receipt
export const SET_EMAIL_RECEIPT_REQUESTED = `${NAME}/SET_EMAIL_RECEIPT_REQUESTED`;
export const SET_EMAIL_RECEIPT_RECEIVED = `${NAME}/SET_EMAIL_RECEIPT_RECEIVED`;
export const SET_EMAIL_RECEIPT_FAILED = `${NAME}/SET_EMAIL_RECEIPT_FAILED`;

export interface AuthState {
  fetching: boolean;
  isAuthenticated: boolean;
  isLoggedInAsCustomer: boolean;
  user: UserData | null;
  favoriteProductsLoading: string[];
  favoriteRecipesLoading: string[];
  productFavorites: FavoriteModel[];
  recipeFavorites: FavoriteModel[];
  errorMessage: string | null;
  phoneUpdateRequesting: boolean;
  phoneUpdateSuccessfull: boolean;
  phoneUpdateError: boolean;
  phoneUpdateErrorMessage: string | null;
  requestingPasswordReset: boolean;
  passwordResetRequestSuccessfull: boolean;
  passwordResetRequestError: boolean;
  passwordResetRequestErrorMessage: string | null;
}
