import {
  ADD_OR_UPDATE,
  REMOVE,
  SET_FAILED
} from '../../types/reducers/request';

export const addOrUpdate = (id, runs, slowOrError, opts) => ({
  type: ADD_OR_UPDATE,
  id: id,
  opts: opts,
  slowOrError: slowOrError,
  runs: runs
});

export const remove = id => ({
  type: REMOVE,
  id: id
});

export const setFailed = id => ({
  type: SET_FAILED,
  id: id
});
