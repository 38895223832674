import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './Select.scss';

const Select = ({
  id,
  label,
  hint,
  options = [],
  defaultValue,
  formLoading,
  children,
  ...rest
}) => (
  <Fragment>
    <label htmlFor={id}>
      {label}
      {children}
      {hint && <span className="hint">{hint}</span>}
    </label>
    <div className="c-stdselect">
      <select
        id={id}
        defaultValue={defaultValue}
        readOnly={formLoading}
        {...rest}
      >
        {options.map((option, index) => (
          <option key={`opt_${index}`} value={option.value ? option.value : ''}>
            {option.label}
          </option>
        ))}
      </select>
      <span className="c-droparrow" />
    </div>
  </Fragment>
);
Select.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  formLoading: PropTypes.bool,
  children: PropTypes.any,
  defaultValue: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string.isRequired
    })
  )
};

export default Select;
