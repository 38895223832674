import { requestClientsReload } from '../../actions/serviceWorker';

export const serviceWorker = ({ dispatch, getState }) => next => action => {
  const state = getState();

  switch (action.type) {
    // Case routing happens
    case '@@router/LOCATION_CHANGE':
      // Only reload if we have activated a new serviceworker
      if (state.sw.newWorkerActivated) {
        // Only on real pathname changes
        // (eg. don't trigger on search change, or when opening flyout routes as
        // this is currently not handled as beautiful by the application)
        if (
          action.payload.location &&
          action.payload.location.pathname &&
          action.payload.location.search === '' &&
          (!action.payload.location.state ||
            !action.payload.location.state.flyout)
        ) {
          dispatch(requestClientsReload());
        }
      }
      break;
  }
  return next(action);
};
