import {
  FETCH_NAVIGATION_REQUESTED,
  FETCH_NAVIGATION_RECEIVED,
  FETCH_NAVIGATION_FAILED,
  TOGGLE_NOTIFICATION,
  SET_NOTIFICATION_HEIGHT,
  INavigationReceivedAction,
  SET_SIDEBAR
} from '../../types/reducers/navigation';

import { Navigation } from '../../types/episerver/navigation/Navigation';

export function isFetching(fetching) {
  return {
    type: FETCH_NAVIGATION_REQUESTED,
    fetching
  };
}

export function receiveNavigation(
  payload: Navigation
): INavigationReceivedAction {
  return {
    type: FETCH_NAVIGATION_RECEIVED,
    payload: payload
  };
}

export function fetchNavigationError(error) {
  return {
    type: FETCH_NAVIGATION_FAILED,
    error
  };
}

export function toggleNotification(active: boolean) {
  return {
    type: TOGGLE_NOTIFICATION,
    active
  };
}

export function setNotificationHeight(height: number) {
  return {
    type: SET_NOTIFICATION_HEIGHT,
    height
  };
}
export function setHasSideBar(bool: boolean) {
  return {
    type: SET_SIDEBAR,
    payload: bool
  };
}
