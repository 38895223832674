import React, { Component } from 'react';
import { fetchPreviewBlock } from '../../actions/preview';
import ContentBlockArea from '../../components/ContentBlocksArea';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';

class PreviewBlock extends Component {
  constructor(props) {
    super(props);
    this.state = { blocks: [] };
  }
  componentDidMount() {
    const { fetchPreviewBlock, id, workId } = this.props;
    fetchPreviewBlock(id, workId);
  }
  render() {
    return (
      <div>
        <h1>Preview</h1>
        {this.props.blockData && (
          <ContentBlockArea blocks={[this.props.blockData]} />
        )}
      </div>
    );
  }
}

PreviewBlock.required = [
  (state, params) => {
    const queries = qs.parse(state.router.location.search);
    return fetchPreviewBlock(queries.id, queries.workId, queries.auth);
  }
];

const mapStateToProps = (state, props) => {
  const queries = qs.parse(props.location.search);
  return {
    id: queries.id,
    workId: queries.workId,
    blockData: state.preview.block
  };
};
const mapDispatchToProps = {
  fetchPreviewBlock
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PreviewBlock)
);
