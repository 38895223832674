import throttle from 'lodash.throttle';

const resizeThrottleWaitMs = 200;

const scrollCallbacks = [];
const resizeCallbacks = [];

const onScroll = (() => {
  let scrollRafId;

  const handleScroll = () => {
    scrollCallbacks.forEach(scrollCallback => scrollCallback());
  };

  return () => {
    cancelAnimationFrame(scrollRafId);
    scrollRafId = requestAnimationFrame(handleScroll);
  };
})();

const onResize = throttle(() => {
  resizeCallbacks.forEach(resizeCallback => resizeCallback());
}, resizeThrottleWaitMs);

const createAddListener = (
  eventType,
  eventCallback,
  callbacksList
) => addListener => {
  if (callbacksList.length === 0) {
    window.addEventListener(eventType, eventCallback, false);
  }

  if (!callbacksList.includes(addListener)) {
    callbacksList.push(addListener);
  }
};

const createRemoveListener = (
  eventType,
  eventCallback,
  callbacksList
) => removeListener => {
  const listenerIndex = callbacksList.indexOf(removeListener);
  if (listenerIndex !== -1) {
    callbacksList.splice(listenerIndex, 1);
  }

  if (callbacksList.length === 0) {
    window.removeEventListener(eventType, eventCallback, false);
  }
};

export const addScrollListener = createAddListener(
  'scroll',
  onScroll,
  scrollCallbacks
);

export const removeScrollListener = createRemoveListener(
  'scroll',
  onScroll,
  scrollCallbacks
);

export const addResizeListener = createAddListener(
  'resize',
  onResize,
  resizeCallbacks
);

export const removeResizeListener = createRemoveListener(
  'resize',
  onResize,
  resizeCallbacks
);
