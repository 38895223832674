import {
  RECEIVE_STORE_INFORMATIONS,
  TOGGLE_MODAL,
  TOGGLE_MENU_TAB,
  SET_ACTIVE_ASSORTMENT,
  SET_ACTIVE_DELIVERYMETHOD,
  SET_INITIAL_ASSORTMENT_VALUES,
  ASSORTMENTS_LOADING,
  SetAssortmentLoadingAction,
  SITES_LOADING,
  SetSitesLoadingAction,
  SetAssortmentAction,
  SetDeliveryMethodAction,
  SetInitialAssortmentValuesAction,
  SetToggleModalActiveAction,
  SetLastSelectedAction,
  TOGGLE_LAST_SELECTED,
  SetToggleInfoModalActiveAction,
  TOGGLE_ASSORTMENT_INFO_MODAL
} from '../../types/reducers/assortments';
import {
  StoreMeta,
  DeliveryMethods,
  HomeDeliverySortimentOptions
} from '../../types/assortments';
import { Store } from '../../types/episerver/store';

export const toggleAssortmentsLoading = (
  loading: boolean
): SetAssortmentLoadingAction => {
  return {
    type: ASSORTMENTS_LOADING,
    loading
  };
};
export const toggleSitesLoading = (loading: boolean): SetSitesLoadingAction => {
  return {
    type: SITES_LOADING,
    loading
  };
};

export const receiveStoreInformations = (
  stores: StoreMeta[],
  epiStores: Store[]
) => {
  return {
    type: RECEIVE_STORE_INFORMATIONS,
    stores,
    epiStores
  };
};

export const toggleModalActive = (
  active: boolean
): SetToggleModalActiveAction => {
  return {
    type: TOGGLE_MODAL,
    active
  };
};

export const toggleInfoModalActive = (
  active: boolean
): SetToggleInfoModalActiveAction => {
  return {
    type: TOGGLE_ASSORTMENT_INFO_MODAL,
    active
  };
};

export const toggleLastSelected = (active: boolean): SetLastSelectedAction => {
  return {
    type: TOGGLE_LAST_SELECTED,
    active
  };
};

export const toggleMenuTab = () => {
  return {
    type: TOGGLE_MENU_TAB
  };
};

export const setInitialAssortmentValues = (
  deliveryType: DeliveryMethods,
  zipCode: number,
  siteId: number
): SetInitialAssortmentValuesAction => {
  return {
    type: SET_INITIAL_ASSORTMENT_VALUES,
    deliveryType,
    zipCode,
    siteId
  };
};

export const setActiveAssortment = (
  deliveryType: DeliveryMethods,
  siteId: number | string,
  homeSiteId: number | string | null,
  zipCode: number | string | null,
  storeNo: number,
  storeProvider?: string | null,
  sortimentOptions?: HomeDeliverySortimentOptions | null,
): SetAssortmentAction => {
  return {
    type: SET_ACTIVE_ASSORTMENT,
    deliveryType,
    siteId,
    homeSiteId,
    zipCode,
    storeNo,
    storeProvider,
    sortimentOptions
  };
};

export const setActiveDeliveryMethod = (
  deliveryType: DeliveryMethods
): SetDeliveryMethodAction => {
  return {
    type: SET_ACTIVE_DELIVERYMETHOD,
    deliveryType
  };
};
