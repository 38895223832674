import React from 'react';
import { Route } from 'react-router-dom';

export const FlyoutRoute = ({
  component: Component,
  close,
  path,
  ...routeProps
}) => {
  return (
    <Route
      path={path}
      render={props => {
        return (
          <Component back={close} close={close} {...routeProps} {...props} />
        );
      }}
    />
  );
};
