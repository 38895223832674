import {
  ADD_OR_UPDATE,
  REMOVE,
  SET_FAILED
} from '../../types/reducers/request';

import makeReducer from '../makeReducer';
const removeKey = (key, { [key]: _, ...rest }) => rest;

const initialState = {};

const ACTION_HANDLERS = {
  [ADD_OR_UPDATE]: (state, action) => ({
    ...state,
    [action.id]: {
      id: action.id,
      opts: action.opts,
      runs: action.runs,
      isSlow: action.slowOrError === 'slow',
      isError: action.slowOrError === 'error',
      failed: false
    }
  }),
  [REMOVE]: (state, action) => ({
    ...removeKey(action.id, state)
  }),
  [SET_FAILED]: (state, action) => ({
    ...state,
    [action.id]: {
      ...state[action.id],
      failed: true
    }
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
