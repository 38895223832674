import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Link from '../../components/Link';
import Form, {
  FormItem,
  SubmitButton,
  ErrorMessage,
  Row,
  Column,
  Line
} from '../../components/Form';
import { setNewPassword } from '../../api/endpoints/customer';

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: null,
      success: null
    };
  }

  onSubmit = formData => {
    const { token } = this.props;
    const { newPassword } = formData;

    this.setState({
      loading: true,
      error: null,
      success: null
    });

    setNewPassword(newPassword, token)
      .then(({ data }) => {
        if (data && data.code && data.message) {
          if (data.message === 'invalid_token') {
            this.setState({
              error: 'INVALID_TOKEN'
            });
          }
        } else {
          this.setState({
            success: true
          });
        }
      })
      .catch(err => {
        this.setState({
          error: 'ERROR'
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    const { loading, error, success } = this.state;
    const { customerService } = this.props;

    return (
      <div>
        <Form
          onSubmit={this.onSubmit}
          loading={loading}
          showRequiredInfo={false}
        >
          <Row>
            <Column width="sm-6">
              <h1>Lösenordsåterställning</h1>
              <p>
                Om du har glömt ditt lösenord kan du ange ett nytt här för att
                återställa ditt gamla lösenord.
              </p>
              {customerService &&
                customerService.link && (
                  <Fragment>
                    <br />
                    <p>
                      Har du problem med att återställa ditt lösenord,{' '}
                      <Link link={customerService.link}>
                        kontakta kundservice här
                      </Link>.
                    </p>
                  </Fragment>
                )}
              <br />
            </Column>
          </Row>
          <Row>
            <Column>
              <Line />
            </Column>
          </Row>
          {!success && (
            <Fragment>
              <Row>
                <Column width="sm-3">
                  <FormItem
                    id="newPassword"
                    type="password"
                    label="Ange nytt lösenord"
                    hint="Minst 8 tecken"
                    required
                    minLength={8}
                    validationHint="Ange ett säkert lösenord med minst 8 tecken."
                  />
                </Column>
                <Column width="sm-3">
                  <FormItem
                    id="newPasswordRepeat"
                    type="password"
                    label="Repetera lösenord"
                    repeatItemId="newPassword"
                    validationHint="Repetera lösenord måste matcha fältet Ange nytt lösenord."
                  />
                </Column>
              </Row>
              <Row>
                <Column width="sm-6">
                  <ErrorMessage />
                </Column>
              </Row>
            </Fragment>
          )}
          {success && (
            <Row>
              <Column width="sm-4">
                <div className="success">
                  <p>
                    <strong>Ditt lösenord har ändrats!</strong>
                  </p>
                  <p>Använd ditt nya lösenord för att logga in.</p>
                </div>
              </Column>
            </Row>
          )}
          {error && (
            <Row>
              <Column width="sm-4">
                <div className="warning">
                  {error === 'INVALID_TOKEN' && (
                    <Fragment>
                      <p>
                        <strong>Ogiltig token!</strong>
                      </p>
                      <p>
                        Länken du använt har en ogiltig eller för gammal token.
                      </p>
                    </Fragment>
                  )}
                  {error === 'ERROR' && (
                    <>
                      <p>Det gick inte att byta lösenordet.</p>
                      <p>Om du begärt nytt lösenord vid flera tillfällen måste du använda den länk som vi skickat till dig senast.</p>
                    </>
                  )}
                </div>
              </Column>
            </Row>
          )}
          {!success && (
            <Row>
              <Column width="sm-4">
                <SubmitButton primary>Skicka</SubmitButton>
              </Column>
            </Row>
          )}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  token: props.match.params.token,
  customerService: state.navigation.customerService
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PasswordReset)
);
