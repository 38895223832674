import {
  FETCH_PAGE_REQUESTED,
  FETCH_PAGE_RECEIVED,
  FETCH_PAGE_FAILED
} from '../../types/reducers/page';

import makeReducer from '../makeReducer';

const initialState = {
  fetching: false,
  pages: [],
  error: null,
  fetchingStorePage: false,
  storePage: null,
  errorStorePage: null
};

const fetching = (state, action) => ({
  ...state,
  fetching: action.fetching
});

const error = (state, action) => ({
  ...state,
  error: action.error,
  fetching: false
});

const ACTION_HANDLERS = {
  [FETCH_PAGE_REQUESTED]: fetching,
  [FETCH_PAGE_FAILED]: error,
  [FETCH_PAGE_RECEIVED]: (state, action) => {
    if (action.isUpdate && action.data.id !== state.pages[0].id) {
      return state;
    }
    return {
      ...state,
      fetching: false,
      pages: [{ ...action.data, ...action.meta }]
    };
  }
};

export default makeReducer(ACTION_HANDLERS, initialState);
