import React from 'react';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import ResponsiveImage from '../../contentblocks/ResponsiveImage';
import RichText from '../../containers/RichText';
import CenterElement from '../CenterElement';
import ContentBlocksArea from '../ContentBlocksArea';

import './ArticleItem.scss';

export const ArticleItem = ({
  mainImage,
  mobileImage,
  headline,
  mainBody,
  blocks,
  title,
  showBreadCrumbs = false
}) => {
  return (
    <div className="c-article">
      {showBreadCrumbs && <PageTitle {...title} />}
      {mainImage && (
        <div className="c-article__topimage">
          <ResponsiveImage
            desktopImage={mainImage}
            mobileImage={mobileImage}
            caption={headline}
          />
        </div>
      )}

      {mainBody && (
        <div className="c-article__mainbody">
          <CenterElement>
            {!mainImage && <h2>{headline}</h2>}
            {mainBody && <RichText text={mainBody} />}
          </CenterElement>
        </div>
      )}

      <div className="c-article__content">
        <ContentBlocksArea blocks={blocks} />
      </div>
    </div>
  );
};

export default ArticleItem;
