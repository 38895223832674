import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';

import './Breadcrumbs.scss';

class Breadcrumbs extends Component {
  componentDidMount() {}
  render() {
    const {
      breadcrumbs: { current, parents },
      passedInCurrent
    } = this.props;
    return (
      <ul
        className="c-breadcrumbs"
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        {parents
          .filter(x => x)
          .map((page, index) => (
            <li
              key={index}
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <Link to={page.link.url} itemProp="item">
                <span itemProp="name">{page.name}</span>
              </Link>

              <meta itemProp="position" content={index} />
            </li>
          ))}
        {current && (
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link to={current && current.link.url} itemProp="item">
              <h1 itemProp="name">{current.name}</h1>
            </Link>

            <meta itemProp="position" content="2" />
          </li>
        )}
        {passedInCurrent && (
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <Link
              to={passedInCurrent && passedInCurrent.link.url}
              itemProp="item"
            >
              <h1 itemProp="name">{passedInCurrent.name}</h1>
            </Link>

            <meta itemProp="position" content="3" />
          </li>
        )}
      </ul>
    );
  }
}

const mapDispatchToProps = {};
const mapStateToProps = (state, props) => {
  return {
    breadcrumbs: breadCrumbSelector(state, props)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs)
);
