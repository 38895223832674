import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import ProductView from './ProductView';
import RecipeView from './RecipeView';
import ContentView from './ContentView';
import qs from 'query-string';
import { ToggleButton } from '../../../components/Buttons';

import './SearchResultPage.scss';
import CategoryFilter from '../../../components/CategoryFilter';
import {
  ExtendedSearchResult,
  TSearchFilters,
  TSearchFilterOption,
  TSearchFilter,
  TSearchPagination
} from '../../../types/search/searchResult';
import { SORT_OPTS } from '../../../pages/ProductCategoryPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentSearch,
  selectNewSearchResultLoading,
  setOpenSearch
} from '../../../slices/searchSlice';
import { BodyText } from '@citygross/typography';
import { Button, Spacer } from '@citygross/components';
import { selectAppSettingProductMarkings } from '../../../slices/appSlice';
import { INiceFilter } from '../../../types/esales';
import SearchMetaCompletions from '../../../modules/Search/SearchMetaCompletions';
import { markingFilterData } from '../../../components/Marking/MarkingFilterData';

const navList = {
  product: { order: 0, translation: 'Matvaror' },
  cateredmeal: { order: 2, translation: 'Catering' },
  recipe: { order: 3, translation: 'Recept' },
  category: { order: 4, translation: 'Kategorier' },
  store: { order: 5, translation: 'Butiker' },
  cmscontent: { order: 6, translation: 'Sidor' }
};

const filter_types = {
  marking: 'marking',
  cookingtime: 'cookingtime',
  brand: 'brand'
};

const initialOptionalFilters = {
  cookingtime: '',
  brand: '',
  marking: ''
};

type TNavListItems = {
  id: string;
  count: string;
  selected: boolean;
  listOrder?: number;
  prettyName?: string;
};

type TSearchResultPage = {
  searchString: string;
  increaseItemQuantity?: any;
  decreaseItemQuantity?: any;
  redirectToLogin?: any;
  addToCart?: any;
  setProductFavorite?: any;
  setRecipeFavorite?: any;
  isAuthenticated?: any;
  removeFavorite?: any;
  storeId?: number;
  fetchExtendedSearch?: any;
  fetchPaginatedSearch?: any;
  newSearch: ExtendedSearchResult;
  location: Location;
};

const SearchResultPage = ({
  searchString,
  increaseItemQuantity,
  decreaseItemQuantity,
  redirectToLogin,
  addToCart,
  setProductFavorite,
  setRecipeFavorite,
  isAuthenticated,
  removeFavorite,
  storeId,
  fetchExtendedSearch,
  fetchPaginatedSearch,
  newSearch,
  location
}: TSearchResultPage) => {
  const {
    product,
    recipe,
    cateredmeal,
    cmscontent,
    storepage,
    category,
    pagination,
    loading,
    activeFilter
  } = newSearch;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState<TSearchFilter[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<TSearchFilterOption[]>(
    []
  );
  const query = qs.parse(location.search + `&store=${storeId}`);

  const paginateParams = {
    page: query?.page ?? 0,
    store: storeId
  };
  const currentSearch = useSelector(selectCurrentSearch);
  const searchLoading = useSelector(selectNewSearchResultLoading);
  const appProductMarkings = useSelector(selectAppSettingProductMarkings);
  const [navState, setNavState] = useState<TNavListItems[]>();

  const makeFormattedNavList = (pagination: TSearchPagination) => {
    const newNavItems = Boolean(pagination?.filters) &&
      pagination?.filters?.type?.options?.length && [
        ...pagination?.filters?.type?.options
      ];

    let formattedNavItems: (TNavListItems & { listOrder: number })[] = [];
    if (newNavItems) {
      newNavItems?.forEach(item =>
        formattedNavItems.push({
          ...item,
          listOrder: navList[item.id]?.order,
          prettyName: navList[item.id]?.translation
        })
      );
      formattedNavItems.sort((a, b) => a?.listOrder - b?.listOrder);
    }
    return formattedNavItems;
  };

  useEffect(() => {
    setNavState(makeFormattedNavList(pagination));

    if (
      pagination?.filters &&
      JSON.stringify(navState) !==
        JSON.stringify(makeFormattedNavList(pagination))
    ) {
      const initFilters: TSearchFilterOption[] = [];
      Object.keys(pagination?.filters)?.forEach(key => {
        if (filter_types[key]) {
          pagination?.filters?.[key]?.options?.forEach(filter => {
            if (filter.selected) {
              initFilters.push({ ...filter, parent: key });
            }
          });
        }
      });
      setSelectedFilters(initFilters);
    }
  }, [currentSearch]);
  const getCountById = id => {
    if (newSearch?.pagination?.filters) {
      //This return in this way because we dont want to break legacy.
      const totalCount =
        newSearch.pagination.filters?.type?.options?.find(
          option => option.id === id
        )?.count ?? 0;
      return {
        totalCount,
        pageIndex: newSearch.pagination?.pageIndex ?? 0,
        pageCount: Math.ceil(
          Number(totalCount) / (newSearch.pagination?.pageSize ?? 0)
        )
      };
    }

    return {
      totalCount: 0,
      pageCount: 1
    };
  };

  const makeFilters = (filters: TSearchFilters) => {
    const newFilters: any = [];
    if (filters?.marking) {
      var markingFilterOptions = markingFilterData(
        appProductMarkings,
        filters.marking
      );

      var markingFilters: TSearchFilter = {
        id: filters.marking.id,
        options: markingFilterOptions
      };

      newFilters.push(markingFilters);
    }
    if (filters?.cookingtime) {
      newFilters.push(filters.cookingtime);
    }
    if (filters?.brand) {
      newFilters.push(filters.brand);
    }
    setFilters(newFilters);
  };

  useEffect(() => {
    if (!pagination?.filters) return;
    makeFilters(pagination.filters);
  }, [pagination.filters]);

  const filterString = (filters: TSearchFilterOption[], type: string) =>
    filters
      ?.filter(filterType => filterType.parent === type)
      ?.map(filter => filter.id)
      ?.join(',');

  const filterAction = (filter: TSearchFilterOption) => {
    let newFilters = [...selectedFilters];
    const [keyValue] = Object.entries(filter);
    const [filterKey, filterValue] = keyValue;

    const filterType = filters.find(filter => filter.id === filterKey);
    const validFilter = filterType?.options?.find(id => id.id === filterValue);
    if (validFilter) {
      const filterClicked: TSearchFilterOption = {
        ...validFilter,
        parent: filterKey
      };

      const filterSelected = selectedFilters.find(
        selected => selected.id === filterClicked.id
      );
      if (filterSelected) {
        newFilters = newFilters.filter(fil => fil.id !== filterClicked.id);
      } else {
        newFilters.push(filterClicked);
      }
    }
    setSelectedFilters(newFilters);
    const chosenFilters = {};
    Object.keys(filter_types).forEach(
      key => (chosenFilters[key] = filterString(newFilters, key))
    );

    if (pagination.sort) {
      chosenFilters['sort'] = pagination.sort;
    }
    fetchExtendedSearch({
      location: location,
      ...query,
      page: 0,
      store: storeId,
      ...chosenFilters,
      type: activeFilter
    });
  };
  const hasSuggestions =
    pagination?.completions && Boolean(pagination.completions.length);
  return (
    <div className="m-searchresultpage">
      <div className="m-searchresultpage__sidebar">
        {navState &&
          navState?.map(filter => {
            if (Number(filter?.count) > 0) {
              return (
                <div
                  key={filter.id}
                  className={cs('m-searchresultpage__navitem', {
                    active: filter.id === activeFilter
                  })}
                  onClick={() => {
                    setSelectedFilters([]);
                    fetchExtendedSearch({
                      location: location,
                      ...query,
                      page: 0,
                      store: storeId,
                      type: filter.id,
                      ...initialOptionalFilters
                    });
                  }}
                >
                  {' '}
                  {filter.prettyName} <span>({filter.count})</span>{' '}
                </div>
              );
            }
          })}
      </div>
      <div className="m-searchresultpage__content">
        <h1>Sökresultat för {`"${searchString}"`}</h1>
        {hasSuggestions ? (
          <div style={{ maxWidth: 'calc(100vw - 20px)' }}>
            <SearchMetaCompletions completions={pagination.completions} />
          </div>
        ) : null}

        <CategoryFilter
          id={''}
          filters={
            activeFilter === 'product' || activeFilter === 'recipe'
              ? filters
              : []
          }
          selectedFilters={selectedFilters}
          sortProducts={(id, params) => {
            document.cookie = `sortArticles=${params.Sort};path=/`;

            fetchExtendedSearch({
              location: location,
              ...query,
              page: 0,
              store: storeId,
              sort: params?.Sort
            });
          }}
          filterAction={filterAction}
          pagination={pagination}
          sortOptions={
            activeFilter === 'product' || activeFilter === 'recipe'
              ? SORT_OPTS
              : [SORT_OPTS[0]]
          }
        />

        <div className="m-searchresultpage__mobilenav">
          {navState &&
            navState?.map((filter, i) => {
              if (Number(filter.count) > 0) {
                return (
                  <ToggleButton
                    key={i}
                    active={filter.id === activeFilter}
                    onClick={() => {
                      setSelectedFilters([]);
                      fetchExtendedSearch({
                        location: location,
                        ...query,
                        page: 0,
                        store: storeId,
                        type: filter.id,
                        ...initialOptionalFilters
                      });
                    }}
                  >
                    {' '}
                    {filter.prettyName} ({filter.count}){' '}
                  </ToggleButton>
                );
              }
            })}
        </div>

        {!activeFilter && !searchLoading && (
          <div className="search-no_found__container">
            <BodyText textAlign="center">Inga resultat hittades</BodyText>
            <Spacer />
            <BodyText textAlign="center">
              testa en annan sökning eller rensa filter
            </BodyText>
            <Spacer />
            <div className="search-no_found__buttons">
              <Button
                size="medium"
                color={'secondary'}
                onClick={() => dispatch(setOpenSearch(true))}
              >
                <BodyText color="#fff">Sök</BodyText>
              </Button>
              <Button
                size="medium"
                color={'secondary'}
                onClick={() => {
                  setSelectedFilters([]);
                  fetchExtendedSearch({
                    location: location,
                    ...query,
                    page: 0,
                    store: storeId,
                    type: 'product',
                    ...initialOptionalFilters
                  });
                }}
              >
                <BodyText color="#fff">Rensa filter</BodyText>
              </Button>
            </div>
          </div>
        )}
        {activeFilter === 'product' && (
          <ProductView
            items={product}
            pagination={getCountById('product')}
            requestMoreProducts={page => {
              fetchPaginatedSearch({
                paginateParams: { page: page, store: storeId, type: 'product' },
                location
              });
            }}
            increaseItemQuantity={increaseItemQuantity}
            decreaseItemQuantity={decreaseItemQuantity}
            redirectToLogin={redirectToLogin}
            isAuthenticated={isAuthenticated}
            fetching={loading}
            addToCart={addToCart}
            setProductFavorite={setProductFavorite}
            removeFavorite={removeFavorite}
          />
        )}
        {activeFilter === 'cateredmeal' && (
          <ContentView
            items={cateredmeal.items}
            pagination={getCountById('cateredmeal')}
            requestMoreContent={page => {
              fetchPaginatedSearch({
                paginateParams: {
                  page: page,
                  store: storeId,
                  type: 'cateredmeal'
                },
                location
              });
            }}
          />
        )}
        {activeFilter === 'recipe' && (
          <RecipeView
            items={recipe}
            pagination={getCountById('recipe')}
            requestMoreRecipes={(_, params) => {
              fetchPaginatedSearch({
                paginateParams: {
                  page: params.page,
                  store: storeId,
                  type: 'recipe'
                },
                location
              });
            }}
            fetching={loading}
            isAuthenticated={isAuthenticated}
            setRecipeFavorite={setRecipeFavorite}
            removeFavorite={removeFavorite}
            redirectToLogin={redirectToLogin}
          />
        )}
        {activeFilter === 'category' && (
          <ContentView
            items={category.items}
            pagination={getCountById('category')}
            requestMoreContent={page => {
              fetchPaginatedSearch({
                paginateParams: {
                  page: page,
                  store: storeId,
                  type: 'category'
                },
                location
              });
            }}
          />
        )}
        {activeFilter === 'store' && (
          <ContentView
            items={storepage.items}
            pagination={getCountById('store')}
            requestMoreContent={page => {
              fetchPaginatedSearch({
                paginateParams: {
                  page: page,
                  store: storeId,
                  type: 'store'
                },
                location
              });
            }}
          />
        )}
        {activeFilter === 'cmscontent' && (
          <ContentView
            items={cmscontent}
            pagination={getCountById('cmscontent')}
            requestMoreContent={page => {
              fetchPaginatedSearch({
                paginateParams: {
                  page: page,
                  store: storeId,
                  type: 'cmscontent'
                },
                location
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default SearchResultPage;
