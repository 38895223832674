/* eslint-disable import/named */
import { createStore, applyMiddleware } from 'redux';
import { History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createDebounce from 'redux-debounced';
import { cartSync } from './middleware/cartSync';
import { analytics } from './middleware/analytics';
import { serviceWorker } from './middleware/serviceWorker';
import { flyoutMiddleware } from './middleware/flyout';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
// eslint-disable-next-line import/namespace
import { configureStore } from '@reduxjs/toolkit';

export default (history: History, initialState = {}) => {
  // Build the middleware for intercepting and dispatching navigation actions
  const historyMiddleware = routerMiddleware(history);
  const middlewares = [
    thunk,
    historyMiddleware,
    createDebounce(),
    cartSync,
    analytics,
    serviceWorker,
    flyoutMiddleware,
  ];
  // return createStore(
  //   rootReducer(history),
  //   initialState,
  //   composeWithDevTools(applyMiddleware(...middlewares))
  // );
  return configureStore({
    middleware: middlewares,
    reducer: rootReducer(history),
    preloadedState: initialState,
  });
};
