import React from 'react';
import RichText from '../../../containers/RichText';
import Image from '../../Image';
const PhoneIcon = require('../../assets/phone.svg');
const MailIcon = require('../../assets/mail.svg');

import './ContactCard.scss';

interface IProps {
  title: string,
  // Define which variants with union???
  icon?: string | null,
  phonenumber?: string | null,
  mailadress?: string | null
}

export const ContactCard : React.FC<IProps> = ({
  title,
  icon,
  phonenumber,
  mailadress,
  children
}) => {
  return (
    <div className="c-contactcard">
      <div className="c-contactcard__header">
        {icon && (
          <div className="c-contactcard__icon">
            <Image
              src={require(`../../assets/${icon}.svg`)}
              width={30}
              height={30}
              alt=""
            />
          </div>
        )}
        {title && title}
      </div>

      <RichText>{children}</RichText>

      <div className="c-contactcard__link-block">
        {phonenumber && (
          <a className="c-contactcard__link" href={`tel:${phonenumber}`}>
            <div className="c-contactcard__icon">
              <Image src={PhoneIcon} width={20} height={20} alt="" />
            </div>
            {phonenumber}
          </a>
        )}

        {mailadress && (
          <a className="c-contactcard__link" href={`mailto:${mailadress}`}>
            <div className="c-contactcard__icon">
              <Image src={MailIcon} width={20} height={20} alt="" />
            </div>
            {mailadress}
          </a>
        )}
      </div>
    </div>
  );
};

export default { ContactCard };
