import styled from 'styled-components'

type TDeliveryDetail = {
  extraLineMargin?: boolean
}
type TDeliveryStores = {
  maxHeight?: number
}

type TDeliverySaveButton = {
  position?: 'absolute' | 'sticky'
}

export const ChooseDeliveryMethodSideModalContainer = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;
  height: 100%;

  @media(max-width: 991px){
    position: relative ;
  }
`

export const IconWrapper = styled.div`
  background: ${props => props.theme.palette?.blueLight };
  padding: 24px;
  border-radius: 50%;
  text-align: center;
  width: 72px;
  height: 72px;
  margin-bottom: 8px;
`

export const DeliveryMethodOptionsWrapper = styled.div`
  width: 100%;
/* 100vh - header + copy + button - spacing */
  max-height: calc(100vh - 243px - 32px);
  min-height: 380px;
  overflow: auto ;
  overflow-x: hidden;
`

export const DeliveryMethodOptionsColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
`

export const ChooseStoreStepStoresListWrapper = styled.div<TDeliveryStores>`
  display: grid; 
  row-gap: 8px;
  /* modal header, input, spacer button */
  max-height: ${props =>  props.maxHeight ? `calc(${props.maxHeight} - 32px - 62px - 92px )` : '100vh'};
  padding-bottom: 60px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
  
  @media(min-width: 991px){
      /* modal header, input, spacers , button*/
    max-height: calc(100vh - 88px - 43px - 24px - 24px - 112px);

  }
`

export const DeliverySaveButtonContainer = styled.div<TDeliverySaveButton>`
  position: ${props => props.position ?? 'sticky'};
  bottom: 0;
  padding: 8px 0;

  width: 100%; 
  background: white;
  @media(min-width: 991px){
      padding: 32px 0;
      position: sticky;

    }
`

export const DeliveryDetail = styled.div<TDeliveryDetail>`
display:flex;
align-items:center;
${props => props.extraLineMargin && `
margin-bottom: 19px;
`}
margin-right: 16px;
> svg {
  margin-right: 4px;
  margin-bottom: 3px ;
}
`

export const StoreGrid = styled.div`
display: grid; 
row-gap: 8px;
width: 100%;

`
