import React from 'react';
import classNames from 'classnames';
import './Spinner.scss';

interface IProps {
  dark?: boolean,
  style?: React.CSSProperties
}

const Spinner : React.FC<IProps> = ({ dark, style }) => (
  <div className={classNames('c-spinner', { dark })} style={style}>
    <div className="c-spinner__left" />
    <div className="c-spinner__middle" />
    <div className="c-spinner__right" />
  </div>
);

export const CenteredSpinner : React.FC<IProps> = props => (
  <div className="center">
    <Spinner {...props} />
  </div>
);

export default Spinner;
