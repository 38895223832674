import {
  FormControl,
  Input,
  ProgressBar,
  SaveButton,
  Spacer
} from '@citygross/components';
import React, { useEffect, useState } from 'react';
import * as styles from './SideModalRegister.styles';
import { BodyText, TextTypes } from '@citygross/typography';
import { validateFormItem } from '../../Form/lib';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import LoginInput from '../../LoginInput/LoginInput';
import { LoginUserProps } from '../../../actions/auth';
import { connect, useDispatch } from 'react-redux';
// eslint-disable-next-line import/namespace
import { Redirect } from 'react-router';
import { setNewCustomer } from '../../../actions/customer';
import * as endpoints from '../../../api/endpoints/customer';
import { loginUser, allUserInfo } from '../../../actions/auth';
import { getUserOrders } from '../../../actions/order';
import { setCurrentRegisterStep } from '../../../slices/loginSlice';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { CustomerData, UserData } from '../../../types/customer/UserData';
import { AuthResponse } from '../../../types/auth/AuthResponse';
import { AssortmentCookie } from '../../../types/assortments';
import queryString from 'query-string';
import { registerSteps } from './SideModalRegister';
import RegisterErrorMessage from '../../Login/CreateAccount/ErrorMessage.js';
import { Icons } from '@citygross/icons';
import { useTheme } from 'styled-components';
import { ga4RegisterSignUp } from '../../../lib/analytics/analytics';

type TLoginEmail = {
  pnr: string;
  setPnr: React.Dispatch<React.SetStateAction<string>>;
  getCustomerById?: (
    customerId: string | number
  ) => (dispatch: Dispatch) => Promise<CustomerData>;
  setNewCustomer?: (
    customer: endpoints.NewCustomerData
  ) => (dispatch: Dispatch) => Promise<UserData>;
  clearCustomer?: () => void;
  clearError?: () => void;
  loginUser?: (
    creds: LoginUserProps
  ) => (dispatch: Dispatch) => Promise<AuthResponse>;
  allUserInfo?: (
    assortmentValues: AssortmentCookie
  ) => (dispatch: Dispatch, getState: any) => Promise<any>;
  customer?: any;
} & IAnimatedStepsChildProps;

type TPasswordStrength = {
  value: number;
  label: string;
};

const passwordStrength = {
  weak: 'Svag',
  medium: 'Medium',
  strong: 'Stark',
  veryStrong: 'Mycket Stark'
};

function testPassword(pwString): TPasswordStrength {
  var strength = 0;

  strength += /[A-Z]+/.test(pwString) ? 1 : 0;
  strength += /[a-z]+/.test(pwString) ? 1 : 0;
  strength += /[0-9]+/.test(pwString) ? 1 : 0;
  strength += /[\W]+/.test(pwString) ? 1 : 0;

  switch (strength) {
    case 2:
      return { value: 50, label: passwordStrength.medium };
    case 3:
      return { value: 70, label: passwordStrength.strong };
    case 4:
      return { value: 100, label: passwordStrength.veryStrong };
    default:
      return { value: 30, label: passwordStrength.weak };
  }
}

const initialForm = {
  emailAddress: '',
  mobilePhone: '',
  password: '',
  passwordVerify: ''
};

const RegisterCreate = ({ pnr, ...props }: TLoginEmail) => {
  const theme = useTheme();
  const { goToStep, stepKey, animating, customer } = props;
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialForm);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);

  const submit = async e => {
    e.preventDefault();
    setLoading(true);
    const emailValid = validateFormItem(form.emailAddress, {
      type: 'email',
      label: form?.emailAddress
    });
    if (!emailValid?.isValid) {
      setLoading(false);
      return;
    }
    const phoneValid = validateFormItem(form.mobilePhone, {
      type: 'mobilePhone',
      label: form?.mobilePhone
    });
    if (!phoneValid?.isValid) {
      setLoading(false);
      return;
    }

    const { emailAddress, password, mobilePhone } = form;

    let customer = {
      pnr: pnr,
      emailAddress: form?.emailAddress,
      password: form?.password,
      mobilePhone: form?.mobilePhone,
      referrer: ''
    };

    if (history.location.search.length > 0) {
      const qs = queryString.parse(history.location.search);
      if (qs) {
        const { utm_source, utm_medium, utm_campaign } = qs;
        if (
          utm_source === 'SMS' &&
          utm_medium === 'SMS' &&
          utm_campaign === 'registrering'
        ) {
          customer.referrer = 'sms';
        }
      }
    }
    try {
      const newCustomer: any = await dispatch(setNewCustomer(customer));
      if (newCustomer?.message) {
        setErrorCode(newCustomer?.message);
        ga4RegisterSignUp(false, newCustomer?.message);
        setLoading(false);
        return;
      }
      await dispatch(loginUser({ username: emailAddress, password }));
      await dispatch(
        // @ts-ignore
        allUserInfo()
      );
      await dispatch(getUserOrders());
      ga4RegisterSignUp(true);
      setCreateSuccess(true);
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  };

  if (history?.location?.search) {
    const qs = queryString.parse(history.location.search);
    if (qs) {
      const { prio } = qs;
      if (prio === 'sms') {
        return (
          <Redirect to="/registrera?utm_source=SMS&utm_medium=SMS&utm_campaign=registrering" />
        );
      }
    }
  }

  useEffect(() => {
    if (customer?.error) {
      setErrorCode('error');
      setLoading(false);
    }
  }, [customer?.error]);

  return (
    <styles.RegisterWrapper>
      <styles.RegisterInputContainer
        onSubmit={e => submit(e)}
        onChange={e => {
          setErrorCode(null);
          setForm({ ...form, [e.target.name]: e.target.value });
        }}
      >
        <styles.RegisterTitle>Mina kontouppgifter</styles.RegisterTitle>
        <styles.RegisterForm>
          <styles.RegisterInputGroup>
            <styles.RegisterInputLabelContainer>
              <styles.RegisterInputLabel>
                E-postadress*
              </styles.RegisterInputLabel>
              <styles.RegisterInputSpan>
                Används vid inloggning
              </styles.RegisterInputSpan>
            </styles.RegisterInputLabelContainer>
            <FormControl
              errorMessage={
                <BodyText color={'#E02721'}>
                  {
                    validateFormItem(form.emailAddress, {
                      type: 'email',
                      label: form?.emailAddress
                    })?.fallbackHint
                  }
                </BodyText>
              }
              isValid={
                form?.emailAddress?.length < 3
                  ? true
                  : validateFormItem(form.emailAddress, {
                      type: 'email',
                      label: form?.emailAddress
                    })?.isValid
              }
            >
              <LoginInput
                placeholder={'Ange E-post'}
                name={'emailAddress'}
                type={'email'}
                loading={animating}
                isValid={
                  form?.emailAddress?.length < 1
                    ? true
                    : validateFormItem(form.emailAddress, {
                        type: 'email',
                        label: form?.emailAddress
                      })?.isValid
                }
              />
            </FormControl>
          </styles.RegisterInputGroup>
          <styles.RegisterInputGroup>
            <styles.RegisterInputLabelContainer>
              <styles.RegisterInputLabel>
                Mobilnummer*
              </styles.RegisterInputLabel>
            </styles.RegisterInputLabelContainer>
            <FormControl
              errorMessage={
                <BodyText color={'#E02721'}>
                  {
                    validateFormItem(form.mobilePhone, {
                      type: 'mobilePhone',
                      label: form?.mobilePhone
                    })?.fallbackHint
                  }
                </BodyText>
              }
              isValid={
                form?.mobilePhone?.length < 3
                  ? true
                  : validateFormItem(form.mobilePhone, {
                      type: 'mobilePhone',
                      label: form?.mobilePhone
                    })?.isValid
              }
            >
              <Input
                placeholder={'Ex. 0709101012'}
                name={'mobilePhone'}
                type={'tel'}
                isValid={
                  form?.mobilePhone?.length < 1
                    ? true
                    : validateFormItem(form.mobilePhone, {
                        type: 'mobilePhone',
                        label: form?.mobilePhone
                      })?.isValid
                }
              />
            </FormControl>
          </styles.RegisterInputGroup>
          <styles.RegisterInputGroup>
            <styles.RegisterInputLabelContainer>
              <styles.RegisterInputLabel>Lösenord*</styles.RegisterInputLabel>
              <styles.RegisterInputSpan>
                Minst 8 tecken
              </styles.RegisterInputSpan>
            </styles.RegisterInputLabelContainer>
            <FormControl>
              <Input
                placeholder={'********'}
                name={'password'}
                type={showPassword ? 'text' : 'password'}
                isValid={
                  form?.password?.length < 1
                    ? true
                    : form?.password?.length >= 8 &&
                      form?.password === form?.passwordVerify
                }
                icon={<Icons.EyeOn />}
                iconOnClick={() => setShowPassword(!showPassword)}
              />
            </FormControl>

            {form?.password?.length >= 8 ? (
              <>
                <Spacer lgSpacing={'xxs'} xsSpacing={'xxs'} />
                <ProgressBar
                  maxValue={100}
                  currentValue={testPassword(form?.password)?.value}
                  background={'#F1F1F1'}
                  color={'#3F9A3C'}
                />
                <BodyText color={'#979797'}>
                  Lösenordsstyrka: {testPassword(form?.password)?.label}
                </BodyText>
              </>
            ) : null}
          </styles.RegisterInputGroup>
          <styles.RegisterInputGroup>
            <styles.RegisterInputLabelContainer>
              <styles.RegisterInputLabel>
                Repetera lösenord*
              </styles.RegisterInputLabel>
              <styles.RegisterInputSpan>
                Minst 8 tecken
              </styles.RegisterInputSpan>
            </styles.RegisterInputLabelContainer>
            <FormControl
              isValid={
                form?.password?.length
                  ? form?.password?.length > 7 &&
                    form?.password === form?.passwordVerify
                  : true
              }
              errorMessage={
                <BodyText color={'#E02721'}>Lösenorden matchar inte</BodyText>
              }
            >
              <Input
                placeholder={'********'}
                name={'passwordVerify'}
                type={showVerifyPassword ? 'text' : 'password'}
                isValid={
                  form?.password?.length < 1
                    ? true
                    : form?.password?.length >= 8 &&
                      form?.password === form?.passwordVerify
                }
                icon={<Icons.EyeOn />}
                iconOnClick={() => setShowVerifyPassword(!showVerifyPassword)}
              />
            </FormControl>
          </styles.RegisterInputGroup>
          <BodyText>* Obligatoriska fält</BodyText>
          <BodyText size={TextTypes.TextSize.SMALL} color={'#979797'}>
            Genom att skapa ett konto godkänner du City Gross villkor.{' '}
            <span>
              <styles.RegisterLink to={'/kundservice/integritetspolicy'}>
                Klicka här{' '}
              </styles.RegisterLink>
            </span>
            för att läsa om hur City Gross hanterar din personliga information.
          </BodyText>
          <Spacer lgSpacing={'xs'} xsSpacing={'xs'} />
          {errorCode && (
            <RegisterErrorMessage errorCode={errorCode} dontShowButton />
          )}
          <SaveButton
            color={theme.palette?.secondary}
            onSavedColor={theme.palette?.alertGreen}
            fullWidth
            onClick={submit}
            saved={createSuccess}
            onAnimationComplete={() => {
              goToStep &&
                (stepKey || stepKey === 0) &&
                goToStep(registerSteps.REGISTER_SUCCESS, stepKey);
              dispatch(
                setCurrentRegisterStep({
                  currentStep: registerSteps.REGISTER_SUCCESS,
                  previousStep: stepKey
                })
              );
            }}
            isDisabled={
              !validateFormItem(form.emailAddress, {
                type: 'email',
                label: form?.emailAddress
              })?.isValid ||
              !validateFormItem(form.mobilePhone, {
                type: 'mobilePhone',
                label: form?.mobilePhone
              })?.isValid ||
              form?.password?.length < 8 ||
              form?.passwordVerify?.length < 8 ||
              form?.password !== form?.passwordVerify
            }
            beforeSaveText={'Skapa konto'}
            afterSaveText={'Konto skapad!'}
            loading={loading && !createSuccess}
          />
        </styles.RegisterForm>
      </styles.RegisterInputContainer>
    </styles.RegisterWrapper>
  );
};
function mapStateToProps(state) {
  return {
    customer: state.customer
  };
}

export default connect(mapStateToProps)(RegisterCreate);
