import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PERSONAL_NUMBER_REGEX } from '../../../../lib/validator';

const PersonalNumberInput = ({
  id,
  label,
  hint,
  placeholder,
  required,
  formLoading,
  children,
  ...rest
}) => {
  const inputAttr = {};
  placeholder && (inputAttr.placeholder = placeholder);
  required && (inputAttr.required = 'required');

  return (
    <Fragment>
      <label htmlFor={id}>
        {label}
        {children}
        {hint && <span className="hint">{hint}</span>}
      </label>
      <input
        type="tel" // Use number keyboard
        pattern={PERSONAL_NUMBER_REGEX}
        id={id}
        name={id}
        readOnly={formLoading}
        {...inputAttr}
        {...rest}
      />
    </Fragment>
  );
};

PersonalNumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hint: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  formLoading: PropTypes.bool,
  children: PropTypes.any
};

export default PersonalNumberInput;
