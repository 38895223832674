import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';
import withBaseBlock from '../../components/BaseBlock';
import Login from '../../containers/Login';
import { ILoginBlockProps } from '../../types/episerver/blocks/LoginBlock';

interface IProps extends ILoginBlockProps {
  isAuthenticated: boolean
}

const LoginBlock : React.FC<IProps> = ({ isAuthenticated = false }) => {
  return !isAuthenticated ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px auto',
        width: '320px'
      }}
    >
      <Login />
    </div>
  ) : null;
};

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withBaseBlock(LoginBlock))
);
