import { RECIPE_FIELDS } from '../../lib/recipeCartItem';

export const normalizeRecipeProps = cartRecipe => {
  const {
    recipePrice,
    numberOfMeals,
    variantId,
    recipe,
    gtin,
    recipeId
  } = cartRecipe;
  // RECIPE_FIELDS
  const recipeLabels = [];
  Object.entries(cartRecipe).forEach(([key, val]) => {
    if (RECIPE_FIELDS.includes(key) && val) recipeLabels.push(key);
  });
  return {
    ...recipe,
    id: variantId,
    recipeLabels: recipeLabels,
    recipeId: recipeId,
    recipeEAN: gtin,
    totalPrice: recipePrice,
    quantity: numberOfMeals,
    type: cartRecipe.type,
    unit: recipe?.quantity?.unit
  };
};
