import {
  DISCOUNT_APPLY,
  DISCOUNT_SUCCESS,
  DISCOUNT_FAILED,
  DISCOUNT_RESET
} from '../../types/reducers/cart';
import makeReducer from '../makeReducer';

const initialState = {};

const ACTION_HANDLERS = {
  [DISCOUNT_APPLY]: state => {
    return {
      ...state,
      applying: true,
      failed: null,
      success: null
    };
  },
  [DISCOUNT_SUCCESS]: state => {
    return {
      ...state,
      failed: null,
      applying: null,
      success: true
    };
  },
  [DISCOUNT_FAILED]: (state, { payload }) => {
    return {
      ...state,
      failed: payload,
      applying: null,
      success: null
    };
  },
  [DISCOUNT_RESET]: state => {
    return {
      ...state,
      failed: null,
      applying: null,
      success: null
    };
  }
};

export default makeReducer(ACTION_HANDLERS, initialState);
