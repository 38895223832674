import React from 'react';
import { Box, CgButton, ECgButtonSize } from '@citygross/components';
import { theme } from '@citygross/design-tokens';
import cs from 'classnames';
import './Leaflet.scss';
import { useDispatch } from 'react-redux';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal';
import { TLeaflet } from '../../../types/offers/offers';

type TLeafletProps = {
  leaflet?: TLeaflet;
};

export const Leaflet = ({ leaflet }: TLeafletProps) => {
  const dispatch = useDispatch();

  return (
    <div className="leaflet-wrapper">
      <Box
        background={theme?.palette?.yellowLight}
        padding={16}
        rounded={false}
      >
        <div className="leaflet-container">
          <div
            className={cs('leaflet', {
              'store-selected': leaflet,
              'no-store-selected': !leaflet
            })}
          >
            <div className="upper">
              <div className="content">
                <span className="title">
                  {leaflet?.title ?? 'Veckans reklamblad'}
                </span>
                <span className="subtitle">
                  {leaflet?.subTitle ??
                    'Välj butik för att se veckans reklamblad'}
                </span>
              </div>
              <div className="image-container">
                <img src="/leaflet.png" alt="Leaflet" />
              </div>
            </div>

            <div className="button-container">
              {leaflet ? (
                <a
                  href={leaflet?.link}
                  rel="noopener noreferrer"
                  tabIndex={0}
                  target="_blank"
                >
                  <CgButton
                    shouldPreventDefault={false}
                    size={ECgButtonSize.small}
                  >
                    {leaflet.buttonText}
                  </CgButton>
                </a>
              ) : (
                <CgButton
                  onClick={() =>
                    dispatch(
                      openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER)
                    )
                  }
                  size={ECgButtonSize.large}
                >
                  Välj butik
                </CgButton>
              )}
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};
