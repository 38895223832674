import {
  FETCH_CATEREDMEAL_RECEIVED,
  FETCH_CATEGORY_CATEREDMEALS_REQUESTED,
  FETCH_CATEGORY_CATEREDMEALS_RECEIVED,
  FETCH_CATEGORY_CATEREDMEALS_FAILED,
  FETCH_CATEREDMEAL_FAILED
} from '../../types/reducers/catering';

const initialState = {
  category: [],
  products: []
};

const hardFetching = (state, action) => ({
  ...state,
  category: [],
  fetching: action.fetching
});

const error = (state, action) => ({
  ...state,
  error: action.error,
  fetching: false
});

export default function cateringReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action //AppActionTypes
) {
  switch (action.type) {
    case FETCH_CATEGORY_CATEREDMEALS_REQUESTED: {
      return hardFetching(state, action);
    }
    case FETCH_CATEGORY_CATEREDMEALS_FAILED: {
      return error(state, action);
    }
    case FETCH_CATEREDMEAL_FAILED: {
      return error(state, action);
    }
    case FETCH_CATEGORY_CATEREDMEALS_RECEIVED: {
      const { filters, ...pagination } = action.payload.meta;
      return {
        ...state,
        fetching: false,
        error: null,
        category: action.payload.products,
        filters,
        pagination: pagination
      };
    }
    case FETCH_CATEREDMEAL_RECEIVED: {
      return {
        ...state,
        error: null,
        fetching: false,
        products: state.products.concat(action.payload)
      };
    }
    default: {
      return state;
    }
  }
}
