import React from 'react';
import cs from 'classnames';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';

import './TabbedContent.scss';

const CGTabs: React.FC<any> = ({
  className = 'c-tabbedcontent--default',
  children,
  ...props
}) => {
  return (
    <Tabs className={cs('c-tabbedcontent', className)} {...props}>
      {children}
    </Tabs>
  );
};

export { Tab, TabList, TabPanel, CGTabs };
