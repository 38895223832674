import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeBlockRecipeEnhancerSelector } from '../../selectors/recipe/recipeSelectors';
import { authenticatedSelector } from '../../selectors/user';
import MediaQuery from 'react-responsive';
import cs from 'classnames';
import Grid from '../../components/Grid';
import Card from '../../components/Card';
import RecipeCard from '../../components/RecipeCard';
import Link from '../../components/Link';
import RecipeSpot from '../../contentblocks/RecipeSpot';
import { setRecipeFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import * as pagelayouts from '../../prop-types/PageTypePropType';
import './RecipeGrid.scss';
import ProductCarousel from '../../components/ProductCarousel';

const RecipeListCard = ({
  recipe,
  setRecipeFavorite,
  removeFavorite,
  impressionListName,
  impressionListPosition,
  isCarousel
}) => (
  <div
    className={cs({
      'b-recipeblock__card': !isCarousel,
      'b-recipeblock__carousel-card': isCarousel
    })}
  >
    <Card isCarousel={isCarousel}>
      <RecipeCard
        {...recipe}
        isCarousel={isCarousel}
        setRecipeFavorite={setRecipeFavorite}
        removeFavorite={removeFavorite}
        impressionListName={impressionListName}
        impressionListPosition={impressionListPosition}
      />
    </Card>
  </div>
);

RecipeListCard.propTypes = {
  recipe: PropTypes.object,
  setRecipeFavorite: PropTypes.func,
  removeFavorite: PropTypes.func
};

const RecipeList = ({ recipes, maxItems, cardFn, impressionListName }) =>
  recipes
    .slice(0, maxItems)
    .map((recipe, index) => (
      <RecipeListCard
        key={index}
        recipe={recipe}
        {...cardFn}
        impressionListName={impressionListName}
        impressionListPosition={index + 1}
      />
    ));

RecipeList.propTypes = {
  recipes: PropTypes.array,
  maxItems: PropTypes.number,
  cardFn: PropTypes.object
};

const RecipeGrid = ({
  title,
  fontColor = '#fff',
  link,
  linkText,
  recipes,
  SingleXimArticleProperties,
  redirectToLogin,
  numRows = 1,
  useGridForSingle = false,
  pageLayout = '',
  noPad = false,
  isAuthenticated,
  setRecipeFavorite,
  removeFavorite,
  impressionListName,
  isCarousel
}) => {
  const cardFn = {
    setRecipeFavorite: isAuthenticated ? setRecipeFavorite : redirectToLogin,
    removeFavorite: isAuthenticated ? removeFavorite : redirectToLogin
  };

  const carouselItems = useMemo(() => {
    return recipes.map((item, index) => (
      <RecipeListCard
        key={index}
        recipe={item}
        {...cardFn}
        impressionListName={impressionListName}
        impressionListPosition={index + 1}
        isCarousel
      />
    ));
  }, [recipes, isCarousel]);
  return (
    // if only one recipe it will render in a different component
    recipes.length === 1 && !useGridForSingle ? (
      <RecipeSpot recipe={recipes[0]} singleMeta={SingleXimArticleProperties} />
    ) : (
      <div
        className={cs(
          'b-recipeblock',
          { 'b-recipeblock--no-pad': noPad },
          pageLayout.toLowerCase()
        )}
        style={{ color: fontColor }}
      >
        {title && <h2>{title}</h2>}
        {isCarousel ? (
          <ProductCarousel productsLength={recipes.length} recipeCarousel>
            {carouselItems}
          </ProductCarousel>
        ) : (
          <Grid className="cards">
            <MediaQuery maxWidth={1099}>
              <RecipeList
                recipes={recipes}
                maxItems={numRows * 2}
                cardFn={cardFn}
                impressionListName={impressionListName}
              />
            </MediaQuery>
            <MediaQuery minWidth={1100} maxWidth={1299}>
              <RecipeList
                recipes={recipes}
                maxItems={numRows * 3}
                cardFn={cardFn}
                impressionListName={impressionListName}
              />
            </MediaQuery>
            {pageLayout === pagelayouts.SIDEBAR ? (
              <Fragment>
                <MediaQuery minWidth={1300} maxWidth={1529}>
                  <RecipeList
                    recipes={recipes}
                    maxItems={numRows * 3}
                    cardFn={cardFn}
                    impressionListName={impressionListName}
                  />
                </MediaQuery>
                <MediaQuery minWidth={1530}>
                  <RecipeList
                    recipes={recipes}
                    maxItems={numRows * 4}
                    cardFn={cardFn}
                    impressionListName={impressionListName}
                  />
                </MediaQuery>
              </Fragment>
            ) : (
              <Fragment>
                <MediaQuery minWidth={1300} maxWidth={1529}>
                  <RecipeList
                    recipes={recipes}
                    maxItems={numRows * 4}
                    cardFn={cardFn}
                    impressionListName={impressionListName}
                  />
                </MediaQuery>
                <MediaQuery minWidth={1530}>
                  <RecipeList
                    recipes={recipes}
                    maxItems={numRows * 5}
                    cardFn={cardFn}
                    impressionListName={impressionListName}
                  />
                </MediaQuery>
              </Fragment>
            )}
          </Grid>
        )}

        {link && linkText && (
          <div className="b-recipeblock__moreprods">
            <Link link={link}>{linkText}</Link>
          </div>
        )}
      </div>
    )
  );
};

RecipeGrid.propTypes = {
  title: PropTypes.string,
  fontColor: PropTypes.string,
  link: PropTypes.object,
  linkText: PropTypes.string,
  recipes: PropTypes.array,
  SingleXimArticleProperties: PropTypes.object,
  redirectToLogin: PropTypes.func,
  numRows: PropTypes.number,
  useGridForSingle: PropTypes.bool,
  pageLayout: PropTypes.string,
  noPad: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  setRecipeFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  isCarousel: PropTypes.bool
};

RecipeGrid.defaultProps = {
  fontColor: '#fff',
  numRows: 1,
  useGridForSingle: false,
  pageLayout: '',
  noPad: false
};

const mapStateToProps = (state, props) => ({
  recipes: makeBlockRecipeEnhancerSelector(state, props.recipes),
  isAuthenticated: authenticatedSelector(state)
});

const mapDispatchToProps = {
  redirectToLogin,
  setRecipeFavorite,
  removeFavorite
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecipeGrid)
);
