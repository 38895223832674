import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageIdSelector, pageSelector } from '../../selectors/page';
import { getPageById } from '../../actions/page';
import { setMode } from '../../actions/shoppingOrder';
import { UNTOGGLE_BEHAVIOUR } from '../../types/reducers/shoppingOrder';
import ArticleItem from '../../components/ArticleItem';
import LoadingBlock from '../../components/LoadingBlock';
import ErrorInfo from '../../components/ErrorInfo';

class ArticleContainer extends Component {
  state = {
    loading: false,
    error: false
  };

  componentDidMount() {
    const { article, setMode, id } = this.props;
    // Dont refetch startpage if already been reloaded
    if (article.id !== id) {
      this.getPageContent();
    }
    setMode(true, false, UNTOGGLE_BEHAVIOUR.none);
  }

  getPageContent = () => {
    const { getPageById, id } = this.props;

    this.setState({
      loading: true,
      error: false
    });

    getPageById(id)
      .catch(err => {
        console.error('getPageById failed', err);
        this.setState({
          error: true
        });
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  render() {
    const { article, title } = this.props;
    const { loading, error } = this.state;

    if (loading) {
      return <LoadingBlock />;
    }

    if (error) {
      return (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={this.getPageContent}
        />
      );
    }
    return (
      <ArticleItem {...article} title={title} blocks={article.contentBlocks} />
    );
  }
}

ArticleContainer.required = [
  (state, params, noCache) => {
    return getPageById(params.id, null, noCache);
  }
];

const mapStateToProps = (state, props) => ({
  title: breadCrumbSelector(state, props),
  article: pageSelector(state, props),
  id: pageIdSelector(state, props)
});

const mapDispatchToProps = {
  getPageById: getPageById,
  setMode
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArticleContainer)
);
