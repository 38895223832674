import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import { DEFAULT_RECIPEPAGE_SIZE } from '../../api/endpoints/recipe';
import { getPageById } from '../../actions/page';
import * as recipeActions from '../../actions/recipe';
import * as pageActions from '../../actions/page';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageIdSelector, pageSelector } from '../../selectors/page';
import { authenticatedSelector } from '../../selectors/user';
import {
  makeRecipeEnhancerSelector,
  selectedFiltersSelector,
  filtersCombinationSelector
} from '../../selectors/recipe/recipeSelectors';
import { setRecipeFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import RecipeCategory from '../../modules/RecipeCategory/RecipeCategory';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';

const SORT_OPTS = [
  {
    value: '',
    label: 'Popularitet'
  },
  {
    value: 'name',
    label: 'Namn A till Ö'
  },
  {
    value: 'nameDesc',
    label: 'Namn Ö till A'
  },
  {
    value: 'price',
    label: 'Lägsta pris'
  },
  {
    value: 'priceDesc',
    label: 'Högsta pris'
  }
];

const impressionListName = 'Recipe Category';

class RecipeCategoryPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorInfo: null,
      pageError: false
    };
  }

  componentDidMount() {
    const { id, page } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }

    this.getRecipes();
  }

  componentDidUpdate(prevProps) {
    const { storeId, recipes } = this.props;

    if (prevProps.storeId !== storeId) {
      this.getRecipes();
    }

    const prevPropRecipes = prevProps.recipes.reduce((acc, curr) => {
      return (acc += curr.id);
    }, '');

    const currRecipes = this.props.recipes.reduce((acc, curr) => {
      return (acc += curr.id);
    }, '');

    if (prevPropRecipes !== currRecipes && currRecipes.length > 0) {
      try {

        const ga4ImpressionItems = recipes.map((recipe, index) => {
          let recipePrice = 0;

          const preview = recipe.previews?.find(
            x => x.storeNumber == this.props.storeId
          );

          if (preview) recipePrice = preview.minimumPrice || 0;

          return {
            item_id: recipe.id,
            item_name: recipe.name,
            item_brand: recipe.source || 'City Gross',
            item_category:
              '/recept' +
                recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) ||
              undefined,
            item_variant: recipe.id,

            item_list_name: impressionListName,
            item_list_id: impressionListName,
            index: index + 1,

            price: recipePrice,
            quantity: 1,
            discount: 0
          };
        });

        ga4.viewItemList({
          items: ga4ImpressionItems,
          item_list_id: impressionListName,
          item_list_name: impressionListName
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  getRecipes = () => {
    const { id, location, storeId, getRecipesByCategory } = this.props;
    let requestQueries = qs.parse(location.search);

    const resumePageIndex =
      requestQueries.page && Math.max(0, parseInt(requestQueries.page) - 1);
 
      requestQueries = { ...requestQueries, size: DEFAULT_RECIPEPAGE_SIZE, page: resumePageIndex };
    

    if (storeId) {
      requestQueries.store = storeId;
    }

    const prodSort = document.cookie.match(/sortArticles=([^;]+)/);
    const recipePriceOnlySort = document.cookie.match(/sortPrices=([^;]+)/);
    if (
      !requestQueries.Sort &&
      prodSort &&
      SORT_OPTS.find(opt => opt.value === prodSort[1])
    ) {
      requestQueries = {
        ...requestQueries,
        Sort: prodSort[1],
        priceonly:
          recipePriceOnlySort &&
          recipePriceOnlySort.length > 0 &&
          recipePriceOnlySort[1]
      };
    }

    getRecipesByCategory(id, requestQueries).catch(({ data }) => {
      this.setState({
        error: true,
        errorBadCategory: !!(
          data &&
          data.length &&
          data.find(({ code }) => code === 'CATEGORY_NOT_CONFIGURED')
        )
      });
    });
  };

  getPageContent = () => {
    const { id, getPageById } = this.props;
    this.setState({
      pageError: false
    });
    getPageById(id).catch(err => {
      console.error('getPageById failed', err); // eslint-disable-line
      this.setState({
        pageError: true
      });
    });
  };

  render() {
    const { error, errorBadCategory, pageError } = this.state;

    return (
      <RecipeCategory
        {...this.props}
        error={error}
        errorBadCategory={errorBadCategory}
        pageError={pageError}
        getPageContent={this.getPageContent}
        getRecipes={this.getRecipes}
        sortOptions={SORT_OPTS}
        impressionListName={impressionListName}
      />
    );
  }
}

RecipeCategoryPage.required = [
  (_, params) => {
    return recipeActions.getRecipesByCategory(params.id);
  },
  (state, params, noCache) => {
    return pageActions.getPageById(
      params.id,
      state.router.location.search,
      noCache,
      params.userSiteId
    );
  }
];

const mapStateToProps = (state, props) => {
  const location = props.location;
  const recipeSelector = makeRecipeEnhancerSelector();

  return {
    storeId: selectCurrentAssortment(state),
    recipes: recipeSelector(state),
    selectedFilters: selectedFiltersSelector(state),
    filters: filtersCombinationSelector(state),
    pagination: state.recipe.pagination,
    title: breadCrumbSelector(state, props),
    id: pageIdSelector(state, props),
    page: pageSelector(state, props),
    fetching: state.recipe.fetching,
    location,
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {
  getRecipesByCategory: recipeActions.getRecipesByCategory,
  getPageById,
  filterRecipes: recipeActions.filterRecipes,
  sortRecipes: recipeActions.sortRecipes,
  requestMoreRecipes: recipeActions.requestMoreRecipes,
  redirectToLogin,
  setRecipeFavorite,
  removeFavorite
};

RecipeCategoryPage.propTypes = {
  getRecipesByCategory: PropTypes.func.isRequired,
  page: PropTypes.object,
  id: PropTypes.number,
  storeId: PropTypes.number,
  location: PropTypes.object,
  getPageById: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecipeCategoryPage)
);
