import * as endpoints from '../../api/endpoints/page';
import { isFetchingPage, receivePage, fetchPageError } from './sync';

export const getPageById = (pageId, search, noCache, userSiteId) => dispatch => {
  dispatch(isFetchingPage(true));
  return endpoints
    .fetchPageById(pageId, search, noCache, userSiteId)
    .then(({ data }) => {
      dispatch(receivePage(data));

      return data;
    })
    .catch(err => {
      dispatch(fetchPageError(err));

      return Promise.reject(err);
    });
};
