/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/display-name */

import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation, withRouter } from 'react-router-dom';
import {
  rootRoutesSpliterSelector,
  activeNavigationSelector
} from '../../../selectors/routing/routingSelector';
import { IconButton, Logo, MenuListHeader } from '@citygross/components';
import SideModalHeader from '../../SideModal/SideModalHeader';
// @ts-ignore
import LogoSmall from '../../Header/assets/logo_small.svg';
import { Icons } from '@citygross/icons';
import SideMenuItem from './SideMenuItem/SideMenuItem';
import * as styles from './SideModalMenu.styles';
import { fireCallbackOnEnter } from '../../../lib/utils';
import { authenticatedSelector } from '../../../selectors/user';
import { logoutUser } from '../../../actions/auth';
import { closeSideModal, openSideModal } from '../../../slices/sideModalSlice';
import { useTheme } from 'styled-components';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { Wizard } from '@citygross/react-use-bg-wizard';
import { BasicAnimatedStep } from '../AnimatedStep';
import { SideModalTabs } from '../../SideModal/SideModal';
import { ga4OpenRegister } from '../../../lib/analytics/analytics';
import { useWindowSize } from '@citygross/utils';
import { HEADER_MENU_BREAKPOINT } from '../../Header/Header.js';

const ConditionalLink = ({ children, to, condition }) =>
  !!condition && to ? (
    <NavLink to={to} exact={true}>
      {children}
    </NavLink>
  ) : (
    <>{children}</>
  );

type TSideModalMenu = {
  navigation?: any;
  links?: any;
  setInitialScroll: React.Dispatch<React.SetStateAction<boolean>>;
  initialScroll: boolean;
  closeModal?: () => void;
};

const SideModalMenu = (props: TSideModalMenu) => {
  //   @TODO - temporary local state, move to redux?
  const [category, setCategory] = useState<any>(null);
  const [isInitial, setIsInitial] = useState(true);
  const history = useHistory();
  const theme = useTheme();
  const isAuthenticated = useSelector(authenticatedSelector);
  const dispatch = useDispatch();

  const location = useLocation();
  const [activeLink, setActiveLink] = useState<HTMLSpanElement>();
  const [backClick, setBackClick] = useState(false);
  const { width } = useWindowSize();
  const measuredRef = useCallback((node: HTMLSpanElement) => {
    if (node !== null) {
      setActiveLink(node);
    }
  }, []);

  const matchParentCategoryWithPath = () => {
    const currentCategory = props?.navigation?.header
      ?.filter(link => link.type !== 'BagFolderPage')
      ?.find(headerItem => location.pathname?.includes(headerItem?.link?.url));
    setCategory(currentCategory);
  };

  useEffect(() => {
    if (backClick) {
      setBackClick(false);
      return;
    }
    if (width < HEADER_MENU_BREAKPOINT) {
      matchParentCategoryWithPath();
    }
  }, [location?.pathname]);

  useEffect(() => {
    if (activeLink && props.initialScroll) {
      activeLink?.scrollIntoView({ block: 'center' });
      props.setInitialScroll(false);
    }
  }, [activeLink]);

  const onLogoutClick = () => {
    dispatch(logoutUser());
    dispatch(closeSideModal());
  };

  const closeModalOnClick = () => {
    dispatch(closeSideModal());
  };

  return (
    <>
      <SideModalHeader
        closeSideModal={props.closeModal}
        leftElement={
          category ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setBackClick(true);
                history.push(category?.link?.url);
                setIsInitial(false);
                setCategory(null);
              }}
              tabIndex={0}
              onKeyDown={fireCallbackOnEnter(() => {
                setIsInitial(false);
                setCategory(null);
              })}
            >
              <IconButton
                borderRadius={100}
                color={'white'}
                height={40}
                width={40}
                onClick={closeSideModal}
                icon={<Icons.ArrowLeft height={20} width={20} />}
                noShadow
                hoverBackground={'#E8E8E8'}
              />
            </div>
          ) : (
            <NavLink to={'/'} exact={true} key={'logo'}>
              <Logo logoImage={<img src={LogoSmall} alt="logo" />} />
            </NavLink>
          )
        }
      />
      <styles.SideMenuContainer>
        <AnimatePresence>
          <Wizard>
            {!category && (
              <BasicAnimatedStep
                slideInDirection={isInitial ? 0 : category ? -1 : 1}
              >
                <styles.MainLinksWrapper>
                  {props?.navigation?.header
                    ?.filter(link => link.type !== 'BagFolderPage')
                    ?.map((navHeaderItem, i) => (
                      <ConditionalLink
                        to={navHeaderItem?.link?.url}
                        key={i}
                        condition={
                          !location.pathname?.includes(navHeaderItem?.link?.url)
                        }
                      >
                        <MenuListHeader
                          key={i}
                          onClick={() => {
                            setIsInitial(false);
                            setCategory(navHeaderItem);
                          }}
                          dropdownHeader={navHeaderItem?.name}
                          hoverBackground={theme.palette?.lighter}
                        />
                      </ConditionalLink>
                    ))}
                  <styles.MainLinkContainer>
                    <NavLink
                      to={'/matvaror/veckans-erbjudande'}
                      onClick={closeModalOnClick}
                    >
                      <styles.StyledMainLink>Erbjudanden</styles.StyledMainLink>
                    </NavLink>
                  </styles.MainLinkContainer>
                  <styles.MainLinkContainer>
                    <NavLink to={'/butiker'} onClick={closeModalOnClick}>
                      <styles.StyledMainLink>Butiker</styles.StyledMainLink>
                    </NavLink>
                  </styles.MainLinkContainer>
                </styles.MainLinksWrapper>
                <styles.SecondaryLinksContainer>
                  {/* @TODO: hard code links for now */}
                  {isAuthenticated ? (
                    <a href={'/mina-sidor'} onClick={closeModalOnClick}>
                      <styles.SecondaryLink>Mina Sidor</styles.SecondaryLink>
                    </a>
                  ) : (
                    <>
                      <NavLink
                        to={'/login'}
                        exact={true}
                        onClick={() =>
                          dispatch(openSideModal(SideModalTabs.LOGIN))
                        }
                      >
                        <styles.SecondaryLink>Logga in</styles.SecondaryLink>
                      </NavLink>
                      <NavLink
                        to={'/registrera'}
                        exact={true}
                        onClick={() => {
                          ga4OpenRegister();
                          dispatch(openSideModal(SideModalTabs.REGISTER));
                        }}
                      >
                        <styles.SecondaryLink>Skapa konto</styles.SecondaryLink>
                      </NavLink>
                    </>
                  )}
                  {props.links?.map(link => (
                    <NavLink
                      to={link?.link?.url}
                      exact={true}
                      key={link?.link?.url}
                      onClick={closeModalOnClick}
                    >
                      <styles.SecondaryLink>{link?.text}</styles.SecondaryLink>
                    </NavLink>
                  ))}
                  {/* om-city-gross */}
                  <NavLink
                    to={'/om-city-gross'}
                    exact={true}
                    onClick={closeModalOnClick}
                  >
                    <styles.SecondaryLink>Om City Gross</styles.SecondaryLink>
                  </NavLink>
                  {/* city-gross app */}
                  <NavLink
                    to={'/om-city-gross/city-gross-app'}
                    exact={true}
                    onClick={closeModalOnClick}
                  >
                    <styles.SecondaryLink>Ladda ner appen</styles.SecondaryLink>
                  </NavLink>
                  {isAuthenticated && (
                    <a href={'/'} onClick={e => onLogoutClick()}>
                      <styles.SecondaryLink>Logga ut</styles.SecondaryLink>
                    </a>
                  )}
                </styles.SecondaryLinksContainer>
              </BasicAnimatedStep>
            )}
            {category && (
              <BasicAnimatedStep
                slideInDirection={isInitial ? 0 : category ? -1 : 1}
              >
                <styles.MainLinksWrapper>
                  {category &&
                    [
                      {
                        name: `${
                          category?.name === 'Catering' ? 'All' : 'Alla'
                        } ${category?.name}`,
                        link: category?.link,
                        id: category?.id,
                        visible: true
                      },
                      ...category?.children
                    ]?.map(categoryItem => {
                      return (
                        <SideMenuItem
                          categoryItem={categoryItem}
                          key={categoryItem?.id}
                          activeRef={measuredRef}
                          closeModal={closeModalOnClick}
                        />
                      );
                    })}
                </styles.MainLinksWrapper>
              </BasicAnimatedStep>
            )}
          </Wizard>
        </AnimatePresence>
      </styles.SideMenuContainer>
    </>
  );
};

// @TODO move to slice logik
function mapStateToProps(state, props) {
  return {
    menuTabActive:
      state.assortments.menuTabActive | state.assortments.lastSelectedActive,

    navigation: {
      ...state.navigation,
      ...rootRoutesSpliterSelector(state),
      tree: activeNavigationSelector(state, props)
    },
    links: state.app.settings?.mobileNavigation
  };
}

export default withRouter(connect(mapStateToProps)(SideModalMenu));
