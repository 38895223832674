import React, { Component } from 'react';
import ScrollLock from '../ScrollLock';
import { forceCheck } from 'react-lazyload';
import cs from 'classnames';

import './Scrollable.scss';
export class Scrollable extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.scrollArea = props?.scrollRef || React.createRef();
  }
  // TODO: Remove when we have refactored cart/delivery tabs. Cart flyout is still in view even if not toggled
  // which makes lazyloading break on mobile-devices because images is "not in view" when rendered
  // therefor doesnt show when we toggle it either.
  checkTask() {
    forceCheck();
  }
  onScroll = e => {
    forceCheck();
    this.props.onScroll && this.props.onScroll(this.scrollArea);
  };
  onTouchStart = e => {
    if (this.props.isCart && e.target.nodeName === 'INPUT') {
      return;
    }
    this.scrollArea.current.focus();
  };
  componentDidMount() {
    this.intervalId = setInterval(this.checkTask.bind(this), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const {
      outerClassName,
      outerStyle,
      className,
      style,
      children
    } = this.props;
    return (
      <ScrollLock
        className={cs('scrollable', outerClassName)}
        style={outerStyle}
      >
        <div
          className={cs('scrollable__container', className)}
          style={{ ...style }}
          ref={this.scrollArea}
          // below is needed because react-lazyload bugs out on IOS/SAFARI when scrolling in container
          onScroll={this.onScroll}
          onTouchStart={this.onTouchStart}
        >
          {children}
        </div>
      </ScrollLock>
    );
  }
}

export default Scrollable;
