import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import Link from '../../../components/Link';

import './CategorySuggestions.scss';
import { ticketNotifierByClick } from '../../../api/endpoints/notify';

const CategorySuggestions = ({
  count,
  isSidebar,
  itemClickHandler,
  items,
  onClose,
  query,
  title,
  type
}) => {
  const showAllText = title === 'Innehåll' ? 'Visa allt' : 'Visa alla';
  return (
    <div
      className={cs('c-category-suggestions', {
        'c-category-suggestions--is-sidebar': isSidebar,
        'c-category-suggestions--is-main-area': !isSidebar
      })}
    >
      <Link to={`/sok?Q=${query}&type=${type}`} onClick={onClose}>
        <h3>
          <span className="c-category-suggestions__title">
            {title}
            {count >= 0 && (
              <span className={'c-category-suggestions__title--count'}>
                {' '}
                ({count})
              </span>
            )}
          </span>
        </h3>
      </Link>
      {items.length ? (
        <ul className={'c-category-suggestions__categorized-items'}>
          {items.slice(0, 5).map(item => (
            <li
              className={'c-category-suggestions__categorized-item'}
              key={item.id}
            >
              <Link 
                to={item.url} 
                onClick={() => {
                  itemClickHandler();
                  ticketNotifierByClick(item.ticket);
                }}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

CategorySuggestions.propTypes = {
  count: PropTypes.number,
  isSidebar: PropTypes.bool,
  itemClickHandler: PropTypes.func,
  items: PropTypes.array,
  onClose: PropTypes.func,
  query: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

export default CategorySuggestions;
