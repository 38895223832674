import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './MixCampaign.scss';

export const MixCampaign = ({ promotion, detailPage, asBadge }) => (
  <Fragment>
    {asBadge ? (
      <span className={'c-mix-campaign-badge'}>{`${promotion.label}`}</span>
    ) : (
      <div
        className={classNames('c-mix-campaign', {
          'c-mix-campaign--lower-position': detailPage
        })}
      >
        <div className={'c-mix-campaign__blue-box-arrow'} />
        <div className={'c-mix-campaign__text-wrapper'}>
          <p>{`${promotion.label}`}</p>
        </div>
      </div>
    )}
  </Fragment>
);

MixCampaign.defaultProps = {
  detailPage: false,
  asBadge: false
};

MixCampaign.propTypes = {
  asBadge: PropTypes.bool,
  detailPage: PropTypes.bool.isRequired,
  promotion: PropTypes.shape({
    label: PropTypes.string,
    effectAmount: PropTypes.number,
    numberOfItems: PropTypes.number
  })
};
