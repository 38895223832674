import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import ClockIcon from '../assets/clock.svg';
import FallbackImage from '../assets/recept-fallback.svg';
import { Button, FavoriteButton, CartButton } from '../Buttons';
import { Marking } from '../Marking';
import { ImageFitTypes, LazyProgressivePicture } from '../ProgressivePicture';
import { ticketNotifierByClick } from '../../api/endpoints/notify';
import './RecipeCard.scss';
import ToolTip from '../ToolTip';
import { formatRecipeUnit } from '../../lib/utils';
import * as ga4 from '@citygross/analytics';

export const RecipeCard = ({
  id,
  minimal,
  url,
  name,
  quantity,
  sellable,
  promotedSource,
  cookingTime,
  image,
  preview,
  source,
  markings,
  favorite,
  isFavoriteLoading,
  setRecipeFavorite,
  removeFavorite,
  portions,
  ticket,
  weightWatchersBluePoints,
  weightWatchersGreenPoints,
  weightWatchersPurplePoints,
  weightWatchersPoints,
  isCarousel,
  impressionListName,
  impressionListPosition
}) => {
  const onFavoriteClick = () => {
    if (favorite) {
      removeFavorite(favorite);
    } else {
      setRecipeFavorite(id, {
        name: name
      });
    }
  };

  const dividedPrice = preview?.fullySatisfied
    ? (preview.minimumPrice / quantity.value).toFixed(2)
    : null; // @TODO replace 4 with new value from PIM
  return (
    <Link
      to={{
        pathname: url,
        search: Boolean(portions) && '?portions=' + portions
        // state: { flyout: true, tight: false, nopadding: true, noheader: true }
      }}
      draggable={isCarousel ? false : true}
      className={`c-recipecard ${!minimal ? 'is-minimal' : ''}`}
      onClick={() => {
        ticketNotifierByClick(ticket);
        if (impressionListName && impressionListPosition) {
          try {
            const ga4SelectItem = {
              item_list_id: impressionListName,
              item_list_name: impressionListName,
              items: [
                {
                  item_id: id,
                  item_name: name,
                  item_brand: source || 'City Gross',
                  item_category:
                    '/recept' + url?.substring(0, url.lastIndexOf('/') + 1) ||
                    '',
                  item_list_name: impressionListName,
                  item_list_id: impressionListName,
                  index: impressionListPosition,

                  item_variant: id,
                  price: preview?.minimumPrice || undefined,
                  quantity: 1,
                  discount: 0
                }
              ]
            };

            ga4.selectItem(ga4SelectItem);
          } catch (error) {
            console.error(error);
          }
        }
      }}
    >
      <div data-productid={id}>
        <div className="actions">
          {setRecipeFavorite && (
            <FavoriteButton
              active={favorite}
              loading={isFavoriteLoading}
              handleClick={onFavoriteClick}
            />
          )}
          {preview?.fullySatisfied ? (
            <ToolTip label={'Köpbart recept'} alignment={'top'}>
              <CartButton active handleClick={onFavoriteClick} />
            </ToolTip>
          ) : null}
        </div>
        <div className="markings">
          {markings &&
            markings
              .slice(0, 5)
              .map(
                marking =>
                  marking.image && (
                    <Marking
                      altText={marking.altText}
                      code={marking.code}
                      key={marking.id}
                      imageUrl={marking.image.url}
                      imageAlt={marking.altText}
                    />
                  )
              )}
        </div>

        <div className="represent">
          {promotedSource && <div className="tag">{promotedSource}</div>}
          <LazyProgressivePicture
            largeImage={{
              ...image,
              url: (process.env.PUBLIC_URL || '') + image.url,
              width: 328,
              height: 230
            }}
            inheritHeight
            fitType={ImageFitTypes.Cover}
            maxWidth={{
              large: 481,
              small: 364
            }}
            placeholder={{
              image: FallbackImage,
              bgColor: 'transparent'
            }}
          />
          {weightWatchersPoints ? (
            <div className="smartpoints">
              <div
                className={`wwpoints ${
                  weightWatchersPoints.length === 1 ? 'singlepoint' : ''
                }`}
              >
                {weightWatchersPoints}
              </div>
            </div>
          ) : (
            (weightWatchersPurplePoints ||
              weightWatchersGreenPoints ||
              weightWatchersBluePoints) && (
              <div className="smartpoints">
                {weightWatchersGreenPoints && (
                  <div className="smartpoint" style={{ background: '#08514a' }}>
                    {weightWatchersGreenPoints}
                  </div>
                )}
                {weightWatchersBluePoints && (
                  <div className="smartpoint" style={{ background: '#0073d6' }}>
                    {weightWatchersBluePoints}
                  </div>
                )}
                {weightWatchersPurplePoints && (
                  <div className="smartpoint" style={{ background: '#7a1d5b' }}>
                    {weightWatchersPurplePoints}
                  </div>
                )}
              </div>
            )
          )}
        </div>
        <div className="infobar">
          {source && <div className="source">{source}</div>}

          {cookingTime && (
            <div className="time">
              <img
                src={ClockIcon}
                width="15"
                height="15"
                alt="tillagningstid"
              />
              <span className="text">{`${cookingTime} min`}</span>
            </div>
          )}
        </div>

        <div className="name">{name}</div>
        {!minimal && (
          <div className="meta">
            {sellable && preview?.fullySatisfied && (
              <React.Fragment>
                <div className="description">Från ca:</div>
                <div className="price">
                  {`${dividedPrice} kr /${formatRecipeUnit(quantity.unit)}`}
                </div>
              </React.Fragment>
            )}
            <div className="cartactions">
              <div className="addtocart active">
                <Button>Se recept</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

RecipeCard.propTypes = {
  id: PropTypes.string,
  minimal: PropTypes.bool,
  url: PropTypes.string,
  name: PropTypes.string,
  sellable: PropTypes.bool,
  promotedSource: PropTypes.string,
  cookingTime: PropTypes.number,
  image: PropTypes.object,
  price: PropTypes.oneOf(PropTypes.number, PropTypes.oneOf('')), // We sometimes get empty strings
  source: PropTypes.string,
  markings: PropTypes.array,
  favorite: PropTypes.bool,
  isFavoriteLoading: PropTypes.bool,
  setRecipeFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  portions: PropTypes.number,
  ticket: PropTypes.string
};

export default RecipeCard;
