import { request } from '../../lib/request';
import qs from 'query-string';
import { domSafe } from '../../lib/document';
import { ISettingsResponse } from '../../types/endpoints/App';

export const fetchSettings = (noCache?: boolean): Promise<ISettingsResponse> => {
  if (domSafe() && qs.parse(window.location.search).noCache || noCache) {
    return request(`/settings?nocache=true`);
  }

  return request('/settings');
};

export const submitForm = (body, reCaptcha) =>
  request(`/formresponse?g-recaptcha-response=${reCaptcha}`, {
    method: 'POST',
    body: JSON.stringify(body)
  });
