import React from 'react';

interface INotFound {
  name: string,
  type: string
}

export const NotFound : React.FC<INotFound> = ({ name, type }) => (
  <div>
    <code>{`Component ${name} of ${type && 'type: ' + type} not found`}</code>
  </div>
);

export default NotFound;
