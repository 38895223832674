export const InformationText =
  'Vi reserverar oss för att mindre förändringar av produktens innehålls- eller näringsförteckning kan ske. Kontrollera därför alltid produktinformation på förpackningen.';

export const LoginNewsBoxText = {
  title: 'Nyhet!',
  body:
    'Nu kan du välja hur du vill att vi ska hantera om en vara är slut eller om du har speciella önskemål i kassan. Tillåt en eller flera varor ersätts genom att trycka på knappen ändra val i varukorgen'
};

export const PromotionText = (value: string, pickingFeeLimit: string) =>
  `Fri plockavgift gäller alltid vid köp av varor över ${pickingFeeLimit} kr, värde från ${
    value ? value : 49
  } kr.`;

export const PickingFeeText = (pickingFeeLimit: string) => `
  Handla varor för mer än ${pickingFeeLimit} kr så bjuder vi på plockavgiften.
  `;
export const NoZipCodeFound =
  'Tyvärr gick det inte att söka efter ditt valda postnummer. Vänligen försök igen eller kontakta kundtjänst';

export const FreePickingFee = 'Fri plockavgift!';
export const PickingFeeLimit = 'Till fri plockavgift';
export const WeightReservation =
  'Vid beställning reserverar vi tillfälligt 10% av varuvärdet för viktvaror och antal kassar. När dina varor har packats justeras beloppet. Du betalar bara för det du får levererat.';
