import React from 'react';
import ReactDOM from 'react-dom';
import Flyout from './';
import { OverlayPortal } from '../../components/Overlay';
import { ConfirmModalPortal } from '../../components/ConfirmModal';

/* HOC for creating a component wrapped in a Flyout
 * The flyout will create a portal to the provided DOM ref
 */

export const withFlyoutPortal = (FlyoutComponent = Flyout) => {
  const WithFlyoutPortalComponent = ({ flyoutRef, ...props }) =>
    ReactDOM.createPortal(<FlyoutComponent show {...props} />, flyoutRef);

  return WithFlyoutPortalComponent;
};

export const withCloseableOverlay = FlyoutComponent => {
  const WithCloseableOverlayComponent = ({
    overlayRef,
    onClose,
    showOverlay,
    ...props
  }) => (
    <React.Fragment>
      <FlyoutComponent {...props} onCloseClicked={onClose} />
      <OverlayPortal
        overlayRef={overlayRef}
        show={showOverlay === true}
        includeHeader
        onClick={onClose}
      />
    </React.Fragment>
  );

  return WithCloseableOverlayComponent;
};

export const withConfirmModal = FlyoutComponent => {
  const WithConfirmModalComponent = ({
    confirmModalRef,
    modalProps,
    ...props
  }) => {
    // Maybe we should no override cancel click but instead add callbacks (will we ever not want to call the back()?)
    if (!modalProps.onCancelClick) {
      modalProps.onCancelClick = props.back;
    }

    return (
      <React.Fragment>
        <FlyoutComponent {...props} />
        <ConfirmModalPortal confirmModalRef={confirmModalRef} {...modalProps} />
      </React.Fragment>
    );
  };

  return WithConfirmModalComponent;
};
