import FullWidthPage from './FullWidthPage';
import ProductFolderPage from './ProductFolderPage';
import ProductCategoryPage from './ProductCategoryPage';
import ProductSinglePage from './ProductSinglePage';
import RecipeFolderPage from './RecipeFolderPage';
import RecipeCategoryPage from './RecipeCategoryPage';
import RecipeSinglePage from './RecipeSinglePage';
// import BagFolderPage from './BagFolderPage';
// import BagCategoryPage from './BagCategoryPage';
// import BagPage from './BagSinglePage';
import StorePage from './StorePage';
import StoreListPage from './StoreListPage';
import ArticlePage from './ArticlePage';
import DeliveryDistrictListPage from './DeliveryDistrictListPage';
import ContentForCategoryPage from './ContentForCategoryPage';

import BlockPreviewPage from './BlockPreviewPage';

// User
import MyBoughtProductsPageRedirect from './MyBoughtProductsPageRedirect';
import MyFavoriteRecipesPageRedirect from './MyFavoriteRecipesPageRedirect';
import MyFavoriteArticlesPageRedirect from './MyFavoriteArticlesPageRedirect';
import LoginPage from './LoginPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import RegisterPage from './RegisterPage';
import CustomerServicePage from './CustomerServicePage';
import SearchPage from './SearchPage';

import TopSellersCategoryPage from './TopSellersCategoryPage';
import CateringCategoryPage from './CateringCategoryPage';
import CateringFolderPage from './CateringFolderPage';
import CateringSinglePage from './CateringSinglePage';

import DefaultLayout from '../containers/Layouts/DefaultLayout';
import { SidebarLayout } from '../components/Layouts/SidebarLayout';
import OfferFolderPage from './OfferFolderPage';
import OfferSinglePage from './OfferSinglePage';

const config = {
  LoginPage: {
    layout: DefaultLayout,
    component: LoginPage,
    opts: {
      className: 'no-nav'
    }
  },
  LostPasswordPage: {
    layout: DefaultLayout,
    component: ForgotPasswordPage,
    opts: { className: 'no-cart' }
  },
  RegisterPage: {
    layout: DefaultLayout,
    component: RegisterPage,
    opts: {
      className: 'no-nav'
    }
  },
  FullWidthPage: {
    layout: DefaultLayout,
    component: FullWidthPage,
    opts: {
      className: 'no-nav',
      thinFooter: true
    }
  },
  OfferPage: {
    layout: SidebarLayout,
    component: OfferFolderPage,
    opts: { hasSidebar: true }
  },
  OfferSinglePage: {
    layout: SidebarLayout,
    component: OfferSinglePage,
    opts: { hasSidebar: true }
  },
  ProductFolderPage: {
    layout: SidebarLayout,
    component: ProductFolderPage,
    opts: { hasSidebar: true }
  },
  ProductCategoryPage: {
    layout: SidebarLayout,
    component: ProductCategoryPage,
    opts: {
      hasSidebar: true,
      hasFilters: true
    }
  },
  ProductSinglePage: {
    layout: SidebarLayout,
    component: ProductSinglePage,
    opts: { hasSidebar: true }
  },
  CateringFolderPage: {
    layout: SidebarLayout,
    component: CateringFolderPage,
    opts: {
      hasSidebar: true,
      hasFilters: false
    }
  },
  CateringCategoryPage: {
    layout: SidebarLayout,
    component: CateringCategoryPage,
    opts: {
      hasSidebar: true,
      hasFilters: false
    }
  },
  CateringSinglePage: {
    layout: SidebarLayout,
    component: CateringSinglePage,
    opts: { hasSidebar: true }
  },
  TopSellersCategoryPage: {
    layout: SidebarLayout,
    component: TopSellersCategoryPage,
    opts: {
      hasSidebar: true,
      hasFilters: false
    }
  },
  RecipeFolderPage: {
    layout: SidebarLayout,
    component: RecipeFolderPage,
    opts: { hasSidebar: true }
  },
  RecipeCategoryPage: {
    layout: SidebarLayout,
    component: RecipeCategoryPage,
    opts: {
      hasSidebar: true,
      hasFilters: true
    }
  },
  RecipeSinglePage: {
    layout: SidebarLayout,
    component: RecipeSinglePage,
    opts: { hasSidebar: true }
  },
  BagFolderPage: {
    layout: SidebarLayout,
    component: ProductFolderPage, // @TODO, remove when this BagFolderPage no longer used in cms
    opts: { hasSidebar: true }
  },
  BagCategoryPage: {
    layout: SidebarLayout,
    component: ProductFolderPage, // @TODO, remove when this BagFolderPage no longer used in cms
    opts: {
      hasSidebar: true,
      hasFilters: true
    }
  },
  BagPage: {
    layout: SidebarLayout,
    component: ProductFolderPage, // @TODO, remove when this BagFolderPage no longer used in cms
    opts: { hasSidebar: true }
  },
  ContentForCategoryPage: {
    layout: SidebarLayout,
    component: ContentForCategoryPage,
    opts: { hasSidebar: true }
  },
  StorePage: {
    layout: DefaultLayout,
    component: StorePage,
    opts: {
      className: 'no-nav',
      thinFooter: true
    }
  },
  StoreListPage: {
    layout: DefaultLayout,
    component: StoreListPage,
    opts: {
      className: 'no-nav',
      thinFooter: true
    }
  },
  ArticlePage: {
    layout: SidebarLayout,
    component: ArticlePage,
    opts: {
      hasSidebar: true,
      hasFilters: false,
      className: 'no-cart'
    }
  },
  CustomerServicePage: {
    layout: SidebarLayout,
    component: CustomerServicePage,
    opts: { hasSidebar: true }
  },
  DeliveryDistrictListPage: {
    layout: DefaultLayout,
    component: DeliveryDistrictListPage,
    opts: {}
  },
  BlockPreviewPage: {
    layout: DefaultLayout,
    component: BlockPreviewPage,
    opts: {}
  },
  SearchPage: {
    layout: DefaultLayout,
    component: SearchPage,
    opts: {
      wideWithCart: true,
      thinFooter: true,
      className: 'no-nav',
      narrowFooter: true
    }
  },

  // Redirects
  MyFavoriteArticlesPageRedirect: {
    layout: SidebarLayout,
    component: MyFavoriteArticlesPageRedirect,
    isProtected: true,
    opts: { hasSidebar: true }
  },
  MyFavoriteRecipesPageRedirect: {
    layout: SidebarLayout,
    component: MyFavoriteRecipesPageRedirect,
    isProtected: true,
    opts: { hasSidebar: true }
  },
  MyBoughtProductsPageRedirect: {
    layout: SidebarLayout,
    component: MyBoughtProductsPageRedirect,
    isProtected: true,
    opts: { hasSidebar: true }
  }
};

export default config;
