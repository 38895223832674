import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOffers,
  selectFetchingOffers,
  selectOfferFilters,
  selectOffers,
  selectOffersCategories
} from '../../../slices/offersSlice';
import { Pill, SelectButton, Spinner } from '@citygross/components';
import { getDay } from 'date-fns';
import CardGrid from '../../../components/CardGrid';
import { selectCurrentAssortment } from '../../../selectors/assortments';
import { formatOfferAvailabilityDate, offerWeek } from '../../../lib/offerInfo';
import { selectDayToShowWeekToggle } from '../../../slices/offersWeekToggleSlice';
import OfferCardList from '../../OfferCardList';
import './OfferListingStoreSelected.scss';

export const OfferListingStoreSelected = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const dispatch = useDispatch();
  const store = useSelector(selectCurrentAssortment);
  const offers = useSelector(selectOffers);
  const loading = useSelector(selectFetchingOffers);
  const offerCategories = useSelector(selectOffersCategories);
  const filters = useSelector(selectOfferFilters);

  const dayToShowWeekToggle = useSelector(selectDayToShowWeekToggle);
  const today = getDay(new Date());
  const showWeekToggle = today === Number(dayToShowWeekToggle);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const nextWeekOfferParam = searchParams.get(offerWeek.next) === 'true';

    const nextWeek =
      (showWeekToggle && nextWeekOfferParam) || filters?.nextWeek;

    dispatch(getOffers({ nextWeek }));
  }, [store]);

  const handleWeekToggle = (nextWeek: boolean) => {
    setSelectedCategory(null);
    dispatch(getOffers({ nextWeek }));
  };

  return (
    <div className="offer-listing">
      {showWeekToggle && (
        <div>
          <SelectButton
            items={[
              {
                label: 'Denna vecka',
                onClick: () => handleWeekToggle(false)
              },
              {
                label: 'Nästa vecka',
                onClick: () => handleWeekToggle(true)
              }
            ]}
            selected={filters?.nextWeek ? 1 : 0}
            width={166}
          />
        </div>
      )}

      {loading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <div className="offer-dates-container">
            Gäller {formatOfferAvailabilityDate(offers?.[0]?.from)} till{' '}
            {formatOfferAvailabilityDate(offers?.[0]?.to, true)}
          </div>

          <div className="filter-container">
            <Pill
              active={!selectedCategory}
              onClick={() => setSelectedCategory(null)}
              text="Alla"
            />

            {/* eslint-disable-next-line react/display-name */}
            {offerCategories?.map(category => (
              <Pill
                active={selectedCategory === category.key}
                amount={Number(category.doc_count)}
                key={category.key}
                onClick={() =>
                  setSelectedCategory(
                    selectedCategory === category.key ? null : category.key
                  )
                }
                text={category.key}
              />
            ))}
          </div>

          <CardGrid
            noPad
            renderItem={maxItems => (
              <OfferCardList
                allRows
                loading={loading}
                maxItems={2 * maxItems}
                offers={
                  !selectedCategory
                    ? offers
                    : offers.filter(offer =>
                        offer.categories.some(
                          category => selectedCategory === category
                        )
                      )
                }
              />
            )}
          />
        </Fragment>
      )}
    </div>
  );
};
