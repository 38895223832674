import {
  Button,
  Link as CgLink,
  Spacer,
  ZipCodeInput
} from '@citygross/components';
import { BodyText } from '@citygross/typography';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchPostalcodeByZip } from '../../../../api/endpoints/delivery';
import { NoZipCodeFound } from '../../../../constants/InformationText';
import {
  selectActiveDeliveryMethod,
  selectActivePostalCode,
  selectInitialLoading,
  setActiveDeliveryMethodAndStore,
  setActivePostalCode
} from '../../../../slices/deliveryPickerSlice';
import {
  DeliveryMethods,
  deliveryPickerSteps
} from '../SideModalDeliveryChoicePicker';

const EnterPostalCode = ({ ...props }) => {
  const { goToStep, stepKey } = props;

  const dispatch = useDispatch();

  const activeDeliveryMethod = useSelector(selectActiveDeliveryMethod);
  const activePostalCode = useSelector(selectActivePostalCode);
  const initialLoading = useSelector(selectInitialLoading);
  const [verified, setVerified] = useState(false);
  const [zipCodeInput, setZipCodeInput] = useState(activePostalCode);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (zipCodeInput === activePostalCode) {
      goToStep(deliveryPickerSteps.CHOOSE_DELIVERY_METHOD, stepKey);
      return;
    }
    setLoading(true);
    try {
      const validPostalCode = await fetchPostalcodeByZip(zipCodeInput);
      if (!validPostalCode.data) {
        toast.error(NoZipCodeFound);
        setLoading(false);
        return;
      }

      dispatch(setActivePostalCode(zipCodeInput));
      if (activeDeliveryMethod === DeliveryMethods.HOME_DELIVERY)
        dispatch(
          setActiveDeliveryMethodAndStore({
            activeDeliveryMethod: null,
            activeStore: null
          })
        );
      setLoading(false);

      goToStep(deliveryPickerSteps.CHOOSE_DELIVERY_METHOD, stepKey);
    } catch (error) {
      console.error(error);
      toast.error(NoZipCodeFound);
      setLoading(false);
    }
  };

  useEffect(() => {
    const enterListener = (e: KeyboardEvent) => {
      if (zipCodeInput?.length === 5 && e.key === 'Enter') {
        handleSubmit();
      }
    };
    window.addEventListener('keydown', enterListener);
    return () => window.removeEventListener('keydown', enterListener);
  }, [zipCodeInput]);

  return (
    <>
      <div style={{ padding: '0 8px' }}>
        <BodyText textAlign={'center'}>
          Fyll i ditt postnummer för att se leveransalternativ för ditt
          närområde
        </BodyText>
        <Spacer />
        <div style={{ width: '100%' }}>
          <ZipCodeInput
            setZipCode={setZipCodeInput}
            verified={verified}
            setVerified={setVerified}
          />
        </div>
        <Spacer />
        <Button
          fullWidth
          color="secondary"
          isDisabled={!verified || initialLoading}
          onClick={handleSubmit}
          loading={loading}
        >
          Sök
        </Button>
        <Spacer />
        <BodyText textAlign={'center'}>
          Eller, välj att{' '}
          <CgLink
            onClick={() => {
              goToStep(deliveryPickerSteps.SHOW_ALL_STORES, stepKey);
            }}
            isDisabled={initialLoading}
          >
            visa alla butiker
          </CgLink>
        </BodyText>
      </div>
    </>
  );
};

export default EnterPostalCode;
